import React from 'react';
import 'react-quill/dist/quill.snow.css';
import Select from 'react-select';
import Overlay from '../Overlay';
import { getBoolSelectOptions, getBoolSelectVal, getNumPages, getPageOptions, getServiceTypeLabel, getSpecificServiceType, hideModal, parseDate, showModalNoOutsideClick, slicePages } from '../../util/FormatUtil';
import { sweetalert } from '../../App';
import TestsAPI from '../../network/TestsAPI';
import SystemAPI from '../../network/SystemAPI';
import FilterCard, { FIELD_TYPE, ReactSelect } from '../FilterCard';
import NetworkUtil from '../../network/NetworkUtil';
import { Column } from '../tables/TableBase';
import { Sorter, TableOrder } from '../../sorting/Sorter';
import SimpleTable from '../tables/SimpleTable';
import InBetweenOverlay from '../InBetweenOverlay';
import RecordType from '../../types/RecordType';
import RecordsAPI from '../../network/RecordsAPI';
import CustomDate from '../form/CustomDate';
import EmployeeAPI from '../../network/EmployeeAPI';
import EventAPI from '../../network/EventAPI';
import { EventSchedForSelect } from '../../types/Event';
import { FacilityAPI } from '../../network/FacilityAPI';
import ServicesAPI from '../../network/ServicesAPI';
import { RecordsModal } from '../modals/RecordsModal';
import CustomFieldsAPI from '../../network/CustomFieldsAPI';

const ITEMS_PER_PAGE = 25;

interface RecordsState {
  selectedRecord?;
  records: RecordType[];
  showLoading?;
  showInBetween?;
  filter?: {
    EmployeeUID: null;
    ReqNum: null;
    LinkedApptsID: null;
    AppointmentDate: null;
    ApptCanceled: null;
    CheckedInDate: null;
  };
  filterTwo?:{
    FacilityID: null;
    ServiceID: null;
    ServiceType: null;
    ServiceTypeSpecificID: null;
    EventApptID: null;
  }
  tableData: {}[];
  direction?: TableOrder;
  page_options: ReactSelect[];
  selected_page?: { label; value };
  recordsQueryResults: RecordType[];
  selectedEmployeeLabel?;
  services?;
  serviceTypes?;
  serviceSpecificTypes?;
  events?;
  facilities?;
  employees?;
  vaccines?;
  evaluations?;
  tests?;
  customQuestions?;
}

export default class Records extends React.Component<any, RecordsState> {
  constructor(props) {
    super(props);
    this.state = {
      showLoading: false,
      showInBetween: false,
      selectedRecord: {} as RecordType,
      records: [],
      serviceTypes: [],
      serviceSpecificTypes: [],
      events: [],
      facilities: [],
      services: [],
      employees: [],
      vaccines: [],
      evaluations: [],
      tests: [],
      filter: {
        EmployeeUID: null,
        ReqNum: null,
        LinkedApptsID: null,
        AppointmentDate: null,
        ApptCanceled: null,
        CheckedInDate: null
      },
      filterTwo: {
        FacilityID: null,
        ServiceID: null,
        ServiceType: null,
        ServiceTypeSpecificID: null,
        EventApptID: null,
      },
      tableData: [] as any,
      direction: 'asc',
      page_options: [{ label: '1', value: '1' }],
      selected_page: { label: '1', value: 1 },
      recordsQueryResults: [] as any,
    };
    this.editRecord = this.editRecord.bind(this);
  }

  componentDidMount() {
    document.title = 'Records';
    this.setState({ showLoading: true }, () => {
        EmployeeAPI.getEmployeesFromEmployeePortal().then(data => {
            this.setState({ employees: data.employees });
        });
        EventAPI.getAllEvents(true, false).then(response => {
            this.setState({events: response.data as { label: string; value: EventSchedForSelect }[]});
        });
        FacilityAPI.getFacilitiesForSubmissionForm().then((data) => {
            this.setState({facilities: data.authorizedFacilities});
        });
        SystemAPI.getAllVaccines().then((data) => {
            this.setState({ vaccines: data });
        });
        SystemAPI.getAllEvaluations().then((data) => {
        this.setState({ evaluations: data });
        });
        SystemAPI.getAllServiceTypes().then((data) => {
        this.setState({ serviceTypes: data });  
        })
        TestsAPI.getAllTestDetails().then((data) => {
        let enabledTests = data.tests.filter((t) => t.IsEnabled);
        this.setState({ tests: enabledTests});
        });
        ServicesAPI.getAllServices().then((response) => {
            this.setState({services: response.data});
        });
        CustomFieldsAPI.getAllCustomFields().then((data) => {
          this.setState({
            customQuestions: data.data.customQuestions,
          });
        });
    });
    this.setState({ showLoading: false });
  }

  queryRecords(page: number) {
    this.setState({ showLoading: true }, async () => {
      try {
        let results = await RecordsAPI.filterRecords({
            EmployeeUID: this.state.selectedEmployeeLabel && this.state.selectedEmployeeLabel.value ? this.state.selectedEmployeeLabel.value : null,
            ServiceType: this.state.filterTwo && this.state.filterTwo.ServiceType ? this.state.filterTwo.ServiceType : null,
            ServiceTypeSpecificID: this.state.filterTwo && this.state.filterTwo.ServiceTypeSpecificID ? this.state.filterTwo.ServiceTypeSpecificID : null,
            ReqNum: this.state.filter && this.state.filter.ReqNum ? this.state.filter.ReqNum : null,
            LinkedApptsID: this.state.filter && this.state.filter.LinkedApptsID ? this.state.filter.LinkedApptsID : null,
            EventApptID: this.state.filterTwo && this.state.filterTwo.EventApptID ? this.state.filterTwo.EventApptID : null,
            AppointmentDate: this.state.filter && this.state.filter.AppointmentDate ? this.state.filter.AppointmentDate : null,
            ApptCanceled: this.state.filter && this.state.filter.ApptCanceled ? this.state.filter.ApptCanceled : null,
            CheckedInDate: this.state.filter && this.state.filter.CheckedInDate ? this.state.filter.CheckedInDate : null,
            FacilityID: this.state.filterTwo && this.state.filterTwo.FacilityID ? this.state.filterTwo.FacilityID : null,
            ServiceID: this.state.filterTwo && this.state.filterTwo.ServiceID ? this.state.filterTwo.ServiceID : null,
        }, page);

        if (results.data.length < 1) {
          this.setState({ showLoading: false, tableData: [], recordsQueryResults: null });
          return sweetalert.fire({ title: '', text: 'No Records returned', icon: 'info' });
        }

        if (!results.success) {
          return sweetalert.fire({ title: '', text: 'Unable to filter Record data at this time', icon: 'error' });
        }
        this.setState({
          tableData: slicePages(results.data, page, ITEMS_PER_PAGE),
          recordsQueryResults: results.data,
          page_options: getPageOptions(getNumPages(results.data, ITEMS_PER_PAGE)),
          showLoading: false,
        });
      } catch (e) {
        console.error(e);
        this.setState({ showLoading: false });
      }
    });
  }

  editRecord(record: RecordType) {
    this.setState({ showLoading: true }, async () => {
      try {
        let result = await RecordsAPI.editRecord(record);
        if (result.success) {
          sweetalert.fire({ icon: 'success', title: '', text: 'Record updated' }).then(() => {
            this.setState(
              {
                selectedRecord: null,
              },
              () => {
                this.queryRecords(this.state.selected_page.value);
              }
            );
          });
        } else {
          sweetalert.fire({ icon: 'error', title: '', text: result.reason });
          this.setState({ showLoading: false });
        }
      } catch (e) {
        console.error(e);
        this.setState({ showLoading: false });
      }
    });
  }

  handleExportToCSV() {
    let combinedFilter = {
      EmployeeUID: this.state.selectedEmployeeLabel && this.state.selectedEmployeeLabel.value ? this.state.selectedEmployeeLabel.value : null,
      ServiceType: this.state.filterTwo && this.state.filterTwo.ServiceType ? this.state.filterTwo.ServiceType : null,
      ServiceTypeSpecificID: this.state.filterTwo && this.state.filterTwo.ServiceTypeSpecificID ? this.state.filterTwo.ServiceTypeSpecificID : null,
      ReqNum: this.state.filter && this.state.filter.ReqNum ? this.state.filter.ReqNum : null,
      LinkedApptsID: this.state.filter && this.state.filter.LinkedApptsID ? this.state.filter.LinkedApptsID : null,
      EventApptID: this.state.filterTwo && this.state.filterTwo.EventApptID ? this.state.filterTwo.EventApptID : null,
      AppointmentDate: this.state.filter && this.state.filter.AppointmentDate ? this.state.filter.AppointmentDate : null,
      ApptCanceled: this.state.filter && this.state.filter.ApptCanceled ? this.state.filter.ApptCanceled : null,
      CheckedInDate: this.state.filter && this.state.filter.CheckedInDate ? this.state.filter.CheckedInDate : null,
      FacilityID: this.state.filterTwo && this.state.filterTwo.FacilityID ? this.state.filterTwo.FacilityID : null,
      ServiceID: this.state.filterTwo && this.state.filterTwo.ServiceID ? this.state.filterTwo.ServiceID : null,
    }
    this.setState({ showLoading: true }, async () => {
      await NetworkUtil.downloadCSV('/api/admin/records/csv', 'Records.xlsx', { filter: combinedFilter });
      this.setState({ showLoading: false });
    });
  }

  useSorter(col: Column) {
    let sorter = new Sorter<any>();

    this.setState({ tableData: sorter.sortByKey(this.state.tableData, col.key as keyof any, this.state.direction), direction: this.state.direction === 'asc' ? 'desc' : 'asc' });
  }

  changePage(page: number) {
    let allRecords = this.state.recordsQueryResults;
    let returnData = slicePages(allRecords, page, ITEMS_PER_PAGE);
    this.setState({ tableData: returnData });
  }

  handleDateOnChange(value, state) {
    if (value) {
      this.setState(prevState => ({
        filter: {
          ...prevState.filter,
          [state]: value,
        },
      }));
    } else {
      this.setState(prevState => ({
        filter: {
          ...prevState.filter,
          [state]: null,
        },
      }));
    }
  }

  renderRecordFilterFieldsLeft() {
    return (
      <>
        <div className="form-group row">
          <label htmlFor={'EmployeeSearch'} className="col-sm-4 col-form-label">
            Employee
          </label>
          <div className="col-sm-8 p-0 m-0" id={'EmployeeSearch'}>
            <Select
              name="EmployeeSearch"
              isSearchable={true}
              isClearable={true}
              placeholder={<div className="accessibilityText">Please Select...</div>}
              noOptionsMessage={() => 'No option'}
              className={'state_select'}
              options={
                this.state.employees && this.state.employees.length > 0
                  ? this.state.employees.map(p => {
                      return {
                        label: `${p.FirstName} ${p.LastName} - ${parseDate(p.DateOfBirth)} - ${p.UID}`,
                        value: p.UID,
                      };
                    })
                  : []
              }
              onChange={e =>
                this.setState({
                  selectedEmployeeLabel: e,
                })
              }
              value={this.state.selectedEmployeeLabel ? this.state.selectedEmployeeLabel : null}
              aria-label="EmployeeSearch"
            />
          </div>
        </div>
        <FilterCard
          fields={[
            {
                label: 'Confirmation Code',
                key: 'ReqNum',
                type: FIELD_TYPE.TEXT,
                options: [],
                isMapped: true,
                onInput: e => (e.target.value = e.target.value.toUpperCase()),
            },
            {
                label: 'Appointment Code',
                key: 'LinkedApptsID',
                type: FIELD_TYPE.TEXT,
                options: [],
                isMapped: true,
                onInput: e => (e.target.value = e.target.value.toUpperCase()),
            },
            // {
            //   label: 'Has Appointment',
            //   key: '',
            //   type: FIELD_TYPE.SELECT,
            //   options: getBoolSelectOptions(),
            //   isMapped: true,
            //   isMulti: true,
            //   textType: 'text',
            //   isFilter: true,
            // },
            {
              label: 'Appointment Canceled',
              key: 'ApptCanceled',
              type: FIELD_TYPE.SELECT,
              options: getBoolSelectOptions(),
              isMapped: true,
              isMulti: true,
              textType: 'text',
              isFilter: true,
            },
          ]}
          filterChanged={e => this.setState({ filter: e })}
        />
        <CustomDate
            fromFilterPage={false}
            value={this.state.filter && this.state.filter.AppointmentDate ? this.state.filter.AppointmentDate : null}
            label={'Appointment Date'}
            tooltip={`Appointment Date`}
            handleDateOnChange={e => this.handleDateOnChange(e, 'AppointmentDate')}
        />
      </>
    );
  }

  renderRecordFilterFieldsRight() {
    return (
        <>
        <FilterCard
          fields={[
            {
                label: 'Facility',
                key: 'FacilityID',
                type: FIELD_TYPE.SELECT,
                options: this.state.facilities && this.state.facilities.length > 0 ? this.state.facilities.map(f => {return {label: f.FacilityName, value: f.ID}}) : [],
                isMapped: true,
                isMulti: true,
                textType: 'text',
                isFilter: true,
            },
            {
                label: 'Service',
                key: 'ServiceID',
                type: FIELD_TYPE.SELECT,
                options: this.state.services && this.state.services.length > 0 ? this.state.services.map(s => {return {label: s.Name, value: s.ID}}) : [],
                isMapped: true,
                isMulti: true,
                textType: 'text',
                isFilter: true,
            },
            {
              label: 'Service Type',
              key: 'ServiceType',
              type: FIELD_TYPE.SELECT,
              options: this.state.serviceTypes,
              isMapped: true,
              isMulti: true,
              textType: 'text',
              isFilter: true,
            },
            // {
            //   label: 'Service Type Item',
            //   key: 'ServiceTypeSpecificID',
            //   type: FIELD_TYPE.SELECT,
            //   options: this.state.serviceSpecificTypes,
            //   isMapped: true,
            //   isMulti: true,
            //   textType: 'text',
            //   isFilter: true,
            // },
            {
                label: 'Event',
                key: 'EventApptID',
                type: FIELD_TYPE.SELECT,
                options: this.state.events,
                isMapped: true,
                isMulti: true,
                textType: 'text',
                isFilter: true,
            },
            // {
            //   label: 'Checked In?',
            //   key: '',
            //   type: FIELD_TYPE.SELECT,
            //   options: getBoolSelectOptions(),
            //   isMapped: true,
            //   isMulti: true,
            //   textType: 'text',
            //   isFilter: true,
            // },
          ]}
          filterChanged={e => this.setState({ filterTwo: e })}
        />
            <CustomDate
                fromFilterPage={false}
                value={this.state.filter && this.state.filter.CheckedInDate ? this.state.filter.CheckedInDate : null}
                label={'Checked In Date'}
                tooltip={`Checked In Date`}
                handleDateOnChange={e => this.handleDateOnChange(e, 'CheckedInDate')}
            />
        </>
    );
  }

  render() {
    // console.log('Records state', this.state)

    return (
      <React.Fragment>
        <Overlay show_loading={this.state.showLoading} />
        <InBetweenOverlay showInBetween={this.state.showInBetween} />
        <RecordsModal
          selectedRecord={this.state.selectedRecord}
          employeeName={this.state.selectedRecord && this.state.selectedRecord.EmployeeUID && this.state.employees && this.state.employees.length > 0 ? this.state.employees.find(e => e.UID === this.state.selectedRecord.EmployeeUID).DisplayName : ''}
          customQuestions={this.state.customQuestions}
          onSubmit={(record: RecordType) => {
            this.editRecord(record);
          }}
          vaccines={this.state.vaccines}
          evaluations={this.state.evaluations}
          tests={this.state.tests}
          serviceTypes={this.state.serviceTypes}
          services={this.state.services && this.state.services.length > 0 ? this.state.services.map(s => {return {label: s.Name, value: s.ID}}) : []}
          facilities={this.state.facilities && this.state.facilities.length > 0 ? this.state.facilities.map(f => {return {label: f.FacilityName, value: f.ID}}) : []}
          events={this.state.events && this.state.events.length > 0 ? this.state.events : []}
        />
        <div className="container-fluid ">
          <div className={'row'}>
            <div className="col-12 pt-2">
              <main id="main-content" tabIndex={-1} aria-label="Records">
                <div className="card mb-2">
                  <div className="card-header verlag-bold">
                    <h4>Records</h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            {this.renderRecordFilterFieldsLeft()}
                        </div>
                        <div className="col-12 col-lg-6">
                            {this.renderRecordFilterFieldsRight()}
                        </div>
                    </div>
                </div>
                  <div className="card-footer">
                    <button
                      className={'btn immySubmitButtonOutline'}
                      onClick={() => {
                        this.setState(
                          {
                            selected_page: { label: 1, value: 1 },
                          },
                          () => {
                            this.queryRecords(this.state.selected_page.value);
                          }
                        );
                      }}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </main>
            </div>

            {this.state.tableData && this.state.tableData.length > 0 && (
              <div className="col-md-12 pt-2">
                <div className="card mt-2 mb-5">
                  <div className="card-header verlag-bold" style={{zIndex: 0}}>
                    <h4 className="text-center text-md-left">
                      Records
                      <section className="tableHeaderSection float-md-right d-flex justify-content-around">
                        <h4 className={'float-md-right'} aria-label="Total Records" role="alert">
                          Total: {this.state.recordsQueryResults.length}
                        </h4>
                        <h4 className="float-right align-middle pr-2 ml-5">Page </h4>
                        <div className=" align-middle float-right pages ">
                          <Select
                            isSearchable={true}
                            placeholder={'1'}
                            noOptionsMessage={() => 'No option'}
                            value={this.state.selected_page}
                            aria-label="Table Page Number"
                            onChange={(e: ReactSelect) => this.setState({ selected_page: e }, () => this.changePage(e.value))}
                            className={'state_select'}
                            options={this.state.page_options}
                          />
                        </div>
                      </section>
                      <button className={'d-none d-md-inline btn btn-outline-primary ml-3'} onClick={() => this.handleExportToCSV()}>
                        Export to CSV
                      </button>
                    </h4>
                  </div>
                  <div className="card-body p-0 m-0 table-responsive">
                    <SimpleTable
                      table_style="tableFixHead"
                      columns={[
                        {
                            label: 'Name',
                            key: 'Name',
                            rawFormat: v => {
                              let employeeName = this.state.employees && this.state.employees.length > 0 ? this.state.employees.find(e => e.UID === v.EmployeeUID).DisplayName : '';
                              return (
                                <a
                                  href={'#!'}
                                  className={'tableNameLinkColor'}
                                  onClick={() => {
                                    this.setState({ selectedRecord: v }, () => {
                                      showModalNoOutsideClick(RecordsModal.ID)
                                    });
                                  }}
                                >
                                  {employeeName}
                                </a>
                              );
                            },
                        },
                        {
                            label: 'Appointment Code',
                            key: 'LinkedApptsID',
                          },
                          {
                            label: 'Confirmation Code',
                            key: 'ReqNum',
                          },
                          {
                            label: 'Appt. Date',
                            key: 'AppointmentDate',
                            rawFormat: v => {
                              return v.AppointmentDate
                              === 'Invalid date' ? '' : parseDate(v.AppointmentDate)
                              ;
                            },
                          },
                          {
                            label: 'Appt. Time',
                            key: 'ApptTime',
                            rawFormat: v => {
                              return v.AppointmentTime && v.AppointmentTime
                              === 'Invalid time' ? '' : v.AppointmentTime ? v.AppointmentTime.replace(/^0/, '') : '';
                              ;
                            },
                          },
                          {
                            label: 'Checked In Date',
                            key: 'CheckedInDate',
                            rawFormat: v => {
                              return v.CheckedInDate
                              === 'Invalid date' ? '' : parseDate(v.CheckedInDate)
                              ;
                            },
                          },
                          {
                            label: 'Service',
                            key: 'ServiceID',
                            rawFormat: v => {
                              return v.ServiceID && this.state.services && this.state.services.length > 0 ? this.state.services.find(s => s.ID === v.ServiceID).Name : '';
                            },
                          },
                          {
                            label: 'Service Type',
                            key: 'ServiceTypeID',
                            rawFormat: v => {
                              let serviceTypeLabel = getServiceTypeLabel(v, this.state.serviceTypes);
                              return serviceTypeLabel && serviceTypeLabel.label ? serviceTypeLabel.label : '';
                            },
                          },
                          {
                            label: 'Service Specific Type',
                            key: 'ServiceSpecificTypeID',
                            rawFormat: v => {
                              let serviceSpecificTypeLabel = getSpecificServiceType(v, this.state.vaccines, this.state.evaluations, this.state.tests);
                              return serviceSpecificTypeLabel && serviceSpecificTypeLabel.label ? serviceSpecificTypeLabel.label : '';
                            },
                          },
                      ]}
                      table_data={this.state.tableData}
                      columnClickedCallback={col => {
                        this.useSorter(col);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
