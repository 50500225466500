import React from "react";
import Overlay from "../Overlay";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
} from "chart.js";
import { Pie, Bar, Line, Doughnut } from "react-chartjs-2";
import { faker } from "@faker-js/faker";
import ReactSpeedometer from "react-d3-speedometer";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement
);

//******************supervisor *******************************************/

const supervisorEmployeeHealthLineChartData = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
  datasets: [
    {
      label: "Smith, Sally",
      data: [65, 59, 80, 81, 56, 55],
      fill: false,
      borderColor: "rgb(255, 99, 132)",
      tension: 0.1,
    },
    {
      label: "Doe, John",
      data: [45, 80, 60, 71, 80, 75],
      fill: false,
      borderColor: "rgb(54, 162, 235)",
      tension: 0.1,
    },
    {
      label: "Skywalker, Luke",
      data: [70, 55, 75, 82, 65, 70],
      fill: false,
      borderColor: "rgb(75, 192, 192)",
      tension: 0.1,
    },
  ],
};

const supervisorEmployeeHealthLineChartOptions = {};

const supervisorVaccineComplianceDoughtnutChartData = {
  labels: ["Compliant", "Non-Compliant"],
  datasets: [
    {
      data: [75, 25], // Percentage of compliant and non-compliant employees
      backgroundColor: ["#36A2EB", "#FF6384"],
      hoverBackgroundColor: ["#36A2EB", "#FF6384"],
    },
  ],
};

const supervisorVaccineComplianceDoughtnutChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "right",
      labels: {
        boxWidth: 20,
      },
    },
  },
};

const supervisorAbsenteeismRateBarChartData = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
  datasets: [
    {
      label: "Absenteeism Rate",
      data: [3, 4, 2, 5, 3, 6], // Replace with actual absenteeism rate data
      backgroundColor: "rgba(54, 162, 235, 0.5)",
      borderColor: "rgba(54, 162, 235, 1)",
      borderWidth: 1,
    },
  ],
};

const supervisorAbsenteeismRateBarChartOptions = {};

let supervisorCardsArray = [
  {
    label: "Submission",
    imageLink:
      "https://storage.googleapis.com/streamline-bucket/appointment.svg",
    url: "/admin/Submission",
  },
  {
    label: "Events",
    imageLink: "https://storage.googleapis.com/streamline-bucket/pods.png",
    url: "/admin/Events",
  },
  {
    label: "Employees",
    imageLink: "https://storage.googleapis.com/streamline-bucket/people.png",
    url: "/admin/Employee",
  },
  {
    label: "Analytics",
    imageLink: "https://storage.googleapis.com/streamline-bucket/report.png",
    url: "/admin/Analytics",
  },
];

let supervisorChartsArray = [
  {
    label: "Employee Health Metrics",
    chartType: "line",
    chartData: supervisorEmployeeHealthLineChartData,
    options: supervisorEmployeeHealthLineChartOptions,
  },
  {
    label: "Vaccine Compliance Status",
    chartType: "doughnut",
    chartData: supervisorVaccineComplianceDoughtnutChartData,
    options: supervisorVaccineComplianceDoughtnutChartOptions,
  },
  {
    label: "Absenteeism Rate (%)",
    chartType: "bar",
    chartData: supervisorAbsenteeismRateBarChartData,
    options: supervisorAbsenteeismRateBarChartOptions,
  },
];

//******************end supervisor *******************************************/

//******************nurse *******************************************/

const nurseAppointmentsBarChartData = {
  labels: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  datasets: [
    {
      label: "Appointments",
      data: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ].map(() => faker.datatype.number({ min: 1, max: 200 })),
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};

const nurseAppointmentsBarChartOptions = {};

const nurseIncidentsByCategoryPieChartData = {
  labels: [
    "Injury",
    "Skin Disorder",
    "Respiratory Condition",
    "Poisoning",
    "Hearing Loss",
    "Other Illness",
  ],
  datasets: [
    {
      label: "# of incidents",
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

const nurseIncidentsByCategoryPieChartOptions = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "right",
      labels: {
        boxWidth: 20,
      },
    },
  },
};

const nurseIncidentBarChartData = {
  labels: ["January", "February", "March", "April", "May", "June", "July"],
  datasets: [
    {
      label: "Injuries",
      data: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
      ].map(() => faker.datatype.number({ min: 1, max: 20 })),
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Illnesses",
      data: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
      ].map(() => faker.datatype.number({ min: 1, max: 20 })),
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};

const nurseIncidentBarChartOptions = {};

let nurseCardsArray = [
  {
    label: "Employees",
    imageLink: "https://storage.googleapis.com/streamline-bucket/people.png",
    url: "/admin/Employee",
  },
  {
    label: "Submission",
    imageLink:
      "https://storage.googleapis.com/streamline-bucket/appointment.svg",
    url: "/admin/Submission",
  },
];

let nurseChartsArray = [
  {
    label: "Average Appointments",
    chartType: "bar",
    chartData: nurseAppointmentsBarChartData,
    options: nurseAppointmentsBarChartOptions,
  },
  {
    label: "2024 Incidents by Category ",
    chartType: "pie",
    chartData: nurseIncidentsByCategoryPieChartData,
    options: nurseIncidentsByCategoryPieChartOptions,
  },
  {
    label: "Incident Injury vs Illness",
    chartType: "bar",
    chartData: nurseIncidentBarChartData,
    options: nurseIncidentBarChartOptions,
  },
];

//******************end nurse *******************************************/

//******************claims *******************************************/

const claimsIncidentsByCategoryPieChartData = {
  labels: [
    "Injury",
    "Skin Disorder",
    "Respiratory Condition",
    "Poisoning",
    "Hearing Loss",
    "Other Illness",
  ],
  datasets: [
    {
      label: "# of incidents",
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

const claimsIncidentsByCategoryPieChartOptions = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "right",
      labels: {
        boxWidth: 20,
      },
    },
  },
};

const claimsIncidentBarChartData = {
  labels: ["January", "February", "March", "April", "May", "June", "July"],
  datasets: [
    {
      label: "Injuries",
      data: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
      ].map(() => faker.datatype.number({ min: 1, max: 20 })),
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Illnesses",
      data: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
      ].map(() => faker.datatype.number({ min: 1, max: 20 })),
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};

const claimsIncidentBarChartOptions = {};

const claimsCostLineChartData = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
  datasets: [
    {
      label: "Medical Expenses",
      data: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"].map(() =>
        faker.datatype.number({ min: 1, max: 5000 })
      ),
      backgroundColor: "rgba(255, 99, 132, 0.5)",
      borderColor: "rgba(255, 99, 132, 1)",
      borderWidth: 1,
      fill: "origin",
    },
    {
      label: "Compensation",
      data: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"].map(() =>
        faker.datatype.number({ min: 1, max: 2500 })
      ),
      backgroundColor: "rgba(54, 162, 235, 0.5)",
      borderColor: "rgba(54, 162, 235, 1)",
      borderWidth: 1,
      fill: "-1",
    },
  ],
};

const claimsCostLineChartOptions = {};

let claimsCardsArray = [
  {
    label: "Employees",
    imageLink: "https://storage.googleapis.com/streamline-bucket/people.png",
    url: "/admin/Employee",
  },
  {
    label: "Claims",
    imageLink: "https://storage.googleapis.com/streamline-bucket/clipboard.png",
    url: "/admin/Claims",
  },
  {
    label: "OSHA",
    imageLink: "https://storage.googleapis.com/streamline-bucket/employee.svg",
    url: "/admin/OSHAReport",
  },
  {
    label: "Analytics",
    imageLink: "https://storage.googleapis.com/streamline-bucket/report.png",
    url: "/admin/Analytics",
  },
];

let claimsChartsArray = [
  {
    label: "2024 Incidents by Category ",
    chartType: "pie",
    chartData: claimsIncidentsByCategoryPieChartData,
    options: claimsIncidentsByCategoryPieChartOptions,
  },
  {
    label: "Incident Injury vs Illness",
    chartType: "bar",
    chartData: claimsIncidentBarChartData,
    options: claimsIncidentBarChartOptions,
  },
  {
    label: "Cost of Claims",
    chartType: "line",
    chartData: claimsCostLineChartData,
    options: claimsCostLineChartOptions,
  },
];

//******************end claims *******************************************/

//******************safety *******************************************/

const safetyTrainingCompletionRatesChartData = {
  labels: ["Module 1", "Module 2", "Module 3", "Module 4"],
  datasets: [
    {
      label: "Completed",
      data: [80, 70, 90, 85], // Completion rates for each module
      backgroundColor: "rgba(75, 192, 192, 0.5)",
      borderColor: "rgba(75, 192, 192, 1)",
      borderWidth: 1,
    },
    {
      label: "Incomplete",
      data: [20, 30, 10, 15], // Incompletion rates for each module
      backgroundColor: "rgba(255, 99, 132, 0.5)",
      borderColor: "rgba(255, 99, 132, 1)",
      borderWidth: 1,
    },
  ],
};

const safetyTrainingCompletionRatesChartOptions = {
  indexAxis: "x", // Rotate chart to use x-axis for categories
  plugins: {
    legend: {
      display: true,
      position: "top",
    },
  },
};

const safetyComplianceScoreChartData = 73;

const safetyComplianceScoreChartOptions = {};

const safetyIncidentBarChartData = {
  labels: ["January", "February", "March", "April", "May", "June", "July"],
  datasets: [
    {
      label: "Injuries",
      data: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
      ].map(() => faker.datatype.number({ min: 1, max: 20 })),
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Illnesses",
      data: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
      ].map(() => faker.datatype.number({ min: 1, max: 20 })),
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};

const safetyIncidentBarChartOptions = {};

let safetyCardsArray = [
  {
    label: "Employees",
    imageLink: "https://storage.googleapis.com/streamline-bucket/people.png",
    url: "/admin/Employee",
  },
  {
    label: "Claims",
    imageLink: "https://storage.googleapis.com/streamline-bucket/clipboard.png",
    url: "/admin/Claims",
  },
  {
    label: "OSHA",
    imageLink: "https://storage.googleapis.com/streamline-bucket/employee.svg",
    url: "/admin/OSHAReport",
  },
  {
    label: "Analytics",
    imageLink: "https://storage.googleapis.com/streamline-bucket/report.png",
    url: "/admin/Analytics",
  },
];

let safetyChartsArray = [
  {
    label: "Training Completion Rates",
    chartType: "bar",
    chartData: safetyTrainingCompletionRatesChartData,
    options: safetyTrainingCompletionRatesChartOptions,
  },
  {
    label: "Safety Compliance Score",
    chartType: "gauge",
    chartData: safetyComplianceScoreChartData,
    options: safetyComplianceScoreChartOptions,
  },
  {
    label: "Incident Injury vs Illness",
    chartType: "bar",
    chartData: safetyIncidentBarChartData,
    options: safetyIncidentBarChartOptions,
  },
];

//******************end safety *******************************************/

interface AdminHomePageState {
  showLoading?: boolean;
}

interface AdminHomePageProps {
  demoUser?;
}

export default class AdminHomePage extends React.Component<
  any,
  AdminHomePageState
> {
  constructor(props) {
    super(props);
    this.state = {
      showLoading: false,
    };
  }

  componentDidMount(): void {
    document.title = "Admin Home Page";
    document.body.style.backgroundImage = ``;
    document.body.style.backgroundColor = "#FFFFFF";
  }

  getCard(data) {
    return (
      <div className="col-12 col-xl-3">
        <a href={data.url} style={{ textDecoration: "none" }}>
          <div className="card mb-3 navTile">
            <div className="row no-gutters">
              <div className="col-md-5 text-center">
                <img
                  src={data.imageLink}
                  className="card-img pt-2 navTileIcon"
                  alt="..."
                  style={{ width: "4em" }}
                />
              </div>
              <div className="col-md-7">
                <div className="card-body text-center">
                  <h3 className="card-title">{data.label}</h3>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    );
  }

  getChart(data) {
    return (
      <div className="col-12 col-lg-4">
        <div
          className="card mb-3 text-center"
          style={{ border: '1px solid rgba(0, 0, 0, 0.125);' }}
        >
          <div
            className="card-body"
            style={
              window.innerWidth > 1540
                ? { height: "450px" }
                : { height: "400px" }
            }
          >
            <div className="row no-gutters">
              <div className="col-12">
                <h3 className="card-title">{data.label}</h3>
              </div>
            </div>
            {data && data.chartType === "bar" ? (
              <div className="row no-gutters">
                <div className="col-12">
                  <div>
                    <Bar
                      height={200}
                      data={data.chartData}
                      options={data.options}
                    />
                  </div>
                </div>
              </div>
            ) : data && data.chartType === "pie" ? (
              <div className="row no-gutters pt-0 pt-xxl-5">
                <div className="col-12">
                  <div>
                    <Pie
                      data={data.chartData}
                      options={data.options}
                      style={{ height: "300px" }}
                    />
                  </div>
                </div>
              </div>
            ) : data && data.chartType === "line" ? (
              <div className="row no-gutters">
                <div className="col-12">
                  <div>
                    <Line
                      height={200}
                      data={data.chartData}
                      options={data.options}
                    />
                  </div>
                </div>
              </div>
            ) : data && data.chartType === "doughnut" ? (
              <div className="row no-gutters pt-0 pt-xxl-5">
                <div className="col-12">
                  <div>
                    <Doughnut
                      height={300}
                      data={data.chartData}
                      options={data.options}
                    />
                  </div>
                </div>
              </div>
            ) : data && data.chartType === "gauge" ? (
              <div
                className="row no-gutters"
                style={
                  window.innerWidth > 1540
                    ? { paddingTop: "40px" }
                    : { paddingTop: "65px" }
                }
              >
                <div className="col-12">
                  <div
                    style={
                      window.innerWidth > 1540
                        ? { width: "100%", height: "325px" }
                        : { width: "100%", height: "300px" }
                    }
                  >
                    <ReactSpeedometer
                      fluidWidth={true}
                      maxValue={100}
                      minValue={0}
                      value={data.chartData}
                      needleColor="blue"
                      startColor="red"
                      segments={10}
                      endColor="green"
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }

  getPageBasedOnUser(user) {
    let cardArray;
    let chartsArray;
    switch (user) {
      case "Supervisor":
        cardArray =
          supervisorCardsArray && supervisorCardsArray.length > 0
            ? supervisorCardsArray
            : [];
        chartsArray =
          supervisorChartsArray && supervisorChartsArray.length > 0
            ? supervisorChartsArray
            : [];
        break;
      case "Nurse":
        cardArray =
          nurseCardsArray && nurseCardsArray.length > 0 ? nurseCardsArray : [];
        chartsArray =
          nurseChartsArray && nurseChartsArray.length > 0
            ? nurseChartsArray
            : [];
        break;
      case "Claims":
        cardArray =
          claimsCardsArray && claimsCardsArray.length > 0
            ? claimsCardsArray
            : [];
        chartsArray =
          claimsChartsArray && claimsChartsArray.length > 0
            ? claimsChartsArray
            : [];
        break;
      case "Safety":
        cardArray =
          safetyCardsArray && safetyCardsArray.length > 0
            ? safetyCardsArray
            : [];
        chartsArray =
          safetyChartsArray && safetyChartsArray.length > 0
            ? safetyChartsArray
            : [];
        break;
      default:
        cardArray =
          supervisorCardsArray && supervisorCardsArray.length > 0
            ? supervisorCardsArray
            : [];
        chartsArray =
          supervisorChartsArray && supervisorChartsArray.length > 0
            ? supervisorChartsArray
            : [];
        break;
    }

    return (
      <main id="main-content" tabIndex={-1} aria-label="Home Page">
        <div className="container-fluid">
          <div className={"row mt-3"}>
            {cardArray.map((f, index) => {
              return <>{this.getCard(f)}</>;
            })}
          </div>
          <div className={"row"}>
            {chartsArray.map((f, index) => {
              return <>{this.getChart(f)}</>;
            })}
          </div>
        </div>
      </main>
    );
  }

  render():
    | React.ReactElement
    | string
    | number
    | {}
    | React.ReactNodeArray
    | React.ReactPortal
    | boolean
    | null
    | undefined {
    return (
      <React.Fragment>
        <Overlay show_loading={this.state.showLoading} />
        {this.props &&
          this.props.demoUser &&
          this.getPageBasedOnUser(this.props.demoUser)}
      </React.Fragment>
    );
  }
}
