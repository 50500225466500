import React, { useState, useLayoutEffect } from "react";
import Select from "react-select";
import Overlay from "../Overlay";
import { sweetalert } from "../../App";
import { isEmptyObject } from "jquery";
import { buildRow, getBoolSelectVal, getLabel, hideModal } from "../../util/FormatUtil";
import DatePicker from "react-datepicker";
import Task from "../../types/Task"
import moment from "moment";

type TaskModalProps = {
    tasks 
    selectedTask:Task
    employees
    requery
    onSubmit: (task: Task) => void
};

export const TaskModal = ({ tasks, selectedTask, employees, requery, onSubmit }: TaskModalProps) => {
    const [task, setTask] = useState(null)
    const [selectedEmployee, setSelectedEmployee] = useState(null)
    const [taskTitle, setTaskTitle] = useState('')
    const [description, setDescription] = useState('')
    const [priority, setPriority] = useState('')
    const [dueDate, setDueDate] = useState(null)
    const [reminderDate, setReminderDate] = useState(null)
    const [repeat, setRepeat] = useState(null)
    const [frequency, setFrequency] = useState(null)
    const [notifySupervisor, setNotifySupervisor] = useState(null)
    const [completedDate, setCompletedDate] = useState(null)
    const [oshaCaseNum, setOSHACaseNum] = useState('')
    const [priorityLabel, setPriorityLabel] = useState({label:'',value:''})
    const [frequencyLabel, setFrequencyLabel] = useState({label:'',value:''})
    const [showLoading, setShowLoading] = useState(false)


    const PriorityOptions = [
        { label: "High", value: "High" },
        { label: "Medium", value: "Medium" },
        { label: "Low", value: "Low" },
    ];

    const FrequencyOptions = [
        { label: "Daily", value: "Daily" },
        { label: "Monthly", value: "Monthly" },
        { label: "Yearly", value: "Yearly" },
    ];

    useLayoutEffect(() => {
        $(`#${TaskModal.ID}`).on('shown.bs.modal', function () {
            $(document).off('focusin.modal');
        });

        if (!isEmptyObject(selectedTask) && selectedTask.ID) {
            setTask(selectedTask);
            setSelectedEmployee(employees.find(emp => emp.ID === selectedTask.EmployeeID))
            setTaskTitle(selectedTask.Title)
            setDescription(selectedTask.Description)
            setPriority(selectedTask.Priority)
            setDueDate(selectedTask.DueDate)
            setReminderDate(selectedTask.ReminderDate)
            setRepeat(selectedTask.Repeat)
            setFrequency(selectedTask.Frequency)
            setNotifySupervisor(selectedTask.NotifySupervisor)
            setOSHACaseNum(selectedTask.OSHACaseNum)
            setCompletedDate(selectedTask.CompletedDate)
            setPriorityLabel({label: selectedTask.Priority, value:selectedTask.Priority})
            setFrequencyLabel({label:selectedTask.Frequency, value:selectedTask.Frequency})
        } else {
            setTask(null);
            setSelectedEmployee(null)
            setTaskTitle('')
            setDescription('')
            setPriority('')
            setDueDate(null)
            setReminderDate(null)
            setRepeat(null)
            setFrequency(null)
            setNotifySupervisor(null)
            setOSHACaseNum(null)
            setCompletedDate(null)
            setPriorityLabel({label:"",value:""})
            setFrequencyLabel({label:'',value:''})
        }
    }, [selectedTask])


    function clear() {
        setSelectedEmployee(null)
        setTask(null);
        setSelectedEmployee(null)
        setTaskTitle('')
        setDescription('')
        setPriority('')
        setDueDate(null)
        setReminderDate(null)
        setRepeat(null)
        setFrequency(null)
        setNotifySupervisor(null)
        setOSHACaseNum(null)
        setCompletedDate(null)
        setPriorityLabel({label:"",value:""})
        setFrequencyLabel({label:"",value:""})
    }

    return (
        <>
            <Overlay show_loading={showLoading} zIndex={100003} />
            <div className="modal fade form_modal" id={TaskModal.ID} tabIndex={-1} role="dialog"
                aria-labelledby="result_modal_label" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-xl" role="document">
                    <div className="modal-content">
                        <div className="container-fluid">
                            <div className="modal-header">
                                <h5 className="modal-title" id="result_modal_label">Task Management</h5>
                                <button style={{ outline: 'none' }} type="button" className="close"
                                    aria-label="Close"
                                    onClick={() => {
                                        // clear()
                                        hideModal("taskmodal")
                                    }}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">

            {/* select Employee to assign task to */}
                                {buildRow("Employee Name",
                                    <Select
                                        isSearchable={true}
                                        placeholder={"Select Employee..."}
                                        onChange={(e) => {
                                            let selectedEmployee = employees.find(emp => emp.ID === e.value)
                                            setSelectedEmployee(selectedEmployee);
                                        }}
                                        value={selectedEmployee ? {label: selectedEmployee.DisplayName, value: selectedEmployee.ID} : null}
                                        className={"state_select"}
                                        options={employees.map(s => {return {label: s.DisplayName, value: s.ID}})}
                                    />
                                    , 'Employee Name')
                                }
                                { buildRow("OSHA Case #",
                                    <input className={"form-control"}
                                        id="OSHACaseNum"
                                        maxLength={50}
                                        placeholder={"OSHA Case #..."}
                                        autoComplete={"off"}
                                        type={"search"}
                                        name={"OSHACaseNum"}
                                        aria-label="OSHA Case #"
                                        onChange={(e) => {
                                            setOSHACaseNum(e.target.value)
                                        }}
                                        onBlur={(e)=>{ 
                                            // TODO:
                                            // OSHAAPI.getFilterREcords(1, {filter: e.target.value})
                                            //     .then((results){
                                            //         // update form with results from query
                                            // })
                                        }}
                                        value={oshaCaseNum ? oshaCaseNum : ''}
                                        readOnly={oshaCaseNum !== "" && selectedTask.ID}
                                    />
                                    , 'OSHA Case #')}
            {/* title of task */}
                                {buildRow("Title",
                                    <input className={"form-control"}
                                        id="TaskTitle"
                                        placeholder={"Enter Title..."}
                                        maxLength={500}
                                        autoComplete={"off"}
                                        type={"search"}
                                        name={"TaskTitle"}
                                        aria-label="Title"
                                        onChange={(e) => {
                                            setTaskTitle(e.target.value)
                                        }}
                                        value={taskTitle ? taskTitle : ''}
                                    />
                                    , 'Title')
                                }
                                {buildRow("Description",
                                    <input className={"form-control"}
                                        id="TaskDescription"
                                        placeholder={"Enter Description..."}
                                        maxLength={500}
                                        autoComplete={"off"}
                                        type={"search"}
                                        name={"TaskDescription"}
                                        aria-label="Description"
                                        onChange={(e) => {
                                            setDescription(e.target.value)
                                        }}
                                        value={description ? description : ''}
                                    />
                                    , 'Description')
                                }
            {/* Priority Select */}
                                {buildRow("Priority",
                                        <Select
                                            isSearchable={true}
                                            placeholder={"Select Priority..."}
                                            noOptionsMessage={() => "No option"}
                                            value={ getLabel(priority, PriorityOptions) }
                                            onChange={(e) => {
                                                setPriority(e.value)
                                                setPriorityLabel({label:e.value,value:e.value})
                                            }}
                                            className={"state_select"}
                                            options={PriorityOptions}
                                        />)
                                }
            {/* Due Date */}
                                {buildRow("Due Date",
                                    <DatePicker
                                        id={'DueDatePicker'}
                                        minDate={new Date('01-01-1970')}
                                        maxDate={new Date('12-31-2100')}
                                        placeholderText="--/--/----"
                                        selected={dueDate? 
                                            Date.parse(moment(new Date(dueDate), 'MM-DD-YYYY').toISOString()) : 
                                            null
                                        }
                                        onChange={(e)=>{ 
                                            setDueDate(new Date(e))
                                        }}
                                        onBlur={(e)=>{}}
                                        ariaLabelledBy={'DueDatePicker'}
                                    />
                                )}
            {/* Reminder Date */}
                                { buildRow("Reminder Date",
                                    <DatePicker
                                        id={'Reminder Date'}
                                        minDate={new Date('01-01-1970')}
                                        maxDate={new Date('12-31-2100')}
                                        placeholderText="--/--/----"
                                        selected={reminderDate? 
                                            Date.parse(moment(new Date(reminderDate), 'MM-DD-YYYY').toISOString()) : 
                                            null
                                        }
                                        onChange={(e)=>{ 
                                            setReminderDate(new Date(e))
                                        }}
                                        onBlur={(e)=>{}}
                                        ariaLabelledBy={'ReminderDate'}
                                    />
                                )}
            {/* Repeat */}
                                {buildRow("Repeat",
                                    <Select
                                        isSearchable={true}
                                        placeholder={"Select Repeat..."}
                                        noOptionsMessage={() => "No option"}
                                        value={ repeat !== null ? getBoolSelectVal(repeat) : null}
                                        onChange={(e) => setRepeat(e.value) }
                                        className={"state_select"}
                                        options={[{ label: "Yes", value: true }, { label: "No", value: false }]}
                                    />)
                                }
            {/* Repeat Frequency */}
                                {repeat === true && buildRow("Repeat Frequency",
                                    <Select
                                        isSearchable={true}
                                        placeholder={"Select Frequency..."}
                                        noOptionsMessage={() => "No option"}
                                        value={ getLabel(frequency, FrequencyOptions) }
                                        onChange={(e) =>{
                                            setFrequency(e.value)
                                            setFrequencyLabel({ label: e.value, value: e.value })
                                        }}
                                        className={"state_select"}
                                        options={FrequencyOptions}
                                    />)
                                }
                                {buildRow("Notify Supervisor?",
                                    <Select
                                        isSearchable={true}
                                        placeholder={"Select Notify Supervisor..."}
                                        noOptionsMessage={() => "No option"}
                                        value={notifySupervisor !== null ? getBoolSelectVal(notifySupervisor) : null}
                                        onChange={(e) =>{
                                            setNotifySupervisor(e.value);
                                        }}
                                        className={"state_select"}
                                        options={[{ label: "Yes", value: true }, { label: "No", value: false }]}
                                    />)
                                }
                                { selectedEmployee !== null && 
                                  notifySupervisor === true && 
                                  buildRow("",<small>Supervisor: {selectedEmployee.Supervisor} will be included in notifications.</small>)
                                }
                                { buildRow("Completed Date",
                                    <DatePicker
                                        id={'CompletedDate'}
                                        minDate={new Date('01-01-1970')}
                                        maxDate={new Date('12-31-2100')}
                                        placeholderText="--/--/----"
                                        selected={completedDate ? 
                                            Date.parse(moment(new Date(completedDate), 'MM-DD-YYYY').toISOString()) : 
                                            null
                                        }
                                        onChange={(e)=>{ 
                                            setCompletedDate(new Date(e))
                                        }}
                                        onBlur={(e)=>{}}
                                        ariaLabelledBy={'CompletedDate'}
                                    />
                                )}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn immySubmitButtonOutline"
                                    style={{ float: 'right' }}
                                    onClick={async () => {
                                        // Faking API call here
                                        setShowLoading(true)
                                        onSubmit(selectedTask)
                                        sweetalert.fire({icon: 'success',title: '', text: 'Saved'})
                                        setShowLoading(false);
                                        hideModal("taskmodal")
                                        clear()
                                        requery()
                                    }}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

TaskModal.ID = "taskmodal"