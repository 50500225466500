import NetworkUtil from "./NetworkUtil";
import Facility, {FacilitySearch} from "../types/Facility";
import {ServerResponse} from "../types/ServerResponse";

export class FacilityAPI{

    public static facilitySearch(filter, page):Promise<{num_pages:number, table_data?:FacilitySearch[],  data:FacilitySearch[]}>{
        return NetworkUtil.makePost("/api/admin/facility/searchFacility?page=" + page, filter);
    }

    public static facilityUpsert(facility:Facility):Promise<ServerResponse>{
        return NetworkUtil.makePost("/api/admin/facility/facilityUpsert", {facility: facility});
    }

    public static async getFacilitiesForSubmissionForm(active:boolean=false):Promise<{authorizedFacilities:Facility[]}>{
        return NetworkUtil.makePost("/api/admin/facility/getFacilitiesForSubmissionForm", {active:active})
    }

    public static async getFacilityServiceCommTemplateStatus():Promise<{data?}>{
        return NetworkUtil.makeGet("/api/admin/facility/getFacilityServiceCommTemplateStatus")
    }

    public static async getFacilitiesWithAddress(active:boolean=false):Promise<{label:string, value:number}[]>{
        return NetworkUtil.makePost("/api/admin/facility/facilities", {active:active})
    }

    public static async getFacilitiesGroups(active:boolean=false):Promise<{label:string, value:string}>{
        return NetworkUtil.makePost("/api/admin/facility/facilityGroups", {active:active})
    }

}