import React, { useState, useLayoutEffect } from "react";
import Select from "react-select";
import Overlay from "../Overlay";
import { sweetalert } from "../../App";
import { isEmptyObject } from "jquery";
import { buildRow, getBoolSelectVal, getLabel, hideModal } from "../../util/FormatUtil";
import { isBlank, isOnlyWhitespace } from "../../util/ValidationUtil";
import moment from "moment";
import DatePicker from "react-datepicker";
import OSHAAPI from "../../network/OSHAAPI";
import Claim, { FakeOSHARecord } from "../../types/Claim";

type ClaimsModalProps = {
    claims
    selectedClaim:Claim
    employees
    requery
};

export const ClaimsModal = ({ claims, selectedClaim, employees, requery }: ClaimsModalProps) => {
    const ID = "claimsmodal";
    const [claim, setClaim] = useState(null)
    const [selectedEmployee, setSelectedEmployee] = useState(null)
    const [employeeName, setEmployeeName] = useState('')
    const [accidentDate, setAccidentDate] = useState(null)
    const [accidentDescription, setAccidentDescription] = useState('')
    const [natureOfInjury, setNatureOfInjury] = useState('')
    const [accidentCausedDeath, setAccidentCausedDeath] = useState(null)
    const [medAttnReceived, setMedAttnReceived] = useState(null)
    const [medAttnDescription, setMedAttnDescription] = useState('')
    const [choicePhysician, setChoicePhysician] = useState(null)
    const [treatingPhysicianName, setTreatingPhysicianName] = useState('')
    const [returnToWorkDate, setReturnToWorkDate] = useState(null)
    const [employeeDeathDate, setEmployeeDeathDate] = useState(null)
    const [oshaCaseNum, setOSHACaseNum] = useState('')
    const [status, setStatus] = useState(null)
    const [showLoading, setShowLoading] = useState(false)
    const [lossOfTime, setLossOfTime] = useState(null)
    const [firstLostTimeDate, setFirstLostTimeDate] = useState(null)
    const [firstLostTimeTime, setFirstLostTimeTime] = useState(null)
    const [stoppedWork, setStoppedWork] = useState(null)
    const [doingUsualWork, setDoingUsualWork] = useState(null)
    const [payStoppedDate, setPayStoppedDate] = useState(null)
    const [employeeWages, setEmployeeWages] = useState("")
    const [employeeWageType, setEmployeeWageType] = useState("")
    const [selectedWageTypeLabel, setSelectedWageTypeLabel] = useState(null)

    const fakeOSHARecord = FakeOSHARecord;

    const WageTypeOptions = [
        { label: "Hourly", value: "Hourly" },
        { label: "Daily", value: "Daily" },
        { label: "Weekly", value: "Weekly" },
        { label: "Bi-Weekly", value: "Bi-Weekly" },
        { label: "Yearly", value: "Yearly" },
    ]

    useLayoutEffect(() => {
        $(`#${ID}`).on('shown.bs.modal', function () {
            $(document).off('focusin.modal');
        });

        if (!isEmptyObject(selectedClaim) && selectedClaim.ID) {
            let wageTypeLabel = getLabel(selectedClaim.WageType, WageTypeOptions)

            setClaim(selectedClaim);
            setSelectedEmployee(employees.find(emp => emp.ID === selectedClaim.EmployeeID))
            setEmployeeName(selectedClaim.EmployeeName)
            setAccidentDate(selectedClaim.AccidentDate)
            setAccidentDescription(selectedClaim.AccidentDescription)
            setNatureOfInjury(selectedClaim.NatureOfInjury)
            setAccidentCausedDeath(selectedClaim.AccidentCausedDeath === 1 ? true : false)
            setMedAttnReceived(selectedClaim.MedAttnReceived)
            setMedAttnDescription(selectedClaim.MedAttnDescription)
            setChoicePhysician(selectedClaim.ChoicePhysician)
            setTreatingPhysicianName(selectedClaim.TreatingPhysicianName)
            setReturnToWorkDate(selectedClaim.ReturnToWorkDate)
            setEmployeeDeathDate(selectedClaim.EmployeeDeathDate)
            setOSHACaseNum(selectedClaim.OSHACaseNum)
            setStatus(selectedClaim.Status)
            setLossOfTime(selectedClaim.LossOfTime)
            setFirstLostTimeDate(selectedClaim.FirstLostTimeDate)
            setFirstLostTimeTime(moment(new Date(selectedClaim.FirstLostTimeDate)).format('h:mm a'))
            setStoppedWork(selectedClaim.StoppedWork)
            setDoingUsualWork(selectedClaim.DoingUsualWork)
            setPayStoppedDate(selectedClaim.PayStoppedDate)
            setEmployeeWages(selectedClaim.Wages)
            setEmployeeWageType(selectedClaim.WageType)
            setSelectedWageTypeLabel(wageTypeLabel);
        } else {
            setSelectedEmployee(null)
            setEmployeeName('')
            setAccidentDate(null)
            setAccidentDescription('')
            setNatureOfInjury('')
            setAccidentCausedDeath(null)
            setMedAttnReceived(null)
            setMedAttnDescription('')
            setChoicePhysician(null)
            setTreatingPhysicianName('')
            setReturnToWorkDate(null)
            setEmployeeDeathDate(null)
            setOSHACaseNum("")
            setStatus("New")
            setLossOfTime(null)
            setFirstLostTimeDate(null)
            setFirstLostTimeTime(null)
            setStoppedWork(null)
            setDoingUsualWork(null)
            setPayStoppedDate(null)
            setEmployeeWages('')
            setEmployeeWageType('')
            setSelectedWageTypeLabel(null)
        }
    }, [selectedClaim])


    function clear() {
        setSelectedEmployee(null)
        setEmployeeName('')
        setAccidentDate(null)
        setAccidentDescription('')
        setNatureOfInjury('')
        setAccidentCausedDeath(null)
        setMedAttnReceived(null)
        setMedAttnDescription('')
        setChoicePhysician(null)
        setTreatingPhysicianName('')
        setReturnToWorkDate(null)
        setEmployeeDeathDate(null)
        setOSHACaseNum("")
        setStatus("New")
        setLossOfTime(null)
        setFirstLostTimeDate(null)
        setFirstLostTimeTime(null)
        setStoppedWork(null)
        setDoingUsualWork(null)
        setPayStoppedDate(null)
        setEmployeeWages("")
        setEmployeeWageType("")
        setSelectedWageTypeLabel(null)
    }

    function displayStatus(){
        if(status && status === 'New'){
            return <h5><span style={{color: "green"}}>{status}</span></h5>
        }
        if(status && status === 'In Review'){
            return <h5><span style={{color: "orange"}}>{status}</span></h5>
        }
        if(status && status === 'Denied'){
            return <h5><span style={{color:"red"}}>{status}</span></h5>
        }
        if(status && status === 'Resolved'){
            return <h5><span style={{color:"blue"}}>{status}</span></h5>
        }
    }

    return (
        <>
            <Overlay show_loading={showLoading} zIndex={100003} />
            <div className="modal fade form_modal" id={ID} tabIndex={-1} role="dialog"
                aria-labelledby="result_modal_label" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-xl" role="document">
                    <div className="modal-content">
                        <div className="container-fluid">
                            <div className="modal-header">
                                <h5 className="modal-title" id="result_modal_label">Claims Management</h5>
                                <button style={{ outline: 'none' }} type="button" className="close"
                                    aria-label="Close"
                                    onClick={() => {
                                        // clear()
                                        hideModal("claimsmodal")
                                    }}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">

                                <div className="form-group row" data-toggle={'tooltip'} data-placement={'top'} title="statusTooltip">
                                    <label id={"status"} htmlFor={"status"} className="col-12 col-sm-4 col-form-label text-center text-md-left">Status</label>
                                    <div className="col-12 col-sm-8 p-0 m-0 text-center text-md-left" id={"status"}>
                                        { displayStatus() }
                                    </div>
                                </div>
            {/* Name First Middle Initial Last */}
            {/* TODO If Create New, display select with FakeEmployee options */}
                                {status === "New" && buildRow("Employee Name",
                                    <Select
                                        isSearchable={true}
                                        placeholder={"Please Select..."}
                                        onChange={(e) => {
                                            let selectedEmployee = employees.find(emp => emp.ID === e.value)
                                            setSelectedEmployee(selectedEmployee);
                                        }}
                                        value={selectedEmployee ? {label: selectedEmployee.DisplayName, value: selectedEmployee.ID} : null}
                                        className={"state_select"}
                                        options={employees.map(s => {return {label: s.DisplayName, value: s.ID}})}
                                    />
                                    , 'Employee Name')}
                                {status !== "New" && buildRow("Employee Name",
                                    <input className={"form-control"}
                                        id="EmployeeName"
                                        maxLength={500}
                                        autoComplete={"off"}
                                        type={"search"}
                                        name={"EmployeeName"}
                                        aria-label="Employee Name"
                                        readOnly
                                        value={employeeName}
                                    />
                                    , 'Employee Name')}
                                { buildRow("OSHA Case #",
                                    <input className={"form-control"}
                                        id="OSHACaseNum"
                                        maxLength={50}
                                        placeholder={"OSHA Case #..."}
                                        autoComplete={"off"}
                                        type={"search"}
                                        name={"OSHACaseNum"}
                                        aria-label="OSHA Case #"
                                        onChange={(e) => {
                                            setOSHACaseNum(e.target.value)
                                        }}
                                        onBlur={(e)=>{ 
                                            // TODO:
                                            // OSHAAPI.getFilterREcords(1, {filter: e.target.value})
                                            //     .then((results){
                                            //         // update form with results from query
                                            // })
                                        }}
                                        value={oshaCaseNum ? oshaCaseNum : ''}
                                        readOnly={oshaCaseNum !== "" && selectedClaim.ID}
                                    />
                                    , 'OSHA Case #')}
            {/* date of accident Month/Day/Year */}
                                {buildRow("Accident Date",
                                    <DatePicker
                                        id={'AcccidentDatePicker'}
                                        minDate={new Date('01-01-1970')}
                                        maxDate={new Date('12-31-2100')}
                                        placeholderText="--/--/----"
                                        selected={accidentDate ? 
                                            Date.parse(moment(new Date(accidentDate), 'MM-DD-YYYY').toISOString()) : 
                                            null
                                        }
                                        onChange={(e)=>{ 
                                            // console.log(" e",e)
                                            setAccidentDate(new Date(e))
                                        }}
                                        onBlur={(e)=>{}}
                                        ariaLabelledBy={'AccidentDatePicker'}
                                    />
                                )}

            {/* description of accident */}
                                {buildRow("Accident Description",
                                    <input className={"form-control"}
                                        id="DescriptionOfAccident"
                                        placeholder={"Describe how the accident occurred..."}
                                        maxLength={500}
                                        autoComplete={"off"}
                                        type={"search"}
                                        name={"AccidentDescription"}
                                        aria-label="Accident Description"
                                        onChange={(e) => {
                                            setAccidentDescription(e.target.value)
                                        }}
                                        value={accidentDescription ? accidentDescription : ''}
                                    />
                                    , 'Accident Description')
                                }
                                {buildRow("Nature of Injury",
                                    <input className={"form-control"}
                                        id="NatureOfInjury"
                                        placeholder={"Name part of body affected..."}
                                        maxLength={500}
                                        autoComplete={"off"}
                                        type={"search"}
                                        name={"NatureOfInjury"}
                                        aria-label="Nature Of Injury"
                                        onChange={(e) => {
                                            setNatureOfInjury(e.target.value)
                                        }}
                                        value={natureOfInjury ? natureOfInjury : ''}
                                    />
                                    , 'Nature of Injury')
                                }
            {/* did accident cause death of employee  */}
                                {buildRow("Accident caused death?",
                                    <Select
                                        isSearchable={true}
                                        placeholder={"Please Select..."}
                                        noOptionsMessage={() => "No option"}
                                        value={ accidentCausedDeath !== null ? getBoolSelectVal(accidentCausedDeath) : null}
                                        onChange={(e) => setAccidentCausedDeath(e.value) }
                                        className={"state_select"}
                                        options={[{ label: "Yes", value: true }, { label: "No", value: false }]}
                                    />)
                                }
                                { accidentCausedDeath === true && 
                                    buildRow("Date of Employee Death",
                                    <DatePicker
                                        id={'EmployeeDeathDate'}
                                        minDate={new Date('01-01-1970')}
                                        maxDate={new Date('12-31-2100')}
                                        placeholderText="--/--/----"
                                        selected={employeeDeathDate ? 
                                            Date.parse(moment(new Date(employeeDeathDate), 'MM-DD-YYYY').toISOString()) : 
                                            null
                                        }
                                        onChange={(e)=>{ 
                                            setEmployeeDeathDate(new Date(e))
                                        }}
                                        onBlur={(e)=>{}}
                                        ariaLabelledBy={'EmployeeDeathDate'}
                                    />
                                )}

{/* Did accident cause loss of time beyond day or shift of accident Y/N */}
                                {(accidentCausedDeath === 0 || accidentCausedDeath === false) &&
                                    buildRow("Loss of time beyond day or shift?",
                                        <Select
                                            isSearchable={true}
                                            placeholder={"Please Select..."}
                                            noOptionsMessage={() => "No option"}
                                            value={lossOfTime !== null ? getBoolSelectVal(lossOfTime) : null}
                                            onChange={(e) => {
                                                setLossOfTime(e.value)
                                            }}
                                            className={"state_select"}
                                            options={[{ label: "Yes", value: true }, { label: "No", value: false }]}
                                        />)
                                }
{/* Date and hour employee first lost time because of injury */}

                                {(accidentCausedDeath === 0 || accidentCausedDeath === false) &&
                                    lossOfTime === true &&
                                    buildRow("Date and time Employee first lost time",
                                        <><DatePicker
                                            id={'FirstLostTimeDate'}
                                            // minDate={new Date('01-01-1970')}
                                            // maxDate={new Date('12-31-2100')}
                                            placeholderText="--/--/----"
                                            selected={firstLostTimeDate ?
                                                Date.parse(moment(new Date(firstLostTimeDate), 'MM-DD-YYYY').toISOString()) : 
                                                null
                                            }
                                            onChange={(e) => {
                                                setFirstLostTimeDate(new Date(e))
                                            }}
                                            onBlur={(e) => { }}
                                            ariaLabelledBy={'FirstLostTimeDate'}
                                        />
                                        
                                            <input
                                                type="time"
                                                name={"First Lost Time"}
                                                onChange={(e) => {
                                                    // console.log(" e ",e)
                                                    setFirstLostTimeTime(e.target.value)
                                                    // setFirstLostTimeTime(moment(new Date(e.target.value)).format('h:mm a'))
                                                }}
                                                value={firstLostTimeTime}
                                            /></>,
                                        'First Lost Time Date')
                                }
{/* Was employee doing usual work when injured killed? if no explain in AccidentDescription */}
                                {(accidentCausedDeath === 0 || accidentCausedDeath === false) &&
                                 buildRow("Doing Usual Work?",
                                    <Select
                                        isSearchable={true}
                                        placeholder={"Please Select..."}
                                        noOptionsMessage={() => "No option"}
                                        value={ doingUsualWork !== null ? getBoolSelectVal(doingUsualWork) : null}
                                        onChange={(e) => setDoingUsualWork(e.value) }
                                        className={"state_select"}
                                        options={[{ label: "Yes", value: true }, { label: "No", value: false }]}
                                    />)
                                }
                                { doingUsualWork === false && buildRow("",<span> *Explain in Accident Description </span>)}
{/* Did employee stop work immediately?  */}

                                {(accidentCausedDeath === 0 || accidentCausedDeath === false) &&
                                buildRow("Stopped Work Immediately?",
                                    <Select
                                        isSearchable={true}
                                        placeholder={"Please Select..."}
                                        noOptionsMessage={() => "No option"}
                                        value={ stoppedWork!== null ? getBoolSelectVal(stoppedWork) : null}
                                        onChange={(e) => setStoppedWork(e.value) }
                                        className={"state_select"}
                                        options={[{ label: "Yes", value: true }, { label: "No", value: false }]}
                                    />)
                                }
{/* Date and hour pay stopped */}

                                {(accidentCausedDeath === 0 || accidentCausedDeath === false) &&
                                    buildRow("Date Pay Stopped",
                                    <DatePicker
                                        id={'PayStoppedDate'}
                                        minDate={new Date('01-01-1970')}
                                        maxDate={new Date('12-31-2100')}
                                        placeholderText="--/--/----"
                                        selected={payStoppedDate? 
                                            Date.parse(moment(new Date(payStoppedDate), 'MM-DD-YYYY').toISOString()) : 
                                            null
                                        }
                                        onChange={(e)=>{ 
                                            setPayStoppedDate(new Date(e))
                                        }}
                                        onBlur={(e)=>{}}
                                        ariaLabelledBy={'PayStoppedDate'}
                                    />
                                )}
{/* Date and hour employee returned to work */}
        {/* Date returned to work mm/dd/yyyy */}
                                { (accidentCausedDeath === 0 || accidentCausedDeath === false) && 
                                    buildRow("Return to Work Date",
                                    <DatePicker
                                        id={'ReturnToWorkDate'}
                                        minDate={new Date('01-01-1970')}
                                        maxDate={new Date('12-31-2100')}
                                        placeholderText="--/--/----"
                                        selected={returnToWorkDate ? 
                                            Date.parse(moment(new Date(returnToWorkDate), 'MM-DD-YYYY').toISOString()) : 
                                            null
                                        }
                                        onChange={(e)=>{ 
                                            setReturnToWorkDate(new Date(e))
                                        }}
                                        onBlur={(e)=>{}}
                                        ariaLabelledBy={'ReturnToWorkDate'}
                                    />
                                )}
{/* Wages or earnings 
amount: string
type:hourly daily weekly yearly  */}
                                {(accidentCausedDeath === 0 || accidentCausedDeath === false) &&
                            buildRow("Employee Wages",
                                <div className="row ml-1">
                                <input className={"form-control col-md-6"}
                                    placeholder={"Enter Amount of Wages"}
                                    id="EmployeeWages"
                                    maxLength={500}
                                    autoComplete={"off"}
                                    type={"search"}
                                    name={"EmployeeWages"}
                                    aria-label="Employee Wages"
                                    onChange={(e)=>{
                                        setEmployeeWages(e.target.value)
                                    }}
                                    value={employeeWages ? employeeWages : ''}
                                /> 
                                <Select
                                    isSearchable={true}
                                    placeholder={"Select Wage Type..."}
                                    noOptionsMessage={() => "No option"}
                                    value={ getLabel(employeeWageType, WageTypeOptions) }
                                    onChange={(e) => {
                                        setEmployeeWageType(e.value)
                                        setSelectedWageTypeLabel({label: e.value, value:e.value})
                                    }}
                                    className={"state_select col-md-6"}
                                    options={WageTypeOptions}
                                />
                            </div>
                            , 'Employee Wages')}
            {/* did employee receive medical attn 
                Y = notes field
                N = notes field */}
                                { accidentCausedDeath !== null && 
                                  (accidentCausedDeath === 0 || accidentCausedDeath === false )&& 
                                    buildRow("Medical Attention Received?",
                                    <Select
                                        isSearchable={true}
                                        placeholder={"Please Select..."}
                                        noOptionsMessage={() => "No option"}
                                        value={ medAttnReceived !== null ? getBoolSelectVal(medAttnReceived) : null }
                                        onChange={(e) => 
                                            // setMedAttnReceived(e.value ? 1 : 0) 
                                            setMedAttnReceived(e.value) 
                                        }
                                        className={"state_select"}
                                        options={[{ label: "Yes", value: true }, { label: "No", value: false }]}
                                    />)
                                }
                                { (accidentCausedDeath === 0 || accidentCausedDeath === false) && 
                                  medAttnReceived !== null && 
                                    buildRow("Medical Attention Description",
                                    <input className={"form-control"}
                                        id="MedicalAttentionDescription"
                                        placeholder={"Please Describe..."}
                                        maxLength={500}
                                        autoComplete={"off"}
                                        type={"search"}
                                        name={"MedAttnDescription"}
                                        aria-label="Medical Attention Description"
                                        onChange={(e)=>{
                                            setMedAttnDescription(e.target.value)
                                        }}
                                        value={medAttnDescription ? medAttnDescription: ''}
                                    />
                                , 'Medical Attention Description')}

            {/* was employee treated by his/her choice of physician*/}
                                { (accidentCausedDeath === 0 || accidentCausedDeath === false) && 
                                  medAttnReceived === true && 
                                    buildRow("Treated by choice physician?",
                                    <Select
                                        isSearchable={true}
                                        placeholder={"Please Select..."}
                                        noOptionsMessage={() => "No option"}
                                        value={ choicePhysician !== null ? getBoolSelectVal(choicePhysician) : null}
                                        onChange={(e) => {
                                            // setChoicePhysician(e.value ? 1 : 0)
                                            setChoicePhysician(e.value)
                                        }}
                                        className={"state_select"}
                                        options={[{ label: "Yes", value: true }, { label: "No", value: false }]}
                                    />)
                                }
                                { (accidentCausedDeath === 0 || accidentCausedDeath === false) &&
                                  choicePhysician === true && 
                                    buildRow("Treating Physician Name",
                                    <input className={"form-control"}
                                        id="TreatingPhysicianName"
                                        placeholder={"Treating Physician Name..."}
                                        maxLength={500}
                                        autoComplete={"off"}
                                        type={"search"}
                                        name={"TreatingPhysicianName"}
                                        aria-label="Treating Physician Name"
                                        onChange={(e)=>{
                                            setTreatingPhysicianName(e.target.value)
                                        }}
                                        value={treatingPhysicianName ? treatingPhysicianName : ''}
                                    />
                                , 'Treating Physician Name')}

                                {/* Did accident cause loss of time beyond day or shift of accident? */}
                                {/* initial period of disability 
                                    from mm/dd/yyyy 
                                    through mm/dd/yyyy 
                                */}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn immySubmitButtonOutline"
                                    style={{ float: 'right' }}
                                    onClick={async () => {
                                        //TODO - unfinished validations 

                                        let edited = {
                                            EmployeeName: employeeName,
                                            AccidentDate: accidentDate,
                                            AccidentDescription: accidentDescription,
                                            NatureOfInjury: natureOfInjury,
                                            AccidentCausedDeath: accidentCausedDeath,
                                            MedAttnReceived: medAttnReceived,
                                            MedAttnDescription: medAttnDescription,
                                            ChoicePhysician: choicePhysician,
                                            TreatingPhysicianName: treatingPhysicianName,
                                            ReturnToWorkDate: returnToWorkDate,
                                            OSHACaseNum: oshaCaseNum,
                                            Status: status
                                        }

                                        // if (isBlank(employeeName) || isOnlyWhitespace(employeeName)) {
                                        //     return sweetalert.fire("", "Employee Name cannot be empty.", "error")
                                        // }
                                        // if (accidentDate === null) {
                                        //     return sweetalert.fire("", "Accident Date cannot be empty.", "error")
                                        // }
                                        // if (isBlank(accidentDescription) || isOnlyWhitespace(accidentDescription)) {
                                        //     return sweetalert.fire("", "Accident Description cannot be empty.", "error")
                                        // }
                                        // if (isBlank(natureOfInjury) || isOnlyWhitespace(natureOfInjury)) {
                                        //     return sweetalert.fire("", "Nature of Injury cannot be empty.", "error")
                                        // }
                                        // if (accidentCausedDeath === null) {
                                        //     return sweetalert.fire("", "Accident Caused Death cannot be empty.", "error")
                                        // }
                                        // if (accidentCausedDeath === true && employeeDeathDate === null) {
                                        //     return sweetalert.fire("", "Date of Employee Death cannot be empty.", "error")
                                        // }
                                        //TODO This validation isn't working properly, hits when accidentCausedDeath === true
                                        // if ( accidentCausedDeath === false && medAttnReceived === null) {
                                        //     return sweetalert.fire("", "Medical Attention Received cannot be empty.", "error")
                                        // }
                                        // if (medAttnReceived !== null && isBlank(medAttnDescription) || isOnlyWhitespace(medAttnDescription)) {
                                        //     return sweetalert.fire("", "Medical Attention Description cannot be empty.", "error")
                                        // }
                                        // if (medAttnReceived === true && choicePhysician === null) {
                                        //     return sweetalert.fire("", "Treated by Choice Physician cannot be empty.", "error")
                                        // }
                                        // if (choicePhysician === true && (isBlank(treatingPhysicianName) || isOnlyWhitespace(treatingPhysicianName)) ) {
                                        //     return sweetalert.fire("", "Treating Physician Name cannot be empty.", "error")
                                        // }
                                        // if (accidentCausedDeath === false && !returnToWorkDate) {
                                        //     return sweetalert.fire("", "Return To Work Date cannot be empty.", "error")
                                        // }
                                        // if (claims && claims.length > 0) {
                                        //     for (let i = 0; i < claims.length; i++) {
                                        //         // if(claims[i].EName.toLowerCase() === employeeName.toLowerCase()){
                                        //         //     if(claims[i].ID !== selectedClaim.ID){
                                        //         //         return sweetalert.fire("","Employee Names cannot be duplicated.", "error")
                                        //         //     }
                                        //         // }
                                        //     }
                                        // }

                                        // Faking API call here
                                        setShowLoading(true)
                                        sweetalert.fire({icon: 'success',title: '', text: 'Saved'})
                                        setShowLoading(false);
                                        hideModal("claimsmodal")
                                        // clear()
                                        requery()
                                    }}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}