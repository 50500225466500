import React from 'react';
import { buildRow, getLabel, getTwelveHourAMPMTime, parseDate, parseDateWithZ } from '../../util/FormatUtil';
import Select from 'react-select';
import EventSched, { EventBreak } from '../../types/Event';
import { isEmptyObject } from 'jquery';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { sweetalert } from '../../App';
import Validator from '../../validation/Validator';
import { Validators } from '../../validation/Validators';
import AdminAPI from '../../network/AdminAPI';
import { CustomFieldBuilder } from '../../customfields/CustomFieldBuilder';
import GetField from '../../customfields/GetField';
import EventAPI from '../../network/EventAPI';

interface EventModalProps {
  selectedEvent;
  facilties;
  schedule;
  services;
  onSubmit: (event: EventSched, EventBreaks: EventBreak[]) => void;
  pageCustomQuestions?;
  allEvents?: { label: string; value: number }[];
}

interface EventModalState {
  selectedEvent?;
  events?;
  eventID?;
  facility?;
  isActive?;
  service?;
  isPrivate?;
  accessCode?;
  date?;
  startTime?;
  endTime?;
  nickName?;
  breaks: { StartTime: Date; EndTime: Date }[];
  breakStartSelector?;
  breakEndSelector?;
  factor?;
  eventLabel?;
  customFieldData?;
  createdDate?;
  hasAppts?: boolean;
  breakChanged?;
  eventDataChanged?;
  rawDateInput
}

export class EventManagementModal extends React.Component<EventModalProps, EventModalState> {
  public static ID = 'eventedit';

  constructor(props) {
    super(props);
    this.state = {
      hasAppts: false,
      breaks: [],
      breakEndSelector: '',
      breakStartSelector: '',
      accessCode: '',
      nickName: '',
      customFieldData: null,
      rawDateInput: '',
    };
    this.deleteBreak = this.deleteBreak.bind(this);
    this.newBreak = this.newBreak.bind(this);
    this.isBreakValid = this.isBreakValid.bind(this);
    this.isOverlapping = this.isOverlapping.bind(this);
    this.breaksOverlapping = this.breaksOverlapping.bind(this);
    this.removeFieldFromPopUp = this.removeFieldFromPopUp.bind(this);
    //this.doesEventConflictWithBreak = this.doesEventConflictWithBreak(this);
  }

  getBoolSelect(val) {
    return val === 1 ? { label: 'Yes', value: true } : { label: 'No', value: false };
  }
  minutesOfDay(m) {
    return m.minutes() + m.hours() * 60;
  }

  //Is break overlapping with existing appointment?
  isOverlapping() {
    let date = new Date();
    let factor = this.state.factor;
    let doesOverlap = 0;

    //B is Break
    let BStart = `${date.toISOString().split('T')[0]}T${this.state.breakStartSelector}`;
    let BEnd = `${date.toISOString().split('T')[0]}T${this.state.breakEndSelector}`;
    if (this.state.events && this.props.selectedEvent && JSON.stringify(this.props.selectedEvent) !== '{}' && this.state.events[this.props.selectedEvent.ID]?.times.length === 0) {
      return true;
    }
    this.state.events && this.props.selectedEvent && JSON.stringify(this.props.selectedEvent) !== '{}' && this.state.events[this.props.selectedEvent.ID]?.times.forEach((element, index) => {
      let time = element.time.slice(0, 5);
      let endTime = element.time.slice(0, 3);
      let endNum = (parseInt(time.slice(3, 5)) + 5).toString();
      if (endNum === '60') {
        let hour = (parseInt(endTime.slice(0, 2)) + 1).toString();
        endTime = hour + ':';
      } else {
      }
      let period = element.time.slice(6);
      let AStart = `${date.toISOString().split('T')[0]}T${time}`;
      let AEnd = `${date.toISOString().split('T')[0]}T${endTime}${endNum}`;
      let BStartDate = Date.parse(BStart);
      let BEndDate = Date.parse(BEnd);
      let AStartDate = Date.parse(AStart);
      let AEndDate = Date.parse(AEnd); 

      if (BStartDate <= AStartDate && BEndDate >= AStartDate && element.remaining < factor) {
        doesOverlap = 1;
      } else if (BEndDate >= AEndDate && BStartDate < AEndDate && element.remaining < factor) {
        doesOverlap = 1;
      } else if (BStartDate >= AStartDate && BEndDate <= AEndDate && element.remaining < factor) {
        doesOverlap = 1;
      }
    });
    if (doesOverlap === 1) {
      return true;
    } else {
      return false;
    }
  }

  //Is break overlapping with another break?
  breaksOverlapping() {
    let doesOverlap = 0;

    //B is Break
    const today = new Date();
    const newBStartDateTimeString = `${today.toISOString().split('T')[0]}T${this.state.breakStartSelector}`;
    let newBStart = Date.parse(newBStartDateTimeString);
    const newBEndDateTimeString = `${today.toISOString().split('T')[0]}T${this.state.breakEndSelector}`;
    let newBEnd = Date.parse(newBEndDateTimeString);

    if (this.state.breaks.length === 0) {
      return false;
    }
    if (this.state.breakChanged) {
      this.state.breaks?.forEach((element, index) => {
        let startTime = element.StartTime;
        let endTime = element.EndTime;
        const oldBStartDateTimeString = `${today.toISOString().split('T')[0]}T${startTime}`;
        let oldBStartDate = Date.parse(oldBStartDateTimeString);
        const oldBEndDateTimeString = `${today.toISOString().split('T')[0]}T${endTime}`;
        let oldBEndDate = Date.parse(oldBEndDateTimeString);

        if (newBStart <= oldBStartDate && newBEnd >= oldBStartDate) {
          doesOverlap = 1;
        } else if (newBEnd >= oldBEndDate && newBStart < oldBEndDate) {
          doesOverlap = 1;
        } else if (newBStart >= oldBStartDate && newBEnd <= oldBEndDate) {
          doesOverlap = 1;
        }
      });
    }
    if (doesOverlap === 1) {
      return true;
    } else {
      return false;
    }
  }

  isBreakValid(EventBreak: EventBreak) {
    if (this.props.selectedEvent && this.isOverlapping()) {
      sweetalert.fire({
        icon: 'error',
        title: '',
        text: 'Break time conflicts with scheduled appointments!',
      });
      return false;
    }
    if (this.breaksOverlapping()) {
      sweetalert.fire({
        icon: 'error',
        title: '',
        text: 'Break time conflicts already scheduled break!',
      });
      return false;
    }
    if (!EventBreak.StartTime || !EventBreak.EndTime) {
      sweetalert.fire({
        icon: 'error',
        title: '',
        text: 'Start and end time must be completed',
      });
      return false;
    }
    let startMoment = this.minutesOfDay(moment(EventBreak.StartTime));
    let endMoment = this.minutesOfDay(moment(EventBreak.EndTime));

    if (startMoment > endMoment) {
      sweetalert.fire({
        icon: 'error',
        title: '',
        text: 'Break start time cannot be after break end time',
      });
      return false;
    }
    let eventEndMoment = this.minutesOfDay(moment(this.state.endTime));
    let eventStartMoment = this.minutesOfDay(moment(this.state.startTime));
    if (endMoment > eventEndMoment) {
      sweetalert.fire({
        icon: 'error',
        title: '',
        text: 'Breaks must end before event ends',
      });
      return false;
    }
    if (startMoment < eventStartMoment) {
      sweetalert.fire({
        icon: 'error',
        title: '',
        text: 'Breaks must start after a event starts',
      });
      return false;
    }

    return true;
  }

  componentWillReceiveProps(nextProps: Readonly<EventModalProps>, nextContext: any) {
    // console.log('nextProps', nextProps);
      if (!isEmptyObject(nextProps.selectedEvent)) {
        let event: EventSched = nextProps.selectedEvent;

        let services = [];
        if (event.ServiceIDs) {
          let serviceIDs = JSON.parse(event.ServiceIDs);
          if (serviceIDs.length > 0)
            serviceIDs.map(s => {
              services.push(getLabel(s, this.props.services));
            });
        }
        let facility = this.props.facilties.find(f => f.value === event.FacilityID);
        let eventLabel = this.props.allEvents.find(f => f.value === event.ID);
        let events = {};
        this.props.schedule[event.FacilityID]
          ? this.props.schedule[event.FacilityID].days.forEach((element, index) => {
              events[element.schedID] = element;
            })
          : (events = {});
        let breaks: EventBreak[] = [];
        for (const eventBreak of event['EventBreaks'] as EventBreak[]) {
          breaks.push({
            StartTime: eventBreak.StartTime,
            EndTime: eventBreak.EndTime,
          });
        }
        this.setState({
          rawDateInput: parseDateWithZ(event.TestDate),
          selectedEvent: {
            eventID: event.ID,
            facility: facility ? facility : '',
            isActive: this.getBoolSelect(event.Active),
            accessCode: event.AccessCode,
            services: services,
            isPrivate: this.getBoolSelect(event.Private),
            date: parseDateWithZ(event.TestDate),
            startTime: event.StartTime,
            endTime: event.EndTime,
            nickName: event.Nickname ? event.Nickname : '',
            breaks: breaks ? breaks : [],
            breakStartSelector: '',
            breakEndSelector: '',
            factor: event.Factor ? event.Factor : 0,
            eventLabel: eventLabel ? eventLabel?.label : '',
            CustomFieldData: nextProps.selectedEvent && nextProps.selectedEvent.CustomFieldData ? JSON.parse(nextProps.selectedEvent.CustomFieldData) : null,
            createdDate: event.CreatedDate ? event.CreatedDate : null,
          },
          hasAppts: event.Booked > 0 ? true : false,
          events: events,
          eventID: event.ID,
          facility: facility ? facility : '',
          isActive: this.getBoolSelect(event.Active),
          accessCode: event.AccessCode,
          service: services,
          isPrivate: this.getBoolSelect(event.Private),
          date: parseDateWithZ(event.TestDate),
          startTime: event.StartTime,
          endTime: event.EndTime,
          nickName: event.Nickname ? event.Nickname : '',
          breaks: breaks ? breaks : [],
          breakStartSelector: '',
          breakEndSelector: '',
          factor: event.Factor ? event.Factor : 0,
          eventLabel: eventLabel.label,
          customFieldData: event && event.CustomFieldData ? JSON.parse(event.CustomFieldData) : null,
          createdDate: event.CreatedDate ? event.CreatedDate : null,
          breakChanged: 0,
          eventDataChanged: [],
        });
      } else {
        this.setState({
          rawDateInput: '',
          selectedEvent: {},
          eventID: -1,
          facility: '',
          accessCode: '',
          isActive: '',
          service: null,
          isPrivate: '',
          date: '',
          startTime: '',
          endTime: '',
          nickName: '',
          breaks: [],
          breakStartSelector: '',
          breakEndSelector: '',
          factor: '',
          customFieldData: null,
          createdDate: new Date(),
          breakChanged: 0,
          eventDataChanged: [],
          hasAppts: false,
        });
      }
  }

  newBreak() {
    let breaks: EventBreak[] = JSON.parse(JSON.stringify(this.state.breaks));
    let now = moment().utc().startOf('day').toDate();
    let eventDate;

    if (this.props.selectedEvent) {
      eventDate = moment(this.props.selectedEvent.TestDate).utc().startOf('day').toDate();
    }
    if (this.props.selectedEvent === null || eventDate >= now) {
      if (
        !this.isBreakValid({
          StartTime: this.state.breakStartSelector,
          EndTime: this.state.breakEndSelector,
        })
      ) {
        return;
      }
    } else {
      sweetalert.fire({
        icon: 'error',
        title: '',
        text: "You can't schedule a break for an Event in the past!",
      });
      return;
    }
    this.setState({
      breakChanged: 0,
    });
    if (this.state.eventDataChanged && !this.state.eventDataChanged.includes('Added Break')) {
      this.setState({
        eventDataChanged: [...this.state.eventDataChanged, 'Added Break'],
      });
    }
    breaks.push({
      StartTime: JSON.parse(JSON.stringify(this.state.breakStartSelector)),
      EndTime: JSON.parse(JSON.stringify(this.state.breakEndSelector)),
    });
    this.setState({ breaks: breaks });
  }

  deleteBreak(index) {
    let breaks: EventBreak[] = JSON.parse(JSON.stringify(this.state.breaks));
    breaks.splice(index, 1);
    this.setState({ breaks: breaks });
  }
  public static display() {
    window['$']('#' + EventManagementModal.ID).modal('show');
  }

  doesEventConflictWithBreak(value, state) {
    let today = new Date();
    let dateToCheck = `${today.toISOString().split('T')[0]}T${value}`;
    let doesConflict = 0;
    let date = new Date();

    this.state.breaks?.forEach((element, index) => {
      let startTime = `${today.toISOString().split('T')[0]}T${element.StartTime}`;
      let endTime = `${today.toISOString().split('T')[0]}T${element.EndTime}`;

      if (state === 'startTime') {
        if (Date.parse(dateToCheck) > Date.parse(startTime)) {
          doesConflict = 1;
        }
      }
      if (state === 'endTime') {
        if (Date.parse(dateToCheck) < Date.parse(endTime)) {
          doesConflict = 1;
        }
      }
    });
    return doesConflict;
  }

  handleDateOnChange(value, state) {
    if (state === 'startTime' || state === 'endTime') {
      if (state === 'startTime' && this.doesEventConflictWithBreak(value, state)) {
        sweetalert.fire({
          icon: 'error',
          title: '',
          text: 'Events can not start after the start of a break.',
        });
      }
      if (state === 'endTime' && this.doesEventConflictWithBreak(value, state)) {
        sweetalert.fire({
          icon: 'error',
          title: '',
          text: 'Events can not end before the end of a break.',
        });
      }
    }
    if (value) {
      this.setState({ [state]: value } as any);
    } else {
      this.setState({ [state]: null } as any);
    }
  }

  getServicesDisplayValue() {
    let servicesLabelArray = [];
    this.state.service.map(s => {
      servicesLabelArray.push(s.label);
    });
    return servicesLabelArray.join(', ');
  }

  removeFieldFromPopUp(field) {
    if (this.state.eventDataChanged && this.state.eventDataChanged.includes(field)) {
      const index = this.state.eventDataChanged.indexOf(field);
      this.state.eventDataChanged.splice(index, 1);
      return;
    }
    return;
  }

  render() {
    // console.log('EventMgmtModal props', this.props);
    // console.log('EventMgmtModal state', this.state);
    return (
      <div className="modal fade form_modal" id={EventManagementModal.ID} tabIndex={-1} role="dialog" aria-labelledby="result_modal_label" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-xl" role="document" style={{ display: 'block' }}>
          <div className="modal-content">
            <div className="container-fluid">
              <div className="modal-header">
                <h5 className="modal-title" id="result_modal_label">
                  {this.state.eventID && this.state.eventID !== -1 ? 'Update Event' : 'Create Event'}
                </h5>
                <button style={{ outline: 'none' }} type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body my-0 mx-3">
                {(this.state.eventID && this.state.eventID !== -1) && buildRow('Event', <input className="form-control" autoComplete="off" disabled={true} value={this.state.eventLabel} />)}

                {this.state.hasAppts
                  ? buildRow('Facility', <input className="form-control" autoComplete="off" disabled={true} value={this.state.facility.label} />)
                  : buildRow(
                      'Facility',
                      <Select
                        isSearchable={true}
                        placeholder={'Please Select...'}
                        noOptionsMessage={() => 'No option'}
                        value={this.state.facility}
                        onChange={e => {
                          this.setState({ facility: e });
                          if (e.value === this.props.selectedEvent?.FacilityID) {
                            this.removeFieldFromPopUp('Facility');
                          } else if (this.state.eventDataChanged && !this.state.eventDataChanged.includes('Facility')) {
                            this.setState({
                              eventDataChanged: [...this.state.eventDataChanged, 'Facility'],
                            });
                          }
                        }}
                        className={'state_select'}
                        options={this.props.facilties}
                        isDisabled={this.state.hasAppts}
                      />
                    )}

                {buildRow(
                  'Is Active?',
                  <Select
                    isSearchable={true}
                    placeholder={'Please Select...'}
                    noOptionsMessage={() => 'No option'}
                    value={this.state.isActive}
                    onChange={e => {
                      this.setState({ isActive: e });
                      if (e.value ? 1 : 0 === this.props.selectedEvent?.Active) {
                        this.removeFieldFromPopUp('Is Active');
                      } else if (this.state.eventDataChanged && !this.state.eventDataChanged.includes('Is Active')) {
                        this.setState({
                          eventDataChanged: [...this.state.eventDataChanged, 'Is Active'],
                        });
                      }
                    }}
                    className={'state_select'}
                    options={[
                      { label: 'Yes', value: true },
                      { label: 'No', value: false },
                    ]}
                  />
                )}

                {buildRow(
                  'Appointments Per 5 Minutes',
                  <input
                    className="form-control"
                    autoComplete="off"
                    onChange={e => {
                      this.setState({ factor: e.target.value });
                      if (e.target.value == this.props.selectedEvent?.Factor) {
                        this.removeFieldFromPopUp('Appointments Per 5 Minutes');
                      } else if (this.state.eventDataChanged && !this.state.eventDataChanged.includes('Appointments Per 5 Minutes')) {
                        this.setState({
                          eventDataChanged: [...this.state.eventDataChanged, 'Appointments Per 5 Minutes'],
                        });
                      }
                    }}
                    value={this.state.factor}
                    pattern={'[0-9]'}
                    min={0}
                    max={100}
                    disabled={this.state.hasAppts}
                  />
                )}
                {this.state.hasAppts
                  ? buildRow('Services', <input className="form-control" autoComplete="off" disabled={true} value={this.getServicesDisplayValue()} />)
                  : buildRow(
                      'Services',
                      <Select
                        isMulti={true}
                        isSearchable={true}
                        placeholder={'Please Select...'}
                        noOptionsMessage={() => 'No option'}
                        value={this.state.service}
                        onChange={e => {
                          let mappedArray;
                          if (e !== null) {
                            mappedArray = e.map(elem => {
                              return elem.value;
                            });
                          }
                          this.setState({ service: e });
                          if (JSON.stringify(mappedArray) === this.props.selectedEvent?.ServiceIDs) {
                            this.removeFieldFromPopUp('Services');
                          } else if (this.state.eventDataChanged && !this.state.eventDataChanged.includes('Services')) {
                            this.setState({
                              eventDataChanged: [...this.state.eventDataChanged, 'Services'],
                            });
                          }
                        }}
                        className={'state_select'}
                        options={this.props.services}
                      />
                    )}

                {this.state.hasAppts
                  ? buildRow('Is Private?', <input className="form-control" autoComplete="off" disabled={true} value={this.state.isPrivate.label} />)
                  : buildRow(
                      'Is Private?',
                      <Select
                        isSearchable={true}
                        placeholder={'Please Select...'}
                        noOptionsMessage={() => 'No option'}
                        value={this.state.isPrivate}
                        onChange={e => {
                          this.setState({ isPrivate: e });
                          if (e.value == this.props.selectedEvent?.Private) {
                            this.removeFieldFromPopUp('Is Private');
                          } else if (this.state.eventDataChanged && !this.state.eventDataChanged.includes('Is Private')) {
                            this.setState({
                              eventDataChanged: [...this.state.eventDataChanged, 'Is Private'],
                            });
                          }
                        }}
                        className={'state_select'}
                        options={[
                          { label: 'Yes', value: true },
                          { label: 'No', value: false },
                        ]}
                        isDisabled={this.state.hasAppts}
                      />
                    )}

                {buildRow(
                  'Access Code',
                  <input
                    className="form-control"
                    autoComplete="off"
                    onChange={e => {
                      this.setState({ accessCode: e.target.value });
                      if (e.target.value == this.props.selectedEvent?.AccessCode) {
                        this.removeFieldFromPopUp('Access Code');
                      } else if (this.state.eventDataChanged && !this.state.eventDataChanged.includes('Access Code')) {
                        this.setState({
                          eventDataChanged: [...this.state.eventDataChanged, 'Access Code'],
                        });
                      }
                    }}
                    value={this.state.accessCode}
                    maxLength={200}
                    disabled={this.state.isPrivate && !this.state.isPrivate.value}
                  />
                )}
                {buildRow(
                  'Date',
                  <input
                    className="form-control"
                    id={this.state.eventID}
                    type={'date'}
                    onChange={(e) => {
                      const value = e.target.value;
                      this.setState({rawDateInput: value});
                  }}
                  onBlur={(e) => {
                    const value = e.target.value;

                     // Regular expression to check for YYYY-MM-DD format
                    const datePattern = /^\d{4}-\d{2}-\d{2}$/;
                    if(value){
                      if (datePattern.test(value)) {
                          // If the date format is correct, update the state
  
                          this.handleDateOnChange(value, 'date');
                          if (this.props.selectedEvent && JSON.stringify(this.props.selectedEvent) !== '{}' && this.props.selectedEvent?.TestDate.includes(moment(e.target.value).format('YYYY-MM-DD'))) {
                            this.removeFieldFromPopUp('Date');
                          } else if (this.state.eventDataChanged && !this.state.eventDataChanged.includes('Date')) {
                            this.setState({
                              eventDataChanged: [...this.state.eventDataChanged, 'Date'],
                            });
                          }
                          this.setState({rawDateInput: value});
                      } else {
                          // Optionally handle invalid date format
                          console.error('Invalid date format:', value);
                          this.setState({ rawDateInput: '' }); // Clear the input or handle as needed
                      }
                    }
                }}
                value={this.state.rawDateInput ? this.state.rawDateInput : null}
                    disabled={this.state.hasAppts}
                    // value={this.state.date ? this.state.date : null}
                  />
                )}
                {buildRow(
                  'Start Time',
                  <input
                    className="form-control"
                    placeholder="--:-- --"
                    id={this.state.eventID}
                    type={'time'}
                    onChange={e => {
                      this.handleDateOnChange(e.target.value, 'startTime');
                      if (e.target.value.includes(this.props.selectedEvent?.StartTime)) {
                        this.removeFieldFromPopUp('Start Time');
                      } else if (this.state.eventDataChanged && !this.state.eventDataChanged.includes('Start Time')) {
                        this.setState({
                          eventDataChanged: [...this.state.eventDataChanged, 'Start Time'],
                        });
                      }
                    }}
                    value={this.state.startTime ? this.state.startTime : null}
                  />
                )}
                {buildRow(
                  'End Time',
                  <input
                    className="form-control"
                    placeholder="--:-- --"
                    id={this.state.eventID}
                    type={'time'}
                    onChange={e => {
                      this.handleDateOnChange(e.target.value, 'endTime');
                      if (e.target.value.includes(this.props.selectedEvent?.EndTime)) {
                        this.removeFieldFromPopUp('End Time');
                      } else if (this.state.eventDataChanged && !this.state.eventDataChanged.includes('End Time')) {
                        this.setState({
                          eventDataChanged: [...this.state.eventDataChanged, 'End Time'],
                        });
                      }
                    }}
                    value={this.state.endTime ? this.state.endTime : null}
                  />
                )}
                {buildRow(
                  'Nickname (internal only)',
                  <input
                    className="form-control"
                    autoComplete="off"
                    onChange={e => {
                      this.setState({ nickName: e.target.value });
                      if (e.target.value === this.props.selectedEvent?.Nickname) {
                        this.removeFieldFromPopUp('Nickname');
                      } else if (this.state.eventDataChanged && !this.state.eventDataChanged.includes('Nickname')) {
                        this.setState({
                          eventDataChanged: [...this.state.eventDataChanged, 'Nickname'],
                        });
                      }
                    }}
                    value={this.state.nickName}
                    maxLength={200}
                  />
                )}

                {/* Custom Field Data  */}

                {this.props.pageCustomQuestions.map((val, index) => {
                  if (val.Options.isActive) {
                    return (
                      <div className="col-12 p-0">
                        <div className="form-group row">
                          <GetField
                            val={val}
                            selectedItem={this.state.selectedEvent ? this.state.selectedEvent : {}}
                            handleChange={e => {
                              this.setState({
                                customFieldData: e.CustomFieldData,
                                selectedEvent: e,
                              });
                            }}
                          />
                        </div>
                      </div>
                    );
                  }
                })}

                <h5 className="text-center text-md-left">Scheduled Breaks</h5>
                <div className="row">
                  {/* tablets and desktop  */}
                  <div className="col-12 d-none d-md-block">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">Start</th>
                          <th scope="col">End</th>
                        </tr>
                      </thead>
                      {this.state.breaks.map((b:any, index) => {
                        return (
                          <tr>
                            <td>{getTwelveHourAMPMTime(b.StartTime)}</td>
                            <td>{getTwelveHourAMPMTime(b.EndTime)}</td>
                            <td>
                              <button
                                className={'btn immyClearButtonOutline'}
                                onClick={() => {
                                  this.deleteBreak(index);
                                  if (this.state.eventDataChanged && !this.state.eventDataChanged.includes('Deleted Break')) {
                                    this.setState({
                                      eventDataChanged: [...this.state.eventDataChanged, 'Deleted Break'],
                                    });
                                  }
                                }}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td>
                        <input
                          className="form-control"
                          placeholder="--:-- --"
                          id={this.state.eventID}
                          type={'time'}
                          onChange={e => {
                            this.handleDateOnChange(e.target.value, 'breakStartSelector');
                              if (e === null && (this.state.breakEndSelector === null || this.state.breakEndSelector === '')) {
                                this.setState({ breakChanged: 0 });
                              } else {
                                this.setState({ breakChanged: 1 });
                              }
                          }}
                          disabled={!this.state.startTime || !this.state.endTime}
                          value={this.state.breakStartSelector ? this.state.breakStartSelector : null}
                        />
                        </td>
                        <td>
                        <input
                          className="form-control"
                          placeholder="--:-- --"
                          id={this.state.eventID}
                          type={'time'}
                          onChange={e => {
                              this.handleDateOnChange(e.target.value, 'breakEndSelector');
                              if (e === null && (this.state.breakStartSelector === null || this.state.breakStartSelector === '')) {
                                this.setState({ breakChanged: 0 });
                              } else {
                                this.setState({ breakChanged: 1 });
                              }
                          }}
                          disabled={!this.state.startTime || !this.state.endTime}
                          value={this.state.breakEndSelector ? this.state.breakEndSelector : null}
                        />
                        </td>
                        <td>
                          <button disabled={!this.state.startTime || !this.state.endTime} className={'btn immySubmitButtonOutline '} onClick={this.newBreak}>
                            Add
                          </button>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>

                {/* mobile  */}
                <div className="row d-block d-md-none">
                  <div className={this.state.breaks?.length > 0 ? 'row d-flex justify-content-center border border-secondary rounded p-2' : 'row'}>
                    {this.state.breaks.map((b:any, index) => {
                      return (
                        <div className="row mt-1">
                          <div className={'col-5'}>{getTwelveHourAMPMTime(b.StartTime)}</div>
                          <div className={'col-5'}>{getTwelveHourAMPMTime(b.EndTime)}</div>
                          <div className={'col-2'}>
                            <button className={'btn btn-sm immyClearButtonOutline'} onClick={() => this.deleteBreak(index)}>
                              Delete
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="row mt-2">
                    <div className="col-2">
                      <label htmlFor="Start">Start</label>
                      <div className="row">
                        <div className="col-12">
                          <label htmlFor="End">End</label>
                        </div>
                      </div>
                    </div>
                    <div className={'col-7'}>
                      <input
                          placeholder="--:-- --"
                          id={this.state.eventID}
                          type={'time'}
                          onChange={e => this.handleDateOnChange(e.target.value, 'breakStartSelector')}
                          disabled={!this.state.startTime || !this.state.endTime}
                          value={this.state.breakStartSelector ? this.state.breakStartSelector : null}
                      />
                      <div className="row">
                        <div className="col-12">
                        <input
                          placeholder="--:-- --"
                          id={this.state.eventID}
                          type={'time'}
                          onChange={e => this.handleDateOnChange(e.target.value, 'breakEndSelector')}
                          disabled={!this.state.startTime || !this.state.endTime}
                          value={this.state.breakEndSelector ? this.state.breakEndSelector : null}
                        />
                        </div>
                      </div>
                    </div>
                    <div className="col-2">
                      <button disabled={!this.state.startTime || !this.state.endTime} className={'btn btn-sm immySubmitButtonOutline'} onClick={this.newBreak}>
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    if (this.state.breakChanged || (this.state.eventDataChanged && this.state.eventID > 0 && this.state.eventDataChanged.length > 0)) {
                      return sweetalert
                        .fire({
                          padding: '8px',
                          icon: 'warning',
                          title: '',
                          html: this.state.breakChanged
                            ? `<p>Are you sure you want to continue? There are breaks that have not been added yet. Please select the Add button to Add a break to this Event."</p>`
                            : `<p>Please be sure to manually notify employees of the changes made to this event. Employees will NOT be notified automatically.</p>  <p>Changes:</p> ${this.state.eventDataChanged && this.state.eventDataChanged.join(
                                '<br>'
                              )}`,
                          showConfirmButton: true,
                          showCancelButton: true,
                          confirmButtonText: 'Continue',
                        })
                        .then(result => {
                          if (result.isConfirmed) {
                            let formValidation = {
                              Facility: this.state.facility,
                              IsPrivate: this.state.isPrivate,
                              IsActive: this.state.isActive,
                              Factor: this.state.factor,
                              Service: this.state.service,
                              AccessCode: this.state.accessCode,
                              Date: this.state.date,
                              StartTime: this.state.startTime,
                              EndTime: this.state.endTime,
                            };

                            let validator = new Validator<any>()
                              .withSimpleValidation('Facility', Validators.requireNonNullValidator())
                              .withSimpleValidation('IsActive', Validators.requireNonNullValidator('Active'))
                              .withValidation('Factor', Validators.requireFactor(0, 100, 'Appointment Per 5 Mins'))
                              .withSimpleValidation('Service', Validators.requireNonNullValidator())
                              .withSimpleValidation('IsPrivate', Validators.requireNonNullValidator('Private'))
                              .withSimpleValidation('Date', Validators.requireNonNullValidator())
                              .withSimpleValidation('StartTime', Validators.requireNonNullValidator('Start Time'))
                              .withSimpleValidation('EndTime', Validators.requireNonNullValidator('End Time'));
                            let validationResponse = validator.validate(formValidation);
                            if (!validationResponse.success) {
                              return sweetalert.fire({
                                icon: 'error',
                                title: '',
                                text: validationResponse.error,
                              });
                            }
                            if (Date.parse(this.state.date) < Date.parse(moment().set({ hour: 0, minute: 0, second: 0 }).toString())) {
                              return sweetalert.fire({
                                icon: 'error',
                                title: '',
                                text: 'Events must be in the future',
                              });
                            }
                            

                            if (this.state.service && this.state.service.length < 1) {
                              return sweetalert.fire({
                                icon: 'error',
                                title: '',
                                text: 'At least one service must be chosen',
                              });
                            }

                            if (this.state.isPrivate.value && !this.state.accessCode.trim()) {
                              return sweetalert.fire({
                                icon: 'error',
                                title: '',
                                text: 'Private events must have an access code',
                              });
                            }
                            if (moment(this.state.startTime, 'HH:mm') > moment(this.state.endTime, 'HH:mm')) {
                              return sweetalert.fire({
                                icon: 'error',
                                title: '',
                                text: 'Start time cannot be before the end time',
                              });
                            }

                            if (this.doesEventConflictWithBreak(this.state.startTime, 'startTime')) {
                              sweetalert.fire({
                                icon: 'error',
                                title: '',
                                text: 'Events can not start after the start of a break.',
                              });
                            }
                            if (this.doesEventConflictWithBreak(this.state.endTime, 'endTime')) {
                              sweetalert.fire({
                                icon: 'error',
                                title: '',
                                text: 'Events can not end before the end of a break.',
                              });
                            }

                            this.props.onSubmit(
                              {
                                AccessCode: this.state.accessCode ? this.state.accessCode.trim() : '',
                                Active: this.state.isActive.value,
                                EndTime: this.state.endTime,
                                FacilityID: this.state.facility.value,
                                Factor: this.state.factor,
                                ID: this.state.eventID === -1 ? null : this.state.eventID, //-1 is no existing Event, so make a new one
                                Nickname: this.state.nickName ? this.state.nickName.trim() : '',
                                Private: this.state.isPrivate.value,
                                ServiceIDs: this.state.service && this.state.service.length > 0 ? this.state.service.map(a => a.value) : null,
                                StartTime: this.state.startTime,
                                TestDate: this.state.date,
                                CustomFieldData: this.state.customFieldData,
                              },
                              JSON.parse(
                                JSON.stringify(
                                  this.state.breaks.map(b => {
                                    return {
                                      StartTime: b.StartTime,
                                      EndTime: b.EndTime,
                                    };
                                  })
                                )
                              )
                            );
                          } else {
                            return;
                          }
                        });
                    }
                    let formValidation = {
                      Facility: this.state.facility,
                      IsPrivate: this.state.isPrivate,
                      IsActive: this.state.isActive,
                      Factor: this.state.factor,
                      Service: this.state.service,
                      AccessCode: this.state.accessCode,
                      Date: this.state.date,
                      StartTime: this.state.startTime,
                      EndTime: this.state.endTime,
                    };

                    let validator = new Validator<any>()
                      .withSimpleValidation('Facility', Validators.requireNonNullValidator())
                      .withSimpleValidation('IsActive', Validators.requireNonNullValidator('Active'))
                      .withValidation('Factor', Validators.requireFactor(0, 100, 'Appointment Per 5 Mins'))
                      .withSimpleValidation('Service', Validators.requireNonNullValidator())
                      .withSimpleValidation('IsPrivate', Validators.requireNonNullValidator('Private'))
                      .withSimpleValidation('Date', Validators.requireNonNullValidator())
                      .withSimpleValidation('StartTime', Validators.requireNonNullValidator('Start Time'))
                      .withSimpleValidation('EndTime', Validators.requireNonNullValidator('End Time'));
                    let validationResponse = validator.validate(formValidation);
                    if (!validationResponse.success) {
                      return sweetalert.fire({
                        icon: 'error',
                        title: '',
                        text: validationResponse.error,
                      });
                    }

                    if (this.state.service && this.state.service.length < 1) {
                      return sweetalert.fire({
                        icon: 'error',
                        title: '',
                        text: 'At least one service must be chosen',
                      });
                    }
                    if (Date.parse(this.state.date) < Date.parse(moment().set({ hour: 0, minute: 0, second: 0 }).toString())) {
                      return sweetalert.fire({
                        icon: 'error',
                        title: '',
                        text: 'You cannot create an event in the past!',
                      });
                    }
                    if (
                      Date.parse(this.state.date) === Date.parse(moment().set({ hour: 0, minute: 0, second: 0 }).toString()) &&
                      Date.parse(this.state.startTime) < Date.parse(moment().toString())
                    ) {
                      return sweetalert.fire({
                        icon: 'error',
                        title: '',
                        text: 'You cannot create an event in the past! Please adjust Start Time before submitting',
                      });
                    }
                    if (this.state.isPrivate.value && !this.state.accessCode.trim()) {
                      return sweetalert.fire({
                        icon: 'error',
                        title: '',
                        text: 'Private events must have an access code',
                      });
                    }
                    if (moment(this.state.startTime, 'HH:mm') > moment(this.state.endTime, 'HH:mm')) {
                      return sweetalert.fire({
                        icon: 'error',
                        title: '',
                        text: 'Start time cannot be before the end time',
                      });
                    }

                    if (this.doesEventConflictWithBreak(this.state.startTime, 'startTime')) {
                      return sweetalert.fire({
                        icon: 'error',
                        title: '',
                        text: 'Events can not start after the start of a break.',
                      });
                    }
                    if (this.doesEventConflictWithBreak(this.state.endTime, 'endTime')) {
                      return sweetalert.fire({
                        icon: 'error',
                        title: '',
                        text: 'Events can not end before the end of a break.',
                      });
                    }

                    for (const EventBreak of this.state.breaks) {
                      if (!this.isBreakValid(EventBreak)) return;
                    }

                    let invalid = CustomFieldBuilder.validateCustomFields(this.state.customFieldData, this.props.pageCustomQuestions, { CreatedDate: this.state.createdDate });

                    if (invalid) {
                      return;
                    }
                    this.props.onSubmit(
                      {
                        AccessCode: this.state.accessCode ? this.state.accessCode.trim() : '',
                        Active: this.state.isActive.value,
                        EndTime: this.state.endTime,
                        FacilityID: this.state.facility.value,
                        Factor: this.state.factor,
                        ID: this.state.eventID === -1 ? null : this.state.eventID, //-1 is no existing schedule, so make a new one
                        Nickname: this.state.nickName ? this.state.nickName.trim() : '',
                        Private: this.state.isPrivate.value,
                        ServiceIDs: this.state.service && this.state.service.length > 0 ? this.state.service.map(a => a.value) : null,
                        StartTime: this.state.startTime,
                        TestDate: this.state.date,
                        CustomFieldData: this.state.customFieldData,
                      },
                      JSON.parse(
                        JSON.stringify(
                          this.state.breaks.map(b => {
                            return {
                              StartTime: b.StartTime,
                              EndTime: b.EndTime,
                            };
                          })
                        )
                      )
                    );
                  }}
                >
                  {this.state.eventID && this.state.eventID !== -1 ? 'Update' : 'Create'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
