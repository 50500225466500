import React from "react";
import Overlay from "../Overlay";
import SimpleTable from "../tables/SimpleTable";
import FilterCard, { FIELD_TYPE, ReactSelect } from "../FilterCard";
import {
  createCustomFieldDataArray,
  getBoolSelectOptions,
  getBoolSelectVal,
  getFieldTypeVal,
  getNumPages,
  getPageOptions,
  hideModal,
  showModalNoOutsideClick,
  slicePages,
} from "../../util/FormatUtil";
import { sweetalert } from "../../App";
import Select from "react-select";
import NetworkUtil from "../../network/NetworkUtil";
import CustomFieldsAPI from "../../network/CustomFieldsAPI";
import { Column } from "../tables/TableBase";
import { Sorter, TableOrder } from "../../sorting/Sorter";
import { CustomFieldsModal } from "../modals/CustomFieldsModal";
import {
  CustomFieldType,
  CustomFieldTypesObj,
  CustomFieldTypesPages,
} from "../../types/CustomFieldType";
import { EmployeeFieldsOrderModal } from "../modals/EmployeeFieldsOrderModal";
import InBetweenOverlay from "../InBetweenOverlay";

const ITEMS_PER_PAGE = 25;

interface EmployeeFieldsState {
  showLoading: boolean;
  selectedCustomField: CustomFieldType;
  allCustomFields: CustomFieldType[];
  customFieldQueryResults: CustomFieldType[];
  tableData: {}[];
  filter?: {
    DisplayName;
    FieldType;
    ValidPages;
    IsRequired;
    IsActive;
  };
  direction?: TableOrder;
  page_options: ReactSelect[];
  selected_page?: { label; value };
  displayNames: { label; value }[];
  fieldTypes: { label; value }[];
  validPages: { label; value }[];
  showInBetween;
}

export default class EmployeeFields extends React.Component<
  any,
  EmployeeFieldsState
> {
  constructor(props) {
    super(props);

    this.state = {
      showLoading: false,
      selectedCustomField: {} as CustomFieldType,
      allCustomFields: [],
      tableData: [] as any,
      customFieldQueryResults: [] as any,
      filter: {
        DisplayName: [],
        FieldType: [],
        ValidPages: [],
        IsRequired: false,
        IsActive: false,
      },
      direction: "asc",
      page_options: [{ label: "1", value: "1" }],
      selected_page: { label: "1", value: 1 },
      displayNames: [],
      fieldTypes: [],
      validPages: [],
      showInBetween: false,
    };
  }

  componentDidMount() {
    this.loadCustomFields();
  }

  loadCustomFields() {
    let tempDisplayNameArray = [];
    this.setState({ showLoading: true }, async () => {
      CustomFieldsAPI.getAllCustomFields()
        .then((data) => {
          this.setState({ allCustomFields: data.data.customEmployeeFields });
          if (data.data.customEmployeeFields.length > 0) {
            data.data.customEmployeeFields.map((field) => {
              if (field.DisplayName) {
                tempDisplayNameArray.push({
                  label: field.DisplayName,
                  value: field.DisplayName,
                });
              }
            });
          }
          this.setState({
            displayNames: tempDisplayNameArray,
            validPages: createCustomFieldDataArray(CustomFieldTypesPages),
            fieldTypes: createCustomFieldDataArray(CustomFieldTypesObj),
            showLoading: false,
          });
        })
        .catch((e) => {
          return sweetalert.fire({
            icon: "info",
            title: "",
            text: "No Services found.",
          });
        });
    });
    this.queryCustomFieldData(1);
  }

  queryCustomFieldData(page: number) {
    this.setState({ showLoading: true }, async () => {
      let results = await CustomFieldsAPI.filterCustomFields({
        filter: {
          DisplayName: this.state.filter.DisplayName,
          FieldTypeID: this.state.filter.FieldType,
          ValidPages: this.state.filter.ValidPages,
          IsRequired: this.state.filter.IsRequired,
          IsActive: this.state.filter.IsActive,
          Employee: true,
        },
      });

      if (!results.success) {
        return sweetalert.fire({
          title: "",
          text: "Unable to filter Employee Field data at this time",
          icon: "error",
        });
      }

      if (results.data.length < 1) {
        return (
          sweetalert.fire({
            icon: "info",
            title: "",
            text: "No Employee Fields found.",
          }),
          this.setState({
            showLoading: false,
            tableData: [],
            customFieldQueryResults: [],
            page_options: [{ label: "1", value: "1" }],
          })
        );
      }

      this.setState({
        tableData: slicePages(results.data, page, ITEMS_PER_PAGE),
        customFieldQueryResults: results.data,
        page_options: getPageOptions(getNumPages(results.data, ITEMS_PER_PAGE)),
        showLoading: false,
      });
    });
  }

  handleExportToCSV() {
    this.setState({ showLoading: true }, async () => {
      await NetworkUtil.downloadCSV(
        "/api/admin/customFields/csv",
        "Employee Fields Management Data.xlsx",
        { filter: this.state.filter, employee: true }
      );
      this.setState({ showLoading: false });
    });
  }

  changePage(page: number) {
    let allCustomFields = this.state.customFieldQueryResults;
    let returnData = slicePages(allCustomFields, page, ITEMS_PER_PAGE);
    this.setState({ tableData: returnData });
  }

  renderServicesFilterFields() {
    return (
      <FilterCard
        fields={[
          {
            label: "Display Name",
            key: "DisplayName",
            type: FIELD_TYPE.SELECT,
            options: this.state.displayNames,
            isMapped: true,
            isMulti: true,
            textType: "text",
            popoverText: "Name displayed on page",
            isFilter: true,
          },
          {
            label: "Field Type",
            key: "FieldType",
            type: FIELD_TYPE.SELECT,
            options: this.state.fieldTypes,
            isMapped: true,
            isMulti: true,
            textType: "text",
            popoverText: "Type of field",
            isFilter: true,
          },
          {
            label: "Is Required",
            key: "IsRequired",
            type: FIELD_TYPE.SELECT,
            options: getBoolSelectOptions(),
            isMapped: true,
            isMulti: true,
            textType: "text",
            popoverText: "Is field required?",
            isFilter: true,
          },
          {
            label: "Is Active",
            key: "IsActive",
            type: FIELD_TYPE.SELECT,
            options: getBoolSelectOptions(),
            isMapped: true,
            isMulti: true,
            textType: "text",
            popoverText: "Is field active? (inactive fields are not displayed)",
            isFilter: true,
          },
        ]}
        filterChanged={(e) => this.setState({ filter: e })}
      />
    );
  }

  useSorter(col: Column) {
    let sorter = new Sorter<any>();

    this.setState({
      tableData: sorter.sortByKey(
        this.state.tableData,
        col.key as keyof any,
        this.state.direction
      ),
      direction: this.state.direction === "asc" ? "desc" : "asc",
    });
  }

  render() {
    //@ts-ignore
    let sortedTableData = this.state.tableData.sort(
      (a:any, b:any) => a.Order - b.Order
    );

    return (
      <React.Fragment>
        <Overlay show_loading={this.state.showLoading} zIndex={100002} />
        <InBetweenOverlay
          showInBetween={this.state.showInBetween}
          zIndex={100002}
        />
        <CustomFieldsModal
          fieldTypes={this.state.fieldTypes}
          validPages={this.state.validPages}
          selectedCustomField={this.state.selectedCustomField}
          customQuestions={this.state.allCustomFields}
          fromEmployee={true}
          onSubmitEmployeeFields={() => {
            hideModal(CustomFieldsModal.ID);
            this.loadCustomFields();
            this.queryCustomFieldData(this.state.selected_page.value);
          }}
        />
        <EmployeeFieldsOrderModal
          allEmployeeFields={
            this.state.allCustomFields
              ? this.state.allCustomFields.filter((f) => f.IsEmployeeField)
              : []
          }
          handleInBetween={() => this.setState({ showInBetween: false })}
        />

        <div className="container-fluid ">
          <div className={"row"}>
            <div className="col-12 col-md-12 col-lg-8 col-xl-5 pt-2">
              <main
                id="main-content"
                tabIndex={-1}
                aria-label="Employee Field Management"
              >
                <div className="card mb-2">
                  <div className="card-header verlag-bold">
                    <h4>Employee Field Management</h4>
                  </div>
                  <div className="card-body">
                    {this.renderServicesFilterFields()}
                  </div>
                  <div className="card-footer">
                    <button
                      className={"btn immySubmitButtonOutline"}
                      onClick={() => {
                        this.setState(
                          {
                            selected_page: { label: 1, value: 1 },
                          },
                          () => {
                            this.queryCustomFieldData(
                              this.state.selected_page.value
                            );
                          }
                        );
                      }}
                    >
                      Search
                    </button>
                    <button
                      className={"btn btn-outline-success ml-2"}
                      onClick={() =>
                        showModalNoOutsideClick(EmployeeFieldsOrderModal.ID)
                      }
                    >
                      Order
                    </button>
                    <button
                      className="btn btn-outline-success float-right"
                      onClick={() =>
                        this.setState(
                          {
                            selectedCustomField: {
                              ID: null,
                              DisplayName: "",
                              FieldTypeID: null,
                              ServiceID: null,
                              IsEmployeeField: 0,
                              Options: {},
                            } as CustomFieldType,
                          },
                          () => {
                            showModalNoOutsideClick(CustomFieldsModal.ID);
                          }
                        )
                      }
                    >
                      Create New
                    </button>
                  </div>
                </div>
              </main>
            </div>

            {this.state.tableData && this.state.tableData.length > 0 && (
              <div className="col-md-12 pt-2">
                <div className="card mt-2 mb-5">
                  <div className="card-header verlag-bold" style={{zIndex: 0}}>
                    <h4 className="text-center text-md-left">
                      Employee Fields
                      <section className="tableHeaderSection float-md-right d-flex justify-content-around">
                        <h4 className={"float-md-right"}>
                          Total: {this.state.customFieldQueryResults.length}
                        </h4>
                        <h4 className="float-right align-middle pr-2 ml-5">
                          Page{" "}
                        </h4>
                        <div className=" align-middle float-right pages ">
                          <Select
                            isSearchable={true}
                            placeholder={"1"}
                            aria-label="Table Page Number"
                            noOptionsMessage={() => "No option"}
                            value={this.state.selected_page}
                            onChange={(e: ReactSelect) =>
                              this.setState({ selected_page: e }, () =>
                                this.changePage(e.value)
                              )
                            }
                            className={"state_select"}
                            options={this.state.page_options}
                          />
                        </div>
                      </section>
                      <button
                        className={
                          "d-none d-md-inline btn btn-outline-primary ml-3"
                        }
                        onClick={() => this.handleExportToCSV()}
                      >
                        Export to CSV
                      </button>
                    </h4>
                  </div>
                  <div className="card-body p-0 m-0 table-responsive">
                    <SimpleTable
                      table_style="tableFixHead"
                      columns={[
                        {
                          label: "Display Name",
                          key: "DisplayName",
                          rawFormat: (val) => {
                            if (
                              val.DisplayName === "First Name" ||
                              val.DisplayName === "Last Name" ||
                              val.DisplayName === "Date of Birth"
                            ) {
                              return (
                                <p
                                  data-toggle={"tooltip"}
                                  data-placement={"top"}
                                  title={`${val.DisplayName} is not editable`}
                                >
                                  {val.DisplayName}
                                </p>
                              );
                            }
                            return (
                              <a
                                href={"#!"}
                                className={"tableNameLinkColor"}
                                onClick={() => {
                                  this.setState(
                                    { selectedCustomField: val },
                                    () =>
                                      showModalNoOutsideClick(
                                        CustomFieldsModal.ID
                                      )
                                  );
                                }}
                              >
                                {val.DisplayName}
                              </a>
                            );
                          },
                          popoverText: "Click to sort by Display Name",
                        },
                        {
                          label: "Field Type",
                          key: "FieldTypeID",
                          popoverText: "Click to sort by Field Type",
                          rawFormat: (val) => {
                            let string = getFieldTypeVal(val.FieldTypeID);
                            return <p>{string.label}</p>;
                          },
                        },
                        // {
                        //     label: "Valid Pages", key: "ValidPages",
                        //     popoverText: "Click to sort by Valid Pages",
                        //     rawFormat: (val) => {
                        //         let vp = val.Options.validPages.map(p => p + ", ");
                        //         let lastInd = vp.length - 1;
                        //         if (lastInd >= 0)
                        //             vp[lastInd] = vp[lastInd].slice(0, vp[lastInd].length - 2); // Remove last space
                        //         return <p>{vp}</p>
                        //     }
                        // },
                        {
                          label: "Is Required",
                          key: "IsRequired",
                          popoverText: "Click to sort by Is Required",
                          rawFormat: (val) => {
                            let string = getBoolSelectVal(
                              val.Options.isRequired
                            );
                            return <p>{string.label}</p>;
                          },
                        },
                        {
                          label: "Is Active",
                          key: "IsActive",
                          popoverText: "Click to sort by Is Active",
                          rawFormat: (val) => {
                            let string = getBoolSelectVal(val.Options.isActive);
                            return <p>{string.label}</p>;
                          },
                        },
                      ]}
                      table_data={sortedTableData}
                      columnClickedCallback={(col) => {
                        this.useSorter(col);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
