import React from "react";
import Select from "react-select";
import {sweetalert} from "../../App";
import {isBlank, isOnlyWhitespace} from "../../util/ValidationUtil";
import {buildRow, getBoolSelectOptions, getBoolSelectVal} from "../../util/FormatUtil";
import {isEmptyObject} from "jquery";
import Banner from "../../types/Banner";

interface BannerManagementState{
    selectedBanner:Banner
    // customFieldData?
}
interface BannerManagementProps{
    selectedBanner
    onSubmit?
    // pageCustomQuestions?
}
export class BannerManagementModal extends React.Component<BannerManagementProps, BannerManagementState>{
    public static ID = "bannermanagement";
    constructor(props) {
        super(props);
        this.state = {
            selectedBanner: {
                BannerID: null,
                Text: "",
                Display: 0,
                BrandingID: null,
                ProductName: ''
              } as Banner
        }
    }

    componentDidMount(): void {
        $(`#${BannerManagementModal.ID}`).on('shown.bs.modal', function() {
            $(document).off('focusin.modal');
        });
    }

    componentWillReceiveProps(nextProps: Readonly<BannerManagementProps>, nextContext: any) {
       

        if(!isEmptyObject(nextProps.selectedBanner)){
            let banner = nextProps.selectedBanner;
            this.setState({
                selectedBanner: {
                    BannerID: banner.BannerID,
                    Text: banner.Text,
                    Display: banner.Display,
                    BrandingID: banner.BrandingID,
                    ProductName: banner.ProductName
                  } as Banner
            });
       
        }
    }

    render(){
        return(
            <div className="modal fade form_modal" id={BannerManagementModal.ID} tabIndex={-1} role="dialog"
                 aria-labelledby="result_modal_label"
                 aria-hidden="true">
                <div className="modal-dialog modal-lg modal-xl" role="document">
                    <div className="modal-content">
                    <div className="container-fluid">
                        <div className="modal-header">
                            <h5 className="modal-title" id="result_modal_label">{this.state.selectedBanner && this.state.selectedBanner.BannerID ? 'Update Banner' : 'Create Banner'}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {buildRow("Product Name",
                                <input className={"form-control"}
                                       id="ProductName"
                                       maxLength={50}
                                       readOnly={this.state.selectedBanner.BannerID != null}
                                       autoComplete={"off"}
                                       type={"search"}
                                       name={"Product Name"}
                                       aria-label="Product Name"
                                       onChange={(e)=>{
                                           this.setState( (prevState) => ({
                                               selectedBanner: {
                                                   ...prevState.selectedBanner,
                                                   ProductName: e.target.value
                                               }
                                           }))
                                       }}
                                       value={this.state.selectedBanner.ProductName}
                                />
                            )}
                            {buildRow("Active",
                                <Select
                                isSearchable={true}
                                placeholder={"Please Select..."}
                                noOptionsMessage={() => "No option"}
                                value={ this.state.selectedBanner && this.state.selectedBanner.BannerID ? getBoolSelectVal(this.state.selectedBanner.Display): null }
                                aria-label="Active"
                                onChange={(e) => this.setState((prevState)=>({
                                    selectedBanner: {
                                        ...prevState.selectedBanner,
                                        Display: e.value ? 1 : 0,
                                    }
                                }))}
                                className={"state_select"}
                                options={getBoolSelectOptions()}
                            />
                            )}
                            {buildRow("Text",
                                <input
                                    className={"form-control"}
                                    id="Text"
                                    maxLength={50}
                                    autoComplete={"off"}
                                    type={"search"}
                                    name={"Text"}
                                    aria-label="Text"
                                    onChange={(e) => this.setState((prevState) => ({
                                        selectedBanner: {
                                            ...prevState.selectedBanner,
                                            Text: e.target.value
                                        }
                                    }))}
                                    value={ this.state.selectedBanner.Text }
                                />
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={() => {
                                if(isBlank(this.state.selectedBanner.ProductName) ||
                                    isOnlyWhitespace(this.state.selectedBanner.ProductName)
                                ){
                                    return sweetalert.fire("","Product cannot be empty.", "error")
                                }
                                if(this.state.selectedBanner.Display === null){
                                    return sweetalert.fire("", "Please select a value for Active", "error")
                                } 
                                if(isBlank(this.state.selectedBanner.Text) || isOnlyWhitespace(this.state.selectedBanner.Text)){
                                    return sweetalert.fire("", "Text cannot be empty", "error")
                                }

                                // let invalid = CustomFieldBuilder.validateCustomFields(this.state.selectedBanner.CustomFieldData, this.props.pageCustomQuestions, this.state.selectedBanner)

                                // if(invalid){
                                //     return;
                                // }


                                this.props.onSubmit(this.state.selectedBanner);
                            }}>
                                {this.state.selectedBanner && this.state.selectedBanner.BannerID ? 'Update' : 'Create'}
                            </button>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        )
    }
}