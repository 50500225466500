import Banner from "../types/Banner";
import NetworkUtil from "./NetworkUtil";

export default class BannerAPI {

    public static async getBanner(id):Promise<{show:boolean, banner:Banner}>{
        return NetworkUtil.makePost("/api/system/banner", {id: id});
    }

    public static async getBanners():Promise<{show:boolean, banners:Banner[]}>{
        return NetworkUtil.makeGet("/api/admin/banner/getBanners");
    }
   
    //route not made yet
    public static async createBanner(banner):Promise<{success:boolean,reason:string}>{
        return NetworkUtil.makePost("/api/admin/banner/createBanner",banner)
    }

    public static async updateBanner(updatedBanner):Promise<{success:boolean,reason:string}>{
        return NetworkUtil.makePost("/api/admin/banner/updateBanner",updatedBanner)
    }

    public static async filterBanner(filter):Promise<{success:boolean, reason:string, data}>{
        return NetworkUtil.makePost("/api/admin/banner/filterBanner", filter)
    }


}