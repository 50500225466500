import React from "react";
import 'react-quill/dist/quill.snow.css';
import Select from "react-select";
import Overlay from "../Overlay";
import {
    getBoolSelectOptions,
    getBoolSelectVal, getNumPages, getPageOptions, hideModal,
    showModalNoOutsideClick,
    slicePages
} from "../../util/FormatUtil";
import {sweetalert} from '../../App';
import SystemAPI from "../../network/SystemAPI";
import {AdminPages} from "./AdminNav";
import FilterCard, {FIELD_TYPE, ReactSelect} from "../FilterCard";
import NetworkUtil from "../../network/NetworkUtil";
import {Column} from "../tables/TableBase";
import {Sorter, TableOrder} from "../../sorting/Sorter";
import SimpleTable from "../tables/SimpleTable";
import InBetweenOverlay from "../InBetweenOverlay";
import VaccineType from "../../types/VaccineType";
import VaccinesAPI from "../../network/VaccinesAPI";
import { VaccineManagementModal } from "../modals/VaccineManagementModal";


const ITEMS_PER_PAGE = 25;

interface VaccineManagementState {
    selectedVaccine?;
    vaccines: VaccineType[]
    testDescription?
    name?
    shortName?
    isEnabled?:{label, value}
    showLoading?
    showInBetween?
    resultTypes:{label, value}[]
    allResults
    pageCustomQuestions?
    filter?:{
        SelectedVaccine
        IsEnabled
    }
    tableData:{}[]
    direction?:TableOrder
    page_options:ReactSelect[]
    selected_page?:{label,value}
    vaccineQueryResults:VaccineType[]
}

export default class VaccineManagement extends React.Component<any, VaccineManagementState> {

    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            showInBetween: false,
            selectedVaccine: {} as VaccineType,
            vaccines: [], 
            resultTypes:[],
            allResults: [],
            pageCustomQuestions: [],
            filter: {SelectedVaccine:[], IsEnabled:[],
            },
            tableData: [] as any,
            direction: 'asc',
            page_options: [{label: "1", value: "1"}],
            selected_page: {label: "1", value: 1},
            vaccineQueryResults: [] as any,
        }
        this.createOrModifyVaccine = this.createOrModifyVaccine.bind(this);
    }


    componentDidMount() {
        this.setState({showLoading: true}, () => {
            SystemAPI.getCustomQuestions(AdminPages.Vaccine).then(res =>{
                this.setState({pageCustomQuestions:res.questions})
            })
            SystemAPI.getAllVaccines().then(data => {
                this.setState({vaccines: data})
            })
            this.queryVaccine(1)
        })
    }

    queryVaccine(page:number) {
        this.setState({showLoading:true}, async () => {
            try {
                let results = await VaccinesAPI.filterVaccine({
                    filter: this.state.filter ? this.state.filter : null
                })

                if(results.data.length < 1){
                    this.setState({showLoading: false, tableData: [], vaccineQueryResults: null})
                    return sweetalert.fire({title: '', text: 'No Vaccines returned', icon: 'info'})
                }

                if(!results.success){
                    return sweetalert.fire({title: '', text: 'Unable to filter Vaccine data at this time', icon: 'error'})
                }
                this.setState({
                    tableData: slicePages(results.data, page, ITEMS_PER_PAGE),
                    vaccineQueryResults: results.data,
                    page_options: getPageOptions(getNumPages(results.data, ITEMS_PER_PAGE)),
                    showLoading: false,
                })
            } catch (e) {
                console.error(e)
                this.setState({showLoading: false})
            }
        })
    }


    createOrModifyVaccine(vaccine:VaccineType){
        this.setState({showLoading:true}, async() => {
            if(vaccine.ID === null){
                try {
                    let result = await VaccinesAPI.newVaccine(vaccine);
                    if(result.success){
                        sweetalert.fire({icon: 'success', title: '', text: 'Vaccine saved'}).then(()=>{
                            this.setState({
                                selectedVaccine: {
                                    Name: '',
                                    IsEnabled: null,
                                    CustomFieldData: null,
                                    CreatedDate: null
                                } as VaccineType
                            }, () => {
                                SystemAPI.getAllVaccines().then(data => {
                                    this.setState({vaccines: data, showLoading: false})
                                })
                                this.queryVaccine(this.state.selected_page.value);
                            });
                        });
                    }
                    else{
                        sweetalert.fire({icon: 'error', title: '', text: result.reason});
                        this.setState({showLoading: false})
                    }
                }catch (e) {
                    console.error(e)
                    this.setState({showLoading: false})
                }
            } else {
                try{
                    let result = await VaccinesAPI.editVaccine(vaccine)
                    if(result.success){
                        sweetalert.fire({icon: 'success', title: '', text: 'Vaccine saved'}).then(()=>{
                            this.setState({
                                selectedVaccine: {
                                    ID: null,
                                    Name: '',
                                    IsEnabled: null,
                                    CustomFieldData: null,
                                    CreatedDate: null
                                } as VaccineType
                            }, () => {
                                SystemAPI.getAllVaccines().then(data => {
                                    this.setState({vaccines: data, showLoading: false})
                                })
                                this.queryVaccine(this.state.selected_page.value);
                            });
                        });
                    }else{
                        sweetalert.fire({icon: 'error', title: '', text: result.reason});
                        this.setState({showLoading:false})
                    }
                }
                catch (e) {
                    console.error(e)
                    this.setState({showLoading:false})
                }
            }
        });
    }

    handleExportToCSV(){
        this.setState({showLoading: true}, async () =>{
            await NetworkUtil.downloadCSV(
                "/api/admin/vaccines/csv",
                "Vaccine_Management.xlsx",
                {filter:  this.state.filter}
            )
            this.setState({showLoading: false})
        });
    }

    useSorter(col:Column){
        let sorter = new Sorter<any>()

        this.setState({tableData: sorter.sortByKey(this.state.tableData, col.key as keyof any,this.state.direction), direction: this.state.direction === 'asc' ? 'desc' : 'asc'});
    }

    changePage(page:number){
        let allVaccines = this.state.vaccineQueryResults;
        let returnData = slicePages(allVaccines, page, ITEMS_PER_PAGE);
        this.setState({ tableData: returnData });
    }

    renderVaccineMgmtFilterFields(){
        return (
            <FilterCard fields={[
                {
                    label: "Name",
                    "key": "SelectedVaccine",
                    type: FIELD_TYPE.SELECT,
                    options: this.state.vaccines,
                    isMapped: true,
                    isMulti: true,
                    textType: 'text'
                }, 
                {
                    label: "Is Enabled",
                    "key": "IsEnabled",
                    type: FIELD_TYPE.SELECT,
                    options: getBoolSelectOptions(),
                    isMapped: true,
                    isMulti: true,
                    textType: 'text',
                    isFilter: true
                }, 
            ]} filterChanged={(e)=> this.setState({ filter: e })} />
        )
    }

    getVaccineDetails(val){

        this.setState({showLoading: true}, async () => {
            try {
                let result = await VaccinesAPI.queryVaccine({ID: val.ID})
                if (result.success) {
                    let vaccine = result.vaccine
                    this.setState({selectedVaccine: {
                        ID: vaccine.ID || vaccine.ID === 0 ? vaccine.ID : null,
                        Name: vaccine.Name ? vaccine.Name : "",
                        IsEnabled: vaccine.IsEnabled ? vaccine.IsEnabled : null,
                        CustomFieldData: vaccine.CustomFieldData ? vaccine.CustomFieldData : null,
                        CreatedDate: vaccine.CreatedDate ? vaccine.CreatedDate : null,
                    },
                    showLoading: false}, () => showModalNoOutsideClick(VaccineManagementModal.ID))
                }
            } catch (e) {
                console.error(e)
                this.setState({showLoading: false})
            }
        })

    }

    render() {

        // console.log('VaccineMgmt state', this.state)

        return (<React.Fragment>
            <Overlay show_loading={this.state.showLoading}/>
            <InBetweenOverlay showInBetween={this.state.showInBetween} />
            <VaccineManagementModal
                selectedVaccine={this.state.selectedVaccine}
                onSubmit={(vaccine:VaccineType) => {
                    hideModal(VaccineManagementModal.ID);
                    this.createOrModifyVaccine(vaccine);
                }}
                pageCustomQuestions={this.state.pageCustomQuestions}
            />
            <div className="container-fluid ">
                <div className={"row"}>
                    <div className="col-12 col-lg-8 col-xl-5 pt-2">
                        <div className="card mb-2">
                            <div className="card-header verlag-bold" style={{zIndex: 0}}>
                                <h4>Vaccine Management</h4>
                            </div>
                            <div className="card-body">
                                {this.renderVaccineMgmtFilterFields()}
                            </div>
                            <div className="card-footer">
                                <button className={"btn immySubmitButtonOutline"}
                                        onClick={ () => {
                                            this.setState({
                                                selected_page: {label: 1, value: 1}}, () => {
                                                this.queryVaccine(this.state.selected_page.value)
                                            })
                                        }}
                                >Search</button>
                                <button className="btn btn-outline-success float-right"
                                        onClick={()=> this.setState({
                                            selectedVaccine: {
                                                ID: null,
                                                Name: '',
                                                IsEnabled: null,
                                            } as VaccineType
                                        },()=>{
                                            showModalNoOutsideClick(VaccineManagementModal.ID)
                                        })}
                                >Create New</button>
                            </div>
                        </div>
                    </div>
                    
                    {
                        this.state.tableData &&
                        this.state.tableData.length > 0 &&

                        <div className="col-md-12 pt-2">
                            <div className="card mt-2 mb-5">
                                <div className="card-header verlag-bold">
                                    <h4 className="text-center text-md-left">Vaccines
                                    <section className="tableHeaderSection float-md-right d-flex justify-content-around">
                                        <h4 className={'float-md-right'}>Total: {this.state.vaccineQueryResults.length}</h4>
                                        <h4 className="float-right align-middle pr-2 ml-5">Page </h4>
                                        <div className=" align-middle float-right pages ">
                                            <Select
                                                isSearchable={true}
                                                placeholder={"1"}
                                                noOptionsMessage={() => "No option"}
                                                value={this.state.selected_page}
                                                onChange={(e: ReactSelect) => this.setState({selected_page: e},
                                                    () => this.changePage(e.value))}
                                                className={"state_select"}
                                                options={this.state.page_options}
                                            />
                                        </div>
                                    </section>
                                    <button className={"d-none d-md-inline btn btn-outline-primary ml-3"}
                                            onClick={() => this.handleExportToCSV()}
                                    >Export to CSV
                                    </button>
                                    </h4>
                                </div>
                                <div className="card-body p-0 m-0 table-responsive">
                                    <SimpleTable table_style='tableFixHead' columns={[
                                        {
                                            label: "Name", key: "Name",
                                            rawFormat: (val) => {
                                                return <a href={"#!"} className={'tableNameLinkColor'} onClick={() => {
                                                    this.getVaccineDetails(val)
                                                }}>{val.Name}</a>
                                            },
                                            popoverText: "Click to sort by Name"
                                        },
                                        {
                                          label: "Is Enabled", key: "IsEnabled",
                                          popoverText: "Click to sort by Enabled",
                                          rawFormat: (val) => {
                                            let string = getBoolSelectVal(val.IsEnabled)
                                            return <p>{string.label}</p>
                                        }
                                      },
                                        
                                    ]} table_data={this.state.tableData} columnClickedCallback={(col =>{
                                        this.useSorter(col);
                                    })} />
                                </div>
                            </div>
                        </div>
                    }

                </div>
            </div>
        </React.Fragment>)
    }
}