import NetworkUtil from "./NetworkUtil";
import TestType from "../types/TestType";
import { ServerResponse } from "../types/ServerResponse";
import RecordType from "../types/RecordType";

export default class RecordsAPI {

  public static async filterRecords(
    filter, page
  ): Promise<{ success: boolean; data: RecordType[]; reason: string }> {
    return await NetworkUtil.makePost("/api/admin/records/filter", filter);
  }

  public static async queryRecord(
    body
  ): Promise<{ success: boolean; record: RecordType }> {
    return await NetworkUtil.makePost("/api/admin/records/details", body);
  }

  public static async editRecord(
    body,
  ): Promise<ServerResponse> {
    return NetworkUtil.makePost("/api/admin/records/edit", body);
  }

  public static async newRecord(body): Promise<ServerResponse> {
    return await NetworkUtil.makePost("/api/admin/records/new", body);
  }

}
