import NetworkUtil from "./NetworkUtil";
import {Result} from "../types/Result";
import {ServerResponse} from "../types/ServerResponse";

export default class ResultsAPI {

    public static async getAllResultsData():Promise<{success:boolean, reason:string, data}>{
        return NetworkUtil.makeGet("/api/admin/results");
    }

    public static async getTestAndResultsMapped():Promise<{success:boolean, reason:string, data}>{
        return NetworkUtil.makeGet("/api/admin/results/testsAndResultsMapped");
    }

    public static async filterResults(filter):Promise<{success:boolean, reason:string, data}>{
        return NetworkUtil.makePost("/api/admin/results/filter", filter)
    }

    public static async createResult(result:Result):Promise<ServerResponse>{
        return NetworkUtil.makePost("/api/admin/results/create", result)
    }
    
    public static async editResult(result):Promise<ServerResponse>{
        return NetworkUtil.makePost("/api/admin/results/edit", result)
    }

}