import NetworkUtil from "./NetworkUtil";
import { ServerResponse } from "../types/ServerResponse";

export default class WalkinAPI {
  public static async walkinForm(
    facilityID,
    employeeUID,
    servicesObjectsArray,
    currentURL,
    selectedServices,
    // submissionFiles
  ): Promise<ServerResponse> {
    let data = new FormData();
    data.set("facilityID", JSON.stringify(facilityID));
    data.set("employeeUID", JSON.stringify(employeeUID));
    data.set("servicesObjectsArray", JSON.stringify(servicesObjectsArray));
    data.set("currentURL", JSON.stringify(currentURL));
    data.set("selectedServices", JSON.stringify(selectedServices));
    // if (submissionFiles) {
    //   for (let i = 0; i < submissionFiles.length; i++) {
    //     data.append("submissionFiles", submissionFiles[i]);
    //   }
    // }

    return NetworkUtil.postForm("/api/admin/form", data);
  }

  public static async getEnabledComms(
    facilityID,
    serviceID
  ): Promise<{
    success: boolean;
    reason?: string;
    emailIsEnabled?: boolean;
    smsIsEnabled?: boolean;
  }> {
    return NetworkUtil.makePost("/api/admin/getEnabledComms", {
      facilityID: facilityID,
      serviceID: serviceID,
    });
  }
}
