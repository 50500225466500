import React from 'react';
import ModalForm from './ModalForm';
import { isBlank, isOnlyWhitespace, isValidEmail } from '../../util/ValidationUtil';
import Select from 'react-select';
import { sweetalert } from '../../App';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import { isEmptyObject } from 'jquery';
import Validator, { ValidationEntry } from '../../validation/Validator';
import { Validators } from '../../validation/Validators';
import { FacilityMgmtBody } from '../../types/Facility';
import { getlabelsFromIDs, getFacilityLabel, getBoolSelectVal, getBoolSelectOptions, buildRow, getLabel, handleCloseModal, hideModal } from '../../util/FormatUtil';
import { CustomFieldBuilder } from '../../customfields/CustomFieldBuilder';
import GetField from '../../customfields/GetField';
import CustomInput from '../form/CustomInput';
import CustomSelect from '../form/CustomSelect';
import CustomPhone from '../form/CustomPhone';
import SystemAPI from '../../network/SystemAPI';
import Overlay from '../Overlay';
import GooglePlacesAutoComplete from '../admin/GooglePlacesAutoComplete';
import { FacilityAPI } from '../../network/FacilityAPI';

interface FacilityProps {
  selectedFacility;
  states;
  countries;
  onSubmit: (facility) => void;
  facilityGroups;
  isITG;
  isManagement;
  pageCustomQuestions?;
  services;
  commTemplates;
  facilityCommTemplateStatusArray;
}

interface FacilityState {
  showLoading: boolean;
  selectedFacility?;
  isSubmitting?;
  facilityArrowDown?;
  customFieldArrowDown?;
  newFacilityGroup?;
  facilityGroups?;
  enabledCommTemplateLabels?;
  changesMade: boolean;
}
export class ManageFacilityModal extends React.Component<FacilityProps, FacilityState> {
  public formRef: React.RefObject<ModalForm>;
  public static readonly ID = 'newfacility'

  constructor(props) {
    super(props);
    this.state = {
      showLoading: false,
      isSubmitting: false,
      facilityArrowDown: true,
      customFieldArrowDown: true,
      changesMade: false,
    };
    this.formRef = React.createRef();
  }

  fromSet(a) {
    return Array.from(new Set(a)).filter((a) => a);
  }

  componentDidMount(): void {
    // this is a hack to swtich focus to the sweetalert modal when creating a New Facility Group
    $(`#${ManageFacilityModal.ID}`).on('shown.bs.modal', function () {
      $(document).off('focusin.modal');
    });
    FacilityAPI.getFacilitiesForSubmissionForm().then((data) => {
      let facs = data.authorizedFacilities;
      let facilityGroupsMapped = this.fromSet(
        facs.map((obj) => {
          return obj.FacilityGroup;
        })
      );
     
      let facilityGroupsForModal = facilityGroupsMapped.map((obj) => {
        return { label: obj, value: obj };
      });

      this.setState({
        facilityGroups: facilityGroupsForModal.sort(function (a, b) {
          return a.label.localeCompare(b.label);
        } as any),
      });
    });
  }

  componentWillReceiveProps(nextProps: Readonly<FacilityProps>, nextContext: any) {
    nextProps = JSON.parse(JSON.stringify(nextProps));

    if (!isEmptyObject(nextProps.selectedFacility)) {
      let serviceIDs = nextProps.selectedFacility.ServiceIDs ? JSON.parse(nextProps.selectedFacility.ServiceIDs) : [];

      this.setState({
        changesMade: false,
        facilityGroups: nextProps.facilityGroups ? JSON.parse(JSON.stringify(this.props.facilityGroups)) : null,
        selectedFacility: {
          selectedServices: serviceIDs ? serviceIDs : [],
          selectedServicesLabel: nextProps.selectedFacility && nextProps.selectedFacility.ServiceIDs ? getlabelsFromIDs(serviceIDs, this.props.services) : '',
          facilityID: nextProps.selectedFacility && nextProps.selectedFacility.ID ? nextProps.selectedFacility.ID : '',
          facilityName: nextProps.selectedFacility && nextProps.selectedFacility.FacilityName ? nextProps.selectedFacility.FacilityName : '',
          facilityAbbreviation: nextProps.selectedFacility && nextProps.selectedFacility.FacilityAbbreviation ? nextProps.selectedFacility.FacilityAbbreviation : '',
          facilityPhone: nextProps.selectedFacility && nextProps.selectedFacility.FacilityPhone ? nextProps.selectedFacility.FacilityPhone : '',
          facilityEmail: nextProps.selectedFacility && nextProps.selectedFacility.FacilityEmail ? nextProps.selectedFacility.FacilityEmail : '',
          facilityAddress: nextProps.selectedFacility && nextProps.selectedFacility.FacilityAddress ? nextProps.selectedFacility.FacilityAddress : '',
          facilityAddressTwo: nextProps.selectedFacility && nextProps.selectedFacility.FacilityAddressTwo ? nextProps.selectedFacility.FacilityAddressTwo : '',
          facilityCity: nextProps.selectedFacility && nextProps.selectedFacility.FacilityCity ? nextProps.selectedFacility.FacilityCity : '',
          facilityZip: nextProps.selectedFacility && nextProps.selectedFacility.FacilityZip ? nextProps.selectedFacility.FacilityZip : '',
          facilityState: nextProps.selectedFacility && nextProps.selectedFacility.FacilityState ? nextProps.selectedFacility.FacilityState : '',
          facilityCounty: nextProps.selectedFacility && nextProps.selectedFacility.FacilityCounty ? nextProps.selectedFacility.FacilityCounty : '',
          facilityGroup: nextProps.selectedFacility && nextProps.selectedFacility.FacilityGroup ? nextProps.selectedFacility.FacilityGroup : '',
          facilityGroupLabel:
            nextProps.selectedFacility && nextProps.selectedFacility.FacilityGroup ? getFacilityLabel(nextProps.selectedFacility.FacilityGroup, this.props.facilityGroups) : '',
          facilityStateLabel:
            nextProps.selectedFacility && nextProps.selectedFacility.FacilityState ? getLabel(nextProps.selectedFacility.FacilityState, this.props.states) : '',
          facilityGroups: nextProps.facilityGroups ? JSON.parse(JSON.stringify(this.props.facilityGroups)) : null,
          CustomFieldData: nextProps.selectedFacility && nextProps.selectedFacility.CustomFieldData ? JSON.parse(nextProps.selectedFacility.CustomFieldData) : null,
          createdDate: nextProps.selectedFacility && nextProps.selectedFacility.CreatedDate ? nextProps.selectedFacility.CreatedDate : null,
          isActive: nextProps.selectedFacility && nextProps.selectedFacility.Active ? nextProps.selectedFacility.Active : '',
          isActiveLabel:
            nextProps.selectedFacility && nextProps.selectedFacility.Active
              ? getBoolSelectVal(nextProps.selectedFacility.Active)
              : getBoolSelectVal(nextProps.selectedFacility.Active),
          enabledCommTemplateLabels:
            nextProps.selectedFacility && nextProps.selectedFacility.ID && serviceIDs && serviceIDs.length > 0
              ? this.getEnabledCommTemplateLabels(nextProps.selectedFacility.ID, serviceIDs)
              : [],
        },
      });
    } else {
      this.setState({
        changesMade: false,
        facilityGroups: nextProps.facilityGroups ? JSON.parse(JSON.stringify(this.props.facilityGroups)) : null,
        selectedFacility: {
          selectedServices: [],
          selectedServicesLabel: '',
          facilityID: '',
          facilityName: '',
          facilityAbbreviation: '',
          facilityPhone: '',
          facilityEmail: '',
          facilityAddress: '',
          facilityAddressTwo: '',
          facilityCity: '',
          facilityZip: '',
          facilityState: '',
          facilityCounty: '',
          facilityGroup: '',
          facilityGroupLabel: '',
          facilityStateLabel: '',
          facilityGroups: null,
          CustomFieldData: null,
          createdDate: null,
          isActive: '',
          isActiveLabel: getBoolSelectVal(0),
          enabledCommTemplateLabels: [],
        },
      });
    }
  }

  getNewFacilityObj() {
    return {
      ID: this.state.selectedFacility && this.state.selectedFacility.facilityID ? this.state.selectedFacility.facilityID : null,
      ServiceIDs:
        this.state.selectedFacility.selectedServices && this.state.selectedFacility.selectedServicesLabel && this.state.selectedFacility.selectedServicesLabel.length > 0
          ? this.state.selectedFacility.selectedServicesLabel.map(s => {
              return s.value;
            })
          : null,
      FacilityName: this.state.selectedFacility.facilityName ? this.state.selectedFacility.facilityName.trim() : null,
      FacilityAbbreviation: this.state.selectedFacility.facilityAbbreviation ? this.state.selectedFacility.facilityAbbreviation.trim() : null,
      FacilityPhone: this.state.selectedFacility.facilityPhone ? this.state.selectedFacility.facilityPhone : null,
      FacilityEmail: this.state.selectedFacility.facilityEmail ? this.state.selectedFacility.facilityEmail.trim() : null,
      FacilityAddress: this.state.selectedFacility.facilityAddress ? this.state.selectedFacility.facilityAddress.trim() : null,
      FacilityAddressTwo: this.state.selectedFacility.facilityAddressTwo ? this.state.selectedFacility.facilityAddressTwo.trim() : null,
      FacilityCity: this.state.selectedFacility.facilityCity ? this.state.selectedFacility.facilityCity.trim() : null,
      FacilityZip: this.state.selectedFacility.facilityZip ? this.state.selectedFacility.facilityZip : null,
      FacilityState: this.state.selectedFacility.facilityState ? this.state.selectedFacility.facilityState : null,
      FacilityCounty: this.state.selectedFacility.facilityCounty ? this.state.selectedFacility.facilityCounty.trim() : null,
      FacilityGroup: this.state.selectedFacility.facilityGroupLabel ? this.state.selectedFacility.facilityGroupLabel.value : null,
      CustomFieldData: this.state.selectedFacility.CustomFieldData ? this.state.selectedFacility.CustomFieldData : null,
      CreatedDate: this.state.selectedFacility.createdDate ? this.state.selectedFacility.createdDate : new Date(),
      FacilityGroupID: this.state.selectedFacility.facilityGroup ? this.state.selectedFacility.facilityGroup.ID : null,
      Active: this.state.selectedFacility.isActiveLabel ? this.state.selectedFacility.isActiveLabel.value : false,
      CommTemplateStatusArray:
        this.state.selectedFacility.enabledCommTemplateLabels && this.state.selectedFacility.enabledCommTemplateLabels.length > 0
          ? this.state.selectedFacility.enabledCommTemplateLabels
          : null,
    };
  }

  async onSubmit() {
    let validator = new Validator<FacilityMgmtBody>()
      .withComposedValidation(
        'FacilityName',
        new ValidationEntry(Validators.requireNotBlankValidator('Facility Name')),
        new ValidationEntry(Validators.requireLength(200, 'Facility Name'))
      )
      .withSimpleValidation('FacilityGroup', Validators.requireNonNullValidator('Facility Group'))
      .withSimpleValidation('ServiceIDs', Validators.requireNonNullValidator('Services'))
      .withComposedValidation(
        'FacilityAbbreviation',
        new ValidationEntry(Validators.requireNotBlankValidator('Facility Abbreviation')),
        new ValidationEntry(Validators.requireLength(100, 'Facility Abbreviation'))
      )
      .withComposedValidation(
        'FacilityPhone',
        new ValidationEntry(Validators.requireNonNullValidator('Facility Phone')),
        new ValidationEntry(Validators.requirePhone('Facility Phone'))
      )
      .withComposedValidation(
        'FacilityEmail',
        new ValidationEntry(Validators.requireNotBlankValidator('Facility Email')),
        new ValidationEntry(Validators.requireValidEmail('Facility Email')),
        new ValidationEntry(Validators.requireLength(200, 'Facility Email'))
      )
      .withSimpleValidation('FacilityAddress', Validators.requireNotBlankValidator('Facility Address'));
    let validationResponse = validator.validate(this.getNewFacilityObj());
    if (!validationResponse.success) {
      return sweetalert.fire({ icon: 'error', title: '', text: validationResponse.error });
    }

    let invalid = CustomFieldBuilder.validateCustomFields(this.state.selectedFacility.CustomFieldData, this.props.pageCustomQuestions, this.getNewFacilityObj());

    if (invalid) {
      return;
    }

    this.setState({ newFacilityGroup: '' });
    this.props.onSubmit(this.getNewFacilityObj());
  }

  // fix needed to show sweetalert input on top of modal
  // call this before showing SweetAlert:
  fixBootstrapModal() {
    var modalNode = document.querySelector('.modal[tabindex="-1"]');
    if (!modalNode) return;

    modalNode.removeAttribute('tabindex');
    modalNode.classList.add('js-swal-fixed');
  }

  // call this before hiding SweetAlert (inside done callback):
  restoreBootstrapModal() {
    var modalNode = document.querySelector('.modal.js-swal-fixed');
    if (!modalNode) return;

    modalNode.setAttribute('tabindex', '-1');
    modalNode.classList.remove('js-swal-fixed');
  }

  async handleAddNewAuthFacilityGroup() {
    this.fixBootstrapModal();

    const { value: authGroupName } = await sweetalert.fire({
      title: 'Enter new facility group',
      input: 'text',
      showCancelButton: true,
      confirmButtonText: 'Add',
      inputValidator: value => {
        if (isBlank(value) || isOnlyWhitespace(value)) {
          return 'New Facility Group cannot be blank';
        } else if (value.length > 200) {
          return 'New Facility Group must be 200 characters or less';
        }
      },
    });
    if (authGroupName) {
      let updateFacilityGroups = JSON.parse(JSON.stringify(this.state.facilityGroups));
      let duplicateFacilityGroupName = updateFacilityGroups.find(f => f.label === authGroupName);
      if (duplicateFacilityGroupName) {
        return sweetalert.fire({ icon: 'error', title: '', text: 'New Facility Group name cannot be an existing facility group name' });
      } else {
        updateFacilityGroups.push({ label: authGroupName.trim(), value: authGroupName.trim() });

        this.setState(prevState => ({
          facilityGroups: updateFacilityGroups,
          selectedFacility: {
            ...prevState.selectedFacility,
            facilityGroupLabel: { label: authGroupName.trim(), value: authGroupName.trim() },
            facilityGroup: authGroupName,
          },
        }));
      }
      this.restoreBootstrapModal();
    }
  }

  handleChange(e, stateToSet, stateLabelToSet = '', type = 'input', multi = false, selectedServiceID = null) {
    if (type === 'select') {
      if (multi) {
        //CommTemplate added or removed
        if (selectedServiceID) {
          let serviceID = selectedServiceID;
          let enabledCommTemplateLabelsCopy =
            this.state.selectedFacility && this.state.selectedFacility.enabledCommTemplateLabels
              ? JSON.parse(JSON.stringify(this.state.selectedFacility.enabledCommTemplateLabels))
              : null;

          //create objectMap for comparison of e array and enabledCommTemplateLabelsCopy
          const objectMap = new Map();

          //if all CommTemplates removed from a service, set IsEnabled to null for every object
          if (!e || e.length < 1) {
            enabledCommTemplateLabelsCopy.forEach(obj => {
              if (obj.ServiceID === serviceID) {
                obj.IsEnabled = 0;
              }
            });
          }
          //compare e array's value to enabledCommTemplateLabelsCopy's CommTemplateID.
          //if the value is present in e then set IsEnabled to 1 (will display)
          else {
            e.forEach(obj => {
              const identifier = obj.value;
              objectMap.set(identifier, obj);
            });
            enabledCommTemplateLabelsCopy.forEach(obj => {
              const identifier = obj.CommTemplateID;
              if (obj.ServiceID && obj.ServiceID === serviceID) {
                if (!objectMap.has(identifier)) {
                  obj.IsEnabled = 0;
                } else if (objectMap.has(identifier)) {
                  obj.IsEnabled = 1;
                }
              }
            });
          }
          this.setState(prevState => ({
            changesMade: true,
            selectedFacility: {
              ...prevState.selectedFacility,
              enabledCommTemplateLabels: enabledCommTemplateLabelsCopy,
            },
          }));
        }

        //service added or removed
        else {
          let newArray = [];
          let enabledCommTemplatesLabelsCopy =
            this.state.selectedFacility && this.state.selectedFacility.enabledCommTemplateLabels
              ? JSON.parse(JSON.stringify(this.state.selectedFacility.enabledCommTemplateLabels))
              : [];
          if (e) {
            e.map(s => {
              newArray.push(s.value);
            });

            let selectedServiceLength = this.state.selectedFacility.selectedServices ? this.state.selectedFacility.selectedServices.length : 0;

            //if added - see which ServiceID was added and create new CommTemplate objects with that ServiceID and IsEnabled = 0
            if (newArray.length > selectedServiceLength) {
              for (let i = 0; i < newArray.length; i++) {
                if (!!enabledCommTemplatesLabelsCopy.find(f => f.ServiceID === newArray[i])) {
                  continue;
                } else {
                  this.props.commTemplates.map(ct => {
                    enabledCommTemplatesLabelsCopy.push({
                      ID: null,
                      Name: ct.Name,
                      FacilityID: this.state.selectedFacility.facilityID ? this.state.selectedFacility.facilityID : null,
                      ServiceID: newArray[i],
                      CommTemplateID: ct.ID,
                      IsEnabled: 0,
                    });
                  });
                }
              }
            }
            //if service removed - determine which service is still present and push into new array.
            else {
              let temp = [];
              for (let i = 0; i < enabledCommTemplatesLabelsCopy.length; i++) {
                if (newArray.includes(enabledCommTemplatesLabelsCopy[i].ServiceID)) {
                  temp.push(enabledCommTemplatesLabelsCopy[i]);
                }
              }
              enabledCommTemplatesLabelsCopy = temp;
            }
          }
          //if no service selected, set enabledCommTemplatesLabelsCopy to empty array
          else {
            enabledCommTemplatesLabelsCopy = [];
          }

          this.setState(prevState => ({
            changesMade: true,
            selectedFacility: {
              ...prevState.selectedFacility,
              [stateLabelToSet]: e,
              [stateToSet]: newArray.length > 0 ? newArray : null,
              enabledCommTemplateLabels: enabledCommTemplatesLabelsCopy,
            },
          }));
        }
      } else {
        this.setState(prevState => ({
          changesMade: true,
          selectedFacility: {
            ...prevState.selectedFacility,
            [stateLabelToSet]: e,
            [stateToSet]: e.value,
          },
        }));
      }
    } else {
      this.setState(prevState => ({
        changesMade: true,
        selectedFacility: {
          ...prevState.selectedFacility,
          [stateToSet]: e.target.value,
        },
      }));
    }
  }

  handleDateOnChange(value, state) {
    if (value) {
      this.setState(prevState => ({
        changesMade: true,
        selectedFacility: {
          ...prevState.selectedFacility,
          [state]: value,
        },
      }));
    } else {
      this.setState(prevState => ({
        changesMade: true,
        selectedFacility: {
          ...prevState.selectedFacility,
          [state]: null,
        },
      }));
    }
  }

  getEnabledCommTemplateLabels(facilityID, serviceIDs) {
    let returnArray = [];
    if (facilityID && serviceIDs && serviceIDs.length > 0) {
      for (let i = 0; i < serviceIDs.length; i++) {
        if (
          !this.props.facilityCommTemplateStatusArray ||
          this.props.facilityCommTemplateStatusArray.length < 1 ||
          !this.props.facilityCommTemplateStatusArray.find(f => f.FacilityID === facilityID) ||
          !this.props.facilityCommTemplateStatusArray.find(f => f.ServiceID === serviceIDs[i])
        ) {
          this.props.commTemplates.map(ct => {
            returnArray.push({
              Name: ct.Name,
              FacilityID: facilityID,
              ServiceID: serviceIDs[i],
              CommTemplateID: ct.ID,
              IsEnabled: 0,
            });
          });
        } else {
          for (let j = 0; j < this.props.facilityCommTemplateStatusArray.length; j++) {
            if (this.props.facilityCommTemplateStatusArray[j].FacilityID === facilityID && this.props.facilityCommTemplateStatusArray[j].ServiceID === serviceIDs[i]) {
              returnArray.push({
                ID: this.props.facilityCommTemplateStatusArray[j].ID,
                Name: this.props.commTemplates.find(f => f.ID === this.props.facilityCommTemplateStatusArray[j].CommTemplateID).Name,
                FacilityID: facilityID,
                ServiceID: serviceIDs[i],
                CommTemplateID: this.props.facilityCommTemplateStatusArray[j].CommTemplateID,
                IsEnabled: this.props.facilityCommTemplateStatusArray[j].IsEnabled,
              });
            }
            // else if(this.props.facilityCommTemplateStatusArray[j].FacilityID !== facilityID && this.props.facilityCommTemplateStatusArray[j].ServiceID === serviceIDs[i]){
            //     returnArray.push({
            //         ID: this.props.facilityCommTemplateStatusArray[j].ID,
            //         Name: this.props.commTemplates.find(f => f.ID === this.props.facilityCommTemplateStatusArray[j].CommTemplateID).Name,
            //         FacilityID: facilityID,
            //         ServiceID: serviceIDs[i],
            //         CommTemplateID: this.props.facilityCommTemplateStatusArray[j].CommTemplateID,
            //         IsEnabled: 0
            //     })
            // }
          }
        }
      }
    }

    return returnArray;
  }

  clear() {
    this.setState({
      facilityArrowDown: false,
      customFieldArrowDown: false,
      changesMade: false,
    });
  }

  loadScript(src: string) {
    let tag = document.createElement('script');
    tag.src = src;
    document.body.appendChild(tag);
  }

  render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {

    // console.log('MgFacModal props', this.props)
    // console.log('MgFacModal state', this.state)

    let selectedServices = this.state.selectedFacility?.selectedServices
      ? this.props.services.filter(service => this.state.selectedFacility.selectedServices?.includes(service.ID))
      : null;

    return (
      <React.Fragment>
        <Overlay show_loading={this.state.showLoading} zIndex={100003} />
        <div className="modal fade form_modal" aria-label="Facility Management" id={ManageFacilityModal.ID} tabIndex={-1} role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-lg modal-xl" role="document">
            <div className="modal-content">
              <div className="container-fluid">
                <div className="modal-header">
                  <h5 className="modal-title" id="result_modal_label">
                    {this.state.selectedFacility && this.state.selectedFacility.facilityID ? 'Update Facility' : 'Create Facility'}
                  </h5>
                  <button
                    style={{ outline: 'none' }}
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={() => {
                      if (this.state.changesMade) {
                        handleCloseModal(ManageFacilityModal.ID);
                      } else {
                        hideModal(ManageFacilityModal.ID);
                      }
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className={'modal-body my-0 mx-3 p-0'}>
                  <div className="row">
                    <div className="col-12 col-lg-6">
                      <CustomInput
                        fromFilterPage={false}
                        value={this.state.selectedFacility?.facilityName}
                        label={'Facility Name'}
                        tooltip={'Facility Name'}
                        handleChange={e => this.handleChange(e, 'facilityName')}
                        maxLength={200}
                      />
                      <div className={'form-group row'}>
                        <div className={'col-12 col-md-4'} data-toggle={'tooltip'} data-placement={'top'} title={'Facility Group'}>
                          <label htmlFor={'FacilityGroup'} className={'col-form-label'}>
                            {'Facility Group'}
                          </label>
                        </div>
                        <div className={'col-12 col-md-8 pl-md-0 pr-md-1'} id={'FacilityGroup'}>
                          <div className="row">
                            <div className={this.props.isITG || this.props.isManagement ? 'col-12 col-md-7' : 'col-12'}>
                              <Select
                                isSearchable={true}
                                placeholder={'Please select...'}
                                aria-label="Facility Group"
                                onChange={e =>
                                  this.setState(prevState => ({
                                    selectedFacility: {
                                      ...prevState.selectedFacility,
                                      facilityGroupLabel: e,
                                      facilityGroup: e.value,
                                    },
                                    changesMade: true,
                                  }))
                                }
                                className={'state_select'}
                                options={this.state.facilityGroups}
                                value={this.state.selectedFacility?.facilityGroupLabel}
                              />
                            </div>
                            <div
                              className={this.props.isITG || this.props.isManagement ? 'col-12 col-md-5 mt-2 mt-md-0 pl-md-0' : 'd-none'}
                              data-toggle={'tooltip'}
                              data-placement={'top'}
                              title={'This button will open an input field below to create a new facility group'}
                            >
                              <button className={'btn btn-success w-100 h-100 font-weight-bold'} onClick={() => this.handleAddNewAuthFacilityGroup()}>
                                New Group
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <CustomInput
                        fromFilterPage={false}
                        value={this.state.selectedFacility?.facilityAbbreviation}
                        label={'Facility Abbreviation'}
                        tooltip={'Facility Abbreviation'}
                        handleChange={e => this.handleChange(e, 'facilityAbbreviation')}
                        maxLength={50}
                      />
                      <CustomPhone
                        fromFilterPage={false}
                        value={this.state.selectedFacility?.facilityPhone}
                        label={'Facility Phone'}
                        tooltip={`The phone number of the Facility`}
                        handleDateOnChange={e => this.handleDateOnChange(e, 'facilityPhone')}
                      />
                    </div>
                    <div className="col-12 col-lg-6">
                      <CustomInput
                        fromFilterPage={false}
                        value={this.state.selectedFacility?.facilityEmail}
                        label={'Facility Email'}
                        tooltip={'Facility Email'}
                        handleChange={e => this.handleChange(e, 'facilityEmail')}
                        maxLength={100}
                      />
                      <div
                        key={'FacilityAddressAutocomplete'}
                        className="form-group row"
                        data-toggle={'tooltip'}
                        data-placement={'top'}
                        title={'Facility Address'}
                      >
                        <label
                          id={'Facility Address'}
                          htmlFor={'Facility Address'}
                          className="col-12 col-sm-4 col-form-label text-center text-md-left"
                        >
                          {'Facility Address'}
                        </label>
                        <div className="col-12 col-sm-8 p-md-0 m-md-0 text-center text-md-left" id={'Facility Address'}>
                          <GooglePlacesAutoComplete
                            key={'FacilityAddressAutocomplete'}
                            id={'FacilityAddressAutocomplete'}
                            value={
                              this.state.selectedFacility?.facilityCity
                                ? `${this.state.selectedFacility?.facilityAddress}, ${this.state.selectedFacility?.facilityCity}, ${this.state.selectedFacility?.facilityState} ${this.state.selectedFacility?.facilityZip}`
                                : this.state.selectedFacility?.facilityAddress
                            }
                            onChange={addr => {
                              if (!addr) {
                                this.setState({
                                  selectedFacility: {
                                    ...this.state.selectedFacility,
                                    facilityAddress: '',
                                    facilityCity: '',
                                    facilityState: '',
                                    facilityZip: '',
                                  },
                                });
                              }
                              this.setState(prevState => ({
                                selectedFacility: {
                                  ...prevState.selectedFacility,
                                  facilityAddress: addr,
                                },
                              }));
                            }}
                            updateFormData={(addr, city, state, zip) => {
                              this.setState({
                                selectedFacility: {
                                  ...this.state.selectedFacility,
                                  facilityAddress: addr,
                                  facilityCity: city,
                                  facilityState: state,
                                  facilityZip: zip,
                                },
                              });
                            }}
                          />
                        </div>
                      </div>

                      <CustomSelect
                        fromFilterPage={false}
                        value={this.state.selectedFacility?.isActiveLabel}
                        label={'Is Active?'}
                        tooltip={'Is Active?'}
                        handleChange={e => this.handleChange(e, 'isActive', 'isActiveLabel', 'select')}
                        options={getBoolSelectOptions()}
                      />
                      <CustomSelect
                        fromFilterPage={false}
                        value={this.state.selectedFacility?.selectedServicesLabel}
                        label={'Services'}
                        tooltip={'Services'}
                        handleChange={e => this.handleChange(e, 'selectedServices', 'selectedServicesLabel', 'select', true)}
                        options={this.props.services.map(s => {
                          return { label: s.Name, value: s.ID };
                        })}
                        isMulti={true}
                      />
                    </div>
                  </div>
                  <div className="row">
                    {this.props.pageCustomQuestions.map((val, index) => {
                      if (this.props.pageCustomQuestions.length === 1) {
                        if (val.Options.isActive) {
                          return (
                            <div className="col-12">
                              <div className="form-group row">
                                <GetField
                                  val={val}
                                  selectedItem={this.state.selectedFacility ? this.state.selectedFacility : {}}
                                  handleChange={e => {
                                    this.setState({ selectedFacility: e, changesMade: true });
                                  }}
                                />
                              </div>
                            </div>
                          );
                        }
                      } else if (val.Options.isActive) {
                        if (index % 2 === 0) {
                          return (
                            <div className="col-12 col-lg-6">
                              <div className="form-group row">
                                <GetField
                                  val={val}
                                  selectedItem={this.state.selectedFacility ? this.state.selectedFacility : {}}
                                  handleChange={e => {
                                    this.setState({ selectedFacility: e, changesMade: true });
                                  }}
                                />
                              </div>
                            </div>
                          );
                        } else if (index % 2 === 1) {
                          return (
                            <div className="col-12 col-lg-6">
                              <div className="form-group row">
                                <GetField
                                  val={val}
                                  selectedItem={this.state.selectedFacility ? this.state.selectedFacility : {}}
                                  handleChange={e => {
                                    this.setState({ selectedFacility: e, changesMade: true });
                                  }}
                                />
                              </div>
                            </div>
                          );
                        }
                      }
                    })}
                  </div>
                  {this.state.selectedFacility && this.state.selectedFacility.selectedServicesLabel && this.state.selectedFacility.selectedServicesLabel.length > 0 && (
                    <div className={'form-group row'}>
                      <div className={'col-12 text-center'} data-toggle={'tooltip'} data-placement={'top'} title={'Communications'}>
                        <label htmlFor={'Communications'} style={{ fontWeight: 'bold', paddingTop: '1.5em', fontSize: '1.2rem' }}>
                          {'Communications'}
                        </label>
                      </div>
                    </div>
                  )}

                  {this.state.selectedFacility &&
                    this.state.selectedFacility.selectedServicesLabel &&
                    this.state.selectedFacility.selectedServicesLabel.length > 0 &&
                    this.state.selectedFacility.selectedServicesLabel.map((ssl, index) => (
                      <CustomSelect
                        fromFilterPage={false}
                        value={
                          this.state.selectedFacility.enabledCommTemplateLabels && this.state.selectedFacility.enabledCommTemplateLabels.length > 0
                            ? this.state.selectedFacility.enabledCommTemplateLabels
                                .filter(f => f.ServiceID === ssl.value && f.IsEnabled)
                                .map(l => {
                                  return {
                                    label: l.Name.includes('WalkinEmail')
                                      ? 'Submission Email'
                                      : l.Name.includes('WalkinText')
                                      ? 'Submission Text'
                                      : l.Name.replace(/([A-Z])/g, ' $1').trim(),
                                    value: l.CommTemplateID,
                                  };
                                })
                            : []
                        }
                        label={`${ssl.label}`}
                        tooltip={`Enabled Communication Templates for ${ssl.label}`}
                        handleChange={e => this.handleChange(e, '', '', 'select', true, ssl.value)}
                        options={this.props.commTemplates.map(s => {
                          return {
                            label: s.Name.includes('WalkinEmail')
                              ? 'Submission Email'
                              : s.Name.includes('WalkinText')
                              ? 'Submission Text'
                              : s.Name.replace(/([A-Z])/g, ' $1').trim(),
                            value: s.ID,
                          };
                        })}
                        isMulti={true}
                      />
                    ))}

                  <div className="modal-footer">
                    <button className={'btn btn-primary'} onClick={() => this.onSubmit()} disabled={this.state.isSubmitting}>
                      {this.state.selectedFacility && this.state.selectedFacility.facilityID ? 'Update' : 'Create'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
