import NetworkUtil from "./NetworkUtil";
import {ServerResponse} from "../types/ServerResponse";

export default class OSHAAPI {

    public static async getFilteredRecords(page, filter):Promise<{num_pages:number, totalRecords:number, table_data?}>{
        return NetworkUtil.makePost("/api/admin/osha/recordSearch?page=" + page, filter);
    }

    public static upsertReport(report):Promise<ServerResponse>{
        return NetworkUtil.makePost("/api/admin/osha/upsertReport", {report: report});
    }

    public static async generateSummary(facility, beginDate, endDate, annualAverageNumberofEmployees?, totalHoursWorkedByAllEmployeesLastYear?):Promise<any>{
        return NetworkUtil.makePostForBuffer("/api/admin/osha/generateSummary", {facility: facility, beginDate: beginDate, endDate: endDate, annualAverageNumberofEmployees: annualAverageNumberofEmployees, totalHoursWorkedByAllEmployeesLastYear: totalHoursWorkedByAllEmployeesLastYear});
    }

    public static async getAllOSHAReports():Promise<{oshaReports}>{
        return NetworkUtil.makeGet("/api/admin/osha/getAllOSHAReports");
    }

}