import React from "react";
import Overlay from "../Overlay";
import Select from "react-select";
import {
  getSelectLabels,
  getNameFromID,
  showModalNoOutsideClick,
  parseDate,
} from "../../util/FormatUtil";
import TestType from "../../types/TestType";
import { sweetalert } from "../../App";
import SystemAPI from "../../network/SystemAPI";
import TestsAPI from "../../network/TestsAPI";
import Validator from "../../validation/Validator";
import { Validators } from "../../validation/Validators";
import ServicesAPI from "../../network/ServicesAPI";
import { ServiceFormModal } from "../modals/ServiceFormModal";
import WalkinAPI from "../../network/WalkinAPI";
import { ImportantInformationModal } from "../modals/ImportantInformationModal";
import Parser from "html-react-parser";
import InformedConsentModal from "../modals/InformedConsentModal";
import { CustomerAPI } from "../../network/CustomerAPI";
import EmployeeAPI from "../../network/EmployeeAPI";
import CustomFieldsAPI from "../../network/CustomFieldsAPI";
import { FacilityAPI } from "../../network/FacilityAPI";

interface WalkinFormState {
  serviceFormModalKey?;
  showLoading: boolean;
  selectedFacilityIDLabel?;
  selectedEmployeeLabel?;
  facilities?;
  facilityLabels;
  tests: TestType[];
  vaccines?;
  evaluations?;
  serviceHasTests?;
  serviceHasVaccines?;
  serviceHasEvaluations?;
  isSubmitting;
  customQuestions?;
  customEmployeeFields?;
  services;
  selectedServices;
  serviceLabels;
  associatedTests: TestType[];
  associatedEvaluations?;
  associatedVaccines?;
  selectedServiceID?: number;
  serviceSpecificCustomQuestions?;
  savedServices?;
  servicesObjectsArray?;
  savedImportantInformation;
  acknowledged;
  legalName?;
  employees?;
  submissionFiles?;
  serviceTypes?;
}

interface WalkinFormProps {}

export default class WalkinForm extends React.Component<
  WalkinFormProps,
  WalkinFormState
> {
  private serviceFormRef: any;

  constructor(props) {
    super(props);
    this.state = {
      serviceFormModalKey: 1,
      services: [],
      serviceLabels: [],
      facilityLabels: [],
      selectedServices: [],
      showLoading: false,
      isSubmitting: false,
      tests: [],
      customQuestions: [],
      associatedTests: [],
      selectedFacilityIDLabel: null,
      savedServices: [],
      servicesObjectsArray: [],
      savedImportantInformation: false,
      acknowledged: false,
      employees: [],
      serviceHasTests: false,
      serviceHasEvaluations: false,
      serviceHasVaccines: false,
      serviceTypes: [],
    };
    this.submit = this.submit.bind(this);
    this.assignClearService = this.assignClearService.bind(this);
    this.serviceFormRef = React.createRef();
  }

  componentDidMount() {
    document.title = "Submission Form";
    this.setState({ showLoading: true }, () => {
      CustomerAPI.getPolicyName().then((data) => {
        this.setState({ legalName: data.name });
      });
      FacilityAPI.getFacilitiesForSubmissionForm().then((data) => {
        this.setState({
          facilities: data.authorizedFacilities,
          facilityLabels: getSelectLabels(data.authorizedFacilities),
        });
      });
      EmployeeAPI.getEmployeesFromEmployeePortal().then(async (data) => {
        this.setState({ employees: data.employees });
      });
      TestsAPI.getAllTestDetails().then((data) => {
        let enabledTests = data.tests.filter((t) => t.IsEnabled);
        this.setState({ tests: enabledTests });
      });
      CustomFieldsAPI.getAllCustomFields().then((data) => {
        this.setState({
          customQuestions: data.data.customQuestions,
        });
      });
      SystemAPI.getAllEnabledVaccines().then((data) => {
        this.setState({ vaccines: data });
      });
      SystemAPI.getAllEnabledEvaluations().then((data) => {
        this.setState({ evaluations: data });
      });
      SystemAPI.getAllServiceTypes().then((data) => {
        this.setState({ serviceTypes: data });  
      })
      ServicesAPI.getAllServices().then((response) => {
        this.setState({
          services: response.data,
          serviceLabels: getSelectLabels(response.data),
        });
      });
      this.setState({ showLoading: false });
    });
  }

  async submit() {
    this.setState({ showLoading: true, isSubmitting: true });

    if (!this.state.savedImportantInformation) {
      this.setState({ showLoading: false, isSubmitting: false });
      return sweetalert.fire({
        icon: "error",
        title: "",
        text: "Important Information must be reviewed.",
      });
    }

    let formValidation = {
      FacilityID: this.state.selectedFacilityIDLabel,
    };

    let validator = new Validator<any>().withSimpleValidation(
      "FacilityID",
      Validators.requireNonNullValidator("Facility")
    );
    let validationResponse = validator.validate(formValidation);
    if (!validationResponse.success) {
      this.setState({ showLoading: false, isSubmitting: false });
      return sweetalert.fire({
        icon: "error",
        title: "",
        text: validationResponse.error,
      });
    }
    
    let serviceObjectArrayCopy = JSON.parse(
      JSON.stringify(this.state.servicesObjectsArray)
    );


    if (!serviceObjectArrayCopy || serviceObjectArrayCopy.length < 1) {
      let serviceNames = this.state.selectedServices.map(s => s.Name);
      let serviceNamesString = serviceNames.toString();
      let serviceNamesStringWithSpace = serviceNamesString.replace(",", ", ");
      this.setState({ showLoading: false, isSubmitting: false });
      return sweetalert.fire({
        icon: "error",
        title: "",
        text: `${serviceNamesStringWithSpace} ${this.state.selectedServices.length > 1 ? "Services" : "Service"} must be completed.`,
      });
    }

    if(serviceObjectArrayCopy.length > 0){
      let serviceIDs = this.state.selectedServices.map(s => s.ID);
      for(let id of serviceIDs){
        if(!serviceObjectArrayCopy.find(s => s.serviceID === id)){
          this.setState({ showLoading: false, isSubmitting: false });
          return sweetalert.fire({
            icon: "error",
            title: "",
            text: `${this.state.selectedServices.find(s => s.ID === id).Name} Service must be completed.`,
          });
        }

      }
    }


    if (!this.state.acknowledged) {
      this.setState({ showLoading: false, isSubmitting: false });
      return sweetalert.fire({
        icon: "error",
        title: "",
        text: "Summary acknowledgment box must be checked.",
      });
    }

    let currentURL = window.location.href;

    this.setState({ showLoading: true, isSubmitting: true }, async () => {
      try {
        let result = await WalkinAPI.walkinForm(
          this.state.selectedFacilityIDLabel.value,
          this.state.selectedEmployeeLabel.value,
          serviceObjectArrayCopy,
          currentURL,
          this.state.selectedServices,
        );

        if (result.success) {
          sweetalert.fire({icon: 'success', title: '', html: result.reason});
          let ackCheckbox = document.getElementById("acknowledge") as any;
          ackCheckbox.checked = false;

          this.setState(
            {
              serviceFormModalKey: this.state.serviceFormModalKey + 1,
              isSubmitting: false,
              servicesObjectsArray: [],
              savedServices: [],
              savedImportantInformation: false,
              acknowledged: false,
            }
          );
        } else {
          sweetalert.fire({
            icon: "error",
            title: "",
            html: result.reason.replace(/\n/g, "<br>"),
          });
        }
        this.setState({ showLoading: false, isSubmitting: false });
      } catch (e) {
        console.error(e);
        sweetalert.fire({ icon: "error", title: "", text: "Failed to submit" });
        this.setState({ showLoading: false, isSubmitting: false });
      }
    });
  }

  clear() {
    let ackCheckbox = document.getElementById("acknowledge") as any;
    ackCheckbox.checked = false;
    this.setState(
      {
        selectedEmployeeLabel: null,
        isSubmitting: false,
        selectedFacilityIDLabel: null,
        servicesObjectsArray: [],
        savedServices: [],
        selectedServices: [],
        savedImportantInformation: false,
        acknowledged: false,

      },
      () => {
        this.serviceFormRef.current.clearServiceFormState();
      }
    );
  }

  clearServiceForm = null;

  assignClearService(func) {
    this.clearServiceForm = func;
  }

  handleDateOnChange(value, state) {
    if (value) {
      this.setState({ [state]: value } as any);
    } else {
      this.setState({ [state]: null } as any);
    }
  }

  handleServiceChange(service) {
    let tempServices = [];
    let tempTestIDs = [];
    let tempEvaluationIDs = [];
    let tempVaccineIDs = [];
    let tempCustomQuestions = [];

    if (
      service &&
      service.length > 0 &&
      this.state.services &&
      this.state.services.length > 0
    ) {
      service.map((e) => {
        for (let i = 0; i < this.state.services.length; i++) {
          if (e.value === this.state.services[i].ID) {
            //selectedServices
            tempServices.push(this.state.services[i]);

            //assciated tests
            if (
              this.state.services[i].Tests &&
              this.state.services[i].Tests.length > 0
            ) {
              tempTestIDs = tempTestIDs.concat(this.state.services[i].Tests);
            }
            if (
              this.state.services[i].Evaluations &&
              this.state.services[i].Evaluations.length > 0
            ) {
              tempEvaluationIDs = tempEvaluationIDs.concat(
                this.state.services[i].Evaluations
              );
            }
            if (
              this.state.services[i].Vaccines &&
              this.state.services[i].Vaccines.length > 0
            ) {
              tempVaccineIDs = tempVaccineIDs.concat(
                this.state.services[i].Vaccines
              );
            }

            break;
          }
        }

        //customQuestions
        if (
          this.state.customQuestions &&
          this.state.customQuestions.length > 0
        ) {
          tempCustomQuestions.push(
            this.state.customQuestions.filter(
              (q) => q.ServiceID === e.value && q.Options.isActive
            )
          );
        }
      });
    }

    tempTestIDs = Array.from(new Set(tempTestIDs));
    let associatedTests = [];
    if (
      tempTestIDs &&
      tempTestIDs.length > 0 &&
      this.state.tests &&
      this.state.tests.length > 0
    ) {
      tempTestIDs.map((tt) => {
        for (let i = 0; i < this.state.tests.length; i++) {
          if (tt === this.state.tests[i].ID) {
            associatedTests.push(this.state.tests[i]);
            break;
          }
        }
      });
    }
    let associatedEvaluations = [];
    if (
      tempEvaluationIDs &&
      tempEvaluationIDs.length > 0 &&
      this.state.evaluations &&
      this.state.evaluations.length > 0
    ) {
      tempEvaluationIDs.map((tt) => {
        for (let i = 0; i < this.state.evaluations.length; i++) {
          if (tt === this.state.evaluations[i].ID) {
            associatedEvaluations.push(this.state.evaluations[i]);
            break;
          }
        }
      });
    }
    let associatedVaccines = [];
    if (
      tempVaccineIDs &&
      tempVaccineIDs.length > 0 &&
      this.state.vaccines &&
      this.state.vaccines.length > 0
    ) {
      tempVaccineIDs.map((tt) => {
        for (let i = 0; i < this.state.vaccines.length; i++) {
          if (tt === this.state.vaccines[i].ID) {
            associatedVaccines.push(this.state.vaccines[i]);
            break;
          }
        }
      });
    }

    this.setState({
      selectedServices: tempServices,
      associatedTests: associatedTests,
      associatedEvaluations: associatedEvaluations,
      associatedVaccines: associatedVaccines,
      savedImportantInformation: false,
    });
  }

  handleSaveServiceSpecificInfo(e) {
    let serviceID = e && e.serviceID ? e.serviceID : null;
    let savedServices = JSON.parse(JSON.stringify(this.state.savedServices));
    let servicesObjectsArray = JSON.parse(JSON.stringify(this.state.servicesObjectsArray));

    if (serviceID) {
      savedServices.push(serviceID);
    }

    if (servicesObjectsArray?.length > 0) {
      servicesObjectsArray.map((r, i) => {
        if (r.serviceID === serviceID) {
          servicesObjectsArray.splice(i, 1);
        }
      });
    }

    servicesObjectsArray.push({
      serviceID: serviceID,
      submittedAnswers: e.answeredCustomQuestions,
      recordInfo: {
      },
    });

    this.setState({
      savedServices: savedServices,
      servicesObjectsArray: servicesObjectsArray,
    });
  }

  render():
    | React.ReactElement
    | string
    | number
    | {}
    | React.ReactNodeArray
    | React.ReactPortal
    | boolean
    | null
    | undefined {
    // console.log("SubmissionForm", this.state);

    let selectedFacilityIDLabel = this.state.selectedFacilityIDLabel
      ? this.state.selectedFacilityIDLabel
      : null;
    let availableServiceIDs =
      this.state.facilities && selectedFacilityIDLabel
        ? this.state.facilities.find(
            (f) => f.ID === selectedFacilityIDLabel.value
          ).ServiceIDs
        : null;
    let parsedServiceIDs = JSON.parse(availableServiceIDs);
    let availableServicesLabels =
      this.state.serviceLabels && parsedServiceIDs
        ? this.state.serviceLabels.filter((f) =>
            parsedServiceIDs.includes(f.value)
          )
        : [];

    let { serviceFormModalKey } = this.state;

    let employeesThatBelongToFacility = [];
    if (this.state.employees && this.state.selectedFacilityIDLabel) {
      employeesThatBelongToFacility = this.state.employees.filter((e) =>
        JSON.parse(e.FacilityIDs).includes(
          this.state.selectedFacilityIDLabel.value
        )
      );
    }
    return (
      <React.Fragment>
        <Overlay show_loading={this.state.showLoading} />

        <ImportantInformationModal
          services={this.state.services}
          selectedServices={this.state.selectedServices}
          setAccepted={() => this.setState({ savedImportantInformation: true })}
        />

        <ServiceFormModal
          key={serviceFormModalKey}
          ref={this.serviceFormRef}
          submit={(e) => this.handleSaveServiceSpecificInfo(e)}
          service={this.state.services.find(
            (f) => f.ID === this.state.selectedServiceID
          )}
          reviewInfo={
            this.state.servicesObjectsArray?.length > 0 &&
            this.state.servicesObjectsArray.find(
              (f) => f.serviceID === this.state.selectedServiceID
            )
          }
          attachClearStateFunc={this.assignClearService}
          customQuestions={this.state.serviceSpecificCustomQuestions}
          associatedTests={this.state.associatedTests}
          associatedEvaluations={this.state.associatedEvaluations}
          associatedVaccines={this.state.associatedVaccines}
          tests={this.state.tests}
          vaccines={this.state.vaccines}
          evaluations={this.state.evaluations}
          serviceTypes={this.state.serviceTypes}
        />

        {/* <ROIModal
                    ref={this.roiRef}
                    releaseChanged={(r) => this.handleRelease(r)}
                    selectedServices={this.state.selectedServices}
                    services={this.state.services}
                    consentEntities={this.state.consentEntities}
                    handleRemove={(e) => this.handleRemoveRelease(e)}
                    handleRemoveAll={() => this.setState({declineConsent: true}, ()=> this.handleRemoveRelease('all'))}
                    reviewInfo={
                        this.state.roiObjectsArray?.length > 0 &&
                        this.state.roiObjectsArray.find(
                        (f) => f.serviceID === this.state.selectedServiceID
                        )
                    }
                /> */}

        <InformedConsentModal
          legalName={this.state.legalName}
          selectedServices={this.state.selectedServices}
        />

        {/* <SubmissionFileUploadModal
          ref={this.submissionFileRef}
          clearParentState={() => this.setState({ submissionFiles: [] })}
          handleSubmit={(e) =>
            this.setState({ submissionFiles: e, savedFileUpload: true })
          }
        /> */}

        <div className="container-fluid  min-vh-100">
          <div className={"row"}>
            <div className=" col-12 col-sm-12  col-md-12 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3 pt-2">
              <main
                id="main-content"
                tabIndex={-1}
                aria-label="Submission Form"
              >
                <div className="card mt-2">
                  <div className="card-header verlag-bold">
                    <h4>Service Selection</h4>
                  </div>
                  <div className="card-body">
                    <div className="form-group row">
                      <label
                        htmlFor={"Facility"}
                        className="col-sm-4 col-form-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Facility
                      </label>
                      <div className="col-sm-8  p-0 m-0" id={"Facility"}>
                        <Select
                          name="Facility"
                          isSearchable={true}
                          placeholder={
                            <div className="accessibilityText">
                              Please Select...
                            </div>
                          }
                          noOptionsMessage={() => "No option"}
                          className={"state_select"}
                          options={this.state.facilityLabels}
                          onChange={(e) =>
                            this.setState({
                              selectedFacilityIDLabel: e,
                              selectedServices: [],
                              selectedEmployeeLabel: null,
                              // showFileUpload: false,
                            })
                          }
                          value={this.state.selectedFacilityIDLabel}
                          aria-label="Facility"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="serviceDropDown"
                        className="col-sm-4 col-form-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Service
                      </label>
                      <div className="col-sm-8  p-0 m-0">
                        <Select
                          name="serviceDropDown"
                          isMulti={true}
                          isSearchable={true}
                          placeholder={
                            <div className="accessibilityText">
                              Please Select...
                            </div>
                          }
                          noOptionsMessage={() => "No option"}
                          className={"state_select"}
                          options={availableServicesLabels}
                          onChange={(e) => this.handleServiceChange(e)}
                          value={this.state.selectedServices.map((s) => ({
                            label: s.Name,
                            value: s.ID,
                          }))}
                          aria-label="Service"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor={"EmployeeSearch"}
                        className="col-sm-4 col-form-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Employee
                      </label>
                      <div className="col-sm-8 p-0 m-0" id={"EmployeeSearch"}>
                        <Select
                          name="EmployeeSearch"
                          isSearchable={true}
                          placeholder={
                            <div className="accessibilityText">
                              Please Select...
                            </div>
                          }
                          noOptionsMessage={() => "No option"}
                          className={"state_select"}
                          options={employeesThatBelongToFacility.map((p) => {
                            return {
                              label: `${p.FirstName} ${
                                p.LastName
                              } - ${parseDate(p.DateOfBirth)} - ${p.UID}`,
                              value: p.UID,
                            };
                          })}
                          onChange={(e) =>
                            this.setState({
                              selectedEmployeeLabel: e,
                            })
                          }
                          value={
                            this.state.selectedEmployeeLabel
                              ? this.state.selectedEmployeeLabel
                              : null
                          }
                          aria-label="EmployeeSearch"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </main>

              {/* important information section */}
              {this.state.selectedFacilityIDLabel &&
                this.state.selectedServices.length > 0 && (
                  <div className="mb-2">
                    <div className="card mt-2">
                      <div className="card-header verlag-bold">
                        <div className="row">
                          <div className="col-10">
                            <h4>Important Information</h4>
                          </div>
                          <div className="col-2">
                            {this.state.savedImportantInformation ? (
                              <>
                                <button
                                  aria-label={"Important Information Review"}
                                  className={"btn btn-success verlag-bold"}
                                  style={{ float: "right" }}
                                  onClick={() =>
                                    showModalNoOutsideClick(
                                      ImportantInformationModal.ID
                                    )
                                  }
                                >
                                  Review
                                </button>
                              </>
                            ) : (
                              <button
                                aria-label={"Important Information Start"}
                                className={"btn btn-primary verlag-bold"}
                                style={{ float: "right" }}
                                onClick={() =>
                                  showModalNoOutsideClick(
                                    ImportantInformationModal.ID
                                  )
                                }
                              >
                                Start
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              {/* service information  */}
              {this.state.selectedFacilityIDLabel &&
                this.state.selectedServices?.length > 0 &&
                this.state.selectedServices.map((ss) => (
                  <div className="card mt-2">
                    <div className="card-header verlag-bold">
                      <div className="row">
                        <div className="col-10">
                          <h4>{getNameFromID(ss.ID, this.state.services)}</h4>
                        </div>
                        <div className="col-2">
                          {this.state.savedServices &&
                          this.state.savedServices.includes(ss.ID) ? (
                            <>
                              <button
                                aria-label={"Service Information Review"}
                                className={"btn btn-success verlag-bold"}
                                style={{ float: "right" }}
                                onClick={() => {
                                  this.setState(
                                    {
                                      selectedServiceID: ss.ID,
                                      serviceSpecificCustomQuestions:
                                        this.state.customQuestions.filter(
                                          (f) =>
                                            f.ServiceID === ss.ID &&
                                            f.Options.isActive
                                        ),
                                    },
                                    () =>
                                      showModalNoOutsideClick(
                                        ServiceFormModal.ID
                                      )
                                  );
                                }}
                              >
                                Review
                              </button>
                            </>
                          ) : (
                            <button
                              aria-label={"Service Information Start"}
                              className={"btn btn-primary verlag-bold"}
                              style={{ float: "right" }}
                              onClick={() => {
                                this.setState(
                                  {
                                    selectedServiceID: ss.ID,
                                    serviceSpecificCustomQuestions:
                                      this.state.customQuestions.filter(
                                        (f) =>
                                          f.ServiceID === ss.ID &&
                                          f.Options.isActive
                                      ),
                                  },
                                  () =>
                                    showModalNoOutsideClick(ServiceFormModal.ID)
                                );
                              }}
                            >
                              Start
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

              {/* ROI section */}
              {/* {this.state.selectedFacilityIDLabel && this.state.selectedServices?.length > 0 && (
                            <div className="mb-2">
                                <div className="card mt-2">
                                <div className="card-header verlag-bold">
                                <div className="row">
                                            <div className="col-10">
                                                <h4>
                                                    Release of Information
                                                </h4>
                                            </div>
                                            <div className="col-2">
                                                {this.state.savedROIServices.length > 0 || this.state.declineConsent ? (
                                                <>
                                                    <button
                                                        className={"btn btn-success verlag-bold"}
                                                        style={{ float: "right" }}
                                                        onClick={() =>
                                                        showModalNoOutsideClick(
                                                            ROIModal.ID
                                                        )
                                                        }
                                                    >
                                                        Review
                                                    </button>
                                                    </>
                                                ) : (
                                                    <button
                                                    className={"btn btn-primary verlag-bold"}
                                                    style={{ float: "right" }}
                                                    onClick={() =>
                                                        showModalNoOutsideClick(
                                                        ROIModal.ID
                                                        )
                                                    }
                                                    >
                                                    Start
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )} */}

              {/* file upload  */}
              {/* {this.state.selectedFacilityIDLabel &&
                this.state.showFileUpload && (
                  <div className="card mb-2  mt-2">
                    <div className="card-header verlag-bold">
                      <div className="row">
                        <div className="col-10">
                          <h4>File Upload</h4>
                        </div>
                        <div className="col-2">
                          {this.state.savedFileUpload ? (
                            <>
                              <button
                                aria-label={"Submission Files Review"}
                                className={"btn btn-success verlag-bold"}
                                style={{ float: "right" }}
                                onClick={() =>
                                  showModalNoOutsideClick(
                                    "submissionfileupload"
                                  )
                                }
                              >
                                Review
                              </button>
                            </>
                          ) : (
                            <button
                              aria-label={"Service Information Start"}
                              className={"btn btn-primary verlag-bold"}
                              style={{ float: "right" }}
                              onClick={() =>
                                showModalNoOutsideClick("submissionfileupload")
                              }
                            >
                              Start
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )} */}

              {this.state.selectedFacilityIDLabel &&
                this.state.selectedServices.length > 0 && (
                  <div className="card mb-2  mt-2">
                    <div className="card-header verlag-bold">
                      <h4>Summary</h4>
                    </div>
                    <div className="card-body">
                      <div className="row mt-3" id={"infcon"}>
                        <h4 className="col-12">
                          <a
                            href="#infcon"
                            className="text-primary"
                            onClick={() =>
                              showModalNoOutsideClick(InformedConsentModal.ID)
                            }
                          >
                            Informed Consent
                          </a>
                        </h4>
                      </div>
                      <br />
                      {this.state.selectedServices.map((ss) => (
                        <div
                          className={"p-2 mb-2"}
                          style={{
                            borderWidth: "1px",
                            borderColor: "lightgray",
                            borderStyle: "solid",
                            borderRadius: 15,
                          }}
                        >
                          <h5 className="text-center pb-2">
                            <span>{ss.Name}</span>
                          </h5>
                          <div>{Parser(ss.Summary)}</div>
                        </div>
                      ))}
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="acknowledge"
                          value={this.state.acknowledged}
                          onChange={(e) =>
                            this.setState({ acknowledged: e.target.checked })
                          }
                          aria-required={true}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="acknowledge"
                        >
                          I acknowledge and agree that I have read, understand,
                          and agreed to the statements contained within this
                          form.
                        </label>
                      </div>
                    </div>
                  </div>
                )}

              {/* footer  */}
              {this.state.selectedFacilityIDLabel &&
                this.state.selectedServices.length > 0 && (
                  <div
                    className={"pr-3 mt-2 pl-3 "}
                    style={{
                      height: "3.5em",
                      border: "1px solid lightgrey",
                      borderRadius: 5,
                      backgroundColor: "#F7F7F7",
                    }}
                  >
                    <button
                      className={"btn immyClearButtonOutline my-2"}
                      onClick={() => {
                        this.clear();
                      }}
                      disabled={this.state.isSubmitting}
                    >
                      Clear
                    </button>
                    <button
                      className={"btn immySubmitButtonOutline float-right my-2"}
                      onClick={() => {
                        this.submit();
                      }}
                      disabled={this.state.isSubmitting}
                    >
                      Submit
                    </button>
                  </div>
                )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
