import React from "react";
import Overlay from "../Overlay";
import { sweetalert } from "../../App";
import Select from "react-select";
import NetworkUtil from "../../network/NetworkUtil";
import SimpleTable from "../tables/SimpleTable";
import SystemAPI from "../../network/SystemAPI";
import ClaimsAPI from "../../network/ClaimsAPI";
import FilterCard, { FIELD_TYPE, ReactSelect } from "../FilterCard";
import {
    getBoolSelectVal,
    getNumPages,
    getPageOptions,
    hideModal,
    parseDate,
    removeNull,
    showModalNoOutsideClick,
    slicePages,
} from "../../util/FormatUtil";
import { Column } from "../tables/TableBase";
import { Sorter, TableOrder } from "../../sorting/Sorter";
import PaginationTool from "../PaginationTool";
import { ClaimsModal } from "../modals/ClaimsModal";
import moment from "moment";
import DatePicker from 'react-datepicker'
import Claim, { FakeClaims, FakeOSHARecord } from "../../types/Claim";

import OSHAAPI from "../../network/OSHAAPI";
import  OSHAFormModal  from "../modals/OSHAFormModal";
import { fakeEmployees } from "../../types/FakeEmployees";

const ITEMS_PER_PAGE = 25;

interface ClaimsState {
    showLoading: boolean;
    claims?: any;
    selectedClaim;
    claimQueryResults;
    tableData: {}[];
    filter?: {
        EmployeeID
        AccidentDate?
    };
    direction?: TableOrder;
    page_options: ReactSelect[];
    selected_page?: { label; value };
    selectedRecord
    states
    gender
    employees
    selectedEmployee
    showModalLoading
}

export default class Claims extends React.Component<
    any,
    ClaimsState
> {

    constructor(props) {
        super(props);

        this.state = {
            showLoading: false,
            selectedClaim: {},
            tableData: [] as any,
            claimQueryResults: [] as any,
            filter: { EmployeeID: null, AccidentDate: null},
            direction: "asc",
            page_options: [{ label: "1", value: "1" }],
            selected_page: { label: "1", value: 1 },
            selectedRecord: null,
            states: [],
            gender: [],
            employees: [],
            selectedEmployee: null,
            showModalLoading:false
        };

        this.queryClaimsData = this.queryClaimsData.bind(this);
    }

    componentDidMount() {
        document.title = 'Claims Management';
        let currentURL = window.location.href;
        this.setState({ showLoading: true }, () => {
            SystemAPI.getProductBrandingFromURL(currentURL).then(x => {
                // used on OSHAFormModal
                SystemAPI.getAllStates().then((data) => {
                    this.setState({ states: data });
                });
                // used on OSHAFormModal
                SystemAPI.getAllGenders().then((data) => {
                    this.setState({ gender: data });
                });


                // Faking data for DEMO
                let fakeClaims:Claim[] = FakeClaims;
                let employees = fakeEmployees;
                this.setState({
                    claims: fakeClaims, 
                    employees: employees, 
                    showLoading: false 
                })
                this.queryClaimsData(1);
                // ClaimsAPI.getAllClaims().then(response=> {
                //     // console.log(" claims response", response)
                //     this.setState({ claims: response.data, showLoading: false })
                // })
            });
        });
    }

    // TODO get filter working with Employee.DisplayName/ID && AccidentDate
    queryClaimsData(page:number) {
        // console.log('queryClaimsData filter?', this.state.filter)
        this.setState({showLoading:true}, async () => {
            try {
                let filter = {
                    EmployeeID: this.state.filter.EmployeeID,
                    AccidentDate: this.state.filter.AccidentDate
                }
                // let response = await == fakeClaim API call
                // let results = await ClaimsAPI.filterClaims({
                //     filter: {
                //         EmployeeName: this.state.filter.EmployeeName
                //     }
                // });
                // DEMO FAKE DATA
                let fakeClaims: Claim[] = FakeClaims
                let filteredClaims;
                if(filter.EmployeeID !== ''){
                    filteredClaims = fakeClaims.filter(fc => {
                        return fc.EmployeeID === filter.EmployeeID
                    });
                }
                if(filter.AccidentDate !== ''){
                    // console.log(' filter.accidentDate', filter.AccidentDate)
                    let filterDate = moment(filter.AccidentDate).format("MM/DD/YYYY")
                    // console.log(" filterDate", filterDate)
                    filteredClaims = fakeClaims.filter(fc =>{
                        let filterClaimDate = moment(new Date(fc.AccidentDate)).format("MM/DD/YYYY")
                        return filterClaimDate === filterDate
                    })
                }
                if(filter.AccidentDate === "" &&
                    filter.EmployeeID  ===""
                ){
                    filteredClaims = fakeClaims;
                }
                let results = {
                    success: true,
                    reason: "",
                    data: filteredClaims 
                }
                if(filteredClaims.length === 0){ sweetalert.fire('No Claims found.')}
                if(!results.success){
                    return sweetalert.fire({title: '', text: 'Unable to filter Claims data at this time', icon: 'error'})
                }

                this.setState({
                    tableData: slicePages(results.data, page, ITEMS_PER_PAGE),
                    claimQueryResults: results.data,
                    page_options: getPageOptions(getNumPages(results.data, ITEMS_PER_PAGE)),
                    showLoading: false,
                });
            } catch (e) {
                console.error(e)
                this.setState({showLoading: false})
            }
        });
    }

    handleExportToCSV() {
        //TODO Need to ensure data is set for export logic
        let fakeClaims: Claim[] = FakeClaims
        let filteredClaims = fakeClaims;

        if(this.state.filter.EmployeeID !== ''){
            filteredClaims = fakeClaims.filter(fc => {
                return fc.EmployeeID === this.state.filter.EmployeeID
            });
        }
        if(this.state.filter.AccidentDate !== ''){
            filteredClaims = fakeClaims.filter(fc =>{
                fc.AccidentDate === this.state.filter.AccidentDate
            })
        }

        this.setState({ showLoading: true }, async () => {
            await NetworkUtil.downloadCSV(
                "/api/admin/claims/csv",
                "ClaimsManagement.xlsx",
                { filter: filteredClaims});
            this.setState({ showLoading: false });
        });
    }

    changePage(page: number) {
        let allClaims = this.state.claimQueryResults;
        let returnData = slicePages(allClaims, page, ITEMS_PER_PAGE);
        this.setState({ tableData: returnData });
    }

    useSorter(col: Column) {
        let sorter = new Sorter<any>();

        this.setState({
            tableData: sorter.sortByKey(
                this.state.tableData,
                col.key as keyof any,
                this.state.direction
            ),
            direction: this.state.direction === "asc" ? "desc" : "asc",
        });
    }
   
    // Commenting out this feature since its not consistent with other mgmt features
    // clearTable() {
    //     this.setState({
    //         tableData: [],
    //         filter: {EmployeeID: "", AccidentDate:""},
    //         selectedEmployee: null
    //         // saveButton: false,
    //         // submitted: false
    //     })
    // }
    handleReportSubmit(report) {
        // console.log('updatedReport', report)
        hideModal('oshaform')
        this.setState({ showModalLoading: true }, async () => {
            try {
                let result = await OSHAAPI.upsertReport(report)
                // console.log('result', result)
                if (result.success) {
                    this.setState({ selectedRecord: null, showLoading: false, showModalLoading: false })
                    sweetalert.fire({ icon: 'success', title: '', text: `Report ${report.ID ? 'updated' : 'created'} successfully.` })
                    // this.submit(1);
                } else {
                    sweetalert.fire({ icon: 'error', title: '', text: `Could not ${report.ID ? 'Update' : 'Create'} report at this time.` });
                    this.setState({ showModalLoading: false })
                }
            }
            catch (e) {
                console.error(e)
                sweetalert.fire({ icon: 'error', title: '', text: `Could not ${report.ID ? 'Update' : 'Create'} report at this time.` });
                this.setState({ showModalLoading: false })
            }
        })
    }


    render() {
        return (
            <React.Fragment>
                <PaginationTool />
                <Overlay show_loading={this.state.showLoading} />
                <OSHAFormModal
                    onSubmit={(report) => {
                        this.handleReportSubmit(report)
                    }}
                    selectedOSHARecord={
                        this.state.selectedRecord ? this.state.selectedRecord : {}
                    }
                    states={removeNull(this.state.states)}
                    genders={removeNull(this.state.gender)}
                    allEmployees={this.state.employees}
                />

                <ClaimsModal
                    claims={this.state.claims}
                    selectedClaim={this.state.selectedClaim ? this.state.selectedClaim : {}}
                    employees={this.state.employees}
                    requery={() => this.queryClaimsData(1)}
                />
                <div className="container-fluid ">
                    <div className={"row"}>
                        <div className="col-12 col-md-12 col-lg-8 col-xl-5 pt-2">
                            <main id="main-content" tabIndex={-1} aria-label="File Management">
                                <div className="card mb-2">
                                    <div className="card-header verlag-bold">
                                        <h3>Claims Management</h3>
                                    </div>
                                    <div className="card-body">
                                        <FilterCard
                                            fields={[
                                                {
                                                    label: "Employee",
                                                    key: "EmployeeID",
                                                    type: FIELD_TYPE.SELECT,
                                                    options: this.state.employees.map(u => { return { label: u.DisplayName, value: u.ID } }),
                                                    isMapped: true,
                                                    isClearable: true,
                                                    textType: "text",
                                                    isFilter: true
                                                },
                                                {
                                                    label: "Accident Date",
                                                    key: "AccidentDate",
                                                    type: FIELD_TYPE.DATE,
                                                }
                                            ]}
                                            filterChanged={(e) => this.setState({ filter: e, selectedEmployee: this.state.employees.filter(emp => emp.ID === e.value) })}
                                        />
                                    </div>
                                    <div className="card-footer">
                                        <button
                                            className={"btn immySubmitButtonOutline"}
                                            onClick={() => {
                                                this.setState(
                                                    {
                                                        selected_page: { label: 1, value: 1 },
                                                    },
                                                    () => {
                                                        this.queryClaimsData(
                                                            this.state.selected_page.value
                                                        );
                                                    }
                                                );
                                            }}
                                        >
                                            Search
                                        </button>

                                        {/* { this.state.tableData?.length > 0 && 
                                            <>&nbsp;<button
                                                className={"btn immyClearButtonOutline"}
                                                onClick={() => {
                                                    this.clearTable()
                                                }}
                                            >
                                                Clear Table
                                            </button></>
                                        } */}
                                        
                                        <button
                                            className="btn btn-outline-success float-right"
                                            onClick={() =>
                                                this.setState(
                                                    {
                                                        selectedClaim: {
                                                            ID: null,
                                                            EmployeeName: "",
                                                        },
                                                    },
                                                    () => {
                                                        showModalNoOutsideClick("claimsmodal")
                                                    }
                                                )
                                            }
                                        >
                                            Create New
                                        </button>
                                    </div>
                                </div>
                            </main>
                        </div>
                        {this.state.tableData && this.state.tableData.length > 0 && (
                            <div className="col-12 col-md-12 col-lg-12 pt-2">
                                <div className="card mt-2" style={{ marginBottom: '4rem' }}>
                                    <div className="card-header verlag-bold stickToTop">
                                        <h4 className="text-center text-md-left">
                                            Claims
                                            <section className="tableHeaderSection float-md-right d-flex justify-content-around">
                                                <h4 className={"float-md-right"} aria-label="Total Records" role="alert">
                                                    Total: {this.state.claimQueryResults.length}
                                                </h4>
                                                <h4 className="d-inline-block float-right align-middle pr-2 ml-5">
                                                    Page{" "}
                                                </h4>
                                                <div className=" align-middle float-right pages ">
                                                    <Select
                                                        isSearchable={true}
                                                        placeholder={"1"}
                                                        noOptionsMessage={() => "No option"}
                                                        value={this.state.selected_page}
                                                        aria-label="Table Page Number"
                                                        onChange={(e: ReactSelect) =>
                                                            this.setState({ selected_page: e }, () =>
                                                                this.changePage(e.value)
                                                            )
                                                        }
                                                        className={"state_select"}
                                                        options={this.state.page_options}
                                                    />
                                                </div>
                                            </section>
                                            <button
                                                className={"d-none d-md-inline btn btn-outline-primary ml-3"}
                                                onClick={() => this.handleExportToCSV()}
                                            >
                                                Export to CSV
                                            </button>
                                        </h4>
                                    </div>
                                    <div className="card-body p-0 m-0 table-responsive">
                                        <SimpleTable
                                            columns={[
                                                {
                                                    label: "OSHA Case #",
                                                    key: "OSHACaseNum",
                                                    popoverText: "Click to Sort By OSHA Case #",
                                                    rawFormat: (val) => {
                                                        return (
                                                            <a
                                                                href={"#"}
                                                                className={'tableNameLinkColor'}
                                                                onClick={() => {
                                                                    // get record via OSHACaseNum
                                                                    // then open OSHAFormModal
                                                                    let fakeOSHARecord = FakeOSHARecord;
                                                                    this.setState({selectedRecord: fakeOSHARecord},()=>{
                                                                        showModalNoOutsideClick('oshaform');
                                                                    })
                                                                    // OSHAAPI.getFilteredRecords(1,{
                                                                    //     filter:{
                                                                    //         caseNumber: val.OSHACaseNum
                                                                    //     }
                                                                    // })
                                                                    // .then((results) => {
                                                                    //     console.log(' results?? ', results)
                                                                    //     let selected = results.table_data?.filter(r => r.CaseNumber === val.OSHACaseNum)
                                                                    //     // take first result and display for demo 
                                                                    //     this.setState({ selectedRecord: selected[0] }, () => {
                                                                    //         showModalNoOutsideClick('oshaform')
                                                                    //     })
                                                                    // })
                                                                }}
                                                            >
                                                                {val.OSHACaseNum}
                                                            </a>
                                                        );
                                                    },
                                                },
                                                {
                                                    label: "Status",
                                                    key: "Status",
                                                    popoverText: "Click to Sort By Status",
                                                },
                                                {
                                                    label: "Employee Name",
                                                    key: "EmployeeName",
                                                    popoverText: "Click to Sort By Employee Name",
                                                    rawFormat: (val) => {
                                                        return (
                                                            <a
                                                                href={"#"}
                                                                className={'tableNameLinkColor'}
                                                                onClick={() => {
                                                                    this.setState({ selectedClaim: val}, () => {
                                                                        showModalNoOutsideClick("claimsmodal")
                                                                    });
                                                                }}
                                                            >
                                                                {val.EmployeeName}
                                                            </a>
                                                        );
                                                    },
                                                },
                                                {
                                                    label: "Accident Date",
                                                    key: "AccidentDate",
                                                    popoverText: "Click to Sort By Date",
                                                    // rawFormat: (val) => {
                                                    //     return parseDate(val.AccidentDate);
                                                    // },
                                                },
                                                {
                                                    label: "Accident Description",
                                                    key: "AccidentDescription",
                                                    popoverText: "Click to Sort By Accident Descripton",
                                                },
                                                {
                                                    label: "Nature of Injury",
                                                    key: "NatureOfInjury",
                                                    popoverText: "Click to Sort By Nature of Injury",
                                                },
                                                {
                                                    label: "Accident Caused Death?",
                                                    key: "AccidentCausedDeath",
                                                    popoverText: "Click to Sort By Accident Caused Death",
                                                    rawFormat: (val) => {
                                                        let string = getBoolSelectVal(val.AccidentCausedDeath)
                                                        return <p>{string.label}</p>
                                                    }    },
                                                {
                                                    label: "Date of Death",
                                                    key: "EmployeeDeathDate",
                                                    popoverText: "Click to Sort By Date of Death",
                                                    // rawFormat: (val) => {
                                                    //     return parseDate(val.EmployeeDeathDate);
                                                    // },
                                                },
                                                {
                                                    label: "Medical Attn Received?",
                                                    key: "MedAttnReceived",
                                                    popoverText: "Click to Sort By Medical Attn Received",
                                                    rawFormat: (val) => {
                                                        let string = getBoolSelectVal(val.MedAttnReceived)
                                                        return <p>{string.label}</p>
                                                    }
                                                },
                                                {
                                                    label: "Medical Attn Description",
                                                    key: "MedAttnDescription",
                                                    popoverText: "Click to Sort By Medical Attn Description",
                                                },
                                                {
                                                    label: "Choice Physician?",
                                                    key: "ChoicePhysician",
                                                    popoverText: "Click to Sort By Choice Physician",
                                                    rawFormat: (val) => {
                                                        let string = getBoolSelectVal(val.ChoicePhysician)
                                                        return <p>{string.label}</p>
                                                    }
                                                },
                                                {
                                                    label: "Treating Physician Name",
                                                    key: "TreatingPhysicianName",
                                                    popoverText: "Click to Sort By Treating Physician Name",
                                                },
                                                {
                                                    label: "Return To Work Date",
                                                    key: "ReturnToWorkDate",
                                                    popoverText: "Click to Sort By Return To Work Date",
                                                    // rawFormat: (val) => {
                                                    //     return parseDate(val.ReturnToWorkDate)
                                                    // }
                                                },
                                            ]}
                                            table_data={this.state.tableData}
                                            columnClickedCallback={(col) => {
                                                this.useSorter(col);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}