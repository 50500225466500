import React from "react";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import { hideModal } from "../../util/FormatUtil";
import Parser from "html-react-parser";

interface InformedConsentProps {
  legalName: string;
  selectedServices?;
}

interface InformedConsentState {
  arrowClicked?;
}

class InformedConsentModal extends React.Component<
  InformedConsentProps,
  InformedConsentState
> {
  public static readonly ID = "informed_consent";
  constructor(props) {
    super(props);
    this.state = {
      arrowClicked: -1,
    };
  }

  render():
    | React.ReactElement
    | string
    | number
    | {}
    | React.ReactNodeArray
    | React.ReactPortal
    | boolean
    | null
    | undefined {
    return (
      <React.Fragment>
        <div
          className="modal fade form_modal"
          id={InformedConsentModal.ID}
          tabIndex={-1}
          role="dialog"
          aria-hidden="true"
          aria-label="Informed Consent"
        >
          <div className="modal-dialog modal-lg modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-body p-0 m-0">
                <div className="container-fluid">
                  <div className={"row"}>
                    <div className="col-12 pt-2">
                      <div className="card mb-2">
                        <div className="card-header verlag-bold">
                          <div className="row">
                            <div className="col-10">
                              <h4>Informed Consent</h4>
                            </div>
                            <div className="col-2">
                              <button
                                style={{ outline: "none" }}
                                type="button"
                                className="close pr-4"
                                aria-label="Close"
                                onClick={() =>
                                  hideModal(InformedConsentModal.ID)
                                }
                              >
                                <span
                                  aria-hidden="true"
                                  style={{
                                    fontSize: "1.5em",
                                    fontWeight: "bold",
                                  }}
                                >
                                  &times;
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="card-body">
                          <div className="accordion" id="informedConsent">
                            {this.props.selectedServices &&
                              this.props.selectedServices.map((ss, i) => (
                                <div className="card mt-2">
                                  <div
                                    className="card-header"
                                    id={"heading" + i}
                                  >
                                    <button
                                      className={"collapsed verlag-bold"}
                                      data-toggle="collapse"
                                      data-target={"#collapse" + i}
                                      onClick={() => {
                                        if (this.state.arrowClicked === i) {
                                          this.setState({
                                            arrowClicked: -1,
                                          });
                                        } else {
                                          this.setState({
                                            arrowClicked: i,
                                          });
                                        }
                                      }}
                                      aria-expanded={"false"}
                                      aria-controls={"collapse" + i}
                                    >
                                      <div className="row">
                                        <div className="col-10">
                                          <h4>{ss.Name}</h4>
                                        </div>
                                        <div className="col-2">
                                          {this.state.arrowClicked == i ? (
                                            <AiOutlineUp
                                              className={"float-right"}
                                              size={30}
                                              data-toggle="collapse"
                                              data-target={"#collapse" + i}
                                              role="button"
                                              aria-expanded={
                                                i == 0 ? "true" : "false"
                                              }
                                              aria-controls={"collapse" + i}
                                            />
                                          ) : (
                                            <AiOutlineDown
                                              className={"float-right"}
                                              size={30}
                                              data-toggle="collapse"
                                              data-target={"#collapse" + i}
                                              role="button"
                                              aria-expanded={
                                                i == 0 ? "true" : "false"
                                              }
                                              aria-controls={"collapse" + i}
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </button>
                                  </div>

                                  <div
                                    className={
                                      "collapse show verlag-light responsive-p"
                                    }
                                    style={{
                                      borderBottom: "1px solid lightgrey",
                                    }}
                                    id={"collapse" + i}
                                    aria-labelledby={"heading" + i}
                                    data-parent="#informedConsent"
                                  >
                                    <div className="card-body">
                                      {ss.InformedConsent &&
                                        Parser(ss.InformedConsent)}
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="card-footer"
                  style={{
                    backgroundColor: "transparent",
                    borderTop: "none",
                  }}
                >
                  <button
                    className={"btn btn-primary float-right mb-3"}
                    onClick={() => hideModal(InformedConsentModal.ID)}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default InformedConsentModal;
