import React from "react";
import FilterCard, { FIELD_TYPE } from "../FilterCard";
import Overlay from "../Overlay";
import ServicesAPI from "../../network/ServicesAPI";
import ResultsAPI from "../../network/ResultsAPI";
import CommTemplatesAPI from "../../network/CommTemplatesAPI";
import TestsAPI from "../../network/TestsAPI";
import AdminAPI from "../../network/AdminAPI";
import CustomFieldsAPI from "../../network/CustomFieldsAPI";
import { isEmptyObject } from "jquery";
import { AuditLog } from "./AuditLog";
import EmployeeAPI from "../../network/EmployeeAPI";
import SystemAPI from "../../network/SystemAPI";
import { OSHAReportWithEmployeeData } from "../../types/OSHAReport";
import OSHAAPI from "../../network/OSHAAPI";
import { FacilityAPI } from "../../network/FacilityAPI";

interface AuditLogsState {
  show_loading: boolean;
  filter: object;
  AuditTypeList: any;
  facilities: any;
  testTypes: any;
  services: any;
  templates: any;
  customFields: any;
  customQuestions: any;
  customEmployeeFields: any;
  insurance: any;
  tests: any;
  results: any;
  employees: any;
  races: any;
  genders: any;
  ethnicities: any;
  pswdConfigs: any;
  files: any;
  banners: any;
  branding: any;
  vaccines: any;
  evaluations: any;
  oshaReports: any;
  caseClassificationTypes: any;
  injuryTypes: any;
}

export default class AuditLogs extends React.Component<any, AuditLogsState> {
  constructor(props) {
    super(props);
    this.state = {
      show_loading: false,
      filter: {},
      AuditTypeList: [
        // { label: "Facility", value: "facility" },
        // // { label: "Event", value: "event" },
        // // { label: "Alert", value: "alert" },
        // { label: "Service", value: "service"},
        // { label: "Result", value: "result"},
        // { label: "Communication", value: "commTemplate"},
        // { label: "Insurance", value: "insurance" },
        // { label: "Test", value: "test" },
        // { label: "Custom Field", value: "customField" },
        // { label: "Custom Question", value: "customQuestion" },
        // { label: "Employee Field", value: "customEmployeeField" },
        // { label: "Employee", value: "employee" },
        // { label: "Password Requirements", value: "pswdReq" },
        // { label: "Banner", value: "banner" },
        // { label: "File", value: "file" },
        // { label: "Vaccines", value:"vaccines"},
        // { label: "Evaluations", value:"evaluations"},
        { label: "OSHA Report", value: "oshaReport" },
      ],
      facilities: [],
      testTypes: [],
      services: [],
      results: [],
      templates: [],
      customFields: [],
      customQuestions: [],
      customEmployeeFields: [],
      insurance: [],
      tests: [],
      evaluations: [],
      vaccines: [],
      races: [],
      genders: [],
      ethnicities: [],
      pswdConfigs: [],
      files: [],
      banners: [],
      branding: [],
      employees: [],
      oshaReports: [],
      caseClassificationTypes: [],
      injuryTypes: [],
    };
  }

  // componentDidMount() {
  //   document.title = 'Audit Logs Page';
  //   this.setState({ show_loading: true }, () => {
  //     TestsAPI.getAllTestDetails().then((data) => {
  //       let mappedTests = data.tests.map(t => {
  //         return {
  //           label: t.Name,
  //           value: t.ID
  //         }
  //       })
  //       this.setState({ tests: mappedTests, show_loading: false });
  //     });
  //     FacilityAPI.getFacilitiesForSubmissionForm().then((data) => {
  //       let facilityNamesMapped = data.authorizedFacilities.map((fac) => {
  //         return {
  //           label: fac.FacilityName,
  //           value: fac.ID,
  //         };
  //       });
  //       this.setState({ facilities: facilityNamesMapped });
  //     });
  //     ServicesAPI.getAllServices().then((data) => {
  //       this.setState({
  //         services: data.data.map((obj) => {
  //           return obj.Name;
  //         }),
  //       });
  //     });
  //     CommTemplatesAPI.getEditableTemplates().then((data) => {
  //       this.setState({
  //         templates: data.data.map((obj) => {
  //           return obj.Name;
  //         }),
  //       });
  //     });
  //     CustomFieldsAPI.getAllCustomFields().then((data) => {
  //       let customFields = data.data.customFields.map((obj) => {
  //         return obj.DisplayName;
  //       })
  //       let customQuestions = data.data.customQuestions.map((obj) => {
  //         return obj.DisplayName;
  //       })
  //       let customEmployeeFields = data.data.customEmployeeFields.map((obj) => {
  //         return obj.DisplayName;
  //       })
  //       this.setState({
  //         customFields: customFields,
  //         customQuestions: customQuestions,
  //         customEmployeeFields: customEmployeeFields
  //       });
  //     });
  //     ResultsAPI.getAllResultsData().then(data => {
  //       let resultsList = data.data.results.map(result => ({label: result.Name, value: result.Name}))
  //       this.setState({results: resultsList})
  //       })
  //     });
  //     EmployeeAPI.getEmployeesFromPortalDB().then(async data => {
  //       this.setState({emloyees: data.employees})
  //     })
  //     SystemAPI.getAllRaces().then((data) => {
  //       this.setState({ races: data });
  //     });
  //     SystemAPI.getAllGenders().then((data) => {
  //       this.setState({ genders: data });
  //     });
  //     SystemAPI.getAllEvaluations().then((data) => {
  //       this.setState({ evaluations: data });
  //     });
  //     SystemAPI.getAllVaccines().then((data) => {
  //       this.setState({ vaccines: data });
  //     });
  //     SystemAPI.getAllEthnicities().then((data) => {
  //       this.setState({ ethnicities: data });
  //     });
  //     let productID;
  //     let currentURL = window.location.href;
  //     SystemAPI.getProductBrandingFromURL(currentURL).then(dataFromProduct => {
  //         productID = dataFromProduct.ProductID;
  //         DocumentUploadAPI.getDocuments().then(data => {
  //           let documentList = data.documents.map(document => ({label: document.DocumentName, value: document.ID}))
  //             this.setState({files: documentList})
  //         })
  //         BannerAPI.getBanners(productID).then(data => {
  //           let mappedBanners = data.banners.map(m => {return {label: m.ProductName, value: m.BannerID}})
  //           this.setState({banners: mappedBanners})
  //         })
  //     })
  //     SystemAPI.getBranding().then(data => {
  //       this.setState({branding: data.branding, show_loading: false})
  //     })

  //     OSHAAPI.getAllOSHARecords().then((response)=>{
  //       console.log('response in getAllOSHARecords', response)
  //       this.setState({oshaReports:response.data})
  //     })
  // }

  componentDidMount() {
    document.title = "Audit Logs Page";
    this.setState({ show_loading: true }, () => {
      OSHAAPI.getAllOSHAReports().then((response) => {
        this.setState({
          oshaReports: response.oshaReports,
          show_loading: false,
        });
      });
      SystemAPI.getAllEmployeesForOSHA().then((data) => {
        this.setState({ employees: data, show_loading: false });
      });
      SystemAPI.getCaseClassificationTypes().then((data) => {
        this.setState({ caseClassificationTypes: data });
      });
      SystemAPI.getInjuryTypes().then((data) => {
        this.setState({ injuryTypes: data });
      });
      FacilityAPI.getFacilitiesForSubmissionForm().then((data) => {
        let facilityNamesMapped = data.authorizedFacilities.map((fac) => {
          return {
            label: fac.FacilityName,
            value: fac.ID,
          };
        });
        this.setState({ facilities: facilityNamesMapped });
      });
    });
  }

  render() {
    // console.log('AuditLogs state', this.state)
    return (
      <React.Fragment>
        <Overlay show_loading={this.state.show_loading} />
        <div className="container-fluid  min-vh-100 ">
          <div className={"row"}>
            <div className="col-12 col-lg-6 pt-2">
              <main id="main-content" tabIndex={-1} aria-label="Audit Logs">
                <div
                  className={
                    this.state.filter["AuditType"]
                      ? "card mt-2 border-bottom-0"
                      : "card mt-2"
                  }
                >
                  <div className="card-header verlag-bold">
                    <h4>Audit Logs</h4>
                  </div>
                  <div className="card-body">
                    <FilterCard
                      fields={[
                        {
                          label: "Audit Type",
                          key: "AuditType",
                          type: FIELD_TYPE.SELECT,
                          options: this.state.AuditTypeList,
                          isMapped: true,
                          isMulti: false,
                          textType: "text",
                        },
                      ]}
                      filterChanged={(filter) =>
                        this.setState({ filter: filter })
                      }
                    />
                  </div>
                </div>
              </main>
            </div>
          </div>

          {!isEmptyObject(this.state.filter) && (
            <AuditLog
              key={this.state.filter["AuditType"]}
              auditLogType={this.state.filter["AuditType"]}
              templates={this.state.templates}
              facilities={this.state.facilities}
              services={this.state.services}
              customFields={this.state.customFields}
              customQuestions={this.state.customQuestions}
              customEmployeeFields={this.state.customEmployeeFields}
              tests={this.state.tests}
              vaccines={this.state.vaccines}
              evaluations={this.state.evaluations}
              results={this.state.results}
              employees={this.state.employees}
              races={this.state.races}
              genders={this.state.genders}
              ethnicities={this.state.ethnicities}
              files={this.state.files}
              banners={this.state.banners}
              branding={this.state.branding}
              oshaReports={this.state.oshaReports}
              caseClassificationTypes={this.state.caseClassificationTypes}
              injuryTypes={this.state.injuryTypes}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}
