import React from "react";
import Overlay from "../Overlay";
import SimpleTable from "../tables/SimpleTable";
import FilterCard, { FIELD_TYPE, ReactSelect } from "../FilterCard";
import {
  getBoolSelectOptions,
  getBoolSelectVal,
  getNumPages,
  getPageOptions,
  hideModal,
  showModalNoOutsideClick,
  slicePages,
} from "../../util/FormatUtil";
import { sweetalert } from "../../App";
import Select from "react-select";
import { Result } from "../../types/Result";
import { ResultsManagementModal } from "../modals/ResultsManagementModal";
import NetworkUtil from "../../network/NetworkUtil";
import ResultsAPI from "../../network/ResultsAPI";
import { Column } from "../tables/TableBase";
import { Sorter, TableOrder } from "../../sorting/Sorter";
import PaginationTool from "../PaginationTool";
import SystemAPI from "../../network/SystemAPI";
import { AdminPages } from "./AdminNav";
import AdminAPI from "../../network/AdminAPI";

const ITEMS_PER_PAGE = 25;

interface ResultsManagementState {
  showLoading: boolean;
  selectedResult: Result;
  resultQueryResults;
  tableData: {}[];
  filter?: {
    ID;
    IsEnabled;
  };
  direction?: TableOrder;
  page_options: ReactSelect[];
  selected_page?: { label; value };
  resultsList: { label; value }[];
  descList: { label; value }[];
  pageCustomQuestions?
  testAndResultsMapped
}

export default class ResultsManagement extends React.Component<
  any,
  ResultsManagementState
> {
  
  constructor(props) {
    super(props);

    this.state = {
      showLoading: false,
      selectedResult: {} as Result,
      tableData: [] as any,
      resultQueryResults: [] as any,
      filter: { ID: [], IsEnabled: [] },
      direction: "asc",
      page_options: [{ label: "1", value: "1" }],
      selected_page: { label: "1", value: 1 },
      resultsList: [],
      descList: [],
      pageCustomQuestions: [],
      testAndResultsMapped: [],
    };

    this.createOrModifyResult = this.createOrModifyResult.bind(this);
  }

  componentDidMount() {
    document.title = 'Results Management Page';
    this.loadResultData();
    this.queryResultsData(1);
    
  }

  loadResultData() {
    this.setState({ showLoading: true }, async () => {
      SystemAPI.getCustomQuestions(AdminPages.Result).then(res =>{
        this.setState({pageCustomQuestions:res.questions})
      })
      ResultsAPI.getTestAndResultsMapped().then(data => {
        this.setState({testAndResultsMapped: data.data})
      })
      AdminAPI.getAllEnabledTests().then(data => {
        this.setState({testLabels: data} as any)
      })
      ResultsAPI.getAllResultsData()
        .then((data) => {
            let resultsList = data.data.map(result => ({label: result.Name, value: result.ID}))
            this.setState({ resultsList: resultsList});
        })
        .catch((e) => {
          return sweetalert.fire({
            icon: "info",
            title: "",
            text: "No Results found.",
          });
        });
    });
  }

  queryResultsData(page: number) {
    this.setState({ showLoading: true }, async () => {
      let results = await ResultsAPI.filterResults({
        filter: {
          ID: this.state.filter.ID,
          IsEnabled: this.state.filter.IsEnabled,
        },
      });
      if(results.data.length < 1){
        this.setState({showLoading: false, tableData: [], resultQueryResults: null})
        return sweetalert.fire({title: '', text: 'No Results returned', icon: 'info'})
      }
      if (!results.success) {
        return sweetalert.fire({
          title: "",
          text: "Unable to filter Results data at this time",
          icon: "error",
        });
      }

      this.setState({
        tableData: slicePages(results.data, page, ITEMS_PER_PAGE),
        resultQueryResults: results.data,
        page_options: getPageOptions(getNumPages(results.data, ITEMS_PER_PAGE)),
        showLoading: false,
      });
    });
  }

  createOrModifyResult(result: Result) {
    this.setState({ showLoading: true }, async () => {
      if (result.ID === null) {
        let response = await ResultsAPI.createResult(result);
        if (!response.success) {
          let msg = "Unable to create Result at this time.";
          if (response.reason) {
            msg = response.reason;
          }
          return sweetalert.fire({
            icon: "error",
            title: "Attention",
            text: msg,
            allowOutsideClick: false
          }).then((response) => {
            if(response.isConfirmed){
              window.location.reload()
            }
          });
        }
      } else {
        let response = await ResultsAPI.editResult(result);
        if (!response.success) {
          let msg = "Unable to edit Result at this time.";
          if (response.reason) {
            msg = response.reason;
          }
          return sweetalert.fire({
            icon: "error",
            title: "Attention",
            text: msg,
            allowOutsideClick: false
          }).then((response) => {
            if(response.isConfirmed){
              window.location.reload()
            }
          });
        }
      }

      sweetalert
        .fire({ icon: "success", title: "", text: "Result saved" })
        .then(() => {
          this.setState(
            {
              selectedResult: {
                ID: null,
                Name: null,
                Description: null,
                CustomFieldData: null,
                CreatedDate: null,
                IsEnabled: null, 
              } as Result,
            },
            () => {
              this.loadResultData();
              this.queryResultsData(this.state.selected_page.value);
            }
          );
        });
    });
  }

  handleExportToCSV() {
    this.setState({ showLoading: true }, async () => {
      await NetworkUtil.downloadCSV(
        "/api/admin/results/csv",
        "Result_Management.xlsx",
        { filter: this.state.filter });
      this.setState({ showLoading: false });
    });
  }

  changePage(page: number) {
    let allServices = this.state.resultQueryResults[0];
    let returnData = slicePages(allServices, page, ITEMS_PER_PAGE);
    this.setState({ tableData: returnData });
  }

  renderResultsFilterFields() {
    return (
      <FilterCard
        fields={[
          {
            label: "Name",
            key: "ID",
            type: FIELD_TYPE.SELECT,
            options:  this.state.resultsList,
            isMapped: true,
            isMulti: true,
            textType: "text",
            isFilter: true
          },
          {
            label: "Is Enabled",
            "key": "IsEnabled",
            type: FIELD_TYPE.SELECT,
            options: getBoolSelectOptions(),
            isMapped: true,
            isMulti: true,
            textType: "text",
            isFilter: true
          },
        ]}
        filterChanged={(e) => this.setState({ filter: e })}
      />
    );
  }

  useSorter(col: Column) {
    let sorter = new Sorter<any>();

    this.setState({
      tableData: sorter.sortByKey(
        this.state.tableData,
        col.key as keyof any,
        this.state.direction
      ),
      direction: this.state.direction === "asc" ? "desc" : "asc",
    });
  }

  render() {
    // console.log('RESULT MGMT STATE', this.state)
    return (
      <React.Fragment>
        <PaginationTool />
        <Overlay show_loading={this.state.showLoading} />
        <ResultsManagementModal
          key={this.state.selectedResult && this.state.selectedResult.ID}
          testAndResultsMapped={this.state.testAndResultsMapped}
          pageCustomQuestions={this.state.pageCustomQuestions}
          selectedResult={this.state.selectedResult}
          onSubmit={(result) => {
            hideModal(ResultsManagementModal.ID);
            this.createOrModifyResult(result);
          }}
        />

        <div className="container-fluid ">
          <div className={"row"}>
            <div className="col-12 col-md-12 col-lg-8 col-xl-5 pt-2">
              <main id="main-content" tabIndex={-1} aria-label="Result Management">
                <div className="card mb-2">
                  <div className="card-header verlag-bold">
                    <h3>Result Management</h3>
                  </div>
                  <div className="card-body">
                    {this.renderResultsFilterFields()}
                  </div>
                  <div className="card-footer">
                    <button
                      className={"btn immySubmitButtonOutline"}
                      onClick={() => {
                        this.setState(
                          {
                            selected_page: { label: 1, value: 1 },
                          },
                          () => {
                            this.queryResultsData(
                              this.state.selected_page.value
                            );
                          }
                        );
                      }}
                    >
                      Search
                    </button>
                    <button
                      className="btn btn-outline-success float-right"
                      onClick={() =>
                        this.setState(
                          {
                            selectedResult: {
                              ID: null,
                              Name: "",
                              Description: "",
                              CustomFieldData: null,
                              CreatedDate: null,
                              IsEnabled: null,
                            } as Result,
                          },
                          () => {
                            showModalNoOutsideClick(ResultsManagementModal.ID);
                          }
                        )
                      }
                    >
                      Create New
                    </button>
                  </div>
                </div>
              </main>
            </div>
            {this.state.tableData && this.state.tableData.length > 0 && (
              <div className="col-12 col-md-12 col-lg-12 pt-2">
                <div className="card mt-2" style={{marginBottom: '4rem'}}>
                  <div className="card-header verlag-bold stickToTop" style={{zIndex: 0}}>
                    <h4 className="text-center text-md-left">
                      Results
                      <section className="tableHeaderSection float-md-right d-flex justify-content-around">
                        <h4 className={"float-md-right"} aria-label="Total Records" role="alert">
                          Total: {this.state.resultQueryResults.length}
                        </h4>
                        <h4 className="d-inline-block float-right align-middle pr-2 ml-5">
                          Page{" "}
                        </h4>
                        <div className=" align-middle float-right pages ">
                          <Select
                            isSearchable={true}
                            placeholder={"1"}
                            noOptionsMessage={() => "No option"}
                            value={this.state.selected_page}
                            aria-label="Table Page Number"
                            onChange={(e: ReactSelect) =>
                              this.setState({ selected_page: e }, () =>
                                this.changePage(e.value)
                              )
                            }
                            className={"state_select"}
                            options={this.state.page_options}
                          />
                        </div>
                      </section>
                      <button
                        className={"d-none d-md-inline btn btn-outline-primary ml-3"}
                        onClick={() => this.handleExportToCSV()}
                      >
                        Export to CSV
                      </button>
                    </h4>
                  </div>
                  <div className="card-body p-0 m-0 table-responsive">
                    <SimpleTable
                      columns={[
                        {
                          label: "Name",
                          key: "Name",
                          popoverText: "Click to Sort By Name",
                          rawFormat: (val) => {
                            return (
                              <a
                                href={"#"}
                                className={'tableNameLinkColor'}
                                onClick={() => {
                                  this.setState({ selectedResult: val }, () =>
                                    showModalNoOutsideClick(
                                      ResultsManagementModal.ID
                                    )
                                  );
                                }}
                              >
                                {val.Name}
                              </a>
                            );
                          },
                        },
                        {
                          label: "Is Enabled?",
                          key: "IsEnabled",
                          popoverText: "Click to Sort By Is Enabled",
                          rawFormat: (val) => {
                            let string = getBoolSelectVal(val.IsEnabled);
                            return <p>{string.label}</p>;
                          },
                        },
                        {
                          label: "Assigned Tests", key: "",
                          rawFormat: (val) => {
                              let testsLabels = [];
                              if(this.state.testAndResultsMapped && this.state.testAndResultsMapped.length > 0){
                                  for(let i = 0; i < this.state.testAndResultsMapped.length; i++){
                                      if(this.state.testAndResultsMapped[i].ResultID === val.ID){
                                          testsLabels.push(this.state.testAndResultsMapped[i].TestName)
                                      }
                                  }
                              }
                              let nonDuplicateTests = Array.from(new Set(testsLabels));
                              return nonDuplicateTests && nonDuplicateTests.map(m => (
                                  <p className="mb-0">{m}</p>
                              ))
                          }
                      },
                      ]}
                      table_data={this.state.tableData}
                      columnClickedCallback={(col) => {
                        this.useSorter(col);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}