import React from "react";
import Overlay from "../Overlay";
import { sweetalert } from "../../App";
import Select from "react-select";
import NetworkUtil from "../../network/NetworkUtil";
import SimpleTable from "../tables/SimpleTable";
import SystemAPI from "../../network/SystemAPI";
import { ReactSelect } from "../FilterCard";
import {
  getNumPages,
  getPageOptions,
  showModalNoOutsideClick,
  slicePages,
} from "../../util/FormatUtil";

import { Column } from "../tables/TableBase";
import { Sorter, TableOrder } from "../../sorting/Sorter";
import PaginationTool from "../PaginationTool";
import { AdminPages } from "./AdminNav";
import { DocumentUploadModal } from "../modals/DocumentUploadModal";
import { AiOutlineClose } from "react-icons/ai";
import DocumentUploadAPI from "../../network/DocumentUploadAPI";

const ITEMS_PER_PAGE = 25;

interface DocumentUploadState {
  showLoading: boolean;
  documents?: any;
  selectedDocument;
  documentQueryResults;
  tableData: {}[];
  filter?: {
    ID;
  };
  direction?: TableOrder;
  page_options: ReactSelect[];
  selected_page?: { label; value };
}

export default class DocumentUpload extends React.Component<
  any,
  DocumentUploadState
> {
  constructor(props) {
    super(props);

    this.state = {
      showLoading: false,
      selectedDocument: {},
      tableData: [] as any,
      documentQueryResults: [] as any,
      filter: { ID: null },
      direction: "asc",
      page_options: [{ label: "1", value: "1" }],
      selected_page: { label: "1", value: 1 },
    };
  }

  componentDidMount() {
    document.title = "File Management Page";
    let currentURL = window.location.href;
    this.setState({ showLoading: true }, () => {
      SystemAPI.getProductBrandingFromURL(currentURL).then((x) => {
        DocumentUploadAPI.getDocuments().then((data) => {
          this.setState({ documents: data.documents, showLoading: false });
        });
      });
    });
    this.queryDocumentUploadData(1)
  }

  queryDocumentUploadData(page: number) {
    this.setState({ showLoading: true }, async () => {
      let ids =
        this.state.filter &&
        this.state.filter.ID &&
        this.state.filter.ID.length > 0
          ? this.state.filter.ID.map((m) => {
              return m.value;
            })
          : null;
      let results = await DocumentUploadAPI.filterDocuments({
        filter: {
          ID: ids,
        },
      });
      if (!results.success) {
        return sweetalert.fire({
          title: "",
          text: "Unable to filter File data at this time",
          icon: "error",
        });
      }
      if (results.data.length < 1) {
        this.setState({
          showLoading: false,
          tableData: [],
          documentQueryResults: null,
        });
        return sweetalert.fire({
          title: "",
          text: "No Files returned",
          icon: "info",
        });
      }

      this.setState({
        tableData: slicePages(results.data, page, ITEMS_PER_PAGE),
        documentQueryResults: results.data,
        page_options: getPageOptions(getNumPages(results.data, ITEMS_PER_PAGE)),
      });

      DocumentUploadAPI.getDocuments().then((data) => {
        this.setState({ documents: data.documents, showLoading: false });
      });
    });
  }

  handleExportToCSV() {
    this.setState({ showLoading: true }, async () => {
      await NetworkUtil.downloadCSV(
        "/api/admin/documentupload/csv",
        "File_Upload_Management.xlsx",
        { filter: this.state.filter }
      );
      this.setState({ showLoading: false });
    });
  }

  changePage(page: number) {
    let allServices = this.state.documentQueryResults;
    let returnData = slicePages(allServices, page, ITEMS_PER_PAGE);
    this.setState({ tableData: returnData });
  }

  useSorter(col: Column) {
    let sorter = new Sorter<any>();

    this.setState({
      tableData: sorter.sortByKey(
        this.state.tableData,
        col.key as keyof any,
        this.state.direction
      ),
      direction: this.state.direction === "asc" ? "desc" : "asc",
    });
  }

  deleteDocument(obj) {
    sweetalert.fire({
      icon: "warning",
      title: "",
      html: `<p>Are you sure you want to delete <span style="font-weight: bold;">${obj.DocumentName}</span>?</p>`,
      allowOutsideClick: false,
      confirmButtonText: "Yes, delete",
      confirmButtonColor: "#C31230",
      showDenyButton: true,
      denyButtonColor: "green",
      denyButtonText: "Go back",
    }).then((response) => {
      if(response.isDenied){
        return
      }
      if(response.isConfirmed){
        this.setState({ showLoading: true }, async () => {
          let response = await DocumentUploadAPI.deleteDocument(obj.ID, obj.URL);
          if (!response.success) {
            this.setState({ showLoading: false });
            return sweetalert.fire({
              icon: "error",
              title: "",
              text: response.reason,
            });
          }
          sweetalert
          .fire({
            icon: "success",
            title: "",
            text: `${obj.DocumentName} deleted successfully`,
          })
        .then((res) => {
            this.setState((prevState) => ({
              documents: this.state.documents.filter((d) => d.ID != obj.ID),
              filter: {
                ...prevState.filter,
                ID: null,
              },
            }));
            this.queryDocumentUploadData(1);
          })
        })
      }
    });
  }

  render() {
    return (
      <React.Fragment>
        <PaginationTool />
        <Overlay show_loading={this.state.showLoading} />
        <DocumentUploadModal
          documents={this.state.documents}
          selectedDocument={this.state.selectedDocument}
          requery={() => this.queryDocumentUploadData(1)}
        />
        <div className="container-fluid ">
          <div className={"row"}>
            <div className="col-12 col-md-12 col-lg-8 col-xl-5 pt-2">
              <main
                id="main-content"
                tabIndex={-1}
                aria-label="File Management"
              >
                <div className="card mb-2">
                  <div className="card-header verlag-bold">
                    <h3>File Management</h3>
                  </div>
                  <div className="card-body">
                    <div className="form-group row">
                      <label
                        htmlFor={"Facility"}
                        className="col-sm-4 col-form-label"
                        style={{ fontWeight: "bold" }}
                      >
                        File Name
                      </label>
                      <div className="col-sm-8  p-0 m-0" id={"FileName"}>
                        <Select
                          isMulti={true}
                          isSearchable={true}
                          placeholder={
                            <div className="accessibilityText">
                              Please Select...
                            </div>
                          }
                          noOptionsMessage={() => "No option"}
                          className={"state_select"}
                          aria-label="File Name Search"
                          options={
                            this.state.documents &&
                            this.state.documents.length > 0
                              ? this.state.documents.map((m) => {
                                  return { label: m.DocumentName, value: m.ID };
                                })
                              : []
                          }
                          onChange={(e) => {
                            this.setState({
                              filter: {
                                ID: e,
                              },
                            });
                          }}
                          value={
                            this.state.filter && this.state.filter.ID
                              ? this.state.filter.ID
                              : null
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <button
                      className={"btn immySubmitButtonOutline"}
                      onClick={() => {
                        this.setState(
                          {
                            selected_page: { label: 1, value: 1 },
                          },
                          () => {
                            this.queryDocumentUploadData(
                              this.state.selected_page.value
                            );
                          }
                        );
                      }}
                    >
                      Search
                    </button>
                    <button
                      className="btn btn-outline-success float-right"
                      onClick={() =>
                        this.setState(
                          {
                            selectedDocument: {
                              ID: null,
                              DocumentName: "",
                              URL: "",
                            },
                          },
                          () => {
                            showModalNoOutsideClick("documentupload");
                          }
                        )
                      }
                    >
                      Create New
                    </button>
                  </div>
                </div>
              </main>
            </div>
            {this.state.tableData && this.state.tableData.length > 0 && (
              <div className="col-12 col-md-12 col-lg-12 pt-2">
                <div className="card mt-2" style={{ marginBottom: "4rem" }}>
                  <div className="card-header verlag-bold stickToTop" style={{zIndex: 0}}>
                    <h4 className="text-center text-md-left">
                      Files
                      <section className="tableHeaderSection float-md-right d-flex justify-content-around">
                        <h4
                          className={"float-md-right"}
                          aria-label="Total Records"
                          role="alert"
                        >
                          Total: {this.state.documentQueryResults.length}
                        </h4>
                        <h4 className="d-inline-block float-right align-middle pr-2 ml-5">
                          Page{" "}
                        </h4>
                        <div className=" align-middle float-right pages ">
                          <Select
                            isSearchable={true}
                            placeholder={"1"}
                            noOptionsMessage={() => "No option"}
                            value={this.state.selected_page}
                            aria-label="Table Page Number"
                            onChange={(e: ReactSelect) =>
                              this.setState({ selected_page: e }, () =>
                                this.changePage(e.value)
                              )
                            }
                            className={"state_select"}
                            options={this.state.page_options}
                          />
                        </div>
                      </section>
                      <button
                        className={
                          "d-none d-md-inline btn btn-outline-primary ml-3"
                        }
                        onClick={() => this.handleExportToCSV()}
                      >
                        Export to CSV
                      </button>
                    </h4>
                  </div>
                  <div className="card-body p-0 m-0 table-responsive">
                    <SimpleTable
                      columns={[
                        {
                          label: "File Name",
                          key: "DocumentName",
                          popoverText: "Click to Sort By File Name",
                          rawFormat: (val) => {
                            return (
                              <a
                                href={"#"}
                                className={"tableNameLinkColor"}
                                onClick={() => {
                                  this.setState(
                                    { selectedDocument: val },
                                    () => {
                                      showModalNoOutsideClick("documentupload");
                                    }
                                  );
                                }}
                              >
                                {val.DocumentName}
                              </a>
                            );
                          },
                        },
                        {
                          label: "URL",
                          key: "URL",
                          popoverText: "Click to Sort By URL",
                        },
                        {
                          label: "View",
                          key: "",
                          rawFormat: (val) => {
                            return (
                              <a href={val.URL} target="_blank">
                                Click Here
                              </a>
                            );
                          },
                        },
                        {
                          label: "Delete",
                          key: "",
                          rawFormat: (val) => {
                            return (
                              <div
                                className="pl-2"
                                tabIndex={0}
                                aria-label="Remove Test"
                                role="button"
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    this.deleteDocument(val);
                                  }
                                }}
                                onClick={(e) => this.deleteDocument(val)}
                              >
                                <AiOutlineClose color={"red"} size={30} />
                              </div>
                            );
                          },
                        },
                      ]}
                      table_data={this.state.tableData}
                      columnClickedCallback={(col) => {
                        this.useSorter(col);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
