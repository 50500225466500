import React from 'react'
import { BsCheck } from 'react-icons/bs';
import Select from "react-select";
import { customStyles } from '../../util/FormatUtil';

const CustomSelect = (props) => {

    // console.log('props in CustomSelect', props)

    let labelExt = props.fromFilterPage ? "Filter" : props.forInsurance ? "Insurance" : "";

    return (
        <div className={props.doNotDisplay ? "d-none" : "form-group row"}>
                <div className={props.fromFilterPage ? "col-10 col-md-8 pl-0" : props.resultSelect ? "col-12 col-md-4" : "col-12 col-md-4"} data-toggle={"tooltip"} data-placement={"top"} title={props.tooltip}>
                    <label aria-label={props.label + (props.fromFilterPage ? " Search " : " ") + (props.disabled ? props.value?.label : "")} id={labelExt ? props.label.replaceAll(' ', '') + labelExt  + "Label": props.label.replaceAll(' ', '') + "Label"} htmlFor={labelExt ? props.label.replaceAll(' ', '') + labelExt : props.label.replaceAll(' ', '')} className={"col-form-label"}>
                    {props.label}
                    </label>
                </div>
                <div className={props.fromFilterPage ? "col-2" : "d-none"}>
                    {props.value ? (<BsCheck className={"float-md-right"} color={"#21212E"} size={20} />) : null}
                </div>
                <div className={props.fromFilterPage ? "col-12 col-md-10 pl-0 pr-1" : props.resultSelect ? "col-12 col-md-8 pl-lg-4 pl-xl-5" : "col-12 col-md-8 pl-md-0 pr-md-1"} id={props.label}>
                    <div className="row">
                    <div className="col-12" tabIndex={props.disabled ? 0 : -1} aria-labelledby={`${labelExt ? props.label.replaceAll(' ', '') + labelExt : props.label.replaceAll(' ', '')} ${labelExt ? props.label.replaceAll(' ', '') + labelExt + "Label" : props.label.replaceAll(' ', '') + "Label"} `}>
                        <Select
                            id={labelExt ? props.label.replaceAll(' ', '') + labelExt : props.label.replaceAll(' ', '')}
                            styles={props.fromFilterPage && customStyles}
                            aria-labelledby={labelExt ? props.label.replaceAll(' ', '') + labelExt + "Label" : props.label.replaceAll(' ', '') + "Label"}
                            isDisabled={props.disabled ? props.disabled : false}
                            isMulti={props.fromFilterPage || props.isMulti ? true : false}
                            isSearchable={true}
                            isClearable={props.clearable ? true : false}
                            placeholder={props.disabled ? null : <div className="accessibilityText">Select...</div>}
                            onChange={(e) => {
                                props.handleChange(e)
                            }}
                            className={"state_select"}
                            options={props.options}
                            value={props.value}
                        />
                    </div>
                    </div>
                </div>
            </div>
    )
}

export default CustomSelect