import React, { useState } from 'react'
import { AiFillEye } from 'react-icons/ai'
import { BsCheck } from 'react-icons/bs'
import { getNameFromID } from '../../util/FormatUtil'
import GooglePlacesAutoComplete from '../admin/GooglePlacesAutoComplete';

const CustomInput = (props) => {

    const [eyeColor, setEyeColor] = useState(false)

    const toggleSSN = (key) => {
        let x = document.getElementById(key) as HTMLInputElement;
        if (eyeColor) {
          x.type = "password";
          setEyeColor(false)
        } else {
          x.type = "text";
          setEyeColor(true)
        }
      }

    let labelExt = props.fromFilterPage ? "Filter" : props.forInsurance ? "Insurance" : "";

  return (


    <div className="form-group row">
    <div
      className={props.fromFilterPage ? "col-10 col-md-8 pl-0" : "col-12 col-md-4"}
      data-toggle={"tooltip"}
      data-placement={"top"}
      title={props.tooltip}
    >
      <label
        htmlFor={labelExt ? props.label.replaceAll(' ', '') + labelExt : props.label.replaceAll(' ', '')}
        className={"col-form-label"}
        aria-label={props.label + (props.fromFilterPage ? " Search" : "")}
      >
        {props.label}
      </label>
    </div>
    <div id={props.id ? props.id : ""} className={props.fromFilterPage ? "col-2" : "d-none"}>
        {props.value ? (
        <BsCheck className={"float-md-right"} color={'#21212E'} size={20} />
        ) : null}
    </div>
    <div className={props.fromFilterPage ? "col-12 col-md-10 pl-0 pr-1" : "col-12 col-md-8 pl-md-0 pr-md-1"} id={props.label}>
      <div className="row">
        <div className={props.label === "Social Security #" ? "col-10" : "col-12"}>
          <input
            id={labelExt ? props.label.replaceAll(' ', '') + labelExt : props.label.replaceAll(' ', '')}
            type={props.label === "Social Security #" && !eyeColor ? "password" : props.fromFilterPage ? "search" : "text"}
            min={props.min ? props.min : null}
            max={props.max ? props.max : null}
            maxLength={props.maxLength ? props.maxLength : 50}
            // aria-label={props.label}
            readOnly={props.readOnly ? props.readOnly : false}
            disabled={props.disabled ? props.disabled : false}
            className={"form-control"}
            value={props.label === 'Service' && props.services ? getNameFromID(
                props.value,
                props.services
              ): props.value}
            autoComplete="off"
            name={props.label}
            onChange={(e) => {
                props.handleChange(e)
            }}
            aria-required={props.required ? true : false}
            onBeforeInput={props.label === "Social Security #" ? (e) => props.addDashes(e) : () => {}}
          />
        </div>
        <div className={props.label === "Social Security #" ? "col-2 text-center align-items-center pt-1 m-0" : "d-none"}>
            <AiFillEye color={eyeColor ? "#42A5F5" : "grey"} size={30} onClick={(e) => toggleSSN(props.label)} />
        </div>
      </div>
    </div>
  </div>
  )
}

export default CustomInput