import React from "react";
import ModalMessage from "../modals/ModalMessage";
import Overlay from "../Overlay";
import FilterCard, {FIELD_TYPE} from "../FilterCard";
import Select from "react-select";
import {sweetalert} from "../../App";
import Validator from "../../validation/Validator";
import {Validators} from "../../validation/Validators";
import AlertAPI from "../../network/AlertAPI";
import AdminAPI from "../../network/AdminAPI";
import EventAPI from "../../network/EventAPI";

interface AlertState{
    // text:string
    selectedEvent?:string
    showLoading:boolean
    filter?:{text?: string}
    filterTwo?:{email?:string, cell?:string}
    eventLabel?
    events?
    isSubmitting?:boolean
}

class Alert extends React.Component<any, AlertState>{

    constructor(props) {
        super(props);
        this.state = {
            // text:"",
            showLoading:false,
            filter:{},
            filterTwo:{},
            isSubmitting: false
        }
        this.assignClearState = this.assignClearState.bind(this);
        this.assignClear2State = this.assignClear2State.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
        this.clear = this.clear.bind(this);
    }

    componentDidMount() {
        this.setState({showLoading: true}, () => {
            EventAPI.getAllEvents(false,false).then(response => {
                if(!response.success){
                    sweetalert.fire("Error loading Events")
                }
                this.setState({events: response.data, showLoading: false})
            })
        })
    }

    clearFilterState:() => void = null;


    assignClearState(func){
        this.clearFilterState = func;
    }

    clearState = () =>{
        this.clearFilterState();
    }

    clearFilter2State:() => void = null;

    assignClear2State(func){
        this.clearFilter2State = func;
    }

    clear2State = () =>{
        this.clearFilter2State();
    }

    clear() {
        this.clearState()
        this.clear2State()
    }

    onSubmit(){
        let formValidation = {
            Text: this.state.filter.text,
            SelectedEvent: this.state.selectedEvent,
            Email: this.state.filterTwo.email,
            Cell: this.state.filterTwo.cell,
        }

        let validator = new Validator<any>()
            .withSimpleValidation("SelectedEvent", Validators.requireNonNullValidator("Event"))
            .withSimpleValidation("Text", Validators.requireNotBlankValidator("Message"))
            if(this.state.filterTwo.email){
                validator = validator.withSimpleValidation("Email", Validators.requireValidEmail())
            }
            if(this.state.filterTwo.cell){
                validator = validator.withSimpleValidation("Cell", Validators.requirePhone("Cell Number"))
            }

        let validationResponse = validator.validate(formValidation);
        if(!validationResponse.success) {
            return sweetalert.fire({icon: 'error', title: '', text: validationResponse.error});
        }
        window['$']("#alertID").modal('show')
    }

    async onConfirm(){
        this.setState({showLoading:true, isSubmitting:true}, async()=>{
            try{
                let result = await AlertAPI.sendAlert({
                    text:this.state.filter.text, 
                    event:this.state.selectedEvent, 
                    email:this.state.filterTwo.email, 
                    cell:this.state.filterTwo.cell
                })
                if(result.success){
                    sweetalert.fire({icon: 'success', title: '', text: `${result.numberSent} alerts sent. Not everyone will receive an alert at the same time`});
                    this.clear()
                }else{
                    sweetalert.fire({icon: 'error', title: '', text: result.reason})
                }
            }
            catch (e) {
                sweetalert.fire({icon: 'error', title: '', text: 'Failed sending alert'})
                console.error(e)
            }
            window['$']("#alertID").modal('hide')
            this.clear()
            this.clearState()
            this.clear2State()
            this.setState({showLoading:false, isSubmitting: false});
    })
    }

    getEventLabel(id){
        let eventLabel = this.state.events.find(p => p.value === id)
        if(!eventLabel){
            return null;
        }
        return {label: eventLabel.label, value: id}
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        // console.log('state in alert', this.state)
        return (
            <React.Fragment>
                <Overlay show_loading={this.state.showLoading}/>
            <ModalMessage cancelButton={true} title={"BEFORE ALERT CONFIRMATION"} ID={"alertID"} buttonText={"Send Alert"} message={
                <p>Are you sure?</p>
            } onSubmit={this.onConfirm} isSubmitting={this.state.isSubmitting} />
            <div className="container-fluid  min-vh-100 ">
                <div className={"row"}>
                    <div className="col-12 col-lg-6 pt-2">
                        <div className="card mb-2">
                            <div className="card-header verlag-bold">
                                <h4>Send Alert</h4>
                            </div>
                            <div className={"card-body"}>
                                <p className={"responsive-p verlag-light mb-4"}>This will send an email and text message to all individuals signed up for the event. Please use extreme caution.</p>
                                {/*<EventSelect onChange={(e) => this.setState({selectedEvent:e.event})}/>*/}
                                <div className="form-group row">
                                    <div className={"col-4"}>
                                        <label htmlFor={'Event'} style={{fontWeight: 'bold', paddingTop: '0.5em'}}>Event</label>
                                    </div>
                                    <div className={"col-8 pl-0 pr-1"} id={'Event'}>
                                        <Select
                                            isSearchable={true}
                                            placeholder={"Please select..."}
                                            onChange={(e)=> {this.getEventLabel(e.value); this.setState({eventLabel: e, selectedEvent: e.value}) } }
                                            className={"state_select"}
                                            options={this.state.events}
                                            value={this.state.eventLabel}
                                        />
                                    </div>
                                </div>
                                <FilterCard passClearStateFunc={this.assignClearState} fields={[
                                    {label:"Message", key:"text", rows: 5, type:FIELD_TYPE.TEXT_AREA, maxLength: 1000},
                                    ]} filterChanged={f =>this.setState({filter:f})}/>
                                    
                                <h5 className={'mb-3 mt-5'}>Additional Alert Recipient</h5>
                                <FilterCard passClearStateFunc={this.assignClear2State} fields={[
                                    {label:"Email", key:"email", placeholder: 'optional', type:FIELD_TYPE.TEXT, textType:"email", maxLength: 100},
                                    {label:"Cell Number", key:"cell", placeholder: 'optional', type:FIELD_TYPE.PHONE, textType:"tel", pattern:"[0-9]{3}-[0-9]{3}-[0-9]{4}"}]} filterChanged={f =>this.setState({filterTwo:f})}/>
                            </div>
                            <div className={"card-footer"}> <button className={"btn immySubmitButtonOutline"} onClick={this.onSubmit}> Send</button></div>
                        </div>
                    </div>
                </div>
            </div>
            </React.Fragment>
        )
    }
}
export  default Alert