import React from "react";
import FilterCard, { FIELD_TYPE, ReactSelect } from "../FilterCard";
import {
  getPageOptions,
  getNameFromID,
  getTwelveHourAMPMTime,
  hideModal,
  parseDate,
  showModalNoOutsideClick,
} from "../../util/FormatUtil";
import Overlay from "../Overlay";
import SimpleTable from "../tables/SimpleTable";
import { EventManagementModal } from "../modals/EventManagementModal";
import EventSched, { EventBreak } from "../../types/Event";
import Select from "react-select";
import { sweetalert } from "../../App";
import EventSelect from "../EventSelect";
import NetworkUtil from "../../network/NetworkUtil";
import ServicesAPI from "../../network/ServicesAPI";
import { Column } from "../tables/TableBase";
import { Sorter, TableOrder } from "../../sorting/Sorter";
import moment from "moment";
import SystemAPI from "../../network/SystemAPI";
import AdminAPI from "../../network/AdminAPI";
import { AdminPages } from "./AdminNav";
import PaginationTool from "../PaginationTool";
import EventAPI from "../../network/EventAPI";
import { FacilityAPI } from "../../network/FacilityAPI";
import CustomDate from "../form/CustomDate";

interface EventsState {
  showLoading: boolean;
  tableData: any[];
  totalEvents?: number;
  event?: any;
  selectedEvent?;
  active?;
  facilities?;
  apptsBooked?;
  apptsAvailable?;
  totalAppts?;
  filter?: {
    EventID;
    TestDate;
    FacilityID;
    FacilityGroup;
    Active;
    Service;
  };
  facilityGroups;
  EventDate?;
  FilterID?;
  ActiveLabel?;
  schedMap?: any[];
  services?;
  direction?: TableOrder;

  page_options: ReactSelect[];
  selected_page?;
  searched?;
  pageCustomQuestions?;
  allEvents?: { label: string; value: number }[];
}

export default class Events extends React.Component<any, EventsState> {
  constructor(props) {
    super(props);
    this.state = {
      showLoading: false,
      tableData: [],
      totalEvents: 0,
      selectedEvent: {},
      facilities: [],
      facilityGroups: [],

      services: [],
      direction: "asc",
      page_options: [{ value: "1", label: "1" }],
      selected_page: { label: 1, value: 1 },
      pageCustomQuestions: [],
    };
    this.clear = this.clear.bind(this);
    this.search = this.search.bind(this);
    this.assignClearState = this.assignClearState.bind(this);
    this.handleExportToCSV = this.handleExportToCSV.bind(this);
    this.assignClearStateEvent = this.assignClearStateEvent.bind(this);
  }

  componentDidMount() {
    this.setState({ showLoading: true }, () => {
      FacilityAPI.getFacilitiesWithAddress(true).then((data) => {
        this.setState({ facilities: data });
      });
      ServicesAPI.getAllServices().then((data) => {
        this.setState({
          services: data.data.map((s) => ({ label: s.Name, value: s.ID })),
        });
      });
      FacilityAPI.getFacilitiesGroups().then((data) => {
        this.setState({ facilityGroups: data });
      });

      SystemAPI.getCustomQuestions(AdminPages.Events).then((res) => {
        this.setState({ pageCustomQuestions: res.questions });
      });

      EventAPI.getEvents(1).then((data) => {
        this.setState({ schedMap: data.schedMap });
      });
      EventAPI.getAllEvents(false, false).then(response => {
        if (!response.success) {
          sweetalert.fire('Error loading Events');
        }
        
        this.setState({
          allEvents: response.data as { label: string; value: number }[],
        });
      });
      this.search(1);
    });
  }

  getActiveOptions() {
    return [
      { value: 1, label: "Active" },
      { value: 0, label: "Inactive" },
    ];
  }

  getFilterObj() {
    if (!this.state.filter) return {};

    return {
      EventDate: this.state.filter.TestDate
        ? parseDate(this.state.filter.TestDate)
        : null,
      Facilities: this.state.filter.FacilityID
        ? this.state.filter.FacilityID
        : null,
      Active: this.state.filter.Active ? this.state.filter.Active : null,
      EventID:
        this.state.event && this.state.event.length > 0
          ? this.state.event
          : null,
      FacilityGroup: this.state.filter.FacilityGroup
        ? this.state.filter.FacilityGroup
        : null,
      Service: this.state.filter.Service ? this.state.filter.Service : null,
    };
  }

  search(page) {
    this.setState({ showLoading: true }, async () => {
      let response = await EventAPI.searchEvents(this.getFilterObj(), page);
      if (response.table_data.length === 0) {
        sweetalert.fire({
          icon: "info",
          title: "",
          text: "No events were returned",
        });
      }
      this.setState({
        showLoading: false,
        tableData: response.table_data,
        totalEvents: response.total,
        page_options: getPageOptions(response.num_pages),
        searched: this.getFilterObj(),
      });
    });
  }

  clear() {
    this.clearFilter();
  }

  clearFilterState: () => void = null;

  assignClearState(func) {
    this.clearFilterState = func;
  }

  clearFilter = () => {
    this.clearFilterState();
  };

  clearFilterStateEvent: () => void = null;

  assignClearStateEvent(func) {
    this.clearFilterStateEvent = func;
  }

  onSubmit(event: EventSched, eventBreaks: EventBreak[]) {
    this.setState({ showLoading: true }, async () => {
      try {
        let result = await EventAPI.eventUpsert({
          event: event,
          eventBreaks: eventBreaks,
        });
        if (!result.success) {
          sweetalert.fire({ icon: "error", title: "", text: result.reason });
          this.setState({ showLoading: false });
        } else {
          sweetalert
            .fire({
              icon: "success",
              title: "",
              text: "Event saved successfully",
            })
            .then((data) => {
              if (data.isConfirmed) {
                this.setState({ showLoading: false });
                window.location.reload();
              }
            });
        }
      } catch (e) {
        console.error(e);
        this.setState({ showLoading: false });
        sweetalert.fire({ icon: "error", title: "", text: "Failed" });
      }
    });
  }

  handleExportToCSV() {
    this.setState({ showLoading: true }, async () => {
      await NetworkUtil.downloadCSV("/api/admin/events/export", "Events.xlsx", {
        filter: this.state.searched,
      });
      this.setState({ showLoading: false });
    });
  }

  useSorter(col: Column) {
    let sorter = new Sorter<EventSched>()
      .withRawSorterFunc("Facility.FacilityName", (a, b) => {
        let aFacility = a.Facility.FacilityName;
        let bFacility = b.Facility.FacilityName;
        return aFacility.localeCompare(bFacility);
      })
      .withRawSorterFunc("BreakTimes", (a, b) => {
        let aBreakTimes = a.EventBreaks.length > 0 ? 1 : 0;
        let bBreakTimes = b.EventBreaks.length > 0 ? 1 : 0;
        return aBreakTimes - bBreakTimes;
      })
      .withRawSorterFunc("Time", (a, b) => {
        return moment(a.StartTime, "hh:mm a").diff(
          moment(b.StartTime, "hh:mm a")
        );
      });

    this.setState({
      tableData: sorter.sortByKey(
        this.state.tableData,
        col.key as keyof EventSched,
        this.state.direction
      ),
      direction: this.state.direction === "asc" ? "desc" : "asc",
    });
  }

  handleDateOnChange(value, state) {
    if (value) {
      this.setState(prevState => ({
        filter: {
          ...prevState.filter,
          TestDate: value,
        },
      }));
    } else {
      this.setState(prevState => ({
        filter: {
          ...prevState.filter,
          TestDate: null,
        },
      }));
    }
  }

  render():
    | React.ReactElement
    | string
    | number
    | {}
    | React.ReactNodeArray
    | React.ReactPortal
    | boolean
    | null
    | undefined {
      // console.log('Events state', this.state)
    return (
      <React.Fragment>
        <PaginationTool />
        <Overlay show_loading={this.state.showLoading} />
        <EventManagementModal
          key={this.state.selectedEvent && this.state.selectedEvent.ID ? this.state.selectedEvent.ID : 'blank'}
          allEvents={this.state.allEvents}
          facilties={this.state.facilities}
          services={this.state.services}
          schedule={this.state.schedMap}
          selectedEvent={this.state.selectedEvent}
          onSubmit={(event: EventSched, EventBreaks: EventBreak[]) => {
            hideModal(EventManagementModal.ID);
            this.onSubmit(event, EventBreaks);
          }}
          pageCustomQuestions={this.state.pageCustomQuestions}
        />
        <div className="container-fluid  min-vh-100 ">
          <a id={"top"}>{}</a>
          <div className={"row"}>
            <div className="col-12 col-md-12 col-lg-8 col-xl-5 pt-2">
              <div className="card mb-2">
                <div className="card-header verlag-bold">
                  <h4>Event Search</h4>
                </div>
                <div className="card-body">
                  <EventSelect
                    passClearStateFunc={this.assignClearStateEvent}
                    multi={true}
                    onChange={(e) => this.setState({ event: e.event })}
                  />
                  <CustomDate
                    fromFilterPage={false}
                    value={this.state.filter && this.state.filter.TestDate ? this.state.filter.TestDate : null}
                    label={'Date'}
                    tooltip={`Event Date`}
                    handleDateOnChange={e => this.handleDateOnChange(e, 'filerDate')}
                  />
                  <FilterCard
                    passClearStateFunc={this.assignClearState}
                    fields={[
                      // {label:"Event ID", "key":"EventID", type:FIELD_TYPE.TEXT},
                      // { label: "Date", key: "TestDate", type: FIELD_TYPE.DATE },
                      {
                        label: "Facility",
                        key: "FacilityID",
                        type: FIELD_TYPE.SELECT,
                        options: this.state.facilities,
                        isMapped: true,
                        isMulti: true,
                        textType: "text",
                      },
                      {
                        label: "Facility Group",
                        key: "FacilityGroup",
                        type: FIELD_TYPE.SELECT,
                        options: this.state.facilityGroups,
                        isMapped: true,
                        isMulti: true,
                        textType: "text",
                      },
                      {
                        label: "Active",
                        key: "Active",
                        type: FIELD_TYPE.SELECT,
                        options: this.getActiveOptions(),
                        isMapped: true,
                        isMulti: true,
                        textType: "text",
                      },
                      {
                        label: "Service",
                        key: "Service",
                        type: FIELD_TYPE.SELECT,
                        options: this.state.services,
                        isMapped: true,
                        isMulti: true,
                        textType: "text",
                      },
                    ]}
                    filterChanged={(e) => this.setState({ filter: e })}
                  />
                </div>
                <div className="card-footer">
                  <button
                    className="btn immySubmitButtonOutline"
                    onClick={() =>
                      this.setState(
                        { selected_page: { label: 1, value: 1 } },
                        () => this.search(1)
                      )
                    }
                  >
                    Search
                  </button>
                  <button
                    className={"btn btn-outline-success ml-2 float-right"}
                    onClick={() =>
                      this.setState({ selectedEvent: {} }, () => {
                        showModalNoOutsideClick(EventManagementModal.ID);
                      })
                    }
                  >
                    Create Event
                  </button>
                </div>
              </div>
            </div>

            {this.state.totalEvents > 0 && (
              <div className="col-md-12 pt-2">
                <div className="card mt-2 mb-5">
                  <div className="card-header verlag-bold stickToTop" style={{zIndex: 0}}>
                    <h4 className="text-center text-md-left">
                      Events
                      <button
                        className={
                          "ml-2 d-none d-md-inline btn btn-outline-primary"
                        }
                        onClick={() => this.handleExportToCSV()}
                      >
                        Export to CSV
                      </button>
                      <section className="tableHeaderSection float-md-right d-flex justify-content-around">
                        <h4
                          style={{ float: "right" }}
                          aria-label="Total Records"
                          role="alert"
                        >
                          Total: {this.state.totalEvents}
                        </h4>
                        <h4 className="float-right align-middle pr-2 ml-5">
                          Page{" "}
                        </h4>
                        <div className=" align-middle float-right pages ">
                          <Select
                            isSearchable={true}
                            placeholder={"1"}
                            noOptionsMessage={() => "No option"}
                            value={this.state.selected_page}
                            onChange={(e: ReactSelect) =>
                              this.setState({ selected_page: e }, () =>
                                this.search(e.value)
                              )
                            }
                            className={"state_select page-num-select"}
                            options={this.state.page_options}
                          />
                        </div>
                      </section>
                    </h4>
                  </div>

                  <div className="card-body p-0 m-0 table-responsive">
                    <SimpleTable
                      table_data={this.state.tableData}
                      columns={[
                        {
                          label: "Event#",
                          key: "ID",
                          rawFormat: (val) => {
                            return (
                              <a
                                href={"#top"}
                                className={"tableNameLinkColor"}
                                onClick={() => {
                                  this.setState({ selectedEvent: val }, () => {
                                    showModalNoOutsideClick(
                                      EventManagementModal.ID
                                    );
                                  });
                                }}
                              >
                                {val.ID}
                              </a>
                            );
                          },
                        },
                        {
                          label: "Date",
                          key: "TestDate",
                          formatFunc: parseDate,
                        },
                        {
                          label: "Time",
                          key: "Time",
                          rawFormat: (val) => {
                            return (
                              <div>
                                {getTwelveHourAMPMTime(val.StartTime)} -{" "}
                                {getTwelveHourAMPMTime(val.EndTime)}
                              </div>
                            );
                          },
                        },
                        {
                          label: "Services",
                          key: "Service",
                          rawFormat: (v) => {
                            let serviceIDs = v.ServiceIDs
                              ? JSON.parse(v.ServiceIDs)
                              : [];
                            let serviceName = [];
                            serviceIDs.map((s) => {
                              serviceName.push(
                                getNameFromID(s, this.state.services)
                              );
                            });
                            return (
                              serviceName &&
                              serviceName.map((s) => (
                                <p className="mb-0">{s}</p>
                              ))
                            );
                          },
                        },
                        { label: "Facility", key: "Facility.FacilityName" },
                        {
                          label: "Available Appts",
                          key: "Available",
                          rawFormat: (val) => {
                            return (
                              <div>
                                {val.Available} / {val.Total}
                              </div>
                            );
                          },
                        },
                        { label: "Checked In", key: "CheckedIn" },
                        { label: "Factor", key: "Factor" },
                        {
                          label: "Break Times",
                          key: "BreakTimes",
                          rawFormat: (val) => {
                            return val.EventBreaks.map((p) => {
                              let start = getTwelveHourAMPMTime(p.StartTime);
                              let end = getTwelveHourAMPMTime(p.EndTime);
                              return (
                                <div>
                                  {start} - {end}
                                </div>
                              );
                            });
                          },
                        },
                      ]}
                      columnClickedCallback={(col) => {
                        this.useSorter(col);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
