import { FIELD_TYPE, FilterPart } from "../components/FilterCard";
import { CustomFields, CustomFieldType } from "../types/CustomFieldType";
import { sweetalert } from "../App";
import { isValidEmail } from "../util/ValidationUtil";

export class CustomFieldBuilder {
  public static getFilterParts(customFields: CustomFieldType[]): FilterPart[] {
    let parts: FilterPart[] = [];
    for (let customField of customFields) {
      if (customField.Options.isActive) {
        if (customField.FieldTypeID === CustomFields.TEXT_FIELD) {
          parts.push({
            label: customField.DisplayName,
            key: customField.DisplayName,
            type: FIELD_TYPE.TEXT,
            popoverText: customField.Options.popoverText
              ? customField.Options.popoverText
              : "",
            maxLength: customField.Options.maxLength
              ? customField.Options.maxLength
              : null,
          });
        } else if (customField.FieldTypeID === CustomFields.TEXTAREA_FIELD) {
          parts.push({
            label: customField.DisplayName,
            key: customField.DisplayName,
            type: FIELD_TYPE.TEXT_AREA,
            popoverText: customField.Options.popoverText
              ? customField.Options.popoverText
              : "",
            maxLength: customField.Options.maxLength
              ? customField.Options.maxLength
              : null,
          });
        } else if (customField.FieldTypeID === CustomFields.SELECT_FIELD) {
          parts.push({
            label: customField.DisplayName,
            key: customField.DisplayName,
            type: FIELD_TYPE.SELECT,
            options: customField.Options.options
              ? customField.Options.options
              : [],
            isMulti: customField.Options.isMulti
              ? customField.Options.isMulti
              : false,
            popoverText: customField.Options.popoverText
              ? customField.Options.popoverText
              : "",
            isMapped: true,
          });
        } else if (customField.FieldTypeID === CustomFields.NUMBER_FIELD) {
          parts.push({
            label: customField.DisplayName,
            key: customField.DisplayName,
            type: FIELD_TYPE.NUMBER,
            popoverText: customField.Options.popoverText
              ? customField.Options.popoverText
              : "",
            max: customField.Options.max,
            min: customField.Options.min,
          });
        } else if (customField.FieldTypeID === CustomFields.DATE_FIELD) {
          parts.push({
            label: customField.DisplayName,
            key: customField.DisplayName,
            type: FIELD_TYPE.DATE,
            popoverText: customField.Options.popoverText
              ? customField.Options.popoverText
              : "",
            dateWarning: true,
          });
        }
        // else if(customField.FieldTypeID === CustomFields.TIME_FIELD){
        //
        //     parts.push({
        //         label:customField.DisplayName,
        //         key:customField.DisplayName,
        //         type:"select",
        //         options:customField.Options.options ? customField.Options.options : [],
        //         isMulti:customField.Options.isMulti ? customField.Options.isMulti : false,
        //         popoverText:customField.Options.popoverText ? customField.Options.popoverText : '',
        //         isMapped: true,
        //     })
        // }
        // else if(customField.FieldTypeID === CustomFields.DATETIME_FIELD){
        //
        //     parts.push({
        //         label:customField.DisplayName,
        //         key:customField.DisplayName,
        //         type:FIELD_TYPE.SELECT,
        //         options:customField.Options.options ? customField.Options.options : [],
        //         isMulti:customField.Options.isMulti ? customField.Options.isMulti : false,
        //         popoverText:customField.Options.popoverText ? customField.Options.popoverText : '',
        //         dateWarning: true
        //     })
        // }
        else if (customField.FieldTypeID === CustomFields.PHONE_FIELD) {
          parts.push({
            label: customField.DisplayName,
            key: customField.DisplayName,
            type: FIELD_TYPE.PHONE,
            popoverText: customField.Options.popoverText
              ? customField.Options.popoverText
              : "",
            textType: "tel",
            pattern: "[0-9]{3}-[0-9]{3}-[0-9]{4}",
          });
        } else if (customField.FieldTypeID === CustomFields.EMAIL_FIELD) {
          parts.push({
            label: customField.DisplayName,
            key: customField.DisplayName,
            type: FIELD_TYPE.EMAIL,
            popoverText: customField.Options.popoverText
              ? customField.Options.popoverText
              : "",
            maxLength: customField.Options.maxLength
              ? customField.Options.maxLength
              : null,
          });
        }
      }
    }
    return parts;
  }

  public static validateCustomFields(
    customFields,
    customQuestions,
    record = null,
    employeeSelected = false
  ) {
    let invalid = false;
    let recordCD;

    if (record) {
      recordCD = new Date(record.CreatedDate);
      recordCD = recordCD.getTime() as any;
    }

    for (const q of customQuestions) {
      let checkRecord = false;
      let fieldCD = new Date(q.CreatedDate);
      fieldCD = fieldCD.getTime() as any;
      if(!record || recordCD === 0){
        checkRecord = true;
      }
      //record created before field (record will be greater than field)
      else if(recordCD >= fieldCD){
        checkRecord = true;
      }
      else{
        checkRecord = false;
      }

      if (
        q.Options.isRequired &&
        q.Options.isActive && checkRecord
      ) {
        if (customFields && q.DisplayName in customFields) {
          for (const [key, value] of Object.entries(customFields)) {
            if (key === q.DisplayName) {
              //@ts-ignore
              let valueAnswer = value && value.answer ? value.answer : null;
              if (
                !valueAnswer ||
                (Array.isArray(valueAnswer) && valueAnswer.length < 1) ||
                String(valueAnswer).trim().length < 1
              ) {
                sweetalert.fire({
                  icon: "error",
                  title: "",
                  html: employeeSelected
                    ? `<h3>${q.DisplayName} value is required</h3><p>Please update via Employee Management</p>`
                    : `<p>${q.DisplayName} value is required</p>`,
                });
                invalid = true;
                break;
              }
            }
          }
        } else {
          sweetalert.fire({
            icon: "error",
            title: "",
            html: employeeSelected
              ? `<h3>${q.DisplayName} value is required</h3><p>Please update via Employee Management</p>`
              : `<p>${q.DisplayName} value is required</p>`,
          });
          invalid = true;
          break;
        }
      }
 

      if (customFields) {
        for (const [key, value] of Object.entries(customFields)) {
          //@ts-ignore
          let answerValue = value && value.answer ? value.answer : null;
          if (key === q.DisplayName) {
            //TEXT_FIELD//
            if (
              (q.FieldTypeID === CustomFields.TEXT_FIELD ||
                q.FieldTypeID === CustomFields.TEXTAREA_FIELD) &&
              answerValue &&
              String(answerValue).length > 0
            ) {
              //check if answerValue length is greater than max length
              if (String(answerValue).length > Number(q.Options.maxLength)) {
                sweetalert.fire({
                  icon: "error",
                  title: "",
                  text: `${q.DisplayName} value must be less than ${q.Options.maxLength} characters`,
                });
                invalid = true;
                break;
              }
            }

            //EMAIL_FIELD//

            if (
              q.FieldTypeID === CustomFields.EMAIL_FIELD &&
              answerValue &&
              String(answerValue).length > 0
            ) {
              //check if email is valid
              if (!isValidEmail(String(answerValue))) {
                sweetalert.fire({
                  icon: "error",
                  title: "",
                  text: `${q.DisplayName} must be a valid email`,
                });
                invalid = true;
                break;
              }
            }

            //NUMBER_FIELD//
            if (q.FieldTypeID === CustomFields.NUMBER_FIELD && answerValue) {
              //check if answerValue less than min or greater than max
              if (Number(answerValue) < q.Options.min) {
                sweetalert.fire({
                  icon: "error",
                  title: "",
                  text: `${q.DisplayName} value must be greater than ${q.Options.min}`,
                });
                invalid = true;
                break;
              }
              if (Number(answerValue) > q.Options.max) {
                sweetalert.fire({
                  icon: "error",
                  title: "",
                  text: `${q.DisplayName} value must be less than ${q.Options.max}`,
                });
                invalid = true;
                break;
              }
            }

            //PHONE_FIELD//
            if (
              q.FieldTypeID === CustomFields.PHONE_FIELD &&
              answerValue &&
              String(answerValue).length > 0
            ) {
              let phoneRegex =
                /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
              if (!phoneRegex.test(String(answerValue))) {
                sweetalert.fire({
                  icon: "error",
                  title: "",
                  text: `${q.DisplayName} value must be a valid phone number`,
                });
                invalid = true;
                break;
              }
            }

            //DATE_FIELD//
            if (q.FieldTypeID === CustomFields.DATE_FIELD && answerValue) {
              let valueDate = new Date(answerValue as Date);
              let now = new Date();
              if (valueDate.getTime() > now.getTime()) {
                if (q.Options.allowFutureDate) {
                  invalid = false;
                } else {
                  sweetalert.fire({
                    icon: "error",
                    title: "",
                    text: `${q.DisplayName} cannot be in the future`,
                  });
                  invalid = true;
                }
                break;
              }
            }
          }
        }
      }

      if (invalid) {
        break;
      }
    }

    return invalid;
  }

  // Employee Info Modal Fields - not being use currently
  public static getEmployeeQuestions(
    gender,
    states,
    ethnicity,
    race,
    countries
  ) {
    return [
      {
        label: "First Name",
        key: "FirstName",
        type: FIELD_TYPE.TEXT,
        textType: "text",
        maxLength: 50,
      },
      {
        label: "Middle Name",
        key: "MiddleName",
        type: FIELD_TYPE.TEXT,
        textType: "text",
        maxLength: 50,
      },
      {
        label: "Last Name",
        key: "LastName",
        type: FIELD_TYPE.TEXT,
        textType: "text",
        maxLength: 50,
      },
      {
        label: "Email",
        key: "Email",
        type: FIELD_TYPE.TEXT,
        textType: "email",
        maxLength: 100,
      },
      {
        label: "Confirm Email",
        key: "confirmEmail",
        type: FIELD_TYPE.TEXT,
        textType: "email",
        maxLength: 100,
      },
      {
        label: "Gender",
        key: "GenderID",
        type: FIELD_TYPE.SELECT,
        options: gender,
        isMapped: true,
      },
      {
        label: "Ethnicity",
        key: "EthnicityID",
        type: FIELD_TYPE.SELECT,
        options: ethnicity,
        isMapped: true,
      },
      {
        label: "Race",
        key: "RaceID",
        type: FIELD_TYPE.SELECT,
        options: race,
        isMapped: true,
      },
      {
        label: "Date of Birth",
        key: "DateOfBirth",
        type: FIELD_TYPE.DATE,
        dateWarning: true,
      },
      {
        label: "State",
        key: "State",
        type: FIELD_TYPE.SELECT,
        options: states,
        isMapped: true,
      },
      {
        label: "Country",
        key: "Country",
        type: FIELD_TYPE.SELECT,
        options: countries,
        isMapped: true,
      },
      {
        label: "Street Address",
        key: "StreetAddress",
        type: FIELD_TYPE.TEXT,
        maxLength: 500,
      },
      {
        label: "City",
        key: "City",
        type: FIELD_TYPE.TEXT,
        maxLength: 100,
      },
      { label: "Zip", key: "Zip", type: FIELD_TYPE.TEXT, maxLength: 50 },
      {
        label: "County",
        key: "County",
        type: FIELD_TYPE.TEXT,
        maxLength: 100,
      },
      {
        label: "Cell Phone",
        key: "PhoneNumber",
        type: FIELD_TYPE.PHONE,
        textType: "tel",
        pattern: "[0-9]{3}-[0-9]{3}-[0-9]{4}",
      },
    ];
  }
}