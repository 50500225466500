export default interface Claim {
  ID;
  EmployeeName;
  EmployeeID;
  AccidentDate;
  AccidentDescription;
  NatureOfInjury;
  AccidentCausedDeath;
  MedAttnReceived;
  MedAttnDescription;
  ChoicePhysician;
  TreatingPhysicianName;
  ReturnToWorkDate;
  EmployeeDeathDate;
  Status;
  LossOfTime;
  FirstLostTimeDate;
  StoppedWork;
  DoingUsualWork;
  PayStoppedDate;
  Wages;
  WageType;
  OSHACaseNum;
  CreatedBy;
  CreatedDate;
  LastUpdatedBy;
  LastUpdatedDate;
}

export const FakeClaims = [
  {
    ID: 1,
    EmployeeName: "Emily Anderson",
    EmployeeID: 1,
    AccidentDate: "4/24/2024 12:30:00 PM",
    AccidentDescription: "walking looking at phone, fell into fountain",
    NatureOfInjury: "broken ankle",
    AccidentCausedDeath: false,
    MedAttnReceived: true,
    MedAttnDescription: "Sent to Emergency Room",
    ChoicePhysician: true,
    TreatingPhysicianName: "Susan Haggard, MD",
    ReturnToWorkDate: null,
    EmployeeDeathDate: null,
    Status: "In Review",
    LossOfTime: true,
    FirstLostTimeDate: "4/24/2024 12:30:00 PM",
    StoppedWork: true,
    DoingUsualWork: true,
    PayStoppedDate: "4/24/2024 12:30:00 PM",
    Wages: "25,000",
    WageType: "Yearly",
    OSHACaseNum: "2332-6546",
    CreatedBy: "amber-hayes@immytech.com",
    CreatedDate: "4/24/2023 3:52:15 PM",
    LastUpdatedBy: "amber-hayes@immytech.com",
    LastUpdatedDate: "4/25/2023 09:00:00 AM",
  },
  {
    ID: 2,
    EmployeeName: "Michael Roberts",
    EmployeeID: 2,
    AccidentDate: "3/15/2024 1:35:44 PM",
    AccidentDescription: "Hand got caught in machine",
    NatureOfInjury: "Hand got caught in machine",
    AccidentCausedDeath: false,
    MedAttnReceived: true,
    MedAttnDescription: "sent to hospital",
    ChoicePhysician: false,
    TreatingPhysicianName: "Michael Clark, MD",
    ReturnToWorkDate: "4/4/2024 2:39:10 PM",
    EmployeeDeathDate: null,
    Status: "In Review",
    LossOfTime: true,
    FirstLostTimeDate: "3/15/2024 1:35:44 PM",
    StoppedWork: true,
    DoingUsualWork: true,
    PayStoppedDate: "3/15/2024 1:35:44 PM",
    Wages: "15.00",
    WageType: "Hourly",
    OSHACaseNum: null,
    CreatedBy: "amber-hayes@immytech.com",
    CreatedDate: "4/4/2024 2:39:10 PM",
    LastUpdatedBy: "amber-hayes@immytech.com",
    LastUpdatedDate: "4/4/2024 2:39:10 PM",
  },
  {
    ID: 3,
    EmployeeName: "Olivia Marie",
    EmployeeID: 5,
    AccidentDate: "12/23/2020 3:45:10 PM",
    AccidentDescription: "paper cut",
    NatureOfInjury: "right finger",
    AccidentCausedDeath: false,
    MedAttnReceived: true,
    MedAttnDescription: "band-aid",
    ChoicePhysician: false,
    TreatingPhysicianName: "",
    ReturnToWorkDate: "12/23/2020 3:45:00 PM",
    EmployeeDeathDate: null,
    Status: "Denied",
    LossOfTime: false,
    FirstLostTimeDate: null,
    StoppedWork: false,
    DoingUsualWork: true,
    PayStoppedDate: null,
    Wages: "15.00",
    WageType: "Hourly",
    OSHACaseNum: null,
    CreatedBy: "amber-hayes@immytech.com",
    CreatedDate: "12/23/2020 4:50:15 PM",
    LastUpdatedBy: "amber-hayes@immytech.com",
    LastUpdatedDate: "12/23/2020 4:50:15 PM",
  },
];

export const FakeOSHARecord = {
  ID: 4,
  CaseNumber: "2332-6546",
  EmployeeFirstName: "Emily",
  EmployeeMiddleName: "Grace",
  EmployeeLastName: "Anderson",
  EmployeeGenderID: 2,
  EmployeeDOB: "8/12/1992",
  EmployeeAddress: "789 Maple Street",
  EmployeeCity: "Springfield",
  EmployeeState: "IL",
  EmployeeZip: "62701",
  EmployeeHireDate: "4/25/2018",
  PhysicianFirstName: "Susan",
  PhysicianLastName: "Haggard",
  TreatmentFacilityAddress: "123 Ouch Way",
  TreatmentFacilityCity: "Edmond",
  TreatmentFacilityName: "SH County Hospital",
  TreatmentFacilityState: "OK",
  TreatmentFacilityZip: "73034",
  TreatedInEmergencyRoom: 1,
  WasInemployee: 0,
  DateOfInjury: "4/10/2024",
  TimeEmployeeBeganWork: "16:30:00",
  TimeOfEvent: "17:45:00",
  ActivityBeforeIncident: "walking while looking at tic toc",
  IncidentDescription: "employee fell into fountain",
  InjuryDescription: "broken ankle",
  ObjectOrSubstanceInvolved: "fountain",
  DidEmployeeDie: 0,
  EmployeeDeathDate: null,
  FormCompletedBy: "Peter Parker",
  FormCompletedByTitle: "Supervisor",
  FormCompletedByPhone: "+14055439924",
  FormCompletedDate: "4/25/2024",
  CreatedDate: "4/24/2024",
  CreatedBy: "bryan-rigsby@immytech.com",
  LastUpdatedDate: "4/25/2024",
  LastUpdatedBy: "bryan-rigsby@immytech.com",
};
