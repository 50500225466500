import React, { useState, useEffect} from "react";
import Select from "react-select";
import Overlay from "../Overlay";
import { sweetalert } from "../../App";
import { isEmptyObject } from "jquery";
import { buildRow, getBoolSelectVal, getLabel, hideModal } from "../../util/FormatUtil";
import moment from "moment";
import DatePicker from "react-datepicker";
import Incident from "../../types/Incident";

type IncidentsModalProps = {
    incidents
    selectedIncident:Incident
    employees
    requery
};

export const IncidentsModal = ({ incidents , selectedIncident, employees, requery }: IncidentsModalProps) => {
    const ID = "incidentsmodal";
    const [incident, setIncident] = useState(null)
    const [selectedEmployee, setSelectedEmployee] = useState(null)
    const [employeeName, setEmployeeName] = useState('')
    const [incidentType, setIncidentType] = useState(''); // custom select fields
    const [incidentTypeLabel, setIncidentTypeLabel] = useState(null)

    const [incidentDate, setIncidentDate] = useState(null)
    const [activityBeforeIncident, setActivityBeforeIncident ] = useState('')
    const [description, setDescription] = useState('')
    const [objectOrSubstanceInvolved, setObjectOrSubstanceInvolved] = useState('')
    const [incidentTime, setIncidentTime] = useState(null)
    const [didEmployeeDie, setDidEmployeeDie ] = useState(null)
    const [dateOfDeath, setDateOfDeath ] = useState(null)
    const [caseNumber, setCaseNumber] = useState("")

    const [showLoading, setShowLoading] = useState(false)

    const IncidentTypeOptions = [
        { label: "Accident", value: "Accident" },
        { label: "Injury", value: "Injury" },
        { label: "Altercation", value: "Altercation" },
    ]


    useEffect(() => {
        $(`#${ID}`).on('shown.bs.modal', function () {
            $(document).off('focusin.modal');
        });

        if (!isEmptyObject(selectedIncident) && selectedIncident.ID) {
            let incidentTypeLabel = getLabel(selectedIncident.IncidentType, IncidentTypeOptions)

            setIncident(selectedIncident);
            setSelectedEmployee(employees.find(emp => emp.ID === selectedIncident.EmployeeID))
            setEmployeeName(selectedIncident.EmployeeName)
            setIncidentType(selectedIncident.IncidentType)
            setIncidentTypeLabel(incidentTypeLabel);
            setIncidentDate(selectedIncident.IncidentDate)
            setIncidentTime(selectedIncident.IncidentTime)
            setActivityBeforeIncident(selectedIncident.ActivityBeforeIncident)
            setDescription(selectedIncident.Description)
            setObjectOrSubstanceInvolved(selectedIncident.ObjectOrSubstanceInvolved)
            setDidEmployeeDie(selectedIncident.DidEmployeeDie)
            setDateOfDeath(selectedIncident.DateOfDeath)
            setCaseNumber(selectedIncident.CaseNumber)
        } else {
            setIncident(null)
            setSelectedEmployee(null)
            setEmployeeName('')
            setIncidentType('')
            setIncidentTypeLabel(null)
            setIncidentDate(null)
            setIncidentTime(null)
            setActivityBeforeIncident('')
            setDescription('')
            setObjectOrSubstanceInvolved('')
            setDidEmployeeDie(null)
            setDateOfDeath(null)
            setCaseNumber('')
        }
    }, [selectedIncident])


    function clear() {
        setIncident(null)
        setSelectedEmployee(null)
        setEmployeeName('')
        setIncidentType('')
        setIncidentTypeLabel(null)
        setIncidentDate(null)
        setIncidentTime(null)
        setActivityBeforeIncident('')
        setDescription('')
        setObjectOrSubstanceInvolved('')
        setDidEmployeeDie(null)
        setDateOfDeath(null)
        setCaseNumber('')
    }

    return (
        <>
            <Overlay show_loading={showLoading} zIndex={100003} />
            <div className="modal fade form_modal" id={ID} tabIndex={-1} role="dialog"
                aria-labelledby="result_modal_label" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-xl" role="document">
                    <div className="modal-content">
                        <div className="container-fluid">
                            <div className="modal-header">
                                <h5 className="modal-title" id="result_modal_label">Incident</h5>
                                <button style={{ outline: 'none' }} type="button" className="close"
                                    aria-label="Close"
                                    onClick={() => {
                                        clear()
                                        hideModal("incidentsmodal")
                                    }}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">

            {/* Name First Middle Initial Last */}
            {/* TODO If Create New, display select with FakeEmployee options */}
                                {selectedIncident && !selectedIncident.ID && buildRow("Employee Name",
                                    <Select
                                        isSearchable={true}
                                        placeholder={"Please Select..."}
                                        onChange={(e) => {
                                            let selectedEmployee = employees.find(emp => emp.ID === e.value)
                                            setSelectedEmployee(selectedEmployee);
                                        }}
                                        value={selectedEmployee ? {label: selectedEmployee.DisplayName, value: selectedEmployee.ID} : null}
                                        className={"state_select"}
                                        options={employees.map(s => {return {label: s.DisplayName, value: s.ID}})}
                                    />
                                    , 'Employee Name')}
                                {selectedIncident && selectedIncident.ID && buildRow("Employee Name",
                                    <input className={"form-control"}
                                        id="EmployeeName"
                                        maxLength={500}
                                        autoComplete={"off"}
                                        type={"search"}
                                        name={"EmployeeName"}
                                        aria-label="Employee Name"
                                        readOnly
                                        value={employeeName}
                                    />
                                    , 'Employee Name')}
                                {buildRow("Case Number",
                                    <input className={"form-control"}
                                        id="Case Number"
                                        placeholder={"Case Number..."}
                                        maxLength={500}
                                        autoComplete={"off"}
                                        type={"search"}
                                        name={"CaseNumber"}
                                        aria-label="CaseNumber"
                                        onChange={(e) => {
                                            setCaseNumber(e.target.value)
                                        }}
                                        value={caseNumber ? caseNumber : ''}
                                    />
                                    , 'Case Number')
                                }
                                {buildRow("Incident Type",
                                    <Select
                                        isSearchable={true}
                                        placeholder={"Select Incident Type..."}
                                        noOptionsMessage={() => "No option"}
                                        value={getLabel(incidentType, IncidentTypeOptions)}
                                        onChange={(e) => {
                                            setIncidentType(e.value)
                                            setIncidentTypeLabel({ label: e.value, value: e.value })
                                        }}
                                        className={"state_select"}
                                        options={IncidentTypeOptions}
                                    />
                                    , 'Incident Type')}
            {/* date of incident Month/Day/Year */}
                                {buildRow("Incident Date",
                                    <DatePicker
                                        id={'IncidentDatePicker'}
                                        minDate={new Date('01-01-1970')}
                                        maxDate={new Date('12-31-2100')}
                                        placeholderText="--/--/----"
                                        selected={incidentDate? 
                                            Date.parse(moment(new Date(incidentDate), 'MM-DD-YYYY').toISOString()) : 
                                            null
                                        }
                                        onChange={(e)=>{ 
                                            setIncidentDate(new Date(e))
                                        }}
                                        onBlur={(e)=>{}}
                                        ariaLabelledBy={'IncidentDatePicker'}
                                    />
                                )}
            {/* time of incident */}
                                {buildRow("Time of Incident",
                                    <input
                                        type="time"
                                        name={"Time of Event"}
                                        onChange={(e) => {
                                            setIncidentTime(e.target.value)
                                        }}
                                        value={incidentTime ? incidentTime : null}
                                    />
                                    , 'Time of Incident'
                                )}
            {/* activity before incident */}
                                {buildRow("Activity Before Incident",
                                    <input className={"form-control"}
                                        id="Description"
                                        placeholder={"Activity Before Incident..."}
                                        maxLength={500}
                                        autoComplete={"off"}
                                        type={"search"}
                                        name={"ActivityBeforeIncident"}
                                        aria-label="ActiityBeforeIncident"
                                        onChange={(e) => {
                                            setActivityBeforeIncident(e.target.value)
                                        }}
                                        value={activityBeforeIncident ? activityBeforeIncident : ''}
                                    />
                                    , 'Activity Before Incident')
                                }
            {/* description */}
                                {buildRow("Incident Description",
                                    <input className={"form-control"}
                                        id="Description"
                                        placeholder={"Describe Incident..."}
                                        maxLength={500}
                                        autoComplete={"off"}
                                        type={"search"}
                                        name={"Description"}
                                        aria-label="Description"
                                        onChange={(e) => {
                                            setDescription(e.target.value)
                                        }}
                                        value={description ? description: ''}
                                    />
                                    , 'Description')
                                }
            {/* object or substance involved */}
                                {buildRow("Object or Substance Involved",
                                    <input className={"form-control"}
                                        id="ObjectOrSubstanceInvolved"
                                        placeholder={"Object or Substance Involved..."}
                                        maxLength={500}
                                        autoComplete={"off"}
                                        type={"search"}
                                        name={"ObjectOrSubstance"}
                                        aria-label="ObjectOrSubstance"
                                        onChange={(e) => {
                                            setObjectOrSubstanceInvolved(e.target.value)
                                        }}
                                        value={objectOrSubstanceInvolved ? objectOrSubstanceInvolved : ''}
                                    />
                                    , 'Activity Before Incident')
                                }
            {/* did employee die? */}
                                {buildRow("Did Employee Die?",
                                    <Select
                                        isSearchable={true}
                                        placeholder={"Please Select..."}
                                        noOptionsMessage={() => "No option"}
                                        value={ didEmployeeDie !== null ? getBoolSelectVal(didEmployeeDie) : null}
                                        onChange={(e) => setDidEmployeeDie(e.value) }
                                        className={"state_select"}
                                        options={[{ label: "Yes", value: true }, { label: "No", value: false }]}
                                    />)
                                }
            {/* date of death */}
                                {buildRow("Date of Death",
                                    <DatePicker
                                        id={'DateOfDeath'}
                                        minDate={new Date('01-01-1970')}
                                        maxDate={new Date('12-31-2100')}
                                        placeholderText="--/--/----"
                                        selected={dateOfDeath? 
                                            Date.parse(moment(new Date(dateOfDeath), 'MM-DD-YYYY').toISOString()) : 
                                            null
                                        }
                                        onChange={(e)=>{ 
                                            setDateOfDeath(new Date(e))
                                        }}
                                        onBlur={(e)=>{}}
                                        ariaLabelledBy={'DateOfDeathDatePicker'}
                                    />
                                )}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn immySubmitButtonOutline"
                                    style={{ float: 'right' }}
                                    onClick={async () => {
                                        //TODO - unfinished validations 

                                        let edited = {
                                            EmployeeName: employeeName,
                                            IncidentType: incidentType,
                                            IncidentDate: incidentDate,
                                            Description: description,
                                        }

                                        // Faking API call here
                                        setShowLoading(true)
                                        sweetalert.fire({icon: 'success',title: '', text: 'Saved'})
                                        setShowLoading(false);
                                        hideModal("incidentsmodal")
                                        clear()
                                        requery()
                                    }}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}