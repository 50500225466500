import React from "react";
import {FilterPart} from "../FilterCard";
import {sweetalert} from "../../App";
import {CustomFieldBuilder} from "../../customfields/CustomFieldBuilder";
import { useHistory, Link } from "react-router-dom";
import Validator from "../../validation/Validator";
import {Validators} from "../../validation/Validators";
import {calculateAge, isBlank, isOnlyWhitespace} from "../../util/ValidationUtil";
import Overlay from "../Overlay";
import { hideModal, buildRow, parseDate, isEighteenOrOlder, showModalNoOutsideClick, getSelectLabels, getlabelsFromIDs, getLabel } from "../../util/FormatUtil";
import InBetweenOverlay from "../InBetweenOverlay";
import Select from "react-select";
import GetField from "../../customfields/GetField";
import { isEmptyObject } from "jquery";
import { BsFillRecord2Fill } from "react-icons/bs";
import { HintModal } from "./HintModal";
import { FaInfoCircle } from "react-icons/fa";
import PhoneInput from 'react-phone-number-input'
import Employee from "../../types/Employee";





interface EmployeeInfoModalProps{
    submit:(e) => void,
    attachClearStateFunc?:(func) => void
    attachClearStateFuncGuardian?:(func) => void
    states:any
    gender:any
    ethnicity:any
    race:any
    countries:any
    facilities?
    employees?
    fromWalkin?
    fromEmployeeMgmt?
    employeeFields?
    selectedEmployee?
}

interface EmployeeInfoModalState{
    showLoading:boolean
    showInBetween:boolean
    employeeInfo
    selectedEmployee?
    answeredQuestions?:any
    answeredQuestionsForGetField?
    hintHeader?;
    hintContent?;
}
export default class EmployeeInfoModal extends React.Component<EmployeeInfoModalProps, EmployeeInfoModalState>{
    public static ID = "employeeinfo";
    constructor(props) {
        super(props);
        this.state ={
            showLoading: false,
            showInBetween: false,
            selectedEmployee: null,
            employeeInfo: {},
            hintHeader: "Employee Information Hints",
            hintContent: [
                <div style={{fontSize: '1.5rem', marginBottom: '1rem'}}><BsFillRecord2Fill color={"#21212E"} size={25} /> Readonly employee information can be updated via Employee Management.</div>
            ]
        } as any;
    }
    
    
    
    componentWillReceiveProps(nextProps: Readonly<EmployeeInfoModalProps>, nextContext: any) {
        if(!isEmptyObject(nextProps.selectedEmployee) && nextProps.fromEmployeeMgmt){
            this.updateRows(nextProps.selectedEmployee)
        } 
    }

    handleSave(){
        this.setState({showLoading: true}) 

        let newAnswerQuestionsObj = {}

        if(!isEmptyObject(this.state.employeeInfo)){
            let answeredQuestionsArrays = Object.entries(this.state.employeeInfo.CustomFieldData)
            answeredQuestionsArrays.map(a => {
                return newAnswerQuestionsObj[a[0]] = a[1]
            })
        }

        let allCustomQuestions = this.props.employeeFields ? JSON.parse(JSON.stringify(this.props.employeeFields)) : [];

        let tempAnsweredQuestions = {}
        if(allCustomQuestions?.length > 0){
            //remove questions that dont belong to this service
            allCustomQuestions.map((cq, i) => {
                if(!isEmptyObject(newAnswerQuestionsObj)){
                    for(const [key, value] of Object.entries(newAnswerQuestionsObj)){
                        if(cq.DisplayName === key){
                            tempAnsweredQuestions[key] = value
                            break
                        }
                    }
                }
            })

            //validate questions
            if(isEmptyObject(tempAnsweredQuestions)){
                this.setState({showLoading: false})
                return sweetalert.fire({icon: 'error', title: '', text: `Employee information must be complete`})
            }

            let employeeSelected = false;
            if(this.state.selectedEmployee && !this.props.fromEmployeeMgmt){
                employeeSelected = true
            }

            let invalidCF = CustomFieldBuilder.validateCustomFields(tempAnsweredQuestions, allCustomQuestions, null, employeeSelected)
            if(invalidCF){
                this.setState({showLoading: false})
                return
            }
        }

        this.props.submit({employeeInfo: this.state.employeeInfo})

        hideModal(EmployeeInfoModal.ID)
        this.setState({showLoading: false})
    }

    clearForWalkin = () => {

        this.setState({
            employeeInfo: {
            }, 
            selectedEmployee: null
        })
    }


    async updateRows(e){
        if(!e){
            this.setState({employeeInfo: {}, selectedEmployee: null})
            return;
        }
        this.setState({selectedEmployee: e, employeeInfo: {}})
        let employeeInfo = e ? await this.props.employees.find(p => p.ID === e.value) : null;
        let tempObj = {};

        // Define a mapping configuration object
        const fieldMapping = {
            UID: 'UID',
            FirstName: 'First Name',
            LastName: 'Last Name',
            DateOfBirth: 'Date of Birth',
            MiddleName: 'Middle Name',
            Email: 'Email',
            StreetAddress: 'Address',
            City: 'City',
            State: 'State',
            Zipcode: 'Zipcode',
            Country: 'Country',
            County: 'County',
            EthnicityID: 'Ethnicity', 
            GenderID: 'Gender',
            RaceID: 'Race',
            PhoneNumber: 'Phone',
            Title: 'Title',
            HireDate: 'Hire Date',
        } as Employee;


        try {
            if(employeeInfo){
            for (const key of Object.keys(employeeInfo)) {
                if(this.props.fromEmployeeMgmt && key === 'FacilityIDs'){
                    tempObj[key] =  JSON.parse(employeeInfo[key])
                }
                if (key === 'FirstName' || key === 'LastName' || key === 'Title' || key === 'HireDate' || key === 'UID' || key === 'DateOfBirth') { 
                    tempObj[fieldMapping[key]] = employeeInfo[key];
                    continue;
                }
                if(key !== 'FirstName' && key !== 'LastName' && key !== 'DateOfBirth' && key !== 'Title' && key !== 'HireDate' && key !== 'UID'){
                    let matchingElement = this.props.employeeFields.find(f => f.DisplayName === fieldMapping[key]);
                    if(matchingElement && !isEmptyObject(matchingElement) && matchingElement.Options.isActive){
                        if(key === 'EthnicityID'){
                            tempObj[fieldMapping[key]] = employeeInfo[key] ? this.props.ethnicity.find(f => f.value === employeeInfo[key]).label : null;
                            continue;
                        }
                        if(key === 'RaceID'){
                            tempObj[fieldMapping[key]] = employeeInfo[key] ? this.props.race.find(f => f.value === employeeInfo[key]).label : null;
                            continue;
                        }
                        if(key === 'GenderID'){
                            tempObj[fieldMapping[key]] = employeeInfo[key] ? this.props.gender.find(f => f.value === employeeInfo[key]).label : null;
                            continue;
                        }
                        if(key === 'State'){
                            tempObj[fieldMapping[key]] = employeeInfo[key] ? this.props.states.find(f => f.value === employeeInfo[key]).label : null;
                            continue;
                        }
                        if(key === 'Country'){
                            tempObj[fieldMapping[key]] = employeeInfo[key] ? this.props.countries.find(f => f.value === employeeInfo[key]).label : null;
                            continue;
                        }
                        tempObj[fieldMapping[key]] = employeeInfo[key];
    
                    }
                }
            }
            if(employeeInfo.CustomFieldData && !isEmptyObject(JSON.parse(employeeInfo.CustomFieldData))){
                let parsedCustomFieldData = JSON.parse(employeeInfo.CustomFieldData)
                for (const key of Object.keys(parsedCustomFieldData)) {
                    tempObj[key] = parsedCustomFieldData[key]
                }
            }
        }
        } catch (error) {
            console.error(error)
            return sweetalert.fire({icon: 'error', title: '', text: 'Could not update rows at this time'})
        }
        
        this.setState((prevState) => {
            const updatedEmployeeInfo = { ...prevState.employeeInfo };
            if(isEmptyObject(updatedEmployeeInfo)){
                updatedEmployeeInfo.CustomFieldData = {};
            }
            for (const key in tempObj) {
                if (tempObj.hasOwnProperty(key)) {
                    updatedEmployeeInfo.CustomFieldData[key] = tempObj[key];
                }
            }
            return {
                employeeInfo: updatedEmployeeInfo,
            };
        })
    }

    render() {

        // console.log('EmployeeInfoModal state', this.state)
            return  (
                <React.Fragment>
                    <Overlay show_loading={this.state.showLoading} zIndex={100005}/>
                    <InBetweenOverlay showInBetween={this.state.showInBetween} zIndex={100003} />
                    <HintModal
                        zIndex={100006}
                        handleInBetweenOverlay={() => this.setState({showInBetween: false})}
                        header={this.state.hintHeader}
                        content={this.state.hintContent}
                    />
                    <div className="modal fade form_modal" id={EmployeeInfoModal.ID} aria-modal={true} tabIndex={-1} role="dialog"
                        aria-label="Employee Information"
                        aria-hidden="true">
                        <div className="modal-dialog modal-lg modal-xl" role="document">
                            <div className="modal-content">
                                <div className="modal-body p-0 m-0">
                                    <div className="container-fluid">
                                        <div className={"row"}>
                                            <div className="col-12 pt-2">
                                                <div className="card mt-2">
                                                    <div className="card-header verlag-bold">
                                                        <div className="row">
                                                            <div className="col-10">
                                                                <h4>
                                                                    Employee Information
                                                                    {this.props.fromWalkin && <FaInfoCircle
                                                                        tabIndex={5}
                                                                        className={"ml-1"}
                                                                        size={25}
                                                                        color={"#21212E"}
                                                                        onClick={() => {showModalNoOutsideClick(HintModal.ID); this.setState({showInBetween: true})}}
                                                                    />}
                                                                </h4>
                                                            </div>
                                                            <div className="col-2">
                                                                <button style={{outline: 'none'}} type="button" className="close pr-4"
                                                                        aria-label="Close" onClick={() => hideModal(EmployeeInfoModal.ID)}>
                                                                    <span aria-hidden="true" style={{fontSize: '1.5em', fontWeight: 'bold'}}>&times;</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                    {this.props.fromEmployeeMgmt && this.state.selectedEmployee &&
                                                    <>
                                                        <div className="form-group row" data-toggle={'tooltip'} data-placement={'top'} title={'UID'}>
                                                            <label htmlFor={'UID'} className="col-sm-4 col-form-label">UID</label>
                                                            <div className="col-12 col-md-8 pl-0 pr-4" id={'UID'}>
                                                                <input className={"form-control"}
                                                                    maxLength={50}
                                                                    autoComplete={"off"}
                                                                    type={"search"}
                                                                    name={"UID"}
                                                                    readOnly={true}
                                                                    value={this.state.employeeInfo && this.state.employeeInfo.CustomFieldData && this.state.employeeInfo.CustomFieldData.UID}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row" data-toggle={'tooltip'} data-placement={'top'} title={'Facilities'}>
                                                            <label htmlFor={'Facilities'} className="col-sm-4 col-form-label">Facilities</label>
                                                            <div className="col-12 col-md-8 pl-0 pr-4" id={'Facilities'}>
                                                            <Select
                                                                isSearchable={true}
                                                                isMulti={true}
                                                                placeholder={"Please Select..."}
                                                                noOptionsMessage={() => "No option"}
                                                                value={ this.state.employeeInfo && this.state.employeeInfo.CustomFieldData && this.state.employeeInfo.CustomFieldData.FacilityIDs ? getlabelsFromIDs(this.state.employeeInfo.CustomFieldData.FacilityIDs, this.props.facilities) : [] }
                                                                onChange={(e) => {
                                                                    let updatedFacilityIDs = [];
                                                                    let employeeCurrentFacilityIDs = this.state.employeeInfo && this.state.employeeInfo.CustomFieldData && this.state.employeeInfo.CustomFieldData.FacilityIDs ? this.state.employeeInfo.CustomFieldData.FacilityIDs : null;
                                                                    let dropDownFacilityIDs = this.props.facilities.filter(f => employeeCurrentFacilityIDs.includes(f.ID));
                                                                    dropDownFacilityIDs = dropDownFacilityIDs.map(m => {return m.ID})

                                                                    if(e){
                                                                        if(e.length > dropDownFacilityIDs.length){
                                                                            employeeCurrentFacilityIDs.push(e[e.length-1].value)
                                                                            updatedFacilityIDs = updatedFacilityIDs.concat(employeeCurrentFacilityIDs)
                                                                        }
                                                                        else{
                                                                            for (let i = 0; i < e.length; i++) {
                                                                                for (let j = 0; j < dropDownFacilityIDs.length; j++) {
                                                                                    if(e[i].value === dropDownFacilityIDs[j]){
                                                                                        dropDownFacilityIDs.splice(j, 1)
                                                                                    }                                                                                   
                                                                                }
                                                                            }
                                                                            for (let i = 0; i < employeeCurrentFacilityIDs.length; i++) {
                                                                                if(employeeCurrentFacilityIDs[i] === dropDownFacilityIDs[0]){
                                                                                    employeeCurrentFacilityIDs.splice(i, 1)
                                                                                }                                                                                
                                                                            }
                                                                            updatedFacilityIDs = employeeCurrentFacilityIDs;
                                                                        }
                                                                    }
                                                                    else{
                                                                        for (let i = 0; i < dropDownFacilityIDs.length; i++) {
                                                                            for (let j = 0; j < employeeCurrentFacilityIDs.length; j++) {
                                                                                if(dropDownFacilityIDs[i] === employeeCurrentFacilityIDs[j]){
                                                                                    employeeCurrentFacilityIDs.splice(j, 1)
                                                                                }                                                                                
                                                                            }                                                                            
                                                                        }
                                                                        updatedFacilityIDs = employeeCurrentFacilityIDs;
                                                                    }
                                                                    this.setState((prevState) => {
                                                                        const updatedemployeeInfo = { ...prevState.employeeInfo };
                                                                        if(isEmptyObject(updatedemployeeInfo)){
                                                                            updatedemployeeInfo.CustomFieldData = {};
                                                                        }
                                                                        updatedemployeeInfo.CustomFieldData.FacilityIDs = updatedFacilityIDs;
                                                                                return {
                                                                            employeeInfo: updatedemployeeInfo,
                                                                        };
                                                                    })
                                                                }}
                                                                className={"state_select"}
                                                                options={getSelectLabels(this.props.facilities)}
                                                            />
                                                        </div>
                                                    </div>
                                                    </>
                                                    }
                                                        {this.props.fromWalkin && 
                                                        <div className="form-group row" data-toggle={'tooltip'} data-placement={'top'} title={'Search for existing employee'}>
                                                            <label htmlFor={'employeeSearch'} className="col-sm-4 col-form-label">{'Employee Search'}</label>
                                                            <div className="col-12 col-md-8 pl-0 pr-4" id={'employeeSearch'}>
                                                                <Select
                                                                    id="employeeSearch"
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    placeholder={<div className="accessibilityText">Please Select...</div>}
                                                                    className={"state_select"}
                                                                    name={"employeeSearch"}
                                                                    aria-label="employee Search"
                                                                    onChange={(e)=>{
                                                                        this.updateRows(e)
                                                                    }}
                                                                    value={this.state.selectedEmployee}
                                                                    options={this.props.employees?.map(p => {return {label: `${p.FirstName} ${p.LastName} - ${parseDate(p.DateOfBirth)} - ${p.UID}`, value: p.ID}})}
                                                                />
                                                                    {/* <div className={this.state.selectedemployee ? "row" : "d-none"}>
                                                                        <div className="col-12 pr-1 mb-2 m-0 text-danger verlag-bold" style={{fontSize: '1rem'}} >*if employee information has changed, please edit the profile on the employee Management page.</div>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        }                                                        
                                                        <div className="row">
                                                            {this.props.employeeFields && this.props.employeeFields.length > 0 && this.props.employeeFields.map((val, index) => {
                                                                if (val.Options.isActive) {
                                                                    return (
                                                                    <div className="col-12">
                                                                        <div className="form-group row">
                                                                        <GetField fromEmployeeMgmt={this.props.fromEmployeeMgmt} employeeSearchReadOnly={this.state.selectedEmployee ? true : false} fromSamples={true} val={val} selectedItem={ this.state.employeeInfo ? this.state.employeeInfo : {} } handleChange={(e) => { this.setState({ employeeInfo: e, }); }} />
                                                                        </div>
                                                                    </div>
                                                                    );
                                                                }
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer" style={{backgroundColor: 'transparent', borderTop: 'none'}}>
                                            <button className={"btn btn-primary float-right mb-3"} onClick={() => this.handleSave()}>Save</button>
                                         </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                
                </React.Fragment>
            )
        }

}