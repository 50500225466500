import React from "react";
import {
    getFieldTypeLabel,
    hideModal,
    showModalNoOutsideClick,
    getBoolSelectYN,
    getLabel,
    getNameFromID
} from "../../util/FormatUtil";
import { CustomFieldsModal } from "./CustomFieldsModal";
import InBetweenOverlay from "../InBetweenOverlay";
import Overlay from "../Overlay";
import CustomFieldsAPI from "../../network/CustomFieldsAPI";
import { sweetalert } from "../../App";
import {BiMoveVertical} from "react-icons/bi";

interface QuestionnaireState{
    selectedQuestionnaire?
    customFieldData?
    showInBetween
    questions
    showLoading
    showSaveOrderButton
    mouseGrabbing
}
interface QuestionnaireProps{
    selectedQuestionnaire?
    pageCustomQuestions?
    handleInBetween?
    selectedServiceQuestions?
    validPages?
    fieldTypes?
    selectedService?
    isQuestionnaire
    serviceTypes?
}
export class QuestionnaireModal extends React.Component<QuestionnaireProps, QuestionnaireState>{
    public dragItemRef;
    public dragOverItemRef;
    public static ID = "questionnaire";
    
    constructor(props) {
        super(props);
        
        this.state = {
          showSaveOrderButton: false,
          showLoading: false,
          showInBetween: false,
          questions: [],
          mouseGrabbing: false
        }

        this.dragItemRef = React.createRef();
        this.dragOverItemRef = React.createRef();
    }

    componentDidMount(): void {
      $(`#${QuestionnaireModal.ID}`).on('shown.bs.modal', function() {
        $(document).off('focusin.modal');
    });
    }

    componentWillReceiveProps(nextProps:any, nextContext: any) {
      // console.log('QUESTIONNAIRE nextProps', nextProps)
      // sort by service type and then order
      let initiallySortedQuestions = this.initialServiceTypeThenOrderSort(nextProps.selectedServiceQuestions ? nextProps.selectedServiceQuestions : [])

        this.setState({questions: initiallySortedQuestions})
    }

    initialServiceTypeThenOrderSort(questions){
      return questions.sort((a, b) => {
        // Compare by serviceType
        if (a.Options.serviceType < b.Options.serviceType) return -1;
        if (a.Options.serviceType > b.Options.serviceType) return 1;
    
        // If serviceType is the same, compare by Order
        if (a.Order < b.Order) return -1;
        if (a.Order > b.Order) return 1;
    
        return 0;
      });
    }

    handleSort = () => {
      //duplicate items
      let _questions = [...this.state.questions];

      //remove and save the dragged item content
      const draggedItemContent = _questions.splice(this.dragItemRef.current, 1)[0]

      //switch the position
      _questions.splice(this.dragOverItemRef.current, 0, draggedItemContent)

      //reset the position ref         
      this.dragItemRef.current = null
      this.dragOverItemRef.current = null

      //map through questions to get new order
      _questions.map((q, index) => {
          q.Order = index + 1
      })

      //update the state
      this.setState({questions: _questions, showSaveOrderButton: true})
    }

    saveOrder = () => {
      this.setState({showLoading: true}, async() => {
        let response = await CustomFieldsAPI.updateSortOrder(this.state.questions)
        if(!response.success){
          let msg = `Unable to update sort order at this time.`;
          if(response.reason){
            msg = response.reason;
          }
          this.setState({showLoading: false})
          return sweetalert.fire({icon: 'error', title: "", text: msg});
        }
        sweetalert.fire({icon: "success", title: "", text: `Sort order saved`})
          .then(() => {
            this.setState({showLoading: false, showSaveOrderButton: false})
            this.props.handleInBetween()
            hideModal(QuestionnaireModal.ID)
          })
      })
    }
   
    render(){
        // console.log('QUESTIONNAIRE MODAL STATE', this.state)

        return(
            <>
            <Overlay show_loading={this.state.showLoading} zIndex={100005}/>
            <InBetweenOverlay showInBetween={this.state.showInBetween} zIndex={100004} />
            <CustomFieldsModal
                isQuestionnaire={this.props.isQuestionnaire}
                fieldTypes={this.props.fieldTypes}
                validPages={this.props.validPages}
                customQuestions={this.state.questions}
                selectedCustomField={this.state.selectedQuestionnaire}
                selectedService={this.props.selectedService}
                handleInBetweenOnQuestionnaire={() => this.setState({showInBetween: false})}
                onSubmitQuestionnaire={() => {
                    this.setState({showInBetween: false});
                    hideModal(CustomFieldsModal.ID);
                    hideModal(QuestionnaireModal.ID);
                    this.props.handleInBetween()
                }}
                serviceTypes={this.props.serviceTypes}
            />
            <div className="modal fade form_modal " id={QuestionnaireModal.ID} tabIndex={-1} style={{zIndex: 100003}} role="dialog"
                 aria-labelledby="result_modal_label"
                 aria-hidden="true">
                <div className="modal-dialog modal-lg modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                              <h5 className="modal-title" id="result_modal_label">Questionnaire Management {this.props.selectedService ? `for ${this.props.selectedService.Name}` : ''}</h5>
                              <button  style={{outline: 'none'}} type="button" className="close" data-dismiss="modal" aria-label="Close" 
                              onClick={() => {
                                this.setState({showSaveOrderButton: false})
                                this.props.handleInBetween()
                              }}>
                                  <span aria-hidden="true">&times;</span>
                              </button>                                                             
                        </div>
                        <div className="modal-body m-3 ">

                          {/* mobile  */}
                          {this.state.questions.length > 0 ?
                          <div className="d-md-none">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope='col' style={{width: '18%'}}>Display Name</th>
                              <th scope='col' style={{width: '18%'}}>Service Type</th>
                              <th scope='col' style={{width: '18%'}}>Field Type</th>                              
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.questions.length > 0 && this.state.questions.map((question, index) => {
                              return (
                                <tr>
                                  <td scope='row'>{question.DisplayName}</td>
                                  <td scope='row'>{getNameFromID(question.Options.serviceType, this.props.serviceTypes)}</td>
                                  <td scope='row'>{getFieldTypeLabel(question.FieldTypeID)}</td>
                                </tr>
                            )})}
                            
                          </tbody>
                        </table>
                        </div>
                        : 
                        <div className="d-md-none text-center text-bold" style={{fontSize: '1.5rem'}}>No questions to display</div>

                        }


                         {/* tablet  */}
                         {this.state.questions.length > 0 ?
                          <div className="d-none d-md-block d-lg-none">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope='col' style={{width: '18%'}}>Display Name</th>
                              <th scope='col' style={{width: '18%'}}>Service Type</th>
                              <th scope='col' style={{width: '18%'}}>Field Type</th>   
                              <th scope='col' style={{width: '18%'}}>Active</th>
                              <th scope='col' style={{width: '18%'}}>Required?</th>                           
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.questions.length > 0 && this.state.questions.map((question, index) => {
                              return (
                                <tr>
                                  <td scope='row'>{question.DisplayName}</td>
                                  <td scope='row'>{getNameFromID(question.Options.serviceType, this.props.serviceTypes)}</td>
                                  <td scope='row'>{getFieldTypeLabel(question.FieldTypeID)}</td>
                                  <td scope='row'>{getBoolSelectYN(question.Options.isActive)}</td>
                                  <td scope='row'>{getBoolSelectYN(question.Options.isRequired)}</td>
                                </tr>
                            )})}
                            
                          </tbody>
                        </table>
                        </div>
                        : 
                        <div className="d-none d-md-block d-lg-none text-center text-bold" style={{fontSize: '1.5rem'}}>No questions to display</div>

                        }



                          {/* desktop  */}

                        {this.state.questions.length > 0 ?
                        <div className="d-none d-lg-block">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope='col' style={{width: '10%'}}>Sort</th>
                              <th scope='col' style={{width: '18%'}}>Display Name</th>         
                              <th scope='col' style={{width: '18%'}}>Service Type</th>
                              <th scope='col' style={{width: '18%'}}>Field Type</th>
                              <th scope='col' style={{width: '18%'}}>Required?</th>
                              <th scope='col' style={{width: '18%'}}>PHI?</th>
                              <th scope='col' style={{width: '18%'}}>Active</th>
                              
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.questions.length > 0 && this.state.questions.map((question, index) => {
                              return (
                              <tr 
                                key={index} 
                                draggable 
                                onDragStart={(e) => this.dragItemRef.current = index}
                                onDragEnter={(e) => this.dragOverItemRef.current = index}
                                onDragEnd={this.handleSort}
                                onDragOver={(e) => e.preventDefault()}
                              >
                                <td><BiMoveVertical /></td>
                                <td>
                                  <a href='#' onClick={() => {
                                      if(this.state.showSaveOrderButton){
                                        return sweetalert.fire({icon: 'warning', title: '', text: 'Please save sort order'})
                                      } 
                                      else{
                                        this.setState({showInBetween: true, selectedQuestionnaire: question },
                                          () => showModalNoOutsideClick(CustomFieldsModal.ID))
                                      }
                                  }}>
                                    {question.DisplayName}
                                  </a>
                                </td>
                                <td>{getNameFromID(question.Options.serviceType, this.props.serviceTypes)}</td>
                                <td>{getFieldTypeLabel(question.FieldTypeID)}</td>
                                <td>{getBoolSelectYN(question.Options.isRequired)}</td>
                                <td>{getBoolSelectYN(question.Options.isPHI)}</td>
                                <td>{getBoolSelectYN(question.Options.isActive)}</td>
                              </tr>
                            )})}
                            
                          </tbody>
                        </table>
                        </div>
                        : 
                        <div className="d-none d-lg-block text-center text-bold" style={{fontSize: '1.5rem'}}>No questions to display</div>
                        }

                        </div>
                        <div className="modal-footer">
                        <button type="button" className={this.state.showSaveOrderButton ? "d-none" : "btn btn-success mr-auto"} 
                          onClick={() => {
                            if(this.state.showSaveOrderButton){
                              return sweetalert.fire({icon: 'warning', title: '', text: 'Please save sort order'})
                            } 
                            else{
                              this.setState({showInBetween: true, selectedQuestionnaire: null})
                              showModalNoOutsideClick(CustomFieldsModal.ID)
                            }
                          }}
                          >Add Question
                          </button>
                          <button type="button" className={this.state.showSaveOrderButton ? "btn btn-primary" : "invisible" } onClick={this.saveOrder}>
                                Save Order
                          </button>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}