import NetworkUtil from "./NetworkUtil";
import { Sample } from "../types/Sample";
import { ServerResponse } from "../types/ServerResponse";

export default class SamplesAPI {
  public static async getFilteredSamples(
    page,
    filter
  ): Promise<{
    num_pages: number;
    totalSamples: number;
    table_data?: Sample[];
  }> {
    return NetworkUtil.makePost("/api/admin/sampleSearch?page=" + page, filter);
  }

  public static async get6PlexPDFReport(id): Promise<any> {
    return NetworkUtil.makePostForBuffer(
      "/api/admin/pdfReport/get6PlexPDFReport",
      { id: id }
    );
  }

  public static async getFilteredSamplesForCheckin(
    page,
    filter
  ): Promise<{
    num_pages: number;
    totalSamples: number;
    table_data?: Sample[];
  }> {
    return NetworkUtil.makePost(
      "/api/admin/sampleSearchForCheckin?page=" + page,
      filter
    );
  }

  public static async getAllSamplesForAdminHome(): Promise<{
    facilitySamplesArray;
  }> {
    return NetworkUtil.makeGet("/api/admin/getAllSamplesForAdminHome");
  }

  public static async updateSample(
    sample,
    insuranceInformation,
    insuranceCards,
    submissionFiles?
  ): Promise<ServerResponse> {
    let data = new FormData();
    data.set("insuranceInformation", JSON.stringify(insuranceInformation));
    data.set("employeeInformation", JSON.stringify(sample));
    if (insuranceCards) {
      for (let i = 0; i < insuranceCards.length; i++) {
        let ext = insuranceCards[i].type.split("/").pop();
        data.append(
          "insuranceCards",
          insuranceCards[i],
          `${i === 0 ? "front" : "back"}.${ext}`
        );
      }
    }
    if (submissionFiles) {
      for (let i = 0; i < submissionFiles.length; i++) {
        data.append("submissionFiles", submissionFiles[i]);
      }
    }
    return NetworkUtil.postForm("/api/admin/sampleEdit", data);
  }

  public static async updateResultsAccessedDate(id): Promise<ServerResponse> {
    return NetworkUtil.makePost("/api/admin/sampleResultsAccessedDate", {
      ID: id,
    });
  }

  public static editSample(
    editBody: SampleCheckinBody
  ): Promise<ServerResponse> {
    return NetworkUtil.makePost("/api/admin/sample/checkin", editBody);
  }

  public static getInsuranceCardImagesFromCloud(data): Promise<any> {
    return NetworkUtil.makePostForBlob(
      "/api/admin/getInsuranceCardImagesFromCloud",
      data
    );
  }

  public static downloadFilesFromCloud(data): Promise<any> {
    return NetworkUtil.makePostForBlob(
      "/api/admin/downloadFilesFromCloud",
      data
    );
  }

  public static getSubmittedFilesFromCloud(
    reqNum
  ): Promise<{ success: boolean; reason?: string; data? }> {
    return NetworkUtil.makePost("/api/admin/getSubmittedFilesFromCloud", {
      ReqNum: reqNum,
    });
  }

  public static async resultsReviewedSendEmployeeEmail(
    id
  ): Promise<ServerResponse> {
    return NetworkUtil.makePost("/api/admin/resultsReviewedSendEmployeeEmail", {
      ID: id,
    });
  }

  public static async generateExcelDocBasedOnService(serviceID): Promise<any> {
    return NetworkUtil.makePostForBlob(
      "/api/admin/generateExcelDocBasedOnService",
      { serviceID: serviceID }
    );
  }
}

export interface SampleCheckinBody {
  ID?;
  EmployeeFirstName: string;
  EmployeeLastName: string;
  EmployeeDOB;
  ServiceID;
  TestIDs;
  SpecimenSourceID;
  SpecimenID?;
  Lot?;
  HasLabTest?;
  SpecimenIDsArray?;
  LotsArray?;
  VaccineIDs?;
  EvaluationIDs?;
  NurseFirstName?;
  NurseLastName?;
  NurseLicenseNum?;
  ExpirationDate?;
  InjectionSite?;
}
