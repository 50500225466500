import React from "react";
import Select from "react-select";
import {sweetalert} from "../../App";
import {isBlank, isOnlyWhitespace} from "../../util/ValidationUtil";
import {
    buildRow,
    getBoolSelectOptions,
    getFieldTypeVal,
    getLabel,
    getlabelsFromIDs,
    getValidPageValues,
    showModalNoOutsideClick
} from "../../util/FormatUtil";
import {isEmptyObject} from "jquery";
import {CustomFields, CustomFieldType, FieldOptions} from "../../types/CustomFieldType";
import InBetweenOverlay from "../InBetweenOverlay";
import {FaInfoCircle} from "react-icons/fa";
import { BsFillRecord2Fill } from "react-icons/bs";
import Overlay from "../Overlay";
import CustomFieldsAPI from "../../network/CustomFieldsAPI";
import { HintModal } from "./HintModal";



interface CustomFieldsState{
    selectedCustomField:CustomFieldType
    isRequiredLabel?
    isActiveLabel?
    isMultiLabel?
    isBooleanLabel?
    isNumericLabel?
    allowFutureDateLabel?
    serviceTypeLabel?
    wordToAdd?
    availableOptions?
    showInBetween:boolean
    showLoading:boolean
    minNumericRangeValue?
    maxNumericRangeValue?
    hintHeader?
    hintContent?
    valueToAdd?
}
interface CustomFieldsProps{
    selectedCustomField
    onSubmitCustomField?
    onSubmitQuestionnaire?
    onSubmitEmployeeFields?
    validPages
    fieldTypes
    isCustomField?
    isQuestionnaire?
    selectedService?
    handleInBetweenOnQuestionnaire?
    customQuestions?
    fromEmployee?
    serviceTypes?
}
export class CustomFieldsModal extends React.Component<CustomFieldsProps, CustomFieldsState>{
    public static ID = "customFields";
    constructor(props) {
        super(props);
        this.state = {
            selectedCustomField: {
                ID: null,
                DisplayName: "",
                FieldTypeID: null,
                ServiceID: null,
                IsEmployeeField: this.props.fromEmployee ? 1 : 0,
                Order: null,
                Options: {
                    validPages:  [],
                    popoverText:  '',
                    isActive: null,
                    options:  [],
                    isRequired: null,
                    isMulti: null,
                    isBoolean: null,
                    isNumeric: null,
                    maxLength: null,
                    min: null,
                    max: null,
                    allowFutureDate: null,
                    serviceType: null,
                }
            },
            availableOptions: [],
            isBooleanLabel:{label: '', value: ''},
            isNumericLabel:{label: '', value: ''},
            allowFutureDateLabel:{label: '', value: ''},
            serviceTypeLabel:{label: '', value: ''},
            showInBetween:false,
            showLoading: false,
            minNumericRangeValue: null,
            maxNumericRangeValue: null,
            valueToAdd: null,
            hintHeader: 'Custom Field Hints', hintContent: [
                <div style={{fontSize: '1.5rem', marginBottom: '1rem'}}><BsFillRecord2Fill color={"#21212E"} size={25} /> Field Type and Display Name and not editable.</div>,
                <div style={{fontSize: '1.5rem', marginBottom: '1rem'}}><BsFillRecord2Fill color={"#21212E"} size={25} /> Disable fields that are no longer needed.</div>,
                <div style={{fontSize: '1.5rem', marginBottom: '1rem'}}><BsFillRecord2Fill color={"#21212E"} size={25} /> If Field Type or Display Name is incorrect, disable field/question and create new one.</div>,
            ]
        }
    }



    getValidPagesLabel(searchArray){
        let temp = [];
        searchArray.map(m => {
            temp.push({label: m, value: m})
        })
        return temp;
    }

    clearState(){
        this.setState({
            selectedCustomField: {
                ID: null,
                DisplayName: "",
                FieldTypeID: null,
                ServiceID: null,
                IsEmployeeField: this.props.fromEmployee ? 1 :0,
                Order: null,
                Options: {
                    validPages:  [],
                    popoverText:  '',
                    isActive: null,
                    options:  [],
                    isRequired: null,
                    isMulti: null,
                    isBoolean: null,
                    isNumeric: null,
                    maxLength: null,
                    min: null,
                    max: null,
                    allowFutureDate: null,
                    serviceType: null,
                }
            },
            availableOptions: [],
            showInBetween:false,
            minNumericRangeValue: null,
            maxNumericRangeValue: null,
        })
    }

    componentDidMount(): void {
        $(`#${CustomFieldsModal.ID}`).on('shown.bs.modal', function() {
            $(document).off('focusin.modal');
        });
    }


    componentWillReceiveProps(nextProps: Readonly<CustomFieldsProps>, nextContext: any) {
        // console.log('CustomFieldsModal componentWillReceiveProps', nextProps)
        
        if(!isEmptyObject(nextProps.selectedCustomField)){
            let customField = nextProps.selectedCustomField;

            let minNumericValue;
            let maxNumericValue;
            if(customField.Options.options && customField.Options.options.length > 0 && customField.Options.isNumeric){
                let temp = [];
                customField.Options.options.map(o => {
                    temp.push(o.value)
                })
                temp.sort((a,b)=> a-b)
                minNumericValue = temp[0]
                maxNumericValue = temp[temp.length-1]
            }
 


            this.setState({
                availableOptions: customField.Options.options ? customField.Options.options : [],
                isRequiredLabel: customField.Options ? getLabel(customField.Options.isRequired, getBoolSelectOptions()) : [],
                isActiveLabel: customField.Options ? getLabel(customField.Options.isActive, getBoolSelectOptions()) : [],
                isMultiLabel: customField.Options ? getLabel(customField.Options.isMulti, getBoolSelectOptions()) : [],
                isBooleanLabel: customField.Options ? getLabel(customField.Options.isBoolean, getBoolSelectOptions()) : [],
                isNumericLabel: customField.Options ? getLabel(customField.Options.isNumeric, getBoolSelectOptions()) : [],
                allowFutureDateLabel: customField.Options ? getLabel(customField.Options.allowFutureDate, getBoolSelectOptions()) : [],
                serviceTypeLabel: customField.Options ? getLabel(customField.Options.serviceType, this.props.serviceTypes) : [],
                minNumericRangeValue: customField.Options.isNumeric ? minNumericValue : null,
                maxNumericRangeValue: customField.Options.isNumeric ? maxNumericValue : null,
                selectedCustomField: {
                    ID: customField.ID,
                    DisplayName: customField.DisplayName ? customField.DisplayName : '',
                    FieldTypeID: customField.FieldTypeID ? customField.FieldTypeID : null,
                    ServiceID: customField.ServiceID ? customField.ServiceID : null,
                    IsEmployeeField: customField.IsEmployeeField || nextProps.fromEmployee ? 1 : 0,
                    Order: customField.Order ? customField.Order : null,
                    Options: customField.Options ? {
                        validPages: this.props.fromEmployee ? this.getValidPagesLabel(['Employee Management']) : this.props.isQuestionnaire ? this.getValidPagesLabel(['Submission Form', 'Create Appointment']) : this.props.isCustomField && customField.Options.validPages ? this.getValidPagesLabel(customField.Options.validPages) : [],                        
                        popoverText: customField.Options.popoverText ? customField.Options.popoverText : '',
                        isBoolean: customField.Options.isBoolean,
                        isNumeric: customField.Options.isNumeric,
                        isActive: customField.Options.isActive,
                        options:  customField.Options.options ? customField.Options.options : [],
                        isRequired: customField.Options.isRequired,
                        isMulti: customField.Options.isMulti,
                        maxLength: customField.Options.maxLength,
                        min: customField.Options.min,
                        max: customField.Options.max,
                        allowFutureDate: customField.Options.allowFutureDate,
                        serviceType: customField.Options.serviceType,
                    } as FieldOptions : {}
                } as CustomFieldType
            });
        }
        else{
            this.setState({
                availableOptions: [],
                isRequiredLabel: [],
                isActiveLabel: [],
                isMultiLabel: [],
                isBooleanLabel: [],
                isNumericLabel: [],
                allowFutureDateLabel: [],
                minNumericRangeValue: null,
                maxNumericRangeValue: null,
                selectedCustomField: {
                    ID: null,
                    DisplayName: '',
                    FieldTypeID: null,
                    ServiceID: this.props.isQuestionnaire && this.props.selectedCustomField ? this.props.selectedCustomField.ServiceID : null,
                    IsEmployeeField: this.props.fromEmployee ? 1 : 0,
                    Order: null,
                    Options:  {
                        validPages: this.props.fromEmployee ? this.getValidPagesLabel(['Employee Management']) : this.props.isQuestionnaire ? this.getValidPagesLabel(['Submission Form', 'Create Appointment']) : [],
                        popoverText:  '',
                        isActive: null,
                        options:  [],
                        isRequired: null,
                        isMulti: null,
                        isBoolean: null,
                        isNumeric: null,
                        maxLength: null,
                        min: null,
                        max: null,
                        allowFutureDate: null,
                        serviceType: null,
                    }
                }
            });
        }
    }

    sortOptions(options){
        let temp = [];
        options.map(o => {
            temp.push(o.value)
        })
        temp.sort((a,b)=> a-b)
        
    }
    
    createOrModifyCustomField(customField:CustomFieldType){
        // console.log('customField', customField)
        // console.log('this.props', this.props)

        let modifiedCustomField = {
            ID: customField.ID ? customField.ID : null,
            DisplayName: customField.DisplayName ? customField.DisplayName : '',
            FieldTypeID: customField.FieldTypeID ? customField.FieldTypeID : null,
            ServiceID: this.props.isQuestionnaire ? this.props.selectedService.ID : null,
            IsEmployeeField: this.props.fromEmployee ? 1 : 0,
            Order: (this.props.isQuestionnaire || this.props.fromEmployee) && (!this.props.selectedCustomField || !this.props.selectedCustomField.ID) ? this.props.customQuestions.length + 1 : customField.Order,
            Options: {
                validPages: customField.Options.validPages ? getValidPageValues(customField.Options.validPages) : [],
                popoverText: customField.Options.popoverText ? customField.Options.popoverText : '',
                isActive: customField.Options.isActive,
                options: customField.Options.options ? customField.Options.options : [],
                isRequired: customField.Options.isRequired,
                isMulti: customField.Options.isMulti,
                isBoolean: customField.Options.isBoolean,
                isNumeric: customField.Options.isNumeric, 
                maxLength: customField.Options.maxLength,
                min: customField.Options.min,
                max: customField.Options.max,
                allowFutureDate: customField.Options.allowFutureDate,
                serviceType: customField.Options.serviceType,
            } as FieldOptions
        } as CustomFieldType

        this.setState({showLoading:true}, async() => {
            if(customField.ID === null){
                let response = await CustomFieldsAPI.createCustomField(modifiedCustomField, this.props.fromEmployee)
                if(!response.success){
                    let msg = `Unable to create ${this.props.isCustomField ? 'Custom' : this.props.isQuestionnaire ? 'Questionnaire' : 'Employee'} Field at this time.`;
                    if(response.reason){
                        msg = response.reason
                    }
                    this.setState({showLoading: false})
                    return sweetalert.fire({icon: 'error', title: "", text: msg}).then(() => {
                        if(this.props.isCustomField){
                            this.props.onSubmitCustomField()
                        }
                        else if(this.props.isQuestionnaire){
                            this.props.onSubmitQuestionnaire()
                        }
                        else if(this.props.fromEmployee){
                            this.props.onSubmitEmployeeFields()
                        }
                    });
                }
                else{
                    sweetalert.fire({
                        icon: 'success',
                        title: `${this.props.isCustomField ? 'Custom' : this.props.isQuestionnaire ? 'Questionnaire' : 'Employee'} Field saved`,
                        html: `<div class='mt-2'><span style='font-weight:bold;'>NOTE:</span> ${this.props.isCustomField ? 'Custom Field' : this.props.isQuestionnaire ? 'Questionnaire question' : 'Employee Field'} marked as required will only apply to records created after this field.</div> ` })
                        .then(()=>{
                            this.setState({
                                showLoading: false,
                                showInBetween: false,
                                selectedCustomField: {
                                    ID: null,
                                    DisplayName: "",
                                    FieldTypeID: null,
                                    ServiceID: null,
                                    IsEmployeeField: this.props.fromEmployee ? 1 :0,
                                    Options: {},
                                } as CustomFieldType
                            }, () => {
                                if(this.props.isCustomField){
                                    this.props.onSubmitCustomField()
                                }
                                else if(this.props.isQuestionnaire){
                                    this.props.onSubmitQuestionnaire()
                                }    
                                else if(this.props.fromEmployee){
                                    this.props.onSubmitEmployeeFields()
                                }                            
                            });
                        });
                }
            } else {
                let response = await CustomFieldsAPI.editCustomField(modifiedCustomField)
                if(!response.success){
                    let msg = `Unable to edit ${this.props.isCustomField ? 'Custom' : this.props.isQuestionnaire ? 'Questionnaire' : 'Employee'} Field at this time.`;
                    if(response.reason){
                        msg = response.reason
                    }
                    this.setState({showLoading: false})
                    sweetalert.fire({icon: 'error', title: "", text: msg}).then(() => {
                        if(this.props.isCustomField){
                            this.props.onSubmitCustomField()
                        }
                        else if(this.props.isQuestionnaire){
                            this.props.onSubmitQuestionnaire()
                        }
                        else if(this.props.fromEmployee){
                            this.props.onSubmitEmployeeFields()
                        }
                    });
                }
                else{
                    sweetalert.fire({
                        icon: 'success',
                        title: `${this.props.isCustomField ? 'Custom' : this.props.isQuestionnaire ? 'Questionnaire' : 'Employee'} Field saved`,
                     }).then(()=>{
                            this.setState({
                                showLoading: false,
                                showInBetween: false,
                                selectedCustomField: {
                                    ID: null,
                                    DisplayName: "",
                                    FieldTypeID: null,
                                    ServiceID: null,
                                    IsEmployeeField: this.props.fromEmployee ? 1 : 0,
                                    Options: {},
                                } as CustomFieldType
                            }, () => {
                                if(this.props.isCustomField){
                                    this.props.onSubmitCustomField()
                                }
                                else if(this.props.isQuestionnaire){
                                    this.props.onSubmitQuestionnaire()
                                }
                                else if(this.props.fromEmployee){
                                    this.props.onSubmitEmployeeFields()
                                }
                            });
                        });
                }
            }
        });
    }


    handleAddOption(){
        if(!this.state.valueToAdd || isBlank(this.state.valueToAdd)){
            return sweetalert.fire({icon: 'error', title: "", text: 'Value cannot be blank'});
        }

        let tempOptionsArray = this.state.selectedCustomField.Options.options ? JSON.parse(JSON.stringify(this.state.selectedCustomField.Options.options)) : [];
        
        tempOptionsArray.push({label: this.state.valueToAdd, value: this.state.valueToAdd});

        this.setState((prevState)=>({
            availableOptions: tempOptionsArray,
            showInBetween: false,
            valueToAdd: '',
            selectedCustomField: {
                ...prevState.selectedCustomField,
                Options: {
                    ...prevState.selectedCustomField.Options,
                    options: tempOptionsArray
                }
            }
        }))

    }

    handleNumeric(){
        if(!Number.isInteger(this.state.minNumericRangeValue) || !Number.isInteger(this.state.maxNumericRangeValue)){
            return sweetalert.fire({icon: 'error', title: "", text: 'Numeric Range values must be numeric integers'});
        }
        if(Number(this.state.minNumericRangeValue) < 0 || Number(this.state.maxNumericRangeValue) < 0 || Number(this.state.minNumericRangeValue) > 1000 || Number(this.state.maxNumericRangeValue) > 1000){
            return sweetalert.fire({icon: 'error', title: "", text: 'Numeric Range values must be positive integers between 0 and 1000'});
        }
        if(Number(this.state.maxNumericRangeValue) <= Number(this.state.minNumericRangeValue)){
            return sweetalert.fire({icon: 'error', title: "", text: 'Numeric Range maximum value must be greater than minimum value'});
        }

        let numericOptionsArray = [];
        for(let i = this.state.minNumericRangeValue; i < this.state.maxNumericRangeValue + 1; i++){
            numericOptionsArray.push({label: i, value: i})
        }
        this.setState((prevState)=>({
            minNumericRangeValue: '',
            maxNumericRangeValue: '',
            selectedCustomField: {
                ...prevState.selectedCustomField,
                Options: {
                    ...prevState.selectedCustomField.Options,
                    options: numericOptionsArray,
                    isNumeric: this.state.isNumericLabel.value
                }
            }
        }))
        
    }



    additionalFields(id){
        if(id === CustomFields.TEXT_FIELD) {
            return (
                buildRow("Max Length",
                <input className={"form-control"}
                       autoComplete={"off"}
                       type={"number"}
                       name={"Max Length"}
                       aria-label="Max Length"
                       onChange={(e) => {
                           this.setState((prevState) => ({
                               selectedCustomField: {
                                   ...prevState.selectedCustomField,
                                   Options: {
                                       ...prevState.selectedCustomField.Options,
                                       maxLength: Number(e.target.value)
                                   }
                               }
                           }))
                       }}
                       value={this.state.selectedCustomField.Options.maxLength}
                />,
                    "Maximum length allowed"
            )

            )
        }
        else if(id === CustomFields.SELECT_FIELD) {
            return(
                <>
                    {
                        buildRow("Is Multi-Select",
                        <Select
                            isSearchable={true}
                            placeholder={"Please Select..."}
                            noOptionsMessage={() => "No option"}
                            value={ this.state.isMultiLabel }
                            aria-label="Is Multi-Select"
                            onChange={(e) => this.setState((prevState)=>({
                                isMultiLabel: e,
                                selectedCustomField: {
                                    ...prevState.selectedCustomField,
                                    Options: {
                                        ...prevState.selectedCustomField.Options,
                                        isMulti: e.value
                                    }
                                }
                            }))}
                            className={"state_select"}
                            options={getBoolSelectOptions()}
                        />,
                            "Are multiple selections allowed?"
                        )
                    }
                    {this.state.isNumericLabel && this.state.isNumericLabel.value ? 
                        <div className="invisible"></div>
                        :
                        buildRow("Is Yes/No",
                        <Select
                            isDisabled={this.state.isNumericLabel && this.state.isNumericLabel.value}
                            isSearchable={true}
                            placeholder={"Please Select..."}
                            noOptionsMessage={() => "No option"}
                            value={ this.state.isBooleanLabel }
                            aria-label="Is Yes or No"
                            onChange={(e) => {
                                this.setState((prevState)=>({
                                isBooleanLabel: e,
                                selectedCustomField: {
                                    ...prevState.selectedCustomField,
                                    Options: {
                                        ...prevState.selectedCustomField.Options,
                                        options: e.value === true ? [{label: 'Yes', value: 'True'}, {label: 'No', value: 'False'}] as any : null,
                                        isBoolean: e.value
                                    }
                                }
                            }))
                            }
                                }
                            className={"state_select"}
                            options={getBoolSelectOptions()}
                        />,
                            "Is True or False?"
                        )
                    }
                    {this.state.isBooleanLabel && this.state.isBooleanLabel.value ? 
                        <div className="invisible"></div>
                        :
                        buildRow("Is Numeric",
                        <Select
                            isDisabled={this.state.isBooleanLabel && this.state.isBooleanLabel.value}
                            isSearchable={true}
                            placeholder={"Please Select..."}
                            noOptionsMessage={() => "No option"}
                            value={ this.state.isNumericLabel }
                            aria-label="Is Numeric"
                            onChange={(e) => {
                                this.setState((prevState)=>({
                                    isNumericLabel: e,
                                        selectedCustomField: {
                                            ...prevState.selectedCustomField,
                                            Options: {
                                                ...prevState.selectedCustomField.Options,
                                                isNumeric: e.value,
                                            }
                                        }
                                    }))
                            }}
                            className={"state_select"}
                            options={getBoolSelectOptions()}
                        />,
                            "Is number value?"
                        )
                    }
                    {this.state.isNumericLabel && this.state.isNumericLabel.value &&
                        (
                            <div className="form-group row" data-toggle={'tooltip'} data-placement={'top'} title={'Numeric range'}>
                                <label htmlFor={'NumericRange'} className="col-md-4 col-form-label">Numeric Range</label>
                                <div className="col-4 col-md-3 m-0 pl-0 pr-3" id={'Minimum'}>
                                    <input 
                                        className={"form-control"}
                                        autoComplete={"off"}
                                        type={"number"}
                                        name={"Minimum"}
                                        min={0}
                                        max={1000}
                                        aria-label="Numeric Range Minimum"
                                        placeholder={'Minimum'}
                                        onChange={(e) => {
                                            this.setState((prevState)=>({
                                                minNumericRangeValue: Number(e.target.value)
                                            })
                                            )
                                        }}
                                        value={this.state.minNumericRangeValue}
                                    />
                                </div>
                                <div className="col-4 col-md-3 m-0 pr-xs-2 p-md-0" id={'Maximum'}>
                                    <input className={"form-control"}
                                        autoComplete={"off"}
                                        type={"number"}
                                        name={"Maximum"}
                                        min={0}
                                        max={1000}
                                        aria-label="Numeric Range Maximum"
                                        placeholder={"Maximum"}
                                        onChange={(e) => {
                                            this.setState((prevState)=>({
                                                maxNumericRangeValue: Number(e.target.value)
                                            })
                                            )
                                        }}
                                        value={this.state.maxNumericRangeValue}
                                    />
                                </div>
                                <div className="col-3 col-md-2 m-0 p-md-0 text-right">
                                    <button className={"btn btn-success"} onClick={() => this.handleNumeric()}>Set Range</button>
                                </div>
                            </div>
                            
                        )
                    }
                    {
                        (
                            <div className="form-group row" data-toggle={'tooltip'} data-placement={'top'} title={'statesWarning'}>
                                <label htmlFor={'statesWarning'} className="col-md-4 col-form-label"></label>
                                <div className="col-8 col-md-6 mr-3 mr-sm-0 pl-0 pr-3 pr-sm-0" id={'statesWarning'}>
                                    <p className="text-danger small mb-0">*States should be abbreviations only. </p>
                                </div>
                            </div> 
                        )
                    }
                    {
                        (
                            <div className="form-group row" data-toggle={'tooltip'} data-placement={'top'} title={'Value to add to options'}>
                                <label htmlFor={'Value'} className="col-md-4 col-form-label">Option Value</label>
                                <div className="col-8 col-md-6 mr-3 mr-sm-0 pl-0 pr-3 pr-sm-0" id={'Value'}>
                                    <input className={"form-control"}
                                        autoComplete={"off"}
                                        placeholder="Enter Option Value and then click 'Add Value'"
                                        type={"search"}
                                        name={"Value"}
                                        aria-label="Option Value"
                                        maxLength={100}
                                        onChange={(e) => {
                                            this.setState((prevState)=>({
                                                valueToAdd: e.target.value
                                            })
                                            )
                                        }}
                                        value={this.state.valueToAdd}
                                    />
                                </div>
                                <div className="col-3 col-md-2 m-0 p-0 text-right">
                                    <button className={"btn btn-success"} onClick={() => this.handleAddOption()}>Add Value</button>
                                </div>
                            </div> 
                        )
                    }
                    {
                    buildRow("Options",
                            <Select
                                isMulti={true}
                                isSearchable={true}
                                placeholder={"Please Select..."}
                                noOptionsMessage={() => "No option"}
                                aria-label="Options"
                                value={ this.state.selectedCustomField.Options.options ? this.state.selectedCustomField.Options.options as any : [] }
                                onChange={(e) => { this.setState((prevState)=>({
                                    selectedCustomField: {
                                        ...prevState.selectedCustomField,
                                        Options: {
                                            ...prevState.selectedCustomField.Options,
                                            options: e as any
                                        }
                                    }
                                }))}}
                                className={"state_select"}
                                options={this.state.availableOptions && this.state.availableOptions.length > 0 ? this.state.availableOptions : []}
                            />
                        ,
                    "Options for select"
                        )
                    }
                </>
            )
        }
        else if(id === CustomFields.TEXTAREA_FIELD) {
            return (
                buildRow("Max Length",
                    <input className={"form-control"}
                           autoComplete={"off"}
                           type={"number"}
                           name={"Max Length"}
                           aria-label="Max Length"
                           onChange={(e) => {
                               this.setState((prevState) => ({
                                   selectedCustomField: {
                                       ...prevState.selectedCustomField,
                                       Options: {
                                           ...prevState.selectedCustomField.Options,
                                           maxLength: Number(e.target.value)
                                       }
                                   }
                               }))
                           }}
                           value={this.state.selectedCustomField.Options.maxLength}
                    />,
                    "Maximum length allowed",
                )

            )
        }
        else if(id === CustomFields.NUMBER_FIELD) {
            return(
                <>
                    {
                        buildRow("Minimum",
                            <input className={"form-control"}
                                   autoComplete={"off"}
                                   type={"number"}
                                   name={"Minimum"}
                                   min={0}
                                   max={1000}
                                   aria-label="Minimum"
                                   onChange={(e) => {
                                       this.setState((prevState) => ({
                                           selectedCustomField: {
                                               ...prevState.selectedCustomField,
                                               Options: {
                                                   ...prevState.selectedCustomField.Options,
                                                   min: Number(e.target.value)
                                               }
                                           }
                                       }))
                                   }}
                                   value={this.state.selectedCustomField.Options.min}
                            />,
                            "Minimum value allowed",
                        )
                    }
                    {
                        buildRow("Maximum",
                            <input className={"form-control"}
                                   autoComplete={"off"}
                                   type={"number"}
                                   name={"Maximum"}
                                   min={0}
                                   max={1000}
                                   aria-label="Maximum"
                                   onChange={(e) => {
                                       this.setState((prevState) => ({
                                           selectedCustomField: {
                                               ...prevState.selectedCustomField,
                                               Options: {
                                                   ...prevState.selectedCustomField.Options,
                                                   max: Number(e.target.value)
                                               }
                                           }
                                       }))
                                   }}
                                   value={this.state.selectedCustomField.Options.max}
                            />,
                            "Maximum value allowed",
                        )
                    }
                    </>
            )
        }
        else if(id === CustomFields.DATE_FIELD) {
            return(
                <>
                    {
                        buildRow("Allow Future Date",
                        <Select
                            isSearchable={true}
                            placeholder={"Please Select..."}
                            noOptionsMessage={() => "No option"}
                            value={ this.state.allowFutureDateLabel }
                            aria-label="Allow Future Date"
                            onChange={(e) => {
                                this.setState((prevState)=>({
                                    allowFutureDateLabel: e,
                                    selectedCustomField: {
                                        ...prevState.selectedCustomField,
                                        Options: {
                                            ...prevState.selectedCustomField.Options,
                                            allowFutureDate: e.value
                                        }
                                    }
                                })
                                )
                            }
                                }
                            className={"state_select"}
                            options={getBoolSelectOptions()}
                        />,
                            "Allow date to be in the future?"
                        )
                    }
                </>
            )

        }
    }

    render(){

        // console.log('CustomFieldsModal props', this.props)
        // console.log('CustomFieldsModal state', this.state)

        let serviceSelectedTypes = this.props.selectedService ? this.props.selectedService.ServiceTypes : [];
        return(
            <React.Fragment>
            <HintModal header={this.state.hintHeader} content={this.state.hintContent} zIndex={100007} handleInBetweenOverlay={() => this.setState({showInBetween: false})}  />
            <Overlay show_loading={this.state.showLoading} zIndex={100006}/>
            <InBetweenOverlay showInBetween={this.state.showInBetween} zIndex={100006} />
            <div className="modal fade form_modal" id={CustomFieldsModal.ID} tabIndex={-1} role="dialog"
                 aria-labelledby="result_modal_label"
                 aria-hidden="true" style={{zIndex: 100005}}>
                <div className="modal-dialog modal-lg modal-xl" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="result_modal_label">{this.state.selectedCustomField && this.state.selectedCustomField.ID ? 'Update ' : 'Create '}{this.props.isCustomField ? 'Custom' : this.props.isQuestionnaire ? 'Questionnaire' : this.props.fromEmployee ? 'Employee' : 'Custom'} Field {this.props.selectedService ? ` for ${this.props.selectedService.Name}` : ''}
                                <FaInfoCircle tabIndex={0} className={!!this.state.selectedCustomField.ID ? 'ml-1' : 'invisible'} color={"#21212E"} size={25} onClick={() => {showModalNoOutsideClick(HintModal.ID); this.setState({showInBetween: true})}} />
                            </h5>
                            <button style={{outline: 'none'}}  type="button" className="close" data-dismiss="modal" aria-label="Close" 
                            onClick={()=> {
                                this.clearState();
                                this.props.isQuestionnaire && this.props.handleInBetweenOnQuestionnaire()}}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body my-0 mx-3">
                            {buildRow("Field Type",
                                <Select
                                    isDisabled={!!this.state.selectedCustomField.ID}
                                    isSearchable={true}
                                    placeholder={<div className="accessibilityText">Please Select...</div>}
                                    noOptionsMessage={() => "No option"}
                                    value={ this.state.selectedCustomField.FieldTypeID ? getFieldTypeVal(this.state.selectedCustomField.FieldTypeID) : null }
                                    aria-label="Field Type"
                                    onChange={(e) => this.setState((prevState)=>({
                                        selectedCustomField: {
                                            ...prevState.selectedCustomField,
                                            FieldTypeID: e.value
                                        }
                                    }))}
                                    className={"state_select"}
                                    options={this.props.fieldTypes}
                                    
                                />,
                                "Type of field"
                            )}
                            {this.props.isQuestionnaire && buildRow("Service Type",
                                <Select
                                    isDisabled={!!this.state.selectedCustomField.ID}
                                    isSearchable={true}
                                    placeholder={<div className="accessibilityText">Please Select...</div>}
                                    noOptionsMessage={() => "No option"}
                                    value={ this.state.selectedCustomField && this.state.selectedCustomField.Options && this.state.selectedCustomField.Options.serviceType ? getLabel(this.state.selectedCustomField.Options.serviceType, this.props.serviceTypes) : "" }
                                    aria-label="Serivce Type"
                                    onChange={(e) => {
                                        this.setState((prevState)=>({
                                        selectedCustomField: {
                                            ...prevState.selectedCustomField,
                                            Options: {
                                                ...prevState.selectedCustomField.Options,
                                                serviceType: e.value
                                            }
                                        }
                                    }))}}
                                    className={"state_select"}
                                    options={getlabelsFromIDs(serviceSelectedTypes, this.props.serviceTypes)}
                                />,
                                "Service Type"
                            )}
                            {buildRow(`Display ${this.props.isQuestionnaire ? 'Question' : this.props.fromEmployee ? 'Field' : 'Name'}`,
                                <input className={"form-control"}
                                       id={`Display${this.props.isQuestionnaire ? 'Question' : 'Name'}`}
                                       readOnly={!!this.state.selectedCustomField.ID}
                                       maxLength={100}
                                       autoComplete={"off"}
                                       type={"search"}
                                       name={"DisplayName"}
                                       aria-label={`Display ${this.props.isQuestionnaire ? 'Question' : 'Name'}`}
                                       onChange={(e)=>{
                                           this.setState( (prevState) => ({
                                               selectedCustomField: {
                                                   ...prevState.selectedCustomField,
                                                   DisplayName: e.target.value
                                               }
                                           }))
                                       }}
                                       value={this.state.selectedCustomField.DisplayName}
                                />,
                                `${this.props.isQuestionnaire ? 'Question' : this.props.fromEmployee ? 'Field' : 'Name'} displayed`
                            )}
                            {this.props.isCustomField ? buildRow("Displayed Pages",
                                <Select
                                    isDisabled={this.props.isQuestionnaire || this.props.fromEmployee}
                                    isMulti={true}
                                    isSearchable={true}
                                    placeholder={<div className="accessibilityText">Please Select...</div>}
                                    noOptionsMessage={() => "No option"}
                                    value={ this.state.selectedCustomField.Options.validPages as any }
                                    aria-label="Displayed Pages"
                                    onChange={(e) => this.setState((prevState)=>({
                                        selectedCustomField: {
                                            ...prevState.selectedCustomField,
                                            Options: {
                                                ...prevState.selectedCustomField.Options,
                                                validPages: e as any
                                            }
                                        }
                                    }))}
                                    className={"state_select"}
                                    options={this.props.validPages}
                                />,
                                "Pages the field will be displayed on"
                            ) : <div className="invisible"></div>}
                            {buildRow("Popover Text",
                                <input className={"form-control"}
                                       id="PopoverText"
                                       maxLength={100}
                                       autoComplete={"off"}
                                       type={"search"}
                                       name={"PopoverText"}
                                       aria-label="Popover Text"
                                       onChange={(e)=>{
                                           this.setState( (prevState) => ({
                                               selectedCustomField: {
                                                   ...prevState.selectedCustomField,
                                                   Options: {
                                                       ...prevState.selectedCustomField.Options,
                                                       popoverText: e.target.value
                                                   }
                                               }
                                           }))
                                       }}
                                       value={this.state.selectedCustomField.Options.popoverText}
                                />,
                                "Text to appear when hovered over"
                            )}
                            {buildRow("Is Required",
                                <Select
                                    isSearchable={true}
                                    placeholder={<div className="accessibilityText">Please Select...</div>}
                                    noOptionsMessage={() => "No option"}
                                    value={ this.state.isRequiredLabel }
                                    aria-label="Is Required"
                                    onChange={(e) => this.setState((prevState)=>({
                                        isRequiredLabel: e,
                                        selectedCustomField: {
                                            ...prevState.selectedCustomField,
                                            Options: {
                                                ...prevState.selectedCustomField.Options,
                                                isRequired: e.value
                                            }
                                        }
                                    }))}
                                    className={"state_select"}
                                    options={getBoolSelectOptions()}
                                />,
                                "Is field required?"
                            )}
                            {buildRow("Is Active",
                                <Select
                                    isSearchable={true}
                                    placeholder={<div className="accessibilityText">Please Select...</div>}
                                    noOptionsMessage={() => "No option"}
                                    value={ this.state.isActiveLabel }
                                    aria-label="Is Active"
                                    onChange={(e) => this.setState((prevState)=>({
                                        isActiveLabel: e,
                                        selectedCustomField: {
                                            ...prevState.selectedCustomField,
                                            Options: {
                                                ...prevState.selectedCustomField.Options,
                                                isActive: e.value
                                            }
                                        }
                                    }))}
                                    className={"state_select"}
                                    options={getBoolSelectOptions()}
                                />,
                                "Is field active? (Inactive fields will not be displayed)"
                            )}

                            {this.additionalFields(this.state.selectedCustomField.FieldTypeID)}

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={() => {
                            

                                //all fields validations
                                if(!this.state.selectedCustomField.FieldTypeID){
                                    return sweetalert.fire("", "Please select a value for Field Type", "error")
                                }
                                if(isBlank(this.state.selectedCustomField.DisplayName) ||
                                    isOnlyWhitespace(this.state.selectedCustomField.DisplayName)
                                ){
                                    return sweetalert.fire("","Display Name cannot be empty.", "error")
                                }
                                if(!this.state.selectedCustomField.Options.validPages || this.state.selectedCustomField.Options.validPages.length < 1){
                                    return sweetalert.fire("", "Please select value(s) for Displayed Pages", "error")
                                }
                                if(this.state.selectedCustomField.Options.isRequired === null || this.state.selectedCustomField.Options.isRequired === undefined){
                                    return sweetalert.fire("", "Please select value for Is Required", "error")
                                }
                                if(this.state.selectedCustomField.Options.isActive === null || this.state.selectedCustomField.Options.isActive === undefined){
                                    return sweetalert.fire("", "Please select value for Is Active", "error")
                                }

                                //Text and TextArea fields validations
                                if((this.state.selectedCustomField.FieldTypeID == CustomFields.TEXT_FIELD || this.state.selectedCustomField.FieldTypeID == CustomFields.TEXTAREA_FIELD) && (!this.state.selectedCustomField.Options.maxLength || this.state.selectedCustomField.Options.maxLength < 1 || isNaN(this.state.selectedCustomField.Options.maxLength) || !Number.isInteger(this.state.selectedCustomField.Options.maxLength))){
                                    return sweetalert.fire("", "Max Length must be a positive integer greater than 0", "error")
                                }

                                //Select fields validations
                                if(this.state.selectedCustomField.FieldTypeID == CustomFields.SELECT_FIELD && (this.state.selectedCustomField.Options.isMulti === null || this.state.selectedCustomField.Options.isMulti === undefined)){
                                    return sweetalert.fire("", "Please select value for Is Multi", "error")
                                }
                                if(this.state.selectedCustomField.FieldTypeID == CustomFields.SELECT_FIELD && (!this.state.selectedCustomField.Options.options || this.state.selectedCustomField.Options.options.length < 1)){
                                    return sweetalert.fire("", "Please select value(s) for Options", "error")
                                }


                                //Number fields validations
                                if(this.state.selectedCustomField.FieldTypeID == CustomFields.NUMBER_FIELD && (this.state.selectedCustomField.Options.min < 0 || isNaN(this.state.selectedCustomField.Options.min) || !Number.isInteger(this.state.selectedCustomField.Options.min))){
                                    return sweetalert.fire("", "Minimum must be a positive integer", "error")
                                }
                                if(this.state.selectedCustomField.FieldTypeID == CustomFields.NUMBER_FIELD && (this.state.selectedCustomField.Options.max < 0 || isNaN(this.state.selectedCustomField.Options.max) || !Number.isInteger(this.state.selectedCustomField.Options.max))){
                                    return sweetalert.fire("", "Maximum must be a positive integer", "error")
                                }
                                if(this.state.selectedCustomField.FieldTypeID == CustomFields.NUMBER_FIELD && (Number(this.state.selectedCustomField.Options.min) > Number(this.state.selectedCustomField.Options.max))){
                                    return sweetalert.fire("", "Maximum cannot be less than Minimum", "error")
                                }
                                if(this.state.selectedCustomField.FieldTypeID == CustomFields.NUMBER_FIELD && (Number(this.state.selectedCustomField.Options.min) > 1000)){
                                    return sweetalert.fire("", "Minimum cannot be greater than 1000", "error")
                                }
                                if(this.state.selectedCustomField.FieldTypeID == CustomFields.NUMBER_FIELD && (Number(this.state.selectedCustomField.Options.max) > 1000)){
                                    return sweetalert.fire("", "Maximum cannot be greater than 1000", "error")
                                }

                                //Date fields validations
                                if(this.state.selectedCustomField.FieldTypeID == CustomFields.DATE_FIELD && (this.state.selectedCustomField.Options.allowFutureDate === null || this.state.selectedCustomField.Options.allowFutureDate === undefined)){
                                    return sweetalert.fire("", "Please select value for Allow Future Date", "error")
                                }

                                if(this.state.selectedCustomField && this.state.selectedCustomField.Options.isRequired && !this.state.selectedCustomField.Options.isActive){
                                    return sweetalert.fire({icon: 'error', title: "", text: `${this.props.isCustomField ? 'Custom' : this.props.isQuestionnaire ? 'Questionnaire' : 'Employee'} field cannot be inactive if required` })
                                }


                                // if(this.state.selectedCustomField.ID){
                                //     sweetalert.fire({
                                //         icon: 'warning',
                                //         title: "Modifications could negatively affect past implementations of this custom field.  Consider deactivating and creating a new custom field",
                                //         showCloseButton: true,
                                //         showDenyButton: true,
                                //         showCancelButton: false,
                                //         confirmButtonText: 'Continue',
                                //         denyButtonText: 'Go Back'
                                //     }).then((result) => {
                                //         if(result.isConfirmed){
                                //             this.createOrModifyCustomField(this.state.selectedCustomField);
                                //             this.clearState()
                                //         }
                                //         else{
                                //             return
                                //         }
                                //     })
                                // }
                                // else{
                                    this.createOrModifyCustomField(this.state.selectedCustomField);
                                    this.clearState()
                                // }
                            }}>
                                {this.state.selectedCustomField && this.state.selectedCustomField.ID ? 'Update' : 'Create'}
                            </button>

                        </div>
                    </div>
                </div>
            </div>
            </React.Fragment>
        )
    }
}