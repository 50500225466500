import React from "react";
import ModalForm from "./ModalForm";
import Select from "react-select";
import { FaCameraRetro } from "react-icons/fa";
import { sweetalert } from "../../App";
import DatePicker from "react-datepicker";
import moment from "moment";
import TestType from "../../types/TestType";
import { parseDate, getNameFromID } from "../../util/FormatUtil";
import { isBlank, isOnlyWhitespace } from "../../util/ValidationUtil";

export interface ChangeVals {
  ServiceID?;
}

interface CheckinProps {
  onSubmit;
  vaccines?;
  evaluations?;
  tests?;
  facilities?
  services?;
  selectedRecord;
  event?;
  employees?;
  serviceTypes?;
}
interface CheckinModalState {
  showScanner?: boolean;
  selectedRecord?;
  selectedTests?;
  selectedTestLabels?;
  serviceSpecificTests?;
  EventServices?;
  serviceID?;
}
class CheckinEmployeeModal extends React.Component<
  CheckinProps,
  CheckinModalState
> {
  public formRef: React.RefObject<ModalForm>;

  constructor(props) {
    super(props);
    this.state = { showScanner: false };
    this.formRef = React.createRef();
  }

  componentWillReceiveProps(
    nextProps: Readonly<CheckinProps>,
    nextContext: any
  ) {
    // console.log('CheckinEmployeeModal nextProps', nextProps)
    this.setState({
      selectedRecord: {
        ID: nextProps.selectedRecord ? nextProps.selectedRecord.ID : null,
        ReqNum: nextProps.selectedRecord? nextProps.selectedRecord.ReqNum: null,
        LinkedApptsID: nextProps.selectedRecord? nextProps.selectedRecord.LinkedApptsID: null,
        EmployeeUID: nextProps.selectedRecord? nextProps.selectedRecord.EmployeeUID: null,
        FacilityID: nextProps.selectedRecord? nextProps.selectedRecord.FacilityID: null,
        EventApptID: nextProps.selectedRecord? nextProps.selectedRecord.EventApptID: null,
        AppointmentDate: nextProps.selectedRecord? nextProps.selectedRecord.AppointmentDate: null,
        AppointmentTime: nextProps.selectedRecord? nextProps.selectedRecord.AppointmentTime: null,
        ServiceID: nextProps.selectedRecord? nextProps.selectedRecord.ServiceID: null,
        VaccineID: nextProps.selectedRecord? nextProps.selectedRecord.VaccineID: null,
        EvaluationID: nextProps.selectedRecord? nextProps.selectedRecord.EvaluationID: null,
        TestID: nextProps.selectedRecord? nextProps.selectedRecord.TestID: null,
      },
    });
  }

  public static readonly ID = "checkin";
  render():
    | React.ReactElement
    | string
    | number
    | {}
    | React.ReactNodeArray
    | React.ReactPortal
    | boolean
    | null
    | undefined {
    // console.log('CheckinEmployeeModal props ', this.props)
    // console.log('CheckinEmployeeModal state ', this.state)

    let employeeDisplayName = this.state.selectedRecord && this.state.selectedRecord.EmployeeUID ? `${this.props.employees.find(e => e.UID === this.state.selectedRecord.EmployeeUID).DisplayName} - ${parseDate(this.props.employees.find(e => e.UID === this.state.selectedRecord.EmployeeUID).DateOfBirth)} - ${this.props.employees.find(e => e.UID === this.state.selectedRecord.EmployeeUID).UID}` : '';
    let facilityName = this.state.selectedRecord?.FacilityID ? this.props.facilities.find((f) => f.ID === this.state.selectedRecord.FacilityID).FacilityName : null;
    let serviceName = this.state.selectedRecord?.ServiceID ? this.props.services.find((s) => s.ID === this.state.selectedRecord.ServiceID).Name : null;
    let vaccineName = this.state.selectedRecord?.VaccineID ? this.props.vaccines.find((s) => s.value === this.state.selectedRecord.VaccineID).label : null;
    let evaluationName = this.state.selectedRecord?.EvaluationID ? this.props.evaluations.find((s) => s.value === this.state.selectedRecord.EvaluationID).label : null;
    let testName = this.state.selectedRecord?.TestID ? this.props.tests.find((s) => s.ID === this.state.selectedRecord.TestID).Name : null;


    return (
      <div
        className="modal fade form_modal"
        id={CheckinEmployeeModal.ID}
        tabIndex={-1}
        role="dialog"
        aria-labelledby="result_modal_label"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-xl" role="document">
          <div className="modal-content">
            <div className="container-fluid">
              <div className="modal-header">
                <h5 className="modal-title" id="result_modal_label">
                  Event Check-in
                </h5>
                <button
                  style={{ outline: "none" }}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body mr-3">
                <div className="form-group row">
                  <label htmlFor={'EmployeeSearch'} className="col-sm-4 col-form-label">
                    Employee
                  </label>
                    <div className="col-sm-8 p-0 m-0" id={'EmployeeSearch'}>
                    <input
                      type={"search"}
                      className="form-control"
                      readOnly={true}
                      id={"EmployeeSearch"}
                      value={employeeDisplayName}
                    />
                    </div>
                </div>
                <div className="form-group row">
                  <label htmlFor={'Event'} className="col-sm-4 col-form-label">
                    Event
                  </label>
                    <div className="col-sm-8 p-0 m-0" id={'Event'}>
                    <input
                      type={"search"}
                      className="form-control"
                      readOnly={true}
                      id={"Event"}
                      value={this.props.event && this.props.event.label ? this.props.event.label : ''}
                    />
                    </div>
                </div>
                <div className="form-group row">
                  <label htmlFor={'AppointmentDate'} className="col-sm-4 col-form-label">
                    Appointment Date
                  </label>
                    <div className="col-sm-8 p-0 m-0" id={'AppointmentDate'}>
                    <input
                      type={"search"}
                      className="form-control"
                      readOnly={true}
                      id={"AppointmentDate"}
                      value={this.state.selectedRecord && this.state.selectedRecord.AppointmentDate ? parseDate(this.state.selectedRecord.AppointmentDate) : ''}
                    />
                    </div>
                </div>
                <div className="form-group row">
                  <label htmlFor={'AppointmentTime'} className="col-sm-4 col-form-label">
                    Appointment Time
                  </label>
                    <div className="col-sm-8 p-0 m-0" id={'AppointmentTime'}>
                    <input
                      type={"search"}
                      className="form-control"
                      readOnly={true}
                      id={"AppointmentTime"}
                      value={this.state.selectedRecord && this.state.selectedRecord.AppointmentTime ? this.state.selectedRecord.AppointmentTime.replace(/^0/, '') : ''}
                    />
                    </div>
                </div>
                <div className="form-group row">
                  <label htmlFor={'ReqNum'} className="col-sm-4 col-form-label">
                    Conf #
                  </label>
                    <div className="col-sm-8 p-0 m-0" id={'ReqNum'}>
                    <input
                      type={"search"}
                      className="form-control"
                      readOnly={true}
                      id={"ReqNum"}
                      value={this.state.selectedRecord && this.state.selectedRecord.ReqNum ? this.state.selectedRecord.ReqNum : ''}
                    />
                    </div>
                </div>
                <div className="form-group row">
                  <label htmlFor={'LinkedApptsID'} className="col-sm-4 col-form-label">
                    Appointment #
                  </label>
                    <div className="col-sm-8 p-0 m-0" id={'LinkedApptsID'}>
                    <input
                      type={"search"}
                      className="form-control"
                      readOnly={true}
                      id={"LinkedApptsID"}
                      value={this.state.selectedRecord && this.state.selectedRecord.LinkedApptsID ? this.state.selectedRecord.LinkedApptsID : ''}
                    />
                    </div>
                </div>
                <div className="form-group row">
                  <label htmlFor={'Facility'} className="col-sm-4 col-form-label">
                    Facility
                  </label>
                    <div className="col-sm-8 p-0 m-0" id={'Facility'}>
                    <input
                      type={"search"}
                      className="form-control"
                      readOnly={true}
                      id={"Facility"}
                      value={facilityName}
                    />
                    </div>
                </div>
                <div className="form-group row">
                  <label htmlFor={'Service'} className="col-sm-4 col-form-label">
                    Service
                  </label>
                    <div className="col-sm-8 p-0 m-0" id={'Service'}>
                    <input
                      type={"search"}
                      className="form-control"
                      readOnly={true}
                      id={"Service"}
                      value={serviceName}
                    />
                    </div>
                </div>
                {this.state.selectedRecord && this.state.selectedRecord.VaccineID &&
                  <div className="form-group row">
                    <label htmlFor={'Vaccine'} className="col-sm-4 col-form-label">
                      Vaccine
                    </label>
                      <div className="col-sm-8 p-0 m-0" id={'Vaccine'}>
                      <input
                        type={"search"}
                        className="form-control"
                        readOnly={true}
                        id={"Vaccine"}
                        value={vaccineName}
                      />
                      </div>
                  </div>
                }
                {this.state.selectedRecord && this.state.selectedRecord.EvaluationID &&
                  <div className="form-group row">
                    <label htmlFor={'Evaluation'} className="col-sm-4 col-form-label">
                      Evaluation
                    </label>
                      <div className="col-sm-8 p-0 m-0" id={'Evaluation'}>
                      <input
                        type={"search"}
                        className="form-control"
                        readOnly={true}
                        id={"Evaluation"}
                        value={evaluationName}
                      />
                      </div>
                  </div>
                }
                {this.state.selectedRecord && this.state.selectedRecord.TestID &&
                  <div className="form-group row">
                    <label htmlFor={'Test'} className="col-sm-4 col-form-label">
                      Test
                    </label>
                      <div className="col-sm-8 p-0 m-0" id={'Test'}>
                      <input
                        type={"search"}
                        className="form-control"
                        readOnly={true}
                        id={"Test"}
                        value={testName}
                      />
                      </div>
                  </div>
                }
                {this.state.selectedRecord && !this.state.selectedRecord.VaccineID && !this.state.selectedRecord.TestID && !this.state.selectedRecord.TestID &&
                  <div className="form-group row">
                    <label htmlFor={'Other'} className="col-sm-4 col-form-label">
                      Other
                    </label>
                      <div className="col-sm-8 p-0 m-0" id={'Other'}>
                      <input
                        type={"search"}
                        className="form-control"
                        readOnly={true}
                        id={"Other"}
                        value={'Other'}
                      />
                      </div>
                  </div>
                }
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    let editedRecord = JSON.parse(
                      JSON.stringify(this.state.selectedRecord)
                    );
                    this.props.onSubmit(editedRecord.ReqNum);
                  }}
                >
                  Check In
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CheckinEmployeeModal;
