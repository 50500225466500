import React from "react";
import Select from "react-select";
import {Result} from "../../types/Result";
import {sweetalert} from "../../App";
import {isBlank, isOnlyWhitespace} from "../../util/ValidationUtil";
import {buildRow, getBoolSelectOptions, getBoolSelectVal, hideModal} from "../../util/FormatUtil";
import {isEmptyObject} from "jquery";
import GetField from "../../customfields/GetField";
import { CustomFieldBuilder } from "../../customfields/CustomFieldBuilder";
import SystemAPI from "../../network/SystemAPI";
import Validator from "../../validation/Validator";
import { Validators } from "../../validation/Validators";

interface ResultsManagementState{
    selectedResult:Result
    customFieldData?
}
interface ResultsManagementProps{
    selectedResult
    onSubmit?
    pageCustomQuestions?
    testAndResultsMapped
}
export class ResultsManagementModal extends React.Component<ResultsManagementProps, ResultsManagementState>{
    public static ID = "resultsmanagement";
    constructor(props) {
        super(props);
        this.state = {
            selectedResult: {
                ID: null,
                Name: null,
                Description: null,
                IsEnabled: null,
                CustomFieldData: null,
                CreatedDate: null,
            } as Result
        } as ResultsManagementState
    }

    componentDidMount(): void {
        $(`#${ResultsManagementModal.ID}`).on('shown.bs.modal', function() {
            $(document).off('focusin.modal');
        });
    }

    componentWillReceiveProps(nextProps: Readonly<ResultsManagementProps>, nextContext: any) {
        if(!isEmptyObject(nextProps.selectedResult)){
            let result = nextProps.selectedResult;

            this.setState({
                selectedResult: {
                    ID: result.ID,
                    Name: result.Name,
                    Description: result.Description,
                    IsEnabled: result.IsEnabled,
                    CustomFieldData: result.CustomFieldData && JSON.stringify(result.CustomFieldData) !== '{}' ? JSON.parse(result.CustomFieldData) : null,
                    CreatedDate: result.CreatedDate ? result.CreatedDate : new Date()
                } as Result
            });
       
        }
    }

    clearFields(){
        this.setState({
            selectedResult: {
                ID: null,
                Name: '',
                IsEnabled: null,
                Description: '',
                CustomFieldData: null,
                CreatedDate: null,
            } as Result,
        })
    }

    render(){

        let testLabels = [];
        for(let i = 0; i < this.props.testAndResultsMapped.length; i++){
            if(this.props.testAndResultsMapped[i].ResultID === this.state.selectedResult.ID){
                testLabels.push({label: this.props.testAndResultsMapped[i].TestName, value: this.props.testAndResultsMapped[i].TestID})
            }
        }
        return(
            <div className="modal fade form_modal" id={ResultsManagementModal.ID} tabIndex={-1} role="dialog"
                 aria-labelledby="result_modal_label"
                 aria-hidden="true">
                <div className="modal-dialog modal-lg modal-xl" role="document">
                    <div className="modal-content">
                    <div className="container-fluid">
                        <div className="modal-header">
                            <h5 className="modal-title" id="result_modal_label">{this.state.selectedResult && this.state.selectedResult.ID ? 'Update Result' : 'Create Result'}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.clearFields()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {buildRow("Name",
                                <input className={"form-control"}
                                       id="Name"
                                       maxLength={50}
                                       readOnly={this.state.selectedResult.ID != null}
                                       autoComplete={"off"}
                                       type={"search"}
                                       name={"Name"}
                                       aria-label="Name"
                                       onChange={(e)=>{
                                           this.setState( (prevState) => ({
                                               selectedResult: {
                                                   ...prevState.selectedResult,
                                                   Name: e.target.value
                                               }
                                           }))
                                       }}
                                       value={this.state.selectedResult.Name}
                                />
                            )}
                            {buildRow("Description",
                                <input
                                    className={"form-control"}
                                    id="Description"
                                    maxLength={50}
                                    autoComplete={"off"}
                                    type={"search"}
                                    name={"Description"}
                                    aria-label="Description"
                                    onChange={(e) => this.setState((prevState) => ({
                                        selectedResult: {
                                            ...prevState.selectedResult,
                                            Description: e.target.value
                                        }
                                    }))}
                                    value={ this.state.selectedResult.Description }
                                />
                            )}
                            {buildRow("Is Enabled",
                                <Select
                                isSearchable={true}
                                placeholder={<div className="accessibilityText">Please Select...</div>}
                                noOptionsMessage={() => "No option"}
                                aria-label="Is Enabled?"
                                value={ this.state.selectedResult.ID ? getBoolSelectVal(this.state.selectedResult.IsEnabled):
                                    this.state.selectedResult.IsEnabled === 1 ||  this.state.selectedResult.IsEnabled === 0 ? getBoolSelectVal(this.state.selectedResult.IsEnabled) :
                                    null }
                                onChange={(e) => this.setState((prevState)=>({
                                    selectedResult: {
                                        ...prevState.selectedResult,
                                        IsEnabled: e.value ? 1 : 0
                                    }
                                }))}
                                className={"state_select"}
                                options={getBoolSelectOptions()}
                            />
                            )}
                            {buildRow("Assigned Test",
                                <input
                                    placeholder="No tests found. Assign Results via Test Management..."
                                    disabled={true}
                                    className={"form-control"}
                                    id="Description"
                                    maxLength={50}
                                    autoComplete={"off"}
                                    type={"search"}
                                    name={"Description"}
                                    aria-label="Description"
                                    value={ testLabels && testLabels.length > 0 ? testLabels.map(t => {return t.label}).toString().replace(/,/g, ', ') : null}
                                />
                         
                            )}
                             {/*Custom Field Data*/}

                             {
                                (this.props.pageCustomQuestions)
                                    ?
                                    <div className="row">
                                        {this.props.pageCustomQuestions.map((val, index) => {
                                            if(val.Options.isActive){
                                                return (
                                                    <div className="col-12">
                                                        <div className="form-group row">
                                                            <GetField 
                                                            val={val} 
                                                            selectedItem={this.state.selectedResult ? this.state.selectedResult : null} 
                                                            handleChange={(e) => {
                                                                this.setState({selectedResult: e})
                                                            }}  
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            } 
                                            else {
                                                return <></>
                                            }
                                        })}
                                    </div>
                                    :
                                    <div className="invisible"></div>
                            }


                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" onClick={async() => {
                                let formValidation = {
                                    Name: this.state.selectedResult.Name,
                                    IsEnabled: this.state.selectedResult.IsEnabled,
                                    Description: this.state.selectedResult.Description,
                                }
                                let passed = 1;

                                if(testLabels && testLabels.length > 0 && this.state.selectedResult.IsEnabled === 0){
                                    //check to see if result is assigned to a test
                                    let testNames = [];
                                    for(let i = 0; i < testLabels.length; i++){
                                        testNames.push(testLabels[i].label)
                                    }                                    
                                    if(testNames.length > 0){
                                        passed = 0;
                                        if(testNames.length > 1){
                                            let nameString = testNames.toString().replace(/,/g, ', ')
                                            return sweetalert.fire({icon: 'error', title: '', html:`<p style="font-weight: bold;">${this.state.selectedResult.Name}</p><p>result must be removed from the below tests before it can be disabled.</p><p style="font-weight: bold;">${nameString}</p>`});
                                        } else {
                                            return sweetalert.fire({icon: 'error', title: '', text: `<p style="font-weight: bold;">${this.state.selectedResult.Name}</p><p>result must be removed from the below test before it can be disabled <p style="font-weight: bold;">${testNames[0]}</p>`});
                                        }
                                }}

                                if(passed === 0){
                                    return;
                                }

                                let validator = new Validator<any>()
                                .withValidation("Name", Validators.requireNotBlankAndLength(500))
                                .withValidation("Description", Validators.requireNotBlankAndLength(500))
                                if(!this.state.selectedResult.IsEnabled && this.state.selectedResult.IsEnabled !== 0){
                                    validator = validator.withSimpleValidation("IsEnabled", Validators.requireNonNullValidator("Is Enabled"))
                                }  
                                let validationResponse = validator.validate(formValidation);
                                if(!validationResponse.success) {
                                    return sweetalert.fire({icon: 'error', title: '', text: validationResponse.error});
                                }

                                let invalid = CustomFieldBuilder.validateCustomFields(this.state.selectedResult.CustomFieldData, this.props.pageCustomQuestions, this.state.selectedResult)

                                if(invalid){
                                    return;
                                }

                                
                                hideModal(ResultsManagementModal.ID)
                                this.props.onSubmit(this.state.selectedResult);
                               
                                this.setState({
                                    selectedResult: {
                                        ID: null,
                                        Name: '',
                                        IsEnabled: null,
                                        Description: '',
                                        CustomFieldData: null,
                                        CreatedDate: null
                                    } as Result,
                                })
                            }}>
                                {this.state.selectedResult && this.state.selectedResult.ID ? 'Update' : 'Create'}
                            </button>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        )
    }
}