import React from "react";
import 'react-quill/dist/quill.snow.css';
import Select from "react-select";
import Overlay from "../Overlay";
import {
    getBoolSelectVal, getNumPages, getPageOptions, hideModal,
    showModalNoOutsideClick,
    slicePages
} from "../../util/FormatUtil";
import { sweetalert } from '../../App';
import FilterCard, { FIELD_TYPE, ReactSelect } from "../FilterCard";
import NetworkUtil from "../../network/NetworkUtil";
import { Column } from "../tables/TableBase";
import { Sorter, TableOrder } from "../../sorting/Sorter";
import SimpleTable from "../tables/SimpleTable";
import InBetweenOverlay from "../InBetweenOverlay";
import Task, { FakeTasks} from "../../types/Task";
import { FakeEmployee, fakeEmployees } from "../../types/FakeEmployees";
import { TaskModal } from "../modals/TaskModal";

const ITEMS_PER_PAGE = 25;

interface TasksState {
    selectedTask?;
    tasks: Task[]
    showLoading?
    showInBetween?
    filter?: {
        EmployeeID,
        Title,
        OSHACaseNum
    }
    tableData: {}[]
    direction?: TableOrder
    page_options: ReactSelect[]
    selected_page?: { label, value }
    taskQueryResults: Task[],
    employees?
}

export default class Tasks extends React.Component<any, TasksState> {

    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            showInBetween: false,
            selectedTask: {} as Task,
            tasks: [],
            filter: {
                EmployeeID: "",
                Title:"",
                OSHACaseNum:""
            },
            tableData: [] as any,
            direction: 'asc',
            page_options: [{ label: "1", value: "1" }],
            selected_page: { label: "1", value: 1 },
            taskQueryResults: [] as any,
            employees:[],
        }
        this.createOrModifyTask = this.createOrModifyTask.bind(this);
        this.assignClearState = this.assignClearState.bind(this)
    }


    clearFilterState:() => void = null;

    assignClearState(func){
        this.clearFilterState = func;
    }

    componentDidMount() {
        document.title = 'Task Management';
        this.setState({ showLoading: true }, () => {
            let fakeTasks: Task[] = FakeTasks;
            let employees = fakeEmployees;
            this.setState({ showLoading: false, tasks: fakeTasks, employees: employees})
            this.queryTask(1)
        })
    }

    queryTask(page: number) {
        this.setState({ showLoading: true }, async () => {
            try {
                let filter = {
                    EmployeeID: this.state.filter.EmployeeID,
                    Title: this.state.filter.Title,
                    OSHACaseNum: this.state.filter.OSHACaseNum
                }

                let fakeTasks: Task[] = FakeTasks; 
                let filteredTasks;
                if(filter.EmployeeID !== ''){
                    filteredTasks = fakeTasks.filter(fc => {
                        return fc.EmployeeID === filter.EmployeeID
                    });
                }
                if(filter.Title !== ''){
                    // console.log(' filter.Title', filter.Title)
                    filteredTasks = fakeTasks.filter(fc => {
                        return fc.Title === filter.Title
                    });
                }
                if(filter.OSHACaseNum !== ''){
                    filteredTasks = fakeTasks.filter(fc => {
                        return fc.OSHACaseNum === filter.OSHACaseNum
                    });
                }
                if(filter.EmployeeID === "" &&
                    filter.Title ==="" &&
                    filter.OSHACaseNum === ""
                ){
                    filteredTasks = fakeTasks;
                }

                let results = {
                    success: true,
                    reason: "",
                    data: filteredTasks 
                }

                this.setState({
                    tableData: slicePages(results.data, page, ITEMS_PER_PAGE),
                    taskQueryResults: results.data,
                    page_options: getPageOptions(getNumPages(results.data, ITEMS_PER_PAGE)),
                    showLoading: false,
                })
            } catch (e) {
                console.error(e)
                this.setState({ showLoading: false })
            }
        })
    }


    createOrModifyTask(task: Task) {
        this.setState({ showLoading: true }, async () => {
            let completed = JSON.parse(JSON.stringify(task));
            let updatedTasks = FakeTasks;

            for(let i=0; i < updatedTasks.length; i++){
                if(task.ID === updatedTasks[i].ID){
                    updatedTasks[i].CompletedDate = new Date().toLocaleString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: true
                    });
                }
            }
            this.setState({ showLoading: false, tableData:updatedTasks })
        });
    }

    handleExportToCSV() {
        let fakeTasks: Task[] = FakeTasks 
        let filteredTasks = fakeTasks;

        if(this.state.filter.EmployeeID !== ''){
            filteredTasks = fakeTasks.filter(fc => {
                return fc.EmployeeID === this.state.filter.EmployeeID
            });
        }
        if(this.state.filter.Title !== ''){
            filteredTasks = fakeTasks.filter(fc =>{
                fc.Title === this.state.filter.Title
            })
        }


        this.setState({ showLoading: true }, async () => {
            await NetworkUtil.downloadCSV(
                "/api/admin/tasks/csv",
                "TaskManagement.xlsx",
                { filter: filteredTasks }
            )
            this.setState({ showLoading: false })
        });


        
    }

    useSorter(col: Column) {
        let sorter = new Sorter<any>()
        this.setState({ 
            tableData: sorter.sortByKey(this.state.tableData, col.key as keyof any, this.state.direction), 
            direction: this.state.direction === 'asc' ? 'desc' : 'asc' 
        });
    }

    changePage(page: number) {
        let allTasks = this.state.taskQueryResults;
        let returnData = slicePages(allTasks, page, ITEMS_PER_PAGE);
        this.setState({ tableData: returnData });
    }

    renderTaskFilterFields() {
        return (
            <FilterCard 
                passClearStateFunc={this.assignClearState}
                fields={[
                    {
                        label: "Employee",
                        key: "EmployeeID",
                        type: FIELD_TYPE.SELECT,
                        options: this.state.employees.map(u => { return { label: u.DisplayName, value: u.ID } }),
                        isMapped: true,
                        isClearable: true,
                        textType: "text",
                        isFilter: true,
                        disable: this.state.filter['Title'] !== ""
                    },
                    {
                        label: "Title",
                        "key": "Title",
                        type: FIELD_TYPE.SELECT,
                        options: this.state.tasks?.map(t => { return { label: t.Title, value: t.Title } }),
                        isClearable: true,
                        isMapped: true,
                        isMulti: false,
                        disable: this.state.filter['EmployeeID'] !== "",
                        textType: 'text'
                    },
                    {
                        label: "OSHA Case #",
                        "key": "OSHACaseNum",
                        type: FIELD_TYPE.SELECT,
                        options: this.state.tasks?.filter(task => { 
                            if(task.OSHACaseNum !== null){
                                return task;
                            }
                        }).map( t=> { return { label: t.OSHACaseNum, value: t.OSHACaseNum}}),
                        isClearable: true,
                        isMapped: true,
                        isMulti: false,
                        disable: this.state.filter['EmployeeID'] !== "",
                        textType: 'text'
                    }
                ]}
                filterChanged={(e) => this.setState({ filter: e })} />
        )
    }

    render() {
        return (<React.Fragment>
            <Overlay show_loading={this.state.showLoading} />
            <InBetweenOverlay showInBetween={this.state.showInBetween} />
            <TaskModal
                selectedTask={this.state.selectedTask}
                onSubmit={(task: Task) => {
                    hideModal(TaskModal.ID);
                    this.createOrModifyTask(task);
                }}
                tasks={this.state.tasks}
                employees={this.state.employees}
                requery={null}
            />
            <div className="container-fluid ">
                <div className={"row"}>
                    <div className="col-12 col-lg-8 col-xl-5 pt-2">
                        <div className="card mb-2">
                            <div className="card-header verlag-bold">
                                <h4>Task Management</h4>
                            </div>
                            <div className="card-body">
                                {this.renderTaskFilterFields()}
                            </div>
                            <div className="card-footer">
                                <button className={"btn immySubmitButtonOutline"}
                                    onClick={() => {
                                        this.setState({
                                            selected_page: { label: 1, value: 1 }
                                        }, () => {
                                            this.queryTask(this.state.selected_page.value)
                                        })
                                    }}
                                >Search</button>

                                <button className="btn immyClearButtonOutline ml-1" 
                                    onClick={() => { 
                                        this.clearFilterState(); 
                                        this.setState({ filter: {EmployeeID: "", Title: "", OSHACaseNum: ""}, tableData: [] }) 
                                    }}>Clear
                                </button>

                                <button className="btn btn-outline-success float-right"
                                    onClick={() => this.setState({
                                        selectedTask: {
                                            ID: null,
                                            Title: '',
                                            EmployeeID: '',
                                            Description: null,
                                        } as Task
                                    }, () => {
                                        showModalNoOutsideClick(TaskModal.ID)
                                    })}
                                >Create New</button>
                            </div>
                        </div>
                    </div>

                    {
                        this.state.tableData &&
                        this.state.tableData.length > 0 &&

                        <div className="col-md-12 pt-2">
                            <div className="card mt-2 mb-5">
                                <div className="card-header verlag-bold">
                                    <h4 className="text-center text-md-left">Task List
                                        <section className="tableHeaderSection float-md-right d-flex justify-content-around">
                                            <h4 className={'float-md-right'}>Total: {this.state.taskQueryResults.length}</h4>
                                            <h4 className="float-right align-middle pr-2 ml-5">Page </h4>
                                            <div className=" align-middle float-right pages ">
                                                <Select
                                                    isSearchable={true}
                                                    placeholder={"1"}
                                                    noOptionsMessage={() => "No option"}
                                                    value={this.state.selected_page}
                                                    onChange={(e: ReactSelect) => this.setState({ selected_page: e },
                                                        () => this.changePage(e.value))}
                                                    className={"state_select"}
                                                    options={this.state.page_options}
                                                />
                                            </div>
                                        </section>
                                        <button className={"d-none d-md-inline btn btn-outline-primary ml-3"}
                                            onClick={() => this.handleExportToCSV()}
                                        >Export to CSV
                                        </button>
                                    </h4>
                                </div>
                                <div className="card-body p-0 m-0 table-responsive">
                                    <SimpleTable table_style='tableFixHead' columns={[
                                        {
                                            label: "Employee", key: "EmployeeID",
                                            popoverText: "Click to sort by Employee",
                                            rawFormat: (val) => {
                                                // console.log(" val", val)
                                                let employee:FakeEmployee = this.state.employees?.filter(e => e.ID === val.EmployeeID)

                                                // console.log(" employee? ", employee)
                                                return <>{employee[0].FirstName} {employee[0].LastName}</>;
                                            },
                                        },
                                        {
                                            label: "Title", key: "Title",
                                            rawFormat: (val) => {
                                                return <a href={"#!"} className={'tableNameLinkColor'} onClick={() => {
                                                    this.setState({ selectedTask: val}, () => {
                                                        showModalNoOutsideClick("taskmodal")
                                                    });
                                                }}>{val.Title}</a>
                                            },
                                            popoverText: "Click to sort by Title"
                                        },
                                        {
                                            label: "Description", key: "Description",
                                            popoverText: "Click to sort by Description"
                                        },
                                        {
                                            label: "Priority", key: "Priority",
                                            popoverText: "Click to sort by Priority"
                                        },
                                        {
                                            label: "Due Date", key: "DueDate",
                                            popoverText: "Click to sort by Due Date"
                                        },
                                        {
                                            label: "Reminder Date", key: "DueDate",
                                            popoverText: "Click to sort by Reminder Date"
                                        },
                                        {
                                            label: "Repeat?", key: "Repeat",
                                            popoverText: "Click to sort by Repeat?",
                                            rawFormat: (val) => {
                                                let string = getBoolSelectVal(val.Repeat)
                                                return <p>{string.label}</p>
                                            }
                                        },
                                        {
                                            label: "Frequency", key: "Frequency",
                                            popoverText: "Click to sort by Frequency"
                                        },
                                        {
                                            label: "Notify Supervisor?", key: "NotifySupervisor",
                                            popoverText: "Click to sort by Notify Supervisor?",
                                            rawFormat: (val) => {
                                                let string = getBoolSelectVal(val.NotifySupervisor)
                                                return <p>{string.label}</p>
                                            }
                                        },
                                        {
                                            label: "OSHA Case #", key: "OSHACaseNum",
                                            popoverText: "Click to sort by OSHA Case #"
                                        },
                                        {
                                            label: "Completed Date", key: "CompletedDate",
                                            popoverText: "Click to sort by Completed Date"
                                        },

                                    ]} table_data={this.state.tableData} columnClickedCallback={(col => {
                                        this.useSorter(col);
                                    })} />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </React.Fragment>)
    }
}