import React from "react";
import ReactQuill from "react-quill";
import Select from "react-select";
import { Service } from "../../types/Service";
import { sweetalert } from "../../App";
import { isBlank, isOnlyWhitespace } from "../../util/ValidationUtil";
import {
  buildRow,
  createCustomFieldDataArray,
  getBoolSelectOptions,
  getBoolSelectVal,
  getEmailReportToOptions,
  getEmailReportToVal,
  getNameFromID,
  handleCloseModal,
  hideModal,
  showModalNoOutsideClick,
  getLabelIDsArray,
  getLabel,
  getSelectLabels,
  getlabelsFromIDs,
} from "../../util/FormatUtil";
import { get, isEmptyObject } from "jquery";
import {
  CustomFieldTypesObj,
  CustomFieldTypesPages,
} from "../../types/CustomFieldType";
import { CustomFieldBuilder } from "../../customfields/CustomFieldBuilder";
import { QuestionnaireModal } from "./QuestionnaireModal";
import InBetweenOverlay from "../InBetweenOverlay";
import Overlay from "../Overlay";
import SystemAPI from "../../network/SystemAPI";
import GetField from "../../customfields/GetField";
import ServicesAPI from "../../network/ServicesAPI";

interface ServicesManagementState {
  selectedService: Service;
  customFieldData?;
  showInBetween;
  selectedServiceQuestions?;
  showLoading;
  fieldTypes: { label; value }[];
  validPages: { label; value }[];
  changesMade: boolean;
  isQuestionnaire;
}
interface ServicesManagementProps {
  selectedService;
  onSubmit?;
  pageCustomQuestions?;
  enabledEvaluations?;
  enabledVaccines?;
  enabledTests?;
  tests?;
  vaccines?;
  evaluations?;
  serviceTypes?;
}
export class ServicesManagementModal extends React.Component<
  ServicesManagementProps,
  ServicesManagementState
> {
  public static ID = "servicesmanagement";
  constructor(props) {
    super(props);
    this.state = {
      showInBetween: false,
      showLoading: false,
      changesMade: false,
      isQuestionnaire: false,
      fieldTypes: [],
      validPages: [],
      selectedService: {
        ID: null,
        Name: "",
        IsEnabled: getBoolSelectVal(0),
        // ShowInsurance: getBoolSelectVal(0),
        // ShowFileUpload: getBoolSelectVal(0),
        HtmlTemplate: "",
        AdditionalInformation: "",
        ImportantInformation: "",
        InformedConsent: "",
        Summary: "",
        // ReleaseOfInformation: "",
        // ROIConsentAuth: "",
        CustomFieldData: null,
        Tests: [],
        Vaccines: [],
        Evaluations: [],
        AllowMultipleTests: getBoolSelectVal(0),
        AllowMultipleEvaluations: getBoolSelectVal(0),
        AllowMultipleVaccines: getBoolSelectVal(0),
        ServiceTypes: [],
      } as Service,
    } as ServicesManagementState;
  }

  componentDidMount(): void {
    $(`#${ServicesManagementModal.ID}`).on("shown.bs.modal", function () {
      $(document).off("focusin.modal");
    });
  }

  componentWillReceiveProps(
    nextProps: Readonly<ServicesManagementProps>,
    nextContext: any
  ) {
    // console.log("nextProps", nextProps);
    if (!isEmptyObject(nextProps.selectedService)) {
      let service = nextProps.selectedService;
      if (service.hasOwnProperty("IsEnabled")) {
        this.setState({
          changesMade: false,
          selectedService: {
            ID: service.ID,
            Name: service.Name,
            IsEnabled: getBoolSelectVal(service.IsEnabled),
            // ShowInsurance:getBoolSelectVal(service.ShowInsurance),
            // ShowFileUpload: getBoolSelectVal(service.ShowFileUpload),
            HtmlTemplate: service.HtmlTemplate,
            ImportantInformation: service.ImportantInformation,
            AdditionalInformation: service.AdditionalInformation
              ? service.AdditionalInformation
              : null,
            InformedConsent: service.InformedConsent,
            Summary: service.Summary,
            // ReleaseOfInformation: service.ReleaseOfInformation,
            // ROIConsentAuth: service.ROIConsentAuth,
            CustomFieldData:
              nextProps.selectedService &&
              nextProps.selectedService.CustomFieldData
                ? JSON.parse(nextProps.selectedService.CustomFieldData)
                : null,
            Tests: service.Tests
              ? getlabelsFromIDs(service.Tests, this.props.tests)
              : [],
            Vaccines: service.Vaccines
              ? getlabelsFromIDs(service.Vaccines, this.props.vaccines)
              : [],
            Evaluations: service.Evaluations
              ? getlabelsFromIDs(service.Evaluations, this.props.evaluations)
              : [],
            AllowMultipleTests: getBoolSelectVal(service.AllowMultipleTests),
            AllowMultipleEvaluations: getBoolSelectVal(
              service.AllowMultipleEvaluations
            ),
            AllowMultipleVaccines: getBoolSelectVal(
              service.AllowMultipleVaccines
            ),
            ServiceTypes: service.ServiceTypes
              ? getlabelsFromIDs(service.ServiceTypes, this.props.serviceTypes)
              : [],
          } as Service,
        });
      } else {
        this.setState({
          changesMade: false,
          selectedService: {
            ID: service.ID,
            Name: service.Name,
            IsEnabled: getBoolSelectVal(0),
            // ShowFileUpload: getBoolSelectVal(0),
            HtmlTemplate: "",
            ImportantInformation: "",
            AdditionalInformation: "",
            InformedConsent: "",
            Summary: "",
            // ReleaseOfInformation: '',
            // ROIConsentAuth: '',
            CustomFieldData: null,
            Tests: [],
            Vaccines: [],
            Evaluations: [],
            AllowMultipleTests: getBoolSelectVal(0),
            AllowMultipleEvaluations: getBoolSelectVal(0),
            AllowMultipleVaccines: getBoolSelectVal(0),
            ServiceTypes: [],
          } as Service,
        });
      }
    }
  }

  getCustomQuestions(id) {
    this.setState({ showLoading: true }, async () => {
      SystemAPI.getCustomQuestionsByID(id)
        .then((res) => {
          this.setState(
            {
              isQuestionnaire: true,
              selectedServiceQuestions: res.questions.sort(
                (a, b) => a.Order - b.Order
              ),
              validPages: createCustomFieldDataArray(CustomFieldTypesPages),
              fieldTypes: createCustomFieldDataArray(CustomFieldTypesObj),
              showLoading: false,
            },
            () => showModalNoOutsideClick(QuestionnaireModal.ID)
          );
        })
        .catch((e) => {
          return sweetalert
            .fire({
              icon: "info",
              title: "",
              text: "Could not load questions at this time.",
            })
            .then((res) => {
              hideModal(QuestionnaireModal.ID);
              this.setState({ showLoading: false, showInBetween: false });
            });
        });
    });
  }

  render() {
    // console.log('props in ServiceMmgtModal', this.props)
    // console.log("state in ServiceMmgtModal", this.state);
    let serviceTypesNameArray = this.state.selectedService && this.state.selectedService.ServiceTypes && this.state.selectedService.ServiceTypes.length > 0 ? this.state.selectedService.ServiceTypes.map((val) => {return val.label}) : [];
    return (
      <>
        <Overlay show_loading={this.state.showLoading} zIndex={100003} />
        <InBetweenOverlay
          showInBetween={this.state.showInBetween}
          zIndex={100002}
        />
        <QuestionnaireModal
          isQuestionnaire={this.state.isQuestionnaire}
          validPages={this.state.validPages}
          fieldTypes={this.state.fieldTypes}
          selectedService={this.props.selectedService}
          selectedServiceQuestions={this.state.selectedServiceQuestions}
          handleInBetween={() => this.setState({ showInBetween: false })}
          serviceTypes={this.props.serviceTypes}
        />
        <div
          className="modal fade form_modal"
          id={ServicesManagementModal.ID}
          tabIndex={-1}
          role="dialog"
          aria-labelledby="result_modal_label"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-xl" role="document">
            <div className="modal-content">
              <div className="container-fluid">
                <div className="modal-header">
                  <h5 className="modal-title" id="result_modal_label">
                    {this.state.selectedService && this.state.selectedService.ID ? 'Update Service' : 'Create Service'}
                  </h5>
                  <button
                    style={{ outline: "none" }}
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={() => {
                      if (this.state.changesMade) {
                        handleCloseModal(ServicesManagementModal.ID);
                      } else {
                        hideModal(ServicesManagementModal.ID);
                      }
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                
                <div className="modal-body">
                  {buildRow(
                    "Name",
                    <input
                      className={"form-control"}
                      id="Name"
                      maxLength={50}
                      autoComplete={"off"}
                      type={"search"}
                      name={"Name"}
                      aria-label="Name"
                      onChange={(e) => {
                        this.setState((prevState) => ({
                          changesMade: true,
                          selectedService: {
                            ...prevState.selectedService,
                            Name: e.target.value,
                          },
                        }));
                      }}
                      value={this.state.selectedService.Name}
                    />,
                    "Service Name"
                  )}
                  {buildRow(
                    "Is Enabled",
                    <Select
                      isSearchable={true}
                      placeholder={
                        <div className="accessibilityText">
                          Please Select...
                        </div>
                      }
                      noOptionsMessage={() => "No option"}
                      value={this.state.selectedService.IsEnabled}
                      aria-label="Is Service Enabled?"
                      onChange={(e) =>
                        this.setState((prevState) => ({
                          changesMade: true,
                          selectedService: {
                            ...prevState.selectedService,
                            IsEnabled: e,
                          },
                        }))
                      }
                      className={"state_select"}
                      options={getBoolSelectOptions()}
                    />,
                    "Enable service: Service will not show if it is disabled"
                  )}
                  {buildRow(
                    "Service Type",
                    <Select
                      isSearchable={true}
                      isMulti={true}
                      placeholder={
                        <div className="accessibilityText">
                          Please Select...
                        </div>
                      }
                      noOptionsMessage={() => "No option"}
                      value={this.state.selectedService.ServiceTypes}
                      aria-label="Service Type"
                      onChange={(e) =>
                        this.setState((prevState) => ({
                          changesMade: true,
                          selectedService: {
                            ...prevState.selectedService,
                            ServiceTypes: e,
                            Vaccines: serviceTypesNameArray.includes("Vaccine") ? this.state.selectedService.Vaccines : [],
                            Evaluations: serviceTypesNameArray.includes("Evaluation") ? this.state.selectedService.Evaluations : [],
                            Tests: serviceTypesNameArray.includes("Test") ? this.state.selectedService.Tests : [],
                            AllowMultipleVaccines: serviceTypesNameArray.includes("Vaccine") ? getBoolSelectVal(this.state.selectedService.AllowMultipleVaccines) : getBoolSelectVal(0),
                            AllowMultipleEvaluations: serviceTypesNameArray.includes("Evaluation") ? getBoolSelectVal(this.state.selectedService.AllowMultipleEvaluations) : getBoolSelectVal(0),
                            AllowMultipleTests: serviceTypesNameArray.includes("Test") ? getBoolSelectVal(this.state.selectedService.AllowMultipleTests) : getBoolSelectVal(0),
                          },
                        }))
                      }
                      className={"state_select"}
                      options={this.props.serviceTypes}
                    />,
                    "Service Type that will be associated with this service"
                  )}
                  {/* {buildRow("Show Insurance",
                                <Select
                                    isSearchable={true}
                                    placeholder={<div className="accessibilityText">Please Select...</div>}
                                    noOptionsMessage={() => "No option"}
                                    value={ this.state.selectedService.ShowInsurance }
                                    aria-label="Show insurance?"
                                    onChange={(e) => this.setState((prevState)=>({
                                        changesMade: true,
                                        selectedService: {
                                            ...prevState.selectedService,
                                            ShowInsurance: e
                                        }
                                    }))}
                                    className={"state_select"}
                                    options={getBoolSelectOptions()}
                                />
                            , 'Collect insurance information with this service')} */}
                  {/* {buildRow(
                    "Show File Upload",
                    <Select
                      isSearchable={true}
                      placeholder={
                        <div className="accessibilityText">
                          Please Select...
                        </div>
                      }
                      noOptionsMessage={() => "No option"}
                      value={this.state.selectedService.ShowFileUpload}
                      aria-label="Show insurance?"
                      onChange={(e) =>
                        this.setState((prevState) => ({
                          changesMade: true,
                          selectedService: {
                            ...prevState.selectedService,
                            ShowFileUpload: e,
                          },
                        }))
                      }
                      className={"state_select"}
                      options={getBoolSelectOptions()}
                    />,
                    "Show file upload section with this service"
                  )} */}
                  {serviceTypesNameArray &&
                    serviceTypesNameArray.length > 0 &&
                    serviceTypesNameArray.includes("Vaccine") &&
                    buildRow(
                      "Allow Multiple Vaccines",
                      <Select
                        isSearchable={true}
                        placeholder={
                          <div className="accessibilityText">
                            Please Select...
                          </div>
                        }
                        noOptionsMessage={() => "No option"}
                        value={this.state.selectedService.AllowMultipleVaccines}
                        aria-label="Allow Multiple Vaccines"
                        onChange={(e) =>
                          this.setState((prevState) => ({
                            changesMade: true,
                            selectedService: {
                              ...prevState.selectedService,
                              AllowMultipleVaccines: e,
                            },
                          }))
                        }
                        className={"state_select"}
                        options={getBoolSelectOptions()}
                      />,
                      "Allow for multiple tests to be offered with this service"
                    )}
                  {serviceTypesNameArray &&
                    serviceTypesNameArray.length > 0 &&
                    serviceTypesNameArray.includes("Evaluation") &&
                    buildRow(
                      "Allow Multiple Evaluations",
                      <Select
                        isSearchable={true}
                        placeholder={
                          <div className="accessibilityText">
                            Please Select...
                          </div>
                        }
                        noOptionsMessage={() => "No option"}
                        value={
                          this.state.selectedService.AllowMultipleEvaluations
                        }
                        aria-label="Allow Multiple Evaluations"
                        onChange={(e) =>
                          this.setState((prevState) => ({
                            changesMade: true,
                            selectedService: {
                              ...prevState.selectedService,
                              AllowMultipleEvaluations: e,
                            },
                          }))
                        }
                        className={"state_select"}
                        options={getBoolSelectOptions()}
                      />,
                      "Allow for multiple tests to be offered with this service"
                    )}
                  {serviceTypesNameArray &&
                    serviceTypesNameArray.length > 0 &&
                    serviceTypesNameArray.includes("Test") &&
                    buildRow(
                      "Allow Multiple Tests",
                      <Select
                        isSearchable={true}
                        placeholder={
                          <div className="accessibilityText">
                            Please Select...
                          </div>
                        }
                        noOptionsMessage={() => "No option"}
                        value={this.state.selectedService.AllowMultipleTests}
                        aria-label="Allow Multiple Tests"
                        onChange={(e) =>
                          this.setState((prevState) => ({
                            changesMade: true,
                            selectedService: {
                              ...prevState.selectedService,
                              AllowMultipleTests: e,
                            },
                          }))
                        }
                        className={"state_select"}
                        options={getBoolSelectOptions()}
                      />,
                      "Allow for multiple tests to be offered with this service"
                    )}
                  {serviceTypesNameArray &&
                    serviceTypesNameArray.length > 0 &&
                    serviceTypesNameArray.includes("Vaccine") &&
                    buildRow(
                      "Vaccines",
                      <Select
                        isSearchable={true}
                        isMulti={true}
                        placeholder={"Please Select..."}
                        noOptionsMessage={() => "No option"}
                        value={this.state.selectedService.Vaccines}
                        onChange={(e) =>
                          this.setState((prevState) => ({
                            changesMade: true,
                            selectedService: {
                              ...prevState.selectedService,
                              Vaccines: e,
                            },
                          }))
                        }
                        className={"state_select"}
                        options={this.props.enabledVaccines}
                      />,
                      "Vaccines that will be associated with this service"
                    )}
                  {serviceTypesNameArray &&
                    serviceTypesNameArray.length > 0 &&
                    serviceTypesNameArray.includes("Evaluation") &&
                    buildRow(
                      "Evaluations",
                      <Select
                        isSearchable={true}
                        isMulti={true}
                        placeholder={"Please Select..."}
                        noOptionsMessage={() => "No option"}
                        value={this.state.selectedService.Evaluations}
                        onChange={(e) =>
                          this.setState((prevState) => ({
                            changesMade: true,
                            selectedService: {
                              ...prevState.selectedService,
                              Evaluations: e,
                            },
                          }))
                        }
                        className={"state_select"}
                        options={this.props.enabledEvaluations}
                      />,
                      "Evaluations that will be associated with this service"
                    )}
                  {serviceTypesNameArray &&
                    serviceTypesNameArray.length > 0 &&
                    serviceTypesNameArray.includes("Test") &&
                    buildRow(
                      "Tests",
                      <Select
                        isSearchable={true}
                        isMulti={true}
                        placeholder={
                          <div className="accessibilityText">
                            Please Select...
                          </div>
                        }
                        noOptionsMessage={() => "No option"}
                        value={this.state.selectedService.Tests}
                        aria-label="Tests"
                        onChange={(e) =>
                          this.setState((prevState) => ({
                            changesMade: true,
                            selectedService: {
                              ...prevState.selectedService,
                              Tests: e,
                            },
                          }))
                        }
                        className={"state_select"}
                        options={this.props.tests}
                      />,
                      "Tests that will be associated with this service"
                    )}
                  {buildRow(
                    "Important Information",
                    <ReactQuill
                      theme="snow"
                      value={this.state.selectedService.ImportantInformation}
                      // modules={{keyboard: {
                      //     bindings: {
                      //       tab: {
                      //         key: 9,
                      //         handler: function (range, context) {
                      //           return true;
                      //         },
                      //       },
                      //     },
                      //   }}}
                      onChange={(val) =>
                        this.setState((prevState) => ({
                          changesMade: true,
                          selectedService: {
                            ...prevState.selectedService,
                            ImportantInformation: val,
                          },
                        }))
                      }
                    />,
                    "Text to be displayed on the Appointment page in Important Information section"
                  )}
                  {/* {buildRow("Release of Information",
                                <ReactQuill
                                    theme="snow"
                                    value={this.state.selectedService.ReleaseOfInformation}
                                    // modules={{keyboard: {
                                    //     bindings: {
                                    //       tab: {
                                    //         key: 9,
                                    //         handler: function (range, context) {
                                    //           return true;
                                    //         },
                                    //       },
                                    //     },
                                    //   }}}
                                    onChange={(val) => this.setState((prevState)=>({
                                        changesMade: true,
                                        selectedService: {
                                            ...prevState.selectedService,
                                            ReleaseOfInformation: val
                                        }
                                    }))}
                                />
                            , 'Text to be displayed on the Appointment page in Release of Information section')}
                            {buildRow("ROI Consent Authorization",
                                <ReactQuill
                                    theme="snow"
                                    value={this.state.selectedService.ROIConsentAuth}
                                    // modules={{keyboard: {
                                    //     bindings: {
                                    //       tab: {
                                    //         key: 9,
                                    //         handler: function (range, context) {
                                    //           return true;
                                    //         },
                                    //       },
                                    //     },
                                    //   }}}
                                    onChange={(val) => this.setState((prevState)=>({
                                        changesMade: true,
                                        selectedService: {
                                            ...prevState.selectedService,
                                            ROIConsentAuth: val
                                        }
                                    }))}
                                />
                            , 'Text to be displayed when confirming ROI Consent Authorization')} */}
                  {buildRow(
                    "Informed Consent",
                    <ReactQuill
                      theme="snow"
                      value={this.state.selectedService.InformedConsent}
                      // modules={{keyboard: {
                      //     bindings: {
                      //       tab: {
                      //         key: 9,
                      //         handler: function (range, context) {
                      //           return true;
                      //         },
                      //       },
                      //     },
                      //   }}}
                      onChange={(val) =>
                        this.setState((prevState) => ({
                          changesMade: true,
                          selectedService: {
                            ...prevState.selectedService,
                            InformedConsent: val,
                          },
                        }))
                      }
                    />,
                    "Text to be displayed on the Appointment page in Summary section when Informed Consent button is pressed"
                  )}
                  {buildRow(
                    "Summary",
                    <ReactQuill
                      theme="snow"
                      value={this.state.selectedService.Summary}
                      // modules={{keyboard: {
                      //     bindings: {
                      //       tab: {
                      //         key: 9,
                      //         handler: function (range, context) {
                      //           return true;
                      //         },
                      //       },
                      //     },
                      //   }}}
                      onChange={(val) =>
                        this.setState((prevState) => ({
                          changesMade: true,
                          selectedService: {
                            ...prevState.selectedService,
                            Summary: val,
                          },
                        }))
                      }
                    />,
                    "Text to be displayed on the Appointment page in Summary section"
                  )}
                  {buildRow(
                    "Confirmation Description",
                    <ReactQuill
                      theme="snow"
                      value={this.state.selectedService.HtmlTemplate}
                      // modules={{keyboard: {
                      //     bindings: {
                      //       tab: {
                      //         key: 9,
                      //         handler: function (range, context) {
                      //           return true;
                      //         },
                      //       },
                      //     },
                      //   }}}
                      onChange={(val) =>
                        this.setState((prevState) => ({
                          changesMade: true,
                          selectedService: {
                            ...prevState.selectedService,
                            HtmlTemplate: val,
                          },
                        }))
                      }
                    />,
                    "Text to be displayed on the Confirmation page after an appointment is made"
                  )}
                  {/* {buildRow("Additional Information",
                                <ReactQuill
                                    theme="snow"
                                    value={this.state.selectedService.AdditionalInformation}
                                    onChange={(val) => this.setState((prevState)=>({
                                        changesMade: true,
                                        selectedService: {
                                            ...prevState.selectedService,
                                            AdditionalInformation: val
                                        }
                                    }))}
                                />
                            , 'Text to be displayed on the Confirmation page footer after an appointment is made')} */}
                  {/*Custom Field Data*/}

                  {this.props.pageCustomQuestions ? (
                    <div className="row">
                      {this.props.pageCustomQuestions.map((val, index) => {
                        if (val.Options.isActive) {
                          return (
                            <div className="col-12">
                              <div className="form-group row">
                                <GetField
                                  val={val}
                                  selectedItem={
                                    this.state.selectedService
                                      ? this.state.selectedService
                                      : null
                                  }
                                  handleChange={(e) => {
                                    this.setState({
                                      changesMade: true,
                                      selectedService: e,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          );
                        } else {
                          return <></>;
                        }
                      })}
                    </div>
                  ) : (
                    <div className="invisible"></div>
                  )}
                </div>
                <div className="modal-footer">
                  <div className="d-flex justify-content-start">
                    {this.state.selectedService.ID && (
                      <>
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={() => {
                            if (this.state.changesMade) {
                              return sweetalert.fire(
                                "",
                                "Please submit form to save changes.",
                                "error"
                              );
                            }
                            this.setState({ showInBetween: true });
                            this.getCustomQuestions(
                              this.state.selectedService.ID
                            );
                          }}
                        >
                          View Questionnaire
                        </button>
                      </>
                    )}
                  </div>
                  <div className="ml-auto">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={async () => {
                        if (
                          isBlank(this.state.selectedService.Name) ||
                          isOnlyWhitespace(this.state.selectedService.Name)
                        ) {
                          return sweetalert.fire(
                            "",
                            "Name cannot be empty.",
                            "error"
                          );
                        }
                        if (!this.state.selectedService.IsEnabled) {
                          return sweetalert.fire(
                            "",
                            "Please select a value for Is Enabled",
                            "error"
                          );
                        }
                        if (
                          !this.state.selectedService.ServiceTypes ||
                          this.state.selectedService.ServiceTypes.length < 1
                        ) {
                          return sweetalert.fire(
                            "",
                            "Please select a value for Service Types",
                            "error"
                          );
                        }

                        if(serviceTypesNameArray.includes("Vaccine")){
                          if(!this.state.selectedService.Vaccines || this.state.selectedService.Vaccines.length < 1){
                            return sweetalert.fire(
                              "",
                              "Please select a value for Vaccines",
                              "error"
                            );
                          }
                          if(this.state.selectedService.AllowMultipleVaccines === null){
                            return sweetalert.fire(
                              "",
                              "Please select a value for Allow Multiple Vaccines",
                              "error"
                            );
                          }
                        }
                        if(serviceTypesNameArray.includes("Evaluation")){
                            if(!this.state.selectedService.Evaluations || this.state.selectedService.Evaluations.length < 1){
                              return sweetalert.fire(
                                "",
                                "Please select a value for Evaluations",
                                "error"
                              );
                            }
                            if(this.state.selectedService.AllowMultipleEvaluations === null){
                              return sweetalert.fire(
                                "",
                                "Please select a value for Allow Multiple Evaluations",
                                "error"
                              );
                            }
                        }
                        if(serviceTypesNameArray.includes("Test")){
                            if(!this.state.selectedService.Tests || this.state.selectedService.Tests.length < 1){
                              return sweetalert.fire(
                                "",
                                "Please select a value for Tests",
                                "error"
                              );
                            }
                            if(this.state.selectedService.AllowMultipleTests === null){
                              return sweetalert.fire(
                                "",
                                "Please select a value for Allow Multiple Tests",
                                "error"
                              );
                            }
                        }

                        let regex = /<p>(\s|&nbsp;)*<\/p>/i; //find blank space inbetween p tags
                        if (
                          !this.state.selectedService.ImportantInformation ||
                          isBlank(
                            this.state.selectedService.ImportantInformation
                          ) ||
                          isOnlyWhitespace(
                            this.state.selectedService.ImportantInformation
                          ) ||
                          this.state.selectedService.ImportantInformation ===
                            "<p><br></p>" ||
                          regex.test(
                            this.state.selectedService.ImportantInformation
                          )
                        ) {
                          return sweetalert.fire(
                            "",
                            "Important Information cannot be empty",
                            "error"
                          );
                        }
                        if (
                          !this.state.selectedService.InformedConsent ||
                          isBlank(this.state.selectedService.InformedConsent) ||
                          isOnlyWhitespace(
                            this.state.selectedService.InformedConsent
                          ) ||
                          this.state.selectedService.InformedConsent ===
                            "<p><br></p>" ||
                          regex.test(this.state.selectedService.InformedConsent)
                        ) {
                          return sweetalert.fire(
                            "",
                            "Informed Consent cannot be empty",
                            "error"
                          );
                        }
                        if (
                          !this.state.selectedService.Summary ||
                          isBlank(this.state.selectedService.Summary) ||
                          isOnlyWhitespace(
                            this.state.selectedService.Summary
                          ) ||
                          this.state.selectedService.Summary ===
                            "<p><br></p>" ||
                          regex.test(this.state.selectedService.Summary)
                        ) {
                          return sweetalert.fire(
                            "",
                            "Summary cannot be empty",
                            "error"
                          );
                        }
                        if (
                          !this.state.selectedService.HtmlTemplate ||
                          isBlank(this.state.selectedService.HtmlTemplate) ||
                          isOnlyWhitespace(
                            this.state.selectedService.HtmlTemplate
                          ) ||
                          this.state.selectedService.HtmlTemplate ===
                            "<p><br></p>" ||
                          regex.test(this.state.selectedService.HtmlTemplate)
                        ) {
                          return sweetalert.fire(
                            "",
                            "Confirmation Description cannot be empty",
                            "error"
                          );
                        }
                        let invalid = CustomFieldBuilder.validateCustomFields(
                          this.state.selectedService.CustomFieldData,
                          this.props.pageCustomQuestions,
                          this.state.selectedService
                        );

                        if (invalid) {
                          return;
                        }

                        let selectedServiceCopy = JSON.parse(
                          JSON.stringify(this.state.selectedService)
                        );

                        let selectedServiceTestFixed = {
                          ...selectedServiceCopy,
                          Tests: selectedServiceCopy.Tests
                            ? getLabelIDsArray(selectedServiceCopy.Tests)
                            : null,
                          Vaccines: selectedServiceCopy.Vaccines
                            ? getLabelIDsArray(selectedServiceCopy.Vaccines)
                            : null,
                          Evaluations: selectedServiceCopy.Evaluations
                          ? getLabelIDsArray(selectedServiceCopy.Evaluations)
                            : null,
                          ServiceTypes: selectedServiceCopy.ServiceTypes
                            ? getLabelIDsArray(selectedServiceCopy.ServiceTypes)
                            : null,
                        };


                        this.setState({ changesMade: false });

                        this.props.onSubmit(selectedServiceTestFixed);
                      }}
                    >
                      {this.state.selectedService && this.state.selectedService.ID ? 'Update' : 'Create'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
