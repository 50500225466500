import React from "react";
import { ReactSelect } from "../FilterCard";
import {
  parseDate,
  getPageOptions,
  hideModal,
  showModalNoOutsideClick,
  getEmployeeNameForTable,
  getEmployeeDOBForTable,
  getSelectLabels,
  getTranslatedOptions,
} from "../../util/FormatUtil";
import Overlay from "../Overlay";
import Select from "react-select";
import { sweetalert } from "../../App";
import NetworkUtil from "../../network/NetworkUtil";
import SystemAPI from "../../network/SystemAPI";
import { Column } from "../tables/TableBase";
import { Sorter, TableOrder } from "../../sorting/Sorter";
import PaginationTool from "../PaginationTool";
import OSHAFormModal from "../modals/OSHAFormModal";
import OSHAAPI from "../../network/OSHAAPI";
import SimpleTable from "../tables/SimpleTable";
import InBetweenOverlay from "../InBetweenOverlay";
import { withTranslation } from "react-i18next";
import { FacilityAPI } from "../../network/FacilityAPI";

interface OSHAState {
  states: any;
  genders: any;
  selectedRecord?;
  records?;
  totalRecords?: number;
  showLoading: boolean;
  showModalLoading: boolean;
  direction: TableOrder;
  page_options: ReactSelect[];
  selected_page?;
  tableData?;
  searched?;
  filteredObj: {
    caseNumber;
    employeeUID;
    caseClassification;
    facility;
    injuryType;
    filteredObjBeginDate;
    filteredObjEndDate;
  };
  summaryFilteredObj: {
    summaryFacility;
    annualAverageNumberofEmployees;
    totalHoursWorkedByAllEmployeesLastYear;
    summaryBeginDate;
    summaryEndDate;
  };
  allEmployees;
  allCaseNumbers;
  allInjuryTypes;
  allCaseClassifications;
  selectedEmployee?;
  selectedFacility?;
  selectedCaseNumber?;
  selectedInjuryType?;
  selectedCaseClassification?;
  facilities?;
  facilityLabels;
  summaryFacility?;
}

interface OSHAProps {
  t;
}

class OSHA extends React.Component<OSHAProps, OSHAState> {
  private componentRef: any;
  constructor(props) {
    super(props);
    this.state = {
      selected_page: { label: 1, value: 1 },
      states: [],
      genders: [],
      showLoading: false,
      showModalLoading: false,
      records: [],
      totalRecords: 0,
      direction: "asc",
      page_options: [{ value: "1", label: "1" }],
      filteredObj: {
        caseNumber: "",
        employeeUID: null,
        caseClassification: null,
        facility: null,
        injuryType: null,
        filteredObjBeginDate: null,
        filteredObjEndDate: null,
      },
      summaryFilteredObj: {
        summaryFacility: null,
        annualAverageNumberofEmployees: null,
        totalHoursWorkedByAllEmployeesLastYear: null,
        summaryBeginDate: null,
        summaryEndDate: null,
      },
      allEmployees: [],
      allCaseNumbers: [],
      allInjuryTypes: [],
      allCaseClassifications: [],
      facilities: [],
      facilityLabels: [],
    };
    this.submit = this.submit.bind(this);
    this.handleExportToCSV = this.handleExportToCSV.bind(this);
  }

  async componentDidMount() {
    document.title = "OSHA Report";
    this.setState({ showLoading: true }, () => {
      SystemAPI.getAllStates().then((data) => {
        this.setState({ states: data });
      });
      SystemAPI.getAllGenders().then((data) => {
        this.setState({ genders: data });
      });
      SystemAPI.getAllEmployees().then((data) => {
        this.setState({ allEmployees: data });
      });
      SystemAPI.getAllCaseNumbers().then((data) => {
        this.setState({ allCaseNumbers: data });
      });
      SystemAPI.getCaseClassificationTypes().then((data) => {
        this.setState({ allCaseClassifications: data });
      });
      SystemAPI.getInjuryTypes().then((data) => {
        this.setState({ allInjuryTypes: data });
      });
      FacilityAPI.getFacilitiesForSubmissionForm().then((data) => {
        this.setState({
          facilities: data.authorizedFacilities,
          facilityLabels: getSelectLabels(data.authorizedFacilities),
        });
      });
      this.submit(1);
    });
  }

  submit(page) {
    if (
      this.state.filteredObj.filteredObjBeginDate &&
      this.state.filteredObj.filteredObjEndDate &&
      new Date(this.state.filteredObj.filteredObjBeginDate) >
        new Date(this.state.filteredObj.filteredObjEndDate)
    ) {
      return sweetalert.fire({
        icon: "warning",
        title: "",
        text: this.props.t("Begin date must be before end date"),
      });
    }
    this.setState({ showLoading: true }, async () => {
      hideModal("oshaform");
      try {
        let result = await OSHAAPI.getFilteredRecords(page, {
          filter: this.state.filteredObj,
        });
        if (result.totalRecords < 1) {
          sweetalert.fire({
            icon: "error",
            title: "",
            text: this.props.t("No reports found"),
          });
          this.setState({
            showLoading: false,
            showModalLoading: false,
            tableData: [],
            totalRecords: 0,
          });
          return;
        }
        this.setState({
          totalRecords: result.totalRecords,
          showLoading: false,
          showModalLoading: false,
          page_options: getPageOptions(result.num_pages),
          tableData: result.table_data,
          searched: this.state.filteredObj,
        });
      } catch (e) {
        console.error(e);
        sweetalert.fire({
          icon: "error",
          title: "",
          text: this.props.t("Search failed. Please try again."),
        });
        this.setState({
          tableData: [],
          showLoading: false,
          showModalLoading: false,
        });
      }
    });
  }

  handleExportToCSV() {
    let filter = this.state.searched;
    this.setState({ showLoading: true }, async () => {
      await NetworkUtil.downloadCSV(
        "/api/admin/osha/csv",
        this.props.t(
          "Log of Work-Related Injuries and Illnesses (Form 300).xlsx"
        ),
        { filter: filter }
      );
      this.setState({ showLoading: false });
    });
  }

  useSorter(col: Column) {
    let sorter = new Sorter<any>();
    this.setState({
      tableData: sorter.sortByKey(
        this.state.tableData,
        col.key as keyof any,
        this.state.direction
      ),
      direction: this.state.direction === "asc" ? "desc" : "asc",
    });
  }

  handleReportSubmit(report) {
    hideModal("oshaform");
    this.setState({ showModalLoading: true }, async () => {
      try {
        let result = await OSHAAPI.upsertReport(report);
        if (result.success) {
          this.setState({
            selectedRecord: null,
            showLoading: false,
            showModalLoading: false,
          });
          sweetalert.fire({
            icon: "success",
            title: "",
            text: this.props.t(
              `Report ${
                report.CaseNumber ? "updated" : "created"
              } successfully.`
            ),
          });
          this.submit(1);
        } else {
          sweetalert.fire({
            icon: "error",
            title: "",
            text: this.props.t(
              `Could not ${
                report.CaseNumber ? "Update" : "Create"
              } report at this time.`
            ),
          });
          this.setState({ showModalLoading: false });
        }
      } catch (e) {
        console.error(e);
        sweetalert.fire({
          icon: "error",
          title: "",
          text: this.props.t(
            `Could not ${
              report.CaseNumber ? "Update" : "Create"
            } report at this time.`
          ),
        });
        this.setState({ showModalLoading: false });
      }
    });
  }

  async createSummary() {
    if (!this.state.summaryFilteredObj.summaryFacility) {
      return sweetalert.fire({
        icon: "warning",
        title: "",
        text: this.props.t(
          "Summary Facility needed to generate Summary (Form 300A)"
        ),
      });
    }
    if (
      !this.state.summaryFilteredObj.summaryBeginDate ||
      !this.state.summaryFilteredObj.summaryEndDate
    ) {
      return sweetalert.fire({
        icon: "warning",
        title: "",
        text: this.props.t(
          "Summary Date Range needed to generate Summary (Form 300A)"
        ),
      });
    }
    if (
      new Date(this.state.summaryFilteredObj.summaryBeginDate) >
      new Date(this.state.summaryFilteredObj.summaryEndDate)
    ) {
      return sweetalert.fire({
        icon: "warning",
        title: "",
        text: this.props.t("Summary begin date must be before end date"),
      });
    }

    let summaryFacility = JSON.parse(
      JSON.stringify(this.state.summaryFilteredObj.summaryFacility)
    );
    let summaryBeginDate = JSON.parse(
      JSON.stringify(this.state.summaryFilteredObj.summaryBeginDate)
    );
    let summaryEndDate = JSON.parse(
      JSON.stringify(this.state.summaryFilteredObj.summaryEndDate)
    );
    let annualAverageNumberofEmployees =
      this.state.summaryFilteredObj.annualAverageNumberofEmployees &&
      this.state.summaryFilteredObj.annualAverageNumberofEmployees > 0
        ? JSON.parse(
            JSON.stringify(
              this.state.summaryFilteredObj.annualAverageNumberofEmployees
            )
          )
        : null;
    let totalHoursWorkedByAllEmployeesLastYear =
      this.state.summaryFilteredObj.totalHoursWorkedByAllEmployeesLastYear &&
      this.state.summaryFilteredObj.totalHoursWorkedByAllEmployeesLastYear > 0
        ? JSON.parse(
            JSON.stringify(
              this.state.summaryFilteredObj
                .totalHoursWorkedByAllEmployeesLastYear
            )
          )
        : null;

    if (
      !this.state.summaryFilteredObj.annualAverageNumberofEmployees ||
      this.state.summaryFilteredObj.annualAverageNumberofEmployees < 1 ||
      !this.state.summaryFilteredObj.totalHoursWorkedByAllEmployeesLastYear ||
      this.state.summaryFilteredObj.totalHoursWorkedByAllEmployeesLastYear < 1
    ) {
      sweetalert
        .fire({
          title: "",
          html: `<p style="padding-bottom: 10px; padding-top: 10px; font-weight: bold">${this.props.t(
            "Final Report requires Annual Average Number of Employees and Total Hours Worked By All Employees Last Year"
          )}</p><p>${this.props.t(
            "Would you like to generate a draft report?"
          )}</p>`,
          showCloseButton: false,
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: this.props.t("Generate Draft"),
          denyButtonText: this.props.t("Cancel"),
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.setState({ showLoading: true });
            let reason;
            try {
              let response = await OSHAAPI.generateSummary(
                summaryFacility,
                summaryBeginDate,
                summaryEndDate
              );
              reason = response && response.reason ? response.reason : "";
              let data = await response.arrayBuffer();
              const blob = new Blob([data], { type: "application/pdf" });
              const url = URL.createObjectURL(blob);
              window.open(url, "_blank");
              this.setState({ showLoading: false });
            } catch (e) {
              this.setState({ showLoading: false });
              return sweetalert.fire({
                icon: "error",
                title: "",
                text: reason,
              });
            }
          } else {
            return;
          }
        });
    } else {
      let reason;
      try {
        this.setState({ showLoading: true });
        let response = await OSHAAPI.generateSummary(
          summaryFacility,
          summaryBeginDate,
          summaryEndDate,
          annualAverageNumberofEmployees,
          totalHoursWorkedByAllEmployeesLastYear
        );
        reason = response && response.reason ? response.reason : "";
        let data = await response.arrayBuffer();
        const blob = new Blob([data], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        window.open(url, "_blank");
        this.setState({ showLoading: false });
      } catch (e) {
        this.setState({ showLoading: false });
        return sweetalert.fire({ icon: "error", title: "", text: reason });
      }
    }
  }

  getDateRange(
    beginStateToSet,
    beginState,
    endStateToSet,
    endState,
    label,
    tooltip
  ) {
    if (
      (window.innerWidth > 1000 && window.innerWidth <= 1200) ||
      window.innerWidth >= 1400
    ) {
      return (
        <div className="form-group row">
          <div
            className={"col-sm-4"}
            data-toggle={"tooltip"}
            data-placement={"top"}
            title={this.props.t(tooltip)}
          >
            <label htmlFor={label} className={"pt-1 col-form-label"}>
              {" "}
              {this.props.t(label)}{" "}
            </label>
          </div>

          <div className={"col-sm-1 p-0"}>
            <label
              id={`begin${label.replaceAll(" ", "")}Filter`}
              aria-label=""
              htmlFor="beginDate"
            >
              {this.props.t("Begin")}:{" "}
            </label>
          </div>
          <div className={"col-sm-2 p-0"} id={label}>
            <input
              aria-labelledby={`begin${label.replaceAll(" ", "")}Filter`}
              className={"form-control"}
              autoComplete={"off"}
              type={"date"}
              name={"beginDate"}
              onChange={(e) => {
                this.handleDateOnChange(e.target.value, "filteredObjBeginDate");
              }}
              value={this.state.filteredObj.filteredObjBeginDate}
            />
          </div>
          <div className="col-sm-1"></div>
          <div className={"col-sm-1 p-0"}>
            <label
              id={`end${label.replaceAll(" ", "")}Filter`}
              htmlFor="endDate"
            >
              {this.props.t("End")}:{" "}
            </label>
          </div>
          <div className={"col-sm-2 p-0"} id={label}>
            <input
              aria-labelledby={`end${label.replaceAll(" ", "")}Filter`}
              className={"form-control"}
              autoComplete={"off"}
              type={"date"}
              name={"endDate"}
              onChange={(e) => {
                this.handleDateOnChange(e.target.value, "filteredObjEndDate");
              }}
              value={this.state.filteredObj.filteredObjEndDate}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="form-group row mb-0 pl-3">
          <div
            className={"col-10 col-md-8 pl-0"}
            data-toggle={"tooltip"}
            data-placement={"top"}
            title={this.props.t(tooltip)}
          >
            <label htmlFor={label} className={"py-2 col-form-label"}>
              {" "}
              {this.props.t(label)}{" "}
            </label>
          </div>

          <div className={"col-12 col-md-10 pl-0 pr-1"}>
            <div className="row">
              <div className={"col-4"}>
                <label
                  id={`begin${label.replaceAll(" ", "")}Filter`}
                  aria-label=""
                  htmlFor="beginDate"
                >
                  {this.props.t("Begin")}:{" "}
                </label>
              </div>
              <div className={"col-7 ml-4"} id={label}>
                <input
                  aria-labelledby={`begin${label.replaceAll(" ", "")}Summary`}
                  className={"form-control"}
                  autoComplete={"off"}
                  type={"date"}
                  name={"beginDate"}
                  onChange={(e) => {
                    this.handleDateOnChange(
                      e.target.value,
                      "filteredObjBeginDate"
                    );
                  }}
                  value={this.state.filteredObj.filteredObjBeginDate}
                />
              </div>
            </div>
            <div className={"row"}>
              <div className={"col-4"}>
                <label
                  id={`end${label.replaceAll(" ", "")}Filter`}
                  htmlFor="endDate"
                >
                  {this.props.t("End")}:{" "}
                </label>
              </div>
              <div className={"col-7 ml-4"} id={label}>
                <input
                  aria-labelledby={`end${label.replaceAll(" ", "")}Summary`}
                  className={"form-control"}
                  autoComplete={"off"}
                  type={"date"}
                  name={"endDate"}
                  onChange={(e) => {
                    this.handleDateOnChange(
                      e.target.value,
                      "filteredObjEndDate"
                    );
                  }}
                  value={this.state.filteredObj.filteredObjEndDate}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  getSummaryDateRange = (
    beginStateToSet,
    beginState,
    endStateToSet,
    endState,
    label,
    tooltip
  ) => {
    return (
      <div className="form-group row mb-0 pl-3">
        <div
          className={"col-10 col-md-8 pl-0"}
          data-toggle={"tooltip"}
          data-placement={"top"}
          title={this.props.t(tooltip)}
        >
          <label htmlFor={label} className={"py-2 col-form-label"}>
            {" "}
            {this.props.t(label)}{" "}
          </label>
        </div>

        <div className={"col-12 col-md-10 pl-0 pr-1"}>
          <div className="row">
            <div className={"col-4"}>
              <label
                id={`begin${label.replaceAll(" ", "")}Filter`}
                aria-label=""
                htmlFor="beginDate"
              >
                {this.props.t("Begin")}:{" "}
              </label>
            </div>
            <div className={"col-7"} id={label}>
              <input
                aria-labelledby={`begin${label.replaceAll(" ", "")}Summary`}
                className={"form-control"}
                autoComplete={"off"}
                type={"date"}
                name={"beginDate"}
                onChange={(e) => {
                  this.handleSummaryDateOnChange(
                    e.target.value,
                    beginStateToSet
                  );
                }}
                value={this.state.summaryFilteredObj.summaryBeginDate}
              />
            </div>
          </div>
          <div className={"row"}>
            <div className={"col-4"}>
              <label
                id={`end${label.replaceAll(" ", "")}Filter`}
                htmlFor="endDate"
              >
                {this.props.t("End")}:{" "}
              </label>
            </div>
            <div className={"col-7"} id={label}>
              <input
                aria-labelledby={`end${label.replaceAll(" ", "")}Summary`}
                className={"form-control"}
                autoComplete={"off"}
                type={"date"}
                name={"endDate"}
                onChange={(e) => {
                  this.handleSummaryDateOnChange(e.target.value, endStateToSet);
                }}
                value={this.state.summaryFilteredObj.summaryEndDate}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  handleDateOnChange(value, state) {
    if (value) {
      this.setState((prevState) => ({
        filteredObj: {
          ...prevState.filteredObj,
          [state]: value,
        },
      }));
    } else {
      this.setState((prevState) => ({
        filteredObj: {
          ...prevState.filteredObj,
          [state]: null,
        },
      }));
    }
  }

  handleSummaryDateOnChange(value, state) {
    if (value) {
      this.setState((prevState) => ({
        summaryFilteredObj: {
          ...prevState.summaryFilteredObj,
          [state]: value,
        },
      }));
    } else {
      this.setState((prevState) => ({
        summaryFilteredObj: {
          ...prevState.summaryFilteredObj,
          [state]: null,
        },
      }));
    }
  }

  clearFilterFields() {
    this.setState({
      selectedCaseNumber: null,
      selectedEmployee: null,
      selectedCaseClassification: null,
      selectedFacility: null,
      selectedInjuryType: null,
      filteredObj: {
        caseNumber: "",
        employeeUID: null,
        caseClassification: null,
        facility: null,
        injuryType: null,
        filteredObjBeginDate: "",
        filteredObjEndDate: "",
      },
    });
  }

  clearSummaryFields() {
    this.setState({
      summaryFacility: null,
      summaryFilteredObj: {
        summaryFacility: null,
        annualAverageNumberofEmployees: "",
        totalHoursWorkedByAllEmployeesLastYear: "",
        summaryBeginDate: "",
        summaryEndDate: "",
      },
    });
  }

  render():
    | React.ReactElement
    | string
    | number
    | {}
    | React.ReactNodeArray
    | React.ReactPortal
    | boolean
    | null
    | undefined {
    // console.log('OSHA state', this.state)

    let translationCaseClassifications =
      this.state.allCaseClassifications &&
      this.state.allCaseClassifications.length > 0
        ? getTranslatedOptions(this.state.allCaseClassifications)
        : [];
    let translationInjuryTypes =
      this.state.allInjuryTypes && this.state.allInjuryTypes.length > 0
        ? getTranslatedOptions(this.state.allInjuryTypes)
        : [];

    return (
      <React.Fragment>
        <PaginationTool />
        <div className="container-fluid  min-vh-100 ">
          <a id={"top"}>{}</a>
          <Overlay show_loading={this.state.showLoading} />
          <InBetweenOverlay
            showInBetween={this.state.showModalLoading}
            zIndex={100005}
          />
          <OSHAFormModal
            key={
              this.state.selectedRecord ? this.state.selectedRecord.UID : "New"
            }
            selectedOSHARecord={
              this.state.selectedRecord ? this.state.selectedRecord : {}
            }
            states={this.state.states}
            genders={this.state.genders}
            facilities={this.state.facilities}
            facilityLabels={this.state.facilityLabels}
            allEmployees={this.state.allEmployees}
            caseClassificationTypes={this.state.allCaseClassifications}
            injuryTypes={this.state.allInjuryTypes}
            onSubmit={(e) => this.handleReportSubmit(e)}
          />

          <div className="container-fluid  min-vh-100 ">
            <div className="row">
              <div className="col-12 col-xl-8 pt-2">
                <main
                  id="main-content"
                  tabIndex={-1}
                  aria-label={this.props.t("OSHA")}
                >
                  <div className="card mb-2">
                    <div className="card-header verlag-bold">
                      <h4>
                        {this.props.t("OSHA Reporting")}
                        <span style={{ float: "right" }}>
                          <a
                            style={{ textDecoration: "none", color: "black" }}
                            href="https://www.osha.gov/sites/default/files/OSHA-RK-Forms-Package.pdf"
                            target="_blank"
                          >
                            {this.props.t("Need help?")}
                          </a>
                        </span>
                      </h4>
                    </div>
                    <div className="card-body">
                      <div
                        className="form-group row"
                        data-toggle={"tooltip"}
                        data-placement={"top"}
                        title={this.props.t("Search for employee")}
                      >
                        <label
                          htmlFor={"EmployeeSearch"}
                          className="col-sm-4 col-form-label"
                        >
                          {this.props.t("Employee")}
                        </label>
                        <div
                          className="col-12 col-md-8 pl-0 pr-4"
                          id={"EmployeeSearch"}
                        >
                          <Select
                            id="EmployeeSearch"
                            isMulti={true}
                            isClearable={true}
                            isSearchable={true}
                            placeholder={
                              <div className="accessibilityText">
                                {this.props.t("Please Select...")}
                              </div>
                            }
                            className={"state_select"}
                            name={"EmployeeSearch"}
                            aria-label={this.props.t("Employee Search")}
                            onChange={(e) => {
                              this.setState((prevState) => ({
                                selectedEmployee: e,
                                filteredObj: {
                                  ...prevState.filteredObj,
                                  employeeUID:
                                    e && e.length && e.length > 0 ? e : null,
                                },
                              }));
                            }}
                            value={
                              this.state.selectedEmployee
                                ? this.state.selectedEmployee
                                : null
                            }
                            options={this.state.allEmployees?.map((p) => {
                              return {
                                label: `${p.DisplayName} - ${parseDate(
                                  p.DateOfBirth
                                )}`,
                                value: p.UID,
                              };
                            })}
                          />
                        </div>
                      </div>

                      <div
                        className="form-group row"
                        data-toggle={"tooltip"}
                        data-placement={"top"}
                        title={this.props.t("Search for facility")}
                      >
                        <label
                          htmlFor={"Facility"}
                          className="col-sm-4 col-form-label"
                        >
                          {this.props.t("Facility")}
                        </label>
                        <div
                          className="col-12 col-md-8 pl-0 pr-4"
                          id={"Facility"}
                        >
                          <Select
                            id="Facility"
                            isMulti={true}
                            isClearable={true}
                            isSearchable={true}
                            placeholder={
                              <div className="accessibilityText">
                                {this.props.t("Please Select...")}
                              </div>
                            }
                            className={"state_select"}
                            name={"Facility"}
                            aria-label={this.props.t("Facility")}
                            onChange={(e) => {
                              this.setState((prevState) => ({
                                selectedFacility: e,
                                filteredObj: {
                                  ...prevState.filteredObj,
                                  facility:
                                    e && e.length && e.length > 0 ? e : null,
                                },
                              }));
                            }}
                            value={
                              this.state.selectedFacility
                                ? this.state.selectedFacility
                                : null
                            }
                            options={this.state.facilityLabels}
                          />
                        </div>
                      </div>

                      <div
                        className="form-group row"
                        data-toggle={"tooltip"}
                        data-placement={"top"}
                        title={this.props.t("Search for case classification")}
                      >
                        <label
                          htmlFor={"CaseClassification"}
                          className="col-sm-4 col-form-label"
                        >
                          {this.props.t("Case Classification")}
                        </label>
                        <div
                          className="col-12 col-md-8 pl-0 pr-4"
                          id={"CaseClassification"}
                        >
                          <Select
                            id="CaseClassification"
                            isMulti={true}
                            isClearable={true}
                            isSearchable={true}
                            placeholder={
                              <div className="accessibilityText">
                                {this.props.t("Please Select...")}
                              </div>
                            }
                            className={"state_select"}
                            name={"CaseClassification"}
                            aria-label={this.props.t("Case Classification")}
                            onChange={(e) => {
                              this.setState((prevState) => ({
                                selectedCaseClassification: e,
                                filteredObj: {
                                  ...prevState.filteredObj,
                                  caseClassification:
                                    e && e.length && e.length > 0 ? e : null,
                                },
                              }));
                            }}
                            value={
                              this.state.selectedCaseClassification
                                ? this.state.selectedCaseClassification
                                : null
                            }
                            options={translationCaseClassifications}
                          />
                        </div>
                      </div>

                      <div
                        className="form-group row"
                        data-toggle={"tooltip"}
                        data-placement={"top"}
                        title={this.props.t("Search for injury type")}
                      >
                        <label
                          htmlFor={"InjuryType"}
                          className="col-sm-4 col-form-label"
                        >
                          {this.props.t("Injury Type")}
                        </label>
                        <div
                          className="col-12 col-md-8 pl-0 pr-4"
                          id={"InjuryType"}
                        >
                          <Select
                            id="InjuryType"
                            isMulti={true}
                            isClearable={true}
                            isSearchable={true}
                            placeholder={
                              <div className="accessibilityText">
                                {this.props.t("Please Select...")}
                              </div>
                            }
                            className={"state_select"}
                            name={"InjuryType"}
                            aria-label={this.props.t("Injury Type")}
                            onChange={(e) => {
                              this.setState((prevState) => ({
                                selectedInjuryType: e,
                                filteredObj: {
                                  ...prevState.filteredObj,
                                  injuryType:
                                    e && e.length && e.length > 0 ? e : null,
                                },
                              }));
                            }}
                            value={
                              this.state.selectedInjuryType
                                ? this.state.selectedInjuryType
                                : null
                            }
                            options={translationInjuryTypes}
                          />
                        </div>
                      </div>

                      {this.getDateRange(
                        "filteredObjBeginDate",
                        this.state.filteredObj.filteredObjBeginDate,
                        "filteredObjEndDate",
                        this.state.filteredObj.filteredObjEndDate,
                        this.props.t("Date of Injury Range"),
                        this.props.t("Injury date range to filter by")
                      )}
                    </div>
                    <div className="card-footer">
                      <button
                        className={"btn immyClearButtonOutline float-right"}
                        onClick={() => this.clearFilterFields()}
                      >
                        {this.props.t("Clear")}
                      </button>
                      <button
                        className={"btn immySubmitButtonOutline"}
                        onClick={() => {
                          this.setState(
                            {
                              selected_page: { label: 1, value: 1 },
                            },
                            () => {
                              this.submit(this.state.selected_page.value);
                            }
                          );
                        }}
                      >
                        {this.props.t("Search")}
                      </button>
                      <button
                        className="btn immySubmitButtonOutline ml-2"
                        onClick={() =>
                          this.setState(
                            {
                              selectedRecord: {
                                id: null,
                                employeeFirstName: null,
                                employeeMiddleName: null,
                                employeeLastName: null,
                                employeeAddress: null,
                                employeeCity: null,
                                employeeState: null,
                                employeeStateLabel: null,
                                employeeZip: null,
                                employeeDOB: null,
                                employeeHireDate: null,
                                employeeGenderID: null,
                                employeeGenderLabel: null,
                                physicianFirstName: null,
                                physicianLastName: null,
                                treatmentFacilityAddress: null,
                                treatmentFacilityCity: null,
                                treatmentFacilityName: null,
                                treatmentFacilityState: null,
                                treatmentFacilityStateLabel: null,
                                treatmentFacilityZip: null,
                                treatedInEmergencyRoom: null,
                                treatedInEmergencyRoomLabel: null,
                                wasInemployee: null,
                                wasInemployeeLabel: null,
                                caseNumber: null,
                                dateOfInjury: null,
                                timeEmployeeBeganWork: null,
                                timeOfEvent: null,
                                activityBeforeIncident: null,
                                incidentDescription: null,
                                injuryDescription: null,
                                objectOrSubstanceInvolved: null,
                                didEmployeeDie: null,
                                didEmployeeDieLabel: null,
                                employeeDeathDate: null,
                                formCompletedBy: null,
                                formCompletedByTitle: null,
                                formCompletedByPhone: null,
                                formCompletedDate: null,
                                facilityID: null,
                              },
                            },
                            () => {
                              showModalNoOutsideClick("oshaform");
                            }
                          )
                        }
                      >
                        {this.props.t("New Report")}
                      </button>
                    </div>
                  </div>
                </main>
              </div>
              <div className="col-12 col-xl-4 pt-2">
                <main
                  id="main-content"
                  tabIndex={-1}
                  aria-label={this.props.t("OSHA")}
                >
                  <div className="card mb-2">
                    <div className="card-header verlag-bold">
                      <h4>{this.props.t("Summary (Form 300A)")}</h4>
                    </div>
                    <div className="card-body">
                      <div
                        className="form-group row"
                        data-toggle={"tooltip"}
                        data-placement={"top"}
                        title={this.props.t("Search for summary facility")}
                      >
                        <label
                          htmlFor={"SummaryFacility"}
                          className="col-sm-4 col-form-label"
                        >
                          {this.props.t("Facility")}
                        </label>
                        <div
                          className="col-12 col-md-8 pl-0 pr-4"
                          id={"SummaryFacility"}
                        >
                          <Select
                            id="SummaryFacility"
                            isSearchable={true}
                            placeholder={
                              <div className="accessibilityText">
                                {this.props.t("Please Select...")}
                              </div>
                            }
                            className={"state_select"}
                            name={"SummaryFacility"}
                            aria-label={this.props.t("SummaryFacility")}
                            onChange={(e) => {
                              this.setState((prevState) => ({
                                summaryFacility: e,
                                summaryFilteredObj: {
                                  ...prevState.summaryFilteredObj,
                                  summaryFacility:
                                    e && e.value ? e.value : null,
                                },
                              }));
                            }}
                            value={
                              this.state.summaryFacility
                                ? this.state.summaryFacility
                                : null
                            }
                            options={this.state.facilityLabels}
                          />
                        </div>
                      </div>

                      <div
                        className="form-group row"
                        data-toggle="tooltip"
                        data-placement="top"
                      >
                        <label
                          htmlFor="annualAverageNumberofEmployees"
                          className="col-sm-10 col-form-label"
                        >
                          {this.props.t("Annual Average Number of Employees")}
                        </label>
                        <div className="col-sm-2 p-0 m-0">
                          <input
                            type="number"
                            id="annualAverageNumberofEmployees"
                            autoComplete="off"
                            aria-label={this.props.t(
                              "Annual Average Number of Employees"
                            )}
                            aria-required="false"
                            className="form-control"
                            value={
                              this.state.summaryFilteredObj
                                .annualAverageNumberofEmployees
                            }
                            onChange={(e) => {
                              this.setState((prevState) => ({
                                summaryFilteredObj: {
                                  ...prevState.summaryFilteredObj,
                                  annualAverageNumberofEmployees:
                                    e.target.value,
                                },
                              }));
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="form-group row mb-2"
                        data-toggle="tooltip"
                        data-placement="top"
                      >
                        <label
                          htmlFor="totalHoursWorkedByAllEmployeesLastYear"
                          className="col-sm-10 col-form-label"
                        >
                          {this.props.t(
                            "Total Hours Worked By All Employees Last Year"
                          )}
                        </label>
                        <div className="col-sm-2 p-0 m-0">
                          <input
                            type="number"
                            id="totalHoursWorkedByAllEmployeesLastYear"
                            autoComplete="off"
                            aria-label={this.props.t(
                              "Total Hours Worked By All Employees Last Year"
                            )}
                            aria-required="false"
                            className="form-control"
                            value={
                              this.state.summaryFilteredObj
                                .totalHoursWorkedByAllEmployeesLastYear
                            }
                            onChange={(e) => {
                              this.setState((prevState) => ({
                                summaryFilteredObj: {
                                  ...prevState.summaryFilteredObj,
                                  totalHoursWorkedByAllEmployeesLastYear:
                                    e.target.value,
                                },
                              }));
                            }}
                          />
                        </div>
                      </div>
                      {this.getSummaryDateRange(
                        "summaryBeginDate",
                        this.state.summaryFilteredObj.summaryBeginDate,
                        "summaryEndDate",
                        this.state.summaryFilteredObj.summaryEndDate,
                        this.props.t("Summary Date Range"),
                        this.props.t(
                          "Date range for summary report (Form 300A)."
                        )
                      )}
                    </div>
                    <div className="card-footer">
                      <button
                        className={"btn immyClearButtonOutline float-right"}
                        onClick={() => this.clearSummaryFields()}
                      >
                        {this.props.t("Clear")}
                      </button>
                      <button
                        className={"btn btn-outline-success"}
                        onClick={() => this.createSummary()}
                      >
                        {this.props.t("Generate Summary")}
                      </button>
                    </div>
                  </div>
                </main>
              </div>
            </div>

            {this.state.totalRecords > 0 ? (
              <div className="col-12 pt-2 pr-0 pl-0 pb-3">
                <div className="card mb-5">
                  <div className="card-header verlag-bold stickToTop">
                    <h4 className={"text-center text-md-left"}>
                      {this.props.t("Log (Form 300)")}
                      <button
                        className={
                          this.state.totalRecords < 1
                            ? "d-none"
                            : "btn btn-outline-primary d-none d-md-inline-block d-xxl-inline-block ml-2"
                        }
                        onClick={() => this.handleExportToCSV()}
                      >
                        {this.props.t("Export to CSV")}
                      </button>

                      <section className="tableHeaderSection float-md-right d-flex justify-content-around">
                        <h4
                          className={"float-right"}
                          aria-label={this.props.t("Total Records")}
                          role="alert"
                        >
                          {this.props.t("Total")}: {this.state.totalRecords}
                        </h4>
                        <h4 className="pl-5 pl-md-0 float-md-right align-items-md-center pr-2 ml-md-5">
                          {this.props.t("Page")}
                        </h4>
                        <div className="align-items-md-center float-right pages">
                          <Select
                            isSearchable={true}
                            placeholder={this.props.t("1")}
                            noOptionsMessage={() => this.props.t("No option")}
                            aria-label={this.props.t("Table Page Number")}
                            value={this.state.selected_page}
                            onChange={(e: ReactSelect) =>
                              this.setState({ selected_page: e }, () =>
                                this.submit(e.value)
                              )
                            }
                            className={"state_select page-num-select"}
                            options={this.state.page_options}
                          />
                        </div>
                      </section>
                    </h4>
                  </div>

                  <div className="p-0 table-responsive">
                    <SimpleTable
                      ref={(el) => (this.componentRef = el)}
                      columns={[
                        {
                          label: this.props.t("Employee Name"),
                          key: "",
                          rawFormat: (val) => {
                            return (
                              <a
                                href={"#top"}
                                className={"tableNameLinkColor"}
                                onClick={() =>
                                  this.setState(
                                    {
                                      selectedRecord: JSON.parse(
                                        JSON.stringify(val)
                                      ),
                                    },
                                    () => showModalNoOutsideClick("oshaform")
                                  )
                                }
                              >
                                {val &&
                                this.state.allEmployees &&
                                this.state.allEmployees.length > 0
                                  ? getEmployeeNameForTable(
                                      val,
                                      this.state.allEmployees
                                    )
                                  : null}
                              </a>
                            );
                          },
                        },
                        {
                          label: this.props.t("Employee DOB"),
                          key: "",
                          rawFormat: (val) => {
                            return (
                              <p>
                                {val &&
                                this.state.allEmployees &&
                                this.state.allEmployees.length > 0
                                  ? getEmployeeDOBForTable(
                                      val,
                                      this.state.allEmployees
                                    )
                                  : null}
                              </p>
                            );
                          },
                        },
                        { label: this.props.t("Case #"), key: "CaseNumber" },
                        {
                          label: this.props.t("Facility"),
                          key: "",
                          rawFormat: (val) => {
                            let facilityLabel =
                              this.state.facilities && val.FacilityID
                                ? this.state.facilities.find(
                                    (f) => f.ID === val.FacilityID
                                  )
                                : null;
                            return (
                              <p>
                                {facilityLabel
                                  ? facilityLabel.FacilityName
                                  : null}
                              </p>
                            );
                          },
                        },
                        {
                          label: this.props.t("Injury Date"),
                          key: "DateOfInjury",
                          formatFunc: parseDate,
                        },
                        {
                          label: this.props.t("Case Classification"),
                          key: "",
                          rawFormat: (val) => {
                            let caseClassificationLabel =
                              this.state.allCaseClassifications &&
                              val.CaseClassification
                                ? this.state.allCaseClassifications.find(
                                    (f) => f.value === val.CaseClassification
                                  )
                                : null;
                            return (
                              <p>
                                {caseClassificationLabel
                                  ? caseClassificationLabel.label
                                  : null}
                              </p>
                            );
                          },
                        },
                        {
                          label: this.props.t("Injury Type"),
                          key: "",
                          rawFormat: (val) => {
                            let injuryTypeLabel =
                              this.state.allInjuryTypes && val.TypeOfInjury
                                ? this.state.allInjuryTypes.find(
                                    (f) => f.value === val.TypeOfInjury
                                  )
                                : null;
                            return (
                              <p>
                                {injuryTypeLabel ? injuryTypeLabel.label : null}
                              </p>
                            );
                          },
                        },
                        {
                          label: this.props.t("Submitted By"),
                          key: "FormCompletedBy",
                        },
                        {
                          label: this.props.t("Title"),
                          key: "FormCompletedByTitle",
                        },
                      ]}
                      table_data={this.state.tableData}
                      columnClickedCallback={(col) => {
                        this.useSorter(col);
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(OSHA);
