import React from "react";
import Select from "react-select";
import Overlay from "../Overlay";
import {
    getBoolSelectOptions,
    getBoolSelectVal, getNumPages, getPageOptions, hideModal,
    parseDate,
    showModalNoOutsideClick,
    slicePages
} from "../../util/FormatUtil";
import {sweetalert} from '../../App';
import SystemAPI from "../../network/SystemAPI";
import AdminAPI from "../../network/AdminAPI";
import {AdminPages} from "./AdminNav";
import FilterCard, {FIELD_TYPE, ReactSelect} from "../FilterCard";
import NetworkUtil from "../../network/NetworkUtil";
import {Column} from "../tables/TableBase";
import {Sorter, TableOrder} from "../../sorting/Sorter";
import SimpleTable from "../tables/SimpleTable";
import InBetweenOverlay from "../InBetweenOverlay";
import Employee from "../../types/Employee";
import EmmployeeAPI from "../../network/EmployeeAPI";
import EmployeeManagementModal from "../modals/EmployeeManagementModal";
import EmployeeInfoModal from "../modals/EmployeeInfoModal";
import CustomFieldsAPI from "../../network/CustomFieldsAPI";
import EmployeeAPI from "../../network/EmployeeAPI";
import { FacilityAPI } from "../../network/FacilityAPI";


const ITEMS_PER_PAGE = 25;

interface EmployeeManagementState {
    employeeInformation?;
    employees: Employee[]
    showLoading?
    showInBetween?
    filter?:{
        SelectedEmployee
    }
    tableData:{}[]
    direction?:TableOrder
    page_options:ReactSelect[]
    selected_page?:{label,value}
    employeeQueryResults:Employee[]
    race
    gender
    ethnicity
    states
    countries
    facilities
    customEmployeeFields?
    portalEmployees
}

export default class EmployeeManagement extends React.Component<any, EmployeeManagementState> {
    private employeeInfoRef: any;
    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            showInBetween: false,
            employees: [],
            employeeInformation: {} as Employee,
            filter: {SelectedEmployee:[],
            },
            tableData: [] as any,
            direction: 'asc',
            page_options: [{label: "1", value: "1"}],
            selected_page: {label: "1", value: 1},
            employeeQueryResults: [] as any,
            race: [],
            gender: [],
            ethnicity: [],
            states: [],
            countries: [],
            facilities: [],
            customEmployeeFields: [],
            portalEmployees: []
        }
        // this.modifyEmployee = this.modifyEmployee.bind(this);
        this.employeeInfoRef = React.createRef();
    }


    componentDidMount() {
        document.title = 'Employee Management Page';
        this.setState({showLoading: true}, () => {
            SystemAPI.getAllRaces().then((data) => {
                this.setState({ race: data });
            });
            SystemAPI.getAllGenders().then((data) => {
                this.setState({ gender: data });
            });
            SystemAPI.getAllEthnicities().then((data) => {
                this.setState({ ethnicity: data });
            });
            SystemAPI.getAllStates().then((data) => {
                this.setState({ states: data });
            });
            FacilityAPI.getFacilitiesForSubmissionForm().then((data) => {
                this.setState({facilities: data.authorizedFacilities});
            });
            SystemAPI.getAllCountries().then((data) => {
                this.setState({ countries: data});
            });
            CustomFieldsAPI.getAllCustomFields().then(data =>{
                this.setState({
                    customEmployeeFields: data.data.customEmployeeFields,
                  });
            })
            EmployeeAPI.getEmployeesFromEmployeePortal().then(async data => {
                this.setState({employees: data.employees})
            })
            this.queryEmployees(1)
        })
    }

    queryEmployees(page:number) {
        this.setState({showLoading:true}, async () => {
            try {
                let results = await EmployeeAPI.filterEmployees(this.state.filter);
                if (results.data.length === 0) {
                    sweetalert.fire({
                      icon: "info",
                      title: "",
                      text: "No employees were returned",
                    });
                  }

                if(!results.success){
                    return sweetalert.fire({title: '', text: 'Unable to filter employee data at this time', icon: 'error'})
                }
                this.setState({
                    tableData: slicePages(results.data, page, ITEMS_PER_PAGE),
                    employeeQueryResults: results.data,
                    page_options: getPageOptions(getNumPages(results.data, ITEMS_PER_PAGE)),
                    showLoading: false,
                    portalEmployees: results.data
                })
            } catch (e) {
                console.error(e)
                this.setState({showLoading: false})
            }
        })
    }


    modifyEmployee(employee){
        if(!employee || JSON.stringify(employee) === '{}' || !employee.employeeInfo || JSON.stringify(employee.employeeInfo) === '{}' || !employee.employeeInfo.CustomFieldData || JSON.stringify(employee.employeeInfo.CustomFieldData) === '{}'){
            return sweetalert.fire({icon: 'error', title: '', text: `Employee Information must be complete`})
        }

        let employeeInformationCopy = JSON.parse(JSON.stringify(employee.employeeInfo)); 

        employeeInformationCopy.uid = employeeInformationCopy.CustomFieldData.uid ? employeeInformationCopy.CustomFieldData.uid : null;
        employeeInformationCopy.firstName = employeeInformationCopy.CustomFieldData['First Name'] ? employeeInformationCopy.CustomFieldData['First Name'] : null;
        employeeInformationCopy.middleName = employeeInformationCopy.CustomFieldData['Middle Name'] ? employeeInformationCopy.CustomFieldData['Middle Name'] : null;
        employeeInformationCopy.lastName = employeeInformationCopy.CustomFieldData['Last Name'] ? employeeInformationCopy.CustomFieldData['Last Name'] : null;
        employeeInformationCopy.email = employeeInformationCopy.CustomFieldData.Email ? employeeInformationCopy.CustomFieldData.Email : null;
        employeeInformationCopy.phoneNumber = employeeInformationCopy.CustomFieldData.Phone ? employeeInformationCopy.CustomFieldData.Phone : null;
        employeeInformationCopy.dateOfBirth = employeeInformationCopy.CustomFieldData['Date of Birth'] ? employeeInformationCopy.CustomFieldData['Date of Birth'] : null;
        employeeInformationCopy.streetAddress = employeeInformationCopy.CustomFieldData.Address ? employeeInformationCopy.CustomFieldData.Address : null;
        employeeInformationCopy.streetAddress2 = employeeInformationCopy.CustomFieldData['Address Cont.'] ? employeeInformationCopy.CustomFieldData['Address Cont.'] : null;
        employeeInformationCopy.city = employeeInformationCopy.CustomFieldData.City ? employeeInformationCopy.CustomFieldData.City : null;
        employeeInformationCopy.state = employeeInformationCopy.CustomFieldData.State ? this.state.states.find(f => f.label === employeeInformationCopy.CustomFieldData.State).value : null;
        employeeInformationCopy.county = employeeInformationCopy.CustomFieldData.County ? employeeInformationCopy.CustomFieldData.County : null;
        employeeInformationCopy.zipcode = employeeInformationCopy.CustomFieldData.Zipcode ? employeeInformationCopy.CustomFieldData.Zipcode : null;
        employeeInformationCopy.country = employeeInformationCopy.CustomFieldData.Country ? this.state.countries.find(f => f.label === employeeInformationCopy.CustomFieldData.Country).value : null;
        employeeInformationCopy.genderID = employeeInformationCopy.CustomFieldData.Gender ? this.state.gender.find(f => f.label === employeeInformationCopy.CustomFieldData.Gender).value : null;
        employeeInformationCopy.ethnicityID = employeeInformationCopy.CustomFieldData.Ethnicity ? this.state.ethnicity.find(f => f.label === employeeInformationCopy.CustomFieldData.Ethnicity).value : null;
        employeeInformationCopy.raceID = employeeInformationCopy.CustomFieldData.Race ? this.state.race.find(f => f.label === employeeInformationCopy.CustomFieldData.Race).value : null;
        employeeInformationCopy.guardianFirstName = employeeInformationCopy.CustomFieldData['Guardian First Name'] ? employeeInformationCopy.CustomFieldData['Guardian First Name'] : null;
        employeeInformationCopy.guardianLastName = employeeInformationCopy.CustomFieldData['Guardian Last Name'] ? employeeInformationCopy.CustomFieldData['Guardian Last Name'] : null;
        employeeInformationCopy.FacilityIDs = employeeInformationCopy.CustomFieldData.FacilityIDs ? employeeInformationCopy.CustomFieldData.FacilityIDs : null;

        delete employeeInformationCopy.CustomFieldData.Address;
        delete employeeInformationCopy.CustomFieldData.City;
        delete employeeInformationCopy.CustomFieldData.Country;
        delete employeeInformationCopy.CustomFieldData.County;
        delete employeeInformationCopy.CustomFieldData['Date of Birth'];
        delete employeeInformationCopy.CustomFieldData.Email;
        delete employeeInformationCopy.CustomFieldData.Ethnicity;
        delete employeeInformationCopy.CustomFieldData['First Name'];
        delete employeeInformationCopy.CustomFieldData.Gender;
        delete employeeInformationCopy.CustomFieldData['Last Name'];
        delete employeeInformationCopy.CustomFieldData['Middle Name'];
        delete employeeInformationCopy.CustomFieldData.Phone;
        delete employeeInformationCopy.CustomFieldData.Race;
        delete employeeInformationCopy.CustomFieldData.State;
        delete employeeInformationCopy.CustomFieldData.Zipcode;
        delete employeeInformationCopy.CustomFieldData.uid;
        delete employeeInformationCopy.CustomFieldData['Guardian First Name'];
        delete employeeInformationCopy.CustomFieldData['Guardian Last Name'];
        delete employeeInformationCopy.CustomFieldData.FacilityIDs;
      
        this.setState({showLoading:true}, async() => {
            try{
                let result = await EmployeeAPI.editEmployee(employeeInformationCopy)
                if(result.success){
                    sweetalert.fire({icon: 'success', title: '', text: 'Employee saved'}).then(()=>{
                        this.setState({employeeInformation: {}})
                        this.queryEmployees(this.state.selected_page.value);
                    });
                }else{
                    sweetalert.fire({icon: 'error', title: '', text: result.reason});
                    this.setState({showLoading:false})
                }
            }
            catch (e) {
                console.error(e)
                this.setState({showLoading:false})
            }
        })
    }

    handleExportToCSV(){
        this.setState({showLoading: true}, async () =>{
            await NetworkUtil.downloadCSV(
                "/api/admin/employee/csv",
                "Employee Management Data.xlsx",
                {filter: this.state.filter}
            )
            this.setState({showLoading: false})
        });
    }

    useSorter(col:Column){
        let sorter = new Sorter<any>()

        this.setState({tableData: sorter.sortByKey(this.state.tableData, col.key as keyof any,this.state.direction), direction: this.state.direction === 'asc' ? 'desc' : 'asc'});
    }

    changePage(page:number){
        let allEmployees = this.state.employeeQueryResults;
        let returnData = slicePages(allEmployees, page, ITEMS_PER_PAGE);
        this.setState({ tableData: returnData });
    }

    renderEmployeeMgmtFilterFields(){
        return (
            <FilterCard fields={[
                {
                    label: "UID",
                    "key": "uid",
                    type: FIELD_TYPE.TEXT,
                },
                {
                    label: "First Name",
                    "key": "firstName",
                    type: FIELD_TYPE.TEXT,
                }, 
                {
                    label: "Last Name",
                    "key": "lastName",
                    type: FIELD_TYPE.TEXT,
                }, 
                { label: "Date of Birth", key: "dateOfBirth", type: FIELD_TYPE.DATE },
                // {
                //     label: "Gender",
                //     "key": "genderID",
                //     type: FIELD_TYPE.SELECT,
                //     options: this.state.gender,
                //     isMapped: true,
                //     isMulti: true,
                //     textType: 'text'
                // },
                // {
                //     label: "Ethnicity",
                //     "key": "ethnicityID",
                //     type: FIELD_TYPE.SELECT,
                //     options: this.state.ethnicity,
                //     isMapped: true,
                //     isMulti: true,
                //     textType: 'text'
                // },
                // {
                //     label: "Race",
                //     "key": "raceID",
                //     type: FIELD_TYPE.SELECT,
                //     options: this.state.race,
                //     isMapped: true,
                //     isMulti: true,
                //     textType: 'text'
                // },
            ]} filterChanged={(e)=> this.setState({ filter: e })} />
        )
    }

    render() {

        // console.log('EmployeeMgmt state', this.state)

        return (<React.Fragment>
            <Overlay show_loading={this.state.showLoading}/>
            <InBetweenOverlay showInBetween={this.state.showInBetween} />
            <EmployeeInfoModal 
                ref={this.employeeInfoRef}
                fromEmployeeMgmt={true}
                submit={(e) => {
                    this.modifyEmployee(e);
                }}
                selectedEmployee={this.state.employeeInformation}
                employees={this.state.employees}
                states={this.state.states}
                gender={this.state.gender}
                ethnicity={this.state.ethnicity}
                race={this.state.race}
                countries={this.state.countries}
                facilities={this.state.facilities}
                employeeFields={this.state.customEmployeeFields.sort((a,b) => a.Order - b.Order)}
            />
            <div className="container-fluid ">
                <div className={"row"}>
                    <div className="col-12 col-lg-8 col-xl-5 pt-2">
                        <main id="main-content" tabIndex={-1} aria-label="Employee Management">
                            <div className="card mb-2">
                                <div className="card-header verlag-bold">
                                    <h4>Employee Management</h4>
                                </div>
                                <div className="card-body">
                                    {this.renderEmployeeMgmtFilterFields()}
                                </div>
                                <div className="card-footer">
                                    <button className={"btn immySubmitButtonOutline "}
                                            onClick={ () => {
                                                this.setState({
                                                    selected_page: {label: 1, value: 1}}, () => {
                                                    this.queryEmployees(this.state.selected_page.value)
                                                })
                                            }}
                                    >Search</button>
                                </div>
                            </div>
                        </main>
                    </div>



                    {
                        this.state.tableData &&
                        this.state.tableData.length > 0 &&

                        <div className="col-12 pt-2">
                            <div className="card mt-2 mb-5">
                                <div className="card-header verlag-bold stickToTop">
                                    <h4 className="text-center text-md-left">Employees
                                    <button className={"ml-2 d-none d-md-inline btn btn-outline-primary"} onClick={() => this.handleExportToCSV()} >Export to CSV </button>
                                    <section className="tableHeaderSection float-md-right d-flex justify-content-around">
                                    <h4 style={{ float: "right" }} aria-label="Total Records" role="alert">Total: {this.state.employeeQueryResults.length}</h4>
                                    <h4 className="float-right align-middle pr-2 ml-5">Page </h4>
                                    <div className=" align-middle float-right pages ">
                                        <Select
                                            isSearchable={true}
                                            placeholder={"1"}
                                            noOptionsMessage={() => "No option"}
                                            aria-label="Table Page Number"
                                            value={this.state.selected_page}
                                            onChange={(e: ReactSelect) => this.setState({selected_page: e},
                                                () => this.changePage(e.value))}
                                            className={"state_select"}
                                            options={this.state.page_options}
                                        />
                                    </div>
                                    </section>    
                                    </h4>
                                </div>
                                <div className="card-body p-0 m-0 table-responsive">
                                    <SimpleTable table_style='tableFixHead' columns={[
                                        {
                                            label: "Name", key: "",
                                            rawFormat: (val) => {
                                                return <a href={`/admin/EmployeeRecord/${val ? val.uid : null}`} 
                                                >{val.firstName} {val.lastName}</a>
                                            },
                                            popoverText: "Click to sort by Name"
                                        },
                                        {
                                            label: "UID", key: "uid",
                                            popoverText: "Click to sort by UID"
                                        },
                                        {
                                            label: "Date of Birth", key: "dateOfBirth",
                                            popoverText: "Click to sort by Date of Birth", formatFunc: parseDate
                                        },
                                        {
                                            label: "Email", key: "email",
                                            popoverText: "Click to sort by Email"
                                        },
                                        {
                                            label: "Phone #", key: "phoneNumber",
                                            popoverText: "Click to sort by Phone #"
                                        },
                                        {
                                            label: "City", key: "city",
                                            popoverText: "Click to sort by City"
                                        },
                                        {
                                            label: "State", key: "state",
                                            popoverText: "Click to sort by State"
                                        },
                                    ]} table_data={this.state.tableData} columnClickedCallback={(col =>{
                                        this.useSorter(col);
                                    })} />
                                </div>
                            </div>
                        </div>
                    }

                </div>
            </div>
        </React.Fragment>)
    }
}