import React from "react";
import { AdminPages } from "./AdminNav";

interface SidebarProps {
  items?;
  authPages?;
  onChange: (str: string) => void;
  backgroundColor: string;
  showSidebar;
}

class SidebarItems extends React.Component<SidebarProps, any> {
  private routes = [
    //Submission
    { label: "Submission", value: "Submission" },
    //Events  
    { label: "Events", value: "Events" },
    { label: "Check-in", value: "CheckIn" },
    { label: "Send Alert", value: "Alert" },
    //Employees
    { label: "Employees", value: "Employees" },
    //Reports
    { label: "Audit Logs", value: "AuditLogs" },
    { label: "Analytics", value: "Analytics" },
    //Tasks
    { label: "Tasks", value: "Tasks" },
    //OSHA
    { label: "Report", value: "OSHAReport" },
    //Incidents
    { label: "Incidents", value: "Incidents" },
    //Claims
    { label: "Claims", value: "Claims" },
    //Records
    { label: "Records", value: "Records" },
    //Management
    { label: "Facility", value: "Facility" },
    { label: "Service", value: "Service" },
    { label: "User", value: "User" },
    { label: "Custom Fields", value: "CustomFields" },
    { label: "Employee Fields", value: "EmployeeFields" },
    { label: "Vaccine", value: "VaccineManagement" },
    { label: "Evaluation", value: "EvaluationManagement" },
    { label: "Test", value: "Test" },
    { label: "Result", value: "Result" },
    { label: "Communication", value: "Communication" },
    { label: "File", value: "File" },
    { label: "Banner", value: "Banner" },
    //Settings
    { label: "Password Requirements", value: "PasswordRequirements" },
    // { label: "Import", value: "Import" },
  ];

  private reportPages = [AdminPages.AuditLogs, AdminPages.Analytics];
  private employeeMgmtPages = [AdminPages.Employees];
  private settingsPages = [
    AdminPages.PasswordRequirements,
  ];
  private submissionPages = [AdminPages.Submission];
  private recordPages = [AdminPages.Records,];
  private userManagementPages = [
    AdminPages.Facility,
    AdminPages.User,
    AdminPages.Communication,
    AdminPages.CustomFields,
    AdminPages.EmployeeFields,
    AdminPages.Service,
    AdminPages.File,
    AdminPages.Banner,
    AdminPages.Vaccine,
    AdminPages.Evaluation,
    AdminPages.Test,
    AdminPages.Result,
  ];
  private eventPages = [AdminPages.Events, , AdminPages.CheckIn, AdminPages.Alert];
  private oshaPages = [AdminPages.OSHAReport];
  private incidentPages = [AdminPages.Incidents];
  private claimsPages = [AdminPages.Claims];
  private taskPages = [AdminPages.Tasks];

  itemBelongsUnderTab(pageName, tabName) {
    if (this.props.authPages.includes(pageName) && tabName.includes(pageName)) {
      let route = this.routes.find((f) => f.label === pageName);
      return (
        <li
          className={"sidebar-list-item"}
          role="listitem"
          aria-hidden={!this.props.showSidebar ? "true" : "false"}
          style={{ backgroundColor: this.props.backgroundColor }}
        >
          <a
            className={"navSelect white"}
            tabIndex={!this.props.showSidebar ? -1 : 0}
            aria-hidden={!this.props.showSidebar ? "true" : "false"}
            href={`/admin/${route.value}`}
            onClick={() => this.props.onChange(pageName)}
          >
            {pageName}
          </a>
        </li>
      );
    }

    return <React.Fragment />;
  }

  render() {
    const buildEventsTab = this.props.authPages.some((authPage) =>
      this.eventPages.includes(authPage)
    );
    const buildReportsTab = this.props.authPages.some((authPage) =>
      this.reportPages.includes(authPage)
    );
    const buildSettingsTab = this.props.authPages.some((authPage) =>
      this.settingsPages.includes(authPage)
    );
    const buildEmployeesTab = this.props.authPages.some((authPage) =>
      this.employeeMgmtPages.includes(authPage)
    );
    const buildSubmissionTab = this.props.authPages.some((authPage) =>
      this.submissionPages.includes(authPage)
    );
    const buildUserMgmtTab = this.props.authPages.some((authPage) =>
      this.userManagementPages.includes(authPage)
    );
    const buildRecordsTab = this.props.authPages.some((authPage) =>
      this.recordPages.includes(authPage)
    );
    const buildOSHATab = this.props.authPages.some((authPage) =>
      this.oshaPages.includes(authPage)
    );
    const buildIncidentsTab = this.props.authPages.some((authPage) =>
      this.incidentPages.includes(authPage)
    );
    const buildClaimsTab = this.props.authPages.some((authPage) =>
      this.claimsPages.includes(authPage)
    );
    const buildTasksTab = this.props.authPages.some((authPage) =>
      this.taskPages.includes(authPage)
    );

    return (
      <main className={"sidebar-wrapper"}>
        <ul className={"list-unstyled"} id={"menu"}>
          {buildSubmissionTab ? (
            <li
              className={"sidebar-header"}
              aria-hidden={!this.props.showSidebar ? "true" : "false"}
              id={"submissions-list"}
            >
              <a
                href={"#"}
                className={"navTag white"}
                aria-label="Submission Menu"
                id={"submissions-tab"}
                tabIndex={!this.props.showSidebar ? -1 : 0}
                aria-hidden={!this.props.showSidebar ? "true" : "false"}
                style={{ backgroundColor: this.props.backgroundColor }}
              >
                <span className="navIconWrapper">
                  <img
                    src={
                      "https://storage.googleapis.com/streamline-bucket/appointment.png"
                    }
                    alt=""
                    className="navIcon"
                  />
                </span>
                Submission
                <i
                  id={"employees-caret"}
                  className="fa fa-xs fa-caret-down nav-caret"
                />
              </a>
              <ul className={"list-unstyled"} role="list">
                {this.props.items.map((t) => {
                  return this.itemBelongsUnderTab(t, this.submissionPages);
                })}
              </ul>
            </li>
          ) : (
            <React.Fragment />
          )}
          {buildEventsTab ? (
            <li
              className={"sidebar-header"}
              aria-hidden={!this.props.showSidebar ? "true" : "false"}
              id={"pods-list"}
            >
              <a
                href={"#"}
                className={"navTag white"}
                aria-label="Events Menu"
                id={"pods-tab"}
                tabIndex={!this.props.showSidebar ? -1 : 0}
                aria-hidden={!this.props.showSidebar ? "true" : "false"}
                style={{ backgroundColor: this.props.backgroundColor }}
              >
                <span className="navIconWrapper">
                  <img
                    src={
                      "https://storage.googleapis.com/streamline-bucket/pods.png"
                    }
                    alt=""
                    className="navIcon"
                  />
                </span>
                Events
                <i
                  id={"pods-caret"}
                  className="fa fa-xs fa-caret-down nav-caret"
                />
              </a>
              <ul className={"list-unstyled"} role="list">
                {this.props.items.map((t) => {
                  return this.itemBelongsUnderTab(t, this.eventPages);
                })}
              </ul>
            </li>
          ) : (
            <React.Fragment />
          )}
          {buildRecordsTab ? (
            <li className={"sidebar-header"} id={"records-list"}>
              <a
                href={"#!"}
                className={"navTag primary-black-bg white"}
                id={"records-tab"}
                style={{ backgroundColor: this.props.backgroundColor }}
              >
                <span className="navIconWrapper">
                  <img
                    src={
                      "https://storage.googleapis.com/streamline-bucket/treatment.png"
                    }
                    alt=""
                    className="navIcon"
                  />
                </span>
                Records
                <i
                  id={"records-caret"}
                  className="fa fa-xs fa-caret-down nav-caret"
                />
              </a>
              <ul className={"list-unstyled"}>
                {this.props.items.map((t) => {
                  return this.itemBelongsUnderTab(t, this.recordPages);
                })}
              </ul>
            </li>
          ) : (
            <React.Fragment />
          )}
          {buildEmployeesTab ? (
            <li
              className={"sidebar-header"}
              aria-hidden={!this.props.showSidebar ? "true" : "false"}
              id={"employees-list"}
            >
              <a
                href={"#"}
                className={"navTag primary-black-bg white"}
                aria-label="Employees Menu"
                role="list"
                tabIndex={!this.props.showSidebar ? -1 : 0}
                id={"employees-tab"}
                aria-hidden={!this.props.showSidebar ? "true" : "false"}
                style={{ backgroundColor: this.props.backgroundColor }}
              >
                <span className="navIconWrapper">
                  <img
                    src={
                      "https://storage.googleapis.com/streamline-bucket/people.png"
                    }
                    alt=""
                    className="navIcon"
                  />
                </span>
                Employees
                <i
                  id={"employees-caret"}
                  className="fa fa-xs fa-caret-down nav-caret"
                />
              </a>
              <ul className={"list-unstyled"}>
                {this.props.items.map((t) => {
                  return this.itemBelongsUnderTab(t, this.employeeMgmtPages);
                })}
              </ul>
            </li>
          ) : (
            <React.Fragment />
          )}
          {buildReportsTab ? (
            <li
              className={"sidebar-header"}
              aria-hidden={!this.props.showSidebar ? "true" : "false"}
              id={"reports-list"}
            >
              <a
                href={"#"}
                className={"navTag primary-black-bg white"}
                tabIndex={!this.props.showSidebar ? -1 : 0}
                aria-label="Reports Menu"
                role="list"
                id={"reports-tab"}
                aria-hidden={!this.props.showSidebar ? "true" : "false"}
                style={{ backgroundColor: this.props.backgroundColor }}
              >
                <span className="navIconWrapper">
                  <img
                    src={
                      "https://storage.googleapis.com/streamline-bucket/report.png"
                    }
                    alt=""
                    className="navIcon"
                  />
                </span>
                Reports
                <i
                  id={"reports-caret"}
                  className="fa fa-xs fa-caret-down nav-caret"
                />
              </a>
              <ul className={"list-unstyled"}>
                {this.props.items.map((t) => {
                  return this.itemBelongsUnderTab(t, this.reportPages);
                })}
              </ul>
            </li>
          ) : (
            <React.Fragment />
          )}
          {buildTasksTab ? (
            <li
              className={"sidebar-header"}
              aria-hidden={!this.props.showSidebar ? "true" : "false"}
              id={"task-list"}
            >
              <a
                href={"#"}
                className={"navTag primary-black-bg white"}
                tabIndex={!this.props.showSidebar ? -1 : 0}
                aria-label="Tasks Menu"
                role="list"
                id={"tasks-tab"}
                aria-hidden={!this.props.showSidebar ? "true" : "false"}
                style={{ backgroundColor: this.props.backgroundColor }}
              >
                <span className="navIconWrapper">
                  <img
                    src={
                      "https://storage.googleapis.com/streamline-bucket/planning.png"
                    }
                    alt=""
                    className="navIcon"
                  />
                </span>
                Tasks
                <i
                  id={"user-mgmt-caret"}
                  className="fa fa-xs fa-caret-down nav-caret"
                />
              </a>
              <ul className={"list-unstyled"}>
                {this.props.items.map((t) => {
                  return this.itemBelongsUnderTab(t, this.taskPages);
                })}
              </ul>
            </li>
          ) : (
            <React.Fragment />
          )}
          {buildOSHATab ? (
            <li
              className={"sidebar-header"}
              aria-hidden={!this.props.showSidebar ? "true" : "false"}
              id={"osha-list"}
            >
              <a
                href={"#"}
                className={"navTag primary-black-bg white"}
                tabIndex={!this.props.showSidebar ? -1 : 0}
                aria-label="OSHA"
                role="list"
                id={"osha-tab"}
                aria-hidden={!this.props.showSidebar ? "true" : "false"}
                style={{ backgroundColor: this.props.backgroundColor }}
              >
                <span className="navIconWrapper">
                  <img
                    src={
                      "https://storage.googleapis.com/streamline-bucket/patient.png"
                    }
                    alt=""
                    className="navIcon"
                  />
                </span>
                OSHA
                <i
                  id={"osha-caret"}
                  className="fa fa-xs fa-caret-down nav-caret"
                />
              </a>
              <ul className={"list-unstyled"}>
                {this.props.items.map((t) => {
                  return this.itemBelongsUnderTab(t, this.oshaPages);
                })}
              </ul>
            </li>
          ) : (
            <React.Fragment />
          )}
          {buildIncidentsTab ? (
            <li
              className={"sidebar-header"}
              aria-hidden={!this.props.showSidebar ? "true" : "false"}
              id={"incidents-list"}
            >
              <a
                href={"#"}
                className={"navTag primary-black-bg white"}
                tabIndex={!this.props.showSidebar ? -1 : 0}
                aria-label="Incidents Menu"
                role="list"
                id={"incidents-tab"}
                aria-hidden={!this.props.showSidebar ? "true" : "false"}
                style={{ backgroundColor: this.props.backgroundColor }}
              >
                <span className="navIconWrapper">
                  <img
                    src={
                      "https://storage.googleapis.com/streamline-bucket/evaluation.png"
                    }
                    alt=""
                    className="navIcon"
                  />
                </span>
                Incidents
                <i
                  id={"claims-mgmt-caret"}
                  className="fa fa-xs fa-caret-down nav-caret"
                />
              </a>
              <ul className={"list-unstyled"}>
                {this.props.items.map((t) => {
                  return this.itemBelongsUnderTab(t, this.incidentPages);
                })}
              </ul>
            </li>
          ) : (
            <React.Fragment />
          )}
          {buildClaimsTab ? (
            <li
              className={"sidebar-header"}
              aria-hidden={!this.props.showSidebar ? "true" : "false"}
              id={"claims-list"}
            >
              <a
                href={"#"}
                className={"navTag primary-black-bg white"}
                tabIndex={!this.props.showSidebar ? -1 : 0}
                aria-label="Claims Menu"
                role="list"
                id={"settings-tab"}
                aria-hidden={!this.props.showSidebar ? "true" : "false"}
                style={{ backgroundColor: this.props.backgroundColor }}
              >
                <span className="navIconWrapper">
                  <img
                    src={
                      "https://storage.googleapis.com/streamline-bucket/clipboard.png"
                    }
                    alt=""
                    className="navIcon"
                  />
                </span>
                Claims
                <i
                  id={"claims-mgmt-caret"}
                  className="fa fa-xs fa-caret-down nav-caret"
                />
              </a>
              <ul className={"list-unstyled"}>
                {this.props.items.map((t) => {
                  return this.itemBelongsUnderTab(t, this.claimsPages);
                })}
              </ul>
            </li>
          ) : (
            <React.Fragment />
          )}
          {buildUserMgmtTab ? (
            <li
              className={"sidebar-header"}
              aria-hidden={!this.props.showSidebar ? "true" : "false"}
              id={"user-mgmt-list"}
            >
              <a
                href={"#"}
                className={"navTag primary-black-bg white"}
                tabIndex={!this.props.showSidebar ? -1 : 0}
                aria-label="Management Menu"
                role="list"
                id={"user-mgmt-tab"}
                aria-hidden={!this.props.showSidebar ? "true" : "false"}
                style={{ backgroundColor: this.props.backgroundColor }}
              >
                <span className="navIconWrapper">
                  <img
                    src={
                      "https://storage.googleapis.com/streamline-bucket/setup.png"
                    }
                    alt=""
                    className="navIcon"
                  />
                </span>
                Management
                <i
                  id={"user-mgmt-caret"}
                  className="fa fa-xs fa-caret-down nav-caret"
                />
              </a>
              <ul className={"list-unstyled"}>
                {this.props.items.map((t) => {
                  return this.itemBelongsUnderTab(t, this.userManagementPages);
                })}
              </ul>
            </li>
          ) : (
            <React.Fragment />
          )}
          {buildSettingsTab ? (
            <li
              className={"sidebar-header"}
              aria-hidden={!this.props.showSidebar ? "true" : "false"}
              id={"settings-list"}
            >
              <a
                href={"#"}
                className={"navTag primary-black-bg white"}
                tabIndex={!this.props.showSidebar ? -1 : 0}
                aria-label="Settings Menu"
                role="list"
                id={"settings-tab"}
                aria-hidden={!this.props.showSidebar ? "true" : "false"}
                style={{ backgroundColor: this.props.backgroundColor }}
              >
                <span className="navIconWrapper">
                  <img
                    src={
                      "https://storage.googleapis.com/streamline-bucket/settings.png"
                    }
                    alt=""
                    className="navIcon"
                  />
                </span>
                Settings
                <i
                  id={"user-mgmt-caret"}
                  className="fa fa-xs fa-caret-down nav-caret"
                />
              </a>
              <ul className={"list-unstyled"}>
                {this.props.items.map((t) => {
                  return this.itemBelongsUnderTab(t, this.settingsPages);
                })}
              </ul>
            </li>
          ) : (
            <React.Fragment />
          )}
        </ul>
      </main>
    );
  }
}

export default SidebarItems;
