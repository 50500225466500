import NetworkUtil from "./NetworkUtil";

export default class DocumentUploadAPI {
   
    public static async documentUpload(ID, url:string, documentName:string, file?:Blob):Promise<{success:boolean, reason:string, url?:string}>{
        let data = new FormData()
        data.set('id', ID)
        data.set('url', url)
        data.set('documentName', documentName)
        data.set('file', file)
        return NetworkUtil.postForm("/api/admin/documentUpload/documentUpload", data)
    }

    public static async getDocuments():Promise<{show:boolean, documents?}>{
        return NetworkUtil.makeGet("/api/admin/documentUpload/getDocuments")
    }

    public static async filterDocuments(filter:{filter:{ID}}):Promise<{success:boolean, reason:string, data?}>{
        return NetworkUtil.makePost("/api/admin/documentUpload/filterDocuments", filter)
    }

    public static async deleteDocument(id, url):Promise<{success:boolean, reason:string}>{
        return NetworkUtil.makePost("/api/admin/documentUpload/deleteDocument", {id: id, url: url})
    }

}