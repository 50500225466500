import React from 'react';
import { sweetalert } from '../../App';
import { handleSpecimenIDsOrLots, handleTestsForWalkinAndCheckin, hideModal } from '../../util/FormatUtil';
import Overlay from '../Overlay';
import { CustomFieldBuilder } from '../../customfields/CustomFieldBuilder';
import { isEmptyObject } from 'jquery';
import Select from 'react-select';
import { AiOutlineConsoleSql, AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import { FaCameraRetro } from 'react-icons/fa';
import FilterCard, { FilterPart } from '../FilterCard';
import GetField from '../../customfields/GetField';
import TestType from '../../types/TestType';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import CustomFields from '../admin/CustomFields';
import { ServiceTypes } from '../../types/Service';

interface ServiceFormModalProps {
  submit: (e) => void;
  attachClearStateFunc?: (func) => void;
  customQuestions?;
  service?;
  associatedTests?;
  associatedEvaluations?;
  associatedVaccines?;
  reviewInfo?;
  showTestInfo?;
  showEvaluationInfo?;
  showVaccineInfo?;
  tests?;
  vaccines?;
  evaluations?;
  serviceTypes?;
}

interface ServiceFormModalState {
  showLoading: boolean;
  answeredQuestions?: any;
  recordInfo?: any;
  serviceID?: any;
  serviceSpecificTests;
  answeredQuestionsForGetField?;
}

export class ServiceFormModal extends React.Component<ServiceFormModalProps, ServiceFormModalState> {
  public static ID = 'serviceform';
  constructor(props) {
    super(props);
    this.state = {
      showLoading: false,
      serviceSpecificTests: [],
      recordInfo: {},
    };
  }

  componentDidMount(): void {
    $(`#${ServiceFormModal.ID}`).on('shown.bs.modal', function () {
      $(document).off('focusin.modal');
    });
  }

  componentWillReceiveProps(nextProps: Readonly<ServiceFormModalProps>, nextContext: any): void {
    // console.log('ServiceFormModal nextProps', nextProps);

    if (nextProps.reviewInfo) {
      if (!isEmptyObject(nextProps.reviewInfo)) {
        let customQuestionAnswers = { CustomFieldData: nextProps.reviewInfo.submittedAnswers };

        this.setState({
          answeredQuestions: nextProps.reviewInfo.submittedAnswers,
          recordInfo: nextProps.reviewInfo.recordInfo,
          serviceID: nextProps.reviewInfo.serviceID,
          answeredQuestionsForGetField: customQuestionAnswers,
        });
      }
    } else {
      this.setState({
        answeredQuestions: {},
        answeredQuestionsForGetField: {},
        serviceID: nextProps.service ? nextProps.service.ID : null,
      });
      }
  }

  handleSave() {
    this.setState({ showLoading: true });

    let newAnswerQuestionsObj = {};

    if (!isEmptyObject(this.state.answeredQuestionsForGetField)) {
      let answeredQuestionsArrays = Object.entries(this.state.answeredQuestionsForGetField.CustomFieldData);
      answeredQuestionsArrays.map(a => {
        return (newAnswerQuestionsObj[a[0]] = a[1]);
      });
    }

    let allCustomQuestions = this.props.customQuestions ? JSON.parse(JSON.stringify(this.props.customQuestions)) : [];

    let tempAnsweredQuestions = {};
    if (allCustomQuestions?.length > 0) {
      //remove questions that dont belong to this service
      allCustomQuestions.map((cq, i) => {
        if (!isEmptyObject(newAnswerQuestionsObj)) {
          for (const [key, value] of Object.entries(newAnswerQuestionsObj)) {
            if (cq.DisplayName === key) {
              tempAnsweredQuestions[key] = value;
              break;
            }
          }
        }
      });

      //validate questions
      if (isEmptyObject(tempAnsweredQuestions)) {
        this.setState({ showLoading: false });
        return sweetalert.fire({ icon: 'error', title: '', text: `Service Specific Questions must be answered` });
      }

      let invalidCF = CustomFieldBuilder.validateCustomFields(tempAnsweredQuestions, allCustomQuestions);

      if (invalidCF) {
        this.setState({ showLoading: false });
        return;
      }
    }

    this.props.submit({ answeredCustomQuestions: tempAnsweredQuestions, serviceID: this.state.serviceID });

    hideModal(ServiceFormModal.ID);
    this.setState({ showLoading: false });
  }

  handleDateOnChange(value, state) {
    if (value) {
      this.setState({ [state]: value } as any);
    } else {
      this.setState({ [state]: null } as any);
    }
  }

  clearServiceFormState = () => {
    return this.setState({
      showLoading: false,
      serviceSpecificTests: [],
      recordInfo: {},
    });
  };

  getVetoName(serviceTypeID) {
    let vetoName = '';
    switch (serviceTypeID) {
      case ServiceTypes.Vaccine:
        if(this.props.vaccines && this.props.vaccines.length > 0 && this.props.service.Vaccines && this.props.service.Vaccines.length > 0){
          for(let i = 0; i < this.props.service.Vaccines.length; i++){
            vetoName += this.props.vaccines.find(v => v.value === this.props.service.Vaccines[i]).label;
            vetoName += ', ';
          }
        }
        break;
      case ServiceTypes.Evaluation:
        if(this.props.evaluations && this.props.evaluations.length > 0 && this.props.service.Evaluations && this.props.service.Evaluations.length > 0){
          for(let i = 0; i < this.props.service.Evaluations.length; i++){
            vetoName += this.props.evaluations.find(v => v.value === this.props.service.Evaluations[i]).label;
            vetoName += ', ';
          }
        }
        break;
      case ServiceTypes.Test:
        if(this.props.tests && this.props.tests.length > 0 && this.props.service.Tests && this.props.service.Tests.length > 0){
          for(let i = 0; i < this.props.service.Tests.length; i++){
            vetoName += this.props.tests.find(v => v.ID === this.props.service.Tests[i]).Name;
            vetoName += ', ';
          }
        }
        break;
      case ServiceTypes.Other:
        vetoName += 'Other Questions, '
        break;
    
      default:
        break;
    }
    return vetoName.replace(/,\s*$/, '');
  }

  render() {
    // console.log('props in ServiceForm', this.props);
    // console.log('state in ServiceForm', this.state);
    let sortedCustomQuestions = this.props.customQuestions && this.props.customQuestions.length > 1 ? this.props.customQuestions.sort((a, b) => a.Order - b.Order) : this.props.customQuestions;

    let questionsByServiceType = sortedCustomQuestions?.reduce((acc, question) => {
        let serviceType = question?.Options?.serviceType ? this.props.serviceTypes.find(f => f.value === question.Options.serviceType).label : null;
        
        let options = question.Options;
        if (options.isActive) {
            if (!acc[serviceType]) {
            acc[serviceType] = [];
            }
            acc[serviceType].push(question);
        }
        return acc;
    }, {});

    return (
      <React.Fragment>
        <Overlay show_loading={this.state.showLoading} zIndex={100005} />
        <div className="modal fade form_modal" id={ServiceFormModal.ID} tabIndex={-1} role="dialog" aria-label="Service Related Questions" aria-hidden="true">
          <div className="modal-dialog modal-lg modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-body p-0 m-0">
                <div className="container-fluid">
                  <div className={'row'}>
                    <div className="col-12 pt-2">
                      <div className="card mb-2">
                        <div className="card-header verlag-bold">
                          <div className="row">
                            <div className="col-10">
                              <h4>{this.props.service && this.props.service.Name}</h4>
                            </div>
                            <div className="col-2">
                              <button style={{ outline: 'none' }} type="button" className="close pr-4" aria-label="Close" onClick={() => hideModal(ServiceFormModal.ID)}>
                                <span aria-hidden="true" style={{ fontSize: '1.5em', fontWeight: 'bold' }}>
                                  &times;
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                        {!this.props.customQuestions || this.props.customQuestions.length < 1 ? (
                          <div className="card-body">
                            <div className="row">
                              <div className="col-12">
                                <div>No Questions</div>
                              </div>
                            </div>
                          </div>
                          ) : (
                        <div className="card-body">
                          <div className="row">
                            {questionsByServiceType && Object.keys(questionsByServiceType).map((serviceType, index) => {
                              let vetoName = this.getVetoName(questionsByServiceType[serviceType][0].Options.serviceType)
                              return (
                              <div className="col-12 mb-3" key={index}>
                                <div className="card">
                                  <div className="card-header">
                                    <h5 className="card-title">{vetoName}</h5>
                                  </div>
                                  <div className="card-body">
                                    <div className="row">
                                    {questionsByServiceType[serviceType].map((val, qIndex) => (
                                        <div className="col-12">
                                            <div className="form-group row">
                                                <GetField
                                                val={val}
                                                key={qIndex}
                                                selectedItem={this.state.answeredQuestionsForGetField}
                                                handleChange={e => this.setState({ answeredQuestionsForGetField: e })}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )})}
                          </div>
                        </div>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="card-footer" style={{ backgroundColor: 'transparent', borderTop: 'none' }}>
                    <button className={'btn btn-primary float-right mb-3'} onClick={() => this.handleSave()}>
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
