import NetworkUtil from "./NetworkUtil";
import Facility from "../types/Facility";
import EventSched, { EventSchedForSelect } from "../types/Event";
import Employee from "../types/Employee";
import { PasswordConfigurationValues } from "../types/PasswordConfig";

export default class AdminAPI {
  static getAllSamplesForAdminHome() {
    throw new Error("Method not implemented.");
  }

  public static async getPermissions(): Promise<{
    isITG: boolean;
    isManagement: boolean;
  }> {
    return NetworkUtil.makeGet("/api/admin/permissions");
  }

  public static async getAllResultTypes(): Promise<
    { label: string; value: number; description: string }[]
  > {
    return NetworkUtil.makeGet("/api/admin/resulttypes");
  }

  public static async getAllEnabledResultTypes(): Promise<
    { label: string; value: number; description: string }[]
  > {
    return NetworkUtil.makeGet("/api/admin/enabledresulttypes");
  }

  public static async getAllEnabledTests(): Promise<
    { label: string; value: number; description: string }[]
  > {
    return NetworkUtil.makeGet("/api/admin/enabledtests");
  }

  public static async getAuthenticated(
    body
  ): Promise<{
    success: boolean;
    reason: string;
    user: any;
    groups: string[];
  }> {
    return NetworkUtil.makePost("/api/admin/authenticated", body);
  }

  public static async createUserProfile(
    body
  ): Promise<{ success: boolean; reason: string; user: any }> {
    return NetworkUtil.makePost("/api/admin/createUser", body);
  }

  public static async setPswdConfig(
    body
  ): Promise<{
    success: boolean;
    reason: string;
    data: PasswordConfigurationValues;
  }> {
    return NetworkUtil.makePost("/api/admin/settings/setPswdConfig", body);
  }

  public static async createFakeSamples(
    body
  ): Promise<{ success: boolean; reason: string; samples: any }> {
    return NetworkUtil.makePost("/api/admin/createFakeSamples", body);
  }

  public static async createImportedSamples(
    body
  ): Promise<{ success: boolean; reason: string; createdSamples: any }> {
    return NetworkUtil.makePost("/api/admin/createImportedSamples", body);
  }

  public static async getProductIDofUser(): Promise<{
    brandingID: number;
    isITG: boolean;
  }> {
    return NetworkUtil.makeGet("/api/admin/getProductIDofUser");
  }

  public static async getFilteredRecordsForCheckin(
    page,
    filter
  ): Promise<{
    num_pages: number;
    totalRecords: number;
    table_data?: any[];
  }> {
    return NetworkUtil.makePost(
      "/api/admin/recordSearchForCheckin?page=" + page,
      filter
    );
  }
}
