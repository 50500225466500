
export interface FakeEmployee {
    ID
    FirstName
    MiddleName
    LastName
    DisplayName
    Email
    PhoneNumber
    GenderID
    RaceID
    DOB
    Address
    City
    State
    Zip
    HireDate
    Supervisor
}

export const fakeEmployees = [
    {
        ID: 1,
        FirstName: "Emily",
        MiddleName: "Grace",
        LastName: "Anderson",
        DisplayName: "Emily Anderson",
        Email: "emily.anderson@example.com",
        PhoneNumber: "(555) 123-4567",
        GenderID: 2,
        EthnicityID: 3,
        RaceID: 1,
        DOB: "1992-08-12",
        Address: "789 Maple Street",
        City: "Springfield",
        State: "Illinois",
        Zip: "62701",
        HireDate: "2018-04-25",
        Supervisor: "Peter Parker"
    },
    {
        ID: 2,
        FirstName: "Michael",
        MiddleName: "James",
        LastName: "Roberts",
        DisplayName: "Michael Roberts",
        Email: "michael.roberts@example.com",
        PhoneNumber: "(555) 234-5678",
        GenderID: 1,
        EthnicityID: 1,
        RaceID: 2,
        DOB: "1987-05-19",
        Address: "456 Oak Avenue",
        City: "Lexington",
        State: "Kentucky",
        Zip: "40502",
        HireDate: "2015-09-10",
        Supervisor: "Peter Parker"
    },
    {
        ID: 3,
        FirstName: "Sophia",
        MiddleName: "Elizabeth",
        LastName: "Nguyen",
        DisplayName: "Sophia Nguyen",
        Email: "sophia.nguyen@example.com",
        PhoneNumber: "(555) 345-6789",
        GenderID: 2,
        EthnicityID: 2,
        RaceID: 3,
        DOB: "1990-12-30",
        Address: "123 Cedar Street",
        City: "Houston",
        State: "Texas",
        Zip: "77002",
        HireDate: "2016-11-03",
        Supervisor: "Tom Keene"
    },
    {
        ID: 4,
        FirstName: "David",
        MiddleName: "Alexander",
        LastName: "Brown",
        DisplayName: "David Brown",
        Email: "david.brown@example.com",
        PhoneNumber: "(555) 456-7890",
        GenderID: 1,
        EthnicityID: 3,
        RaceID: 4,
        DOB: "1984-03-18",
        Address: "567 Pine Street",
        City: "Atlanta",
        State: "Georgia",
        Zip: "30303",
        HireDate: "2013-07-22",
        Supervisor: "Tom Keene"
    },
    {
        ID: 5,
        FirstName: "Olivia",
        MiddleName: "Marie",
        LastName: "Smith",
        DisplayName: "Olivia Smith",
        Email: "olivia.smith@example.com",
        PhoneNumber: "(555) 567-8901",
        GenderID: 2,
        EthnicityID: 1,
        RaceID: 5,
        DOB: "1995-09-07",
        Address: "890 Elm Street",
        City: "Los Angeles",
        State: "California",
        Zip: "90001",
        HireDate: "2019-02-14",
        Supervisor: "Tom Keene"
    },
    {
        ID: 6,
        FirstName: "William",
        MiddleName: "Thomas",
        LastName: "Johnson",
        DisplayName: "William Johnson",
        Email: "william.johnson@example.com",
        PhoneNumber: "(555) 678-9012",
        GenderID: 1,
        EthnicityID: 2,
        RaceID: 1,
        DOB: "1989-11-25",
        Address: "234 Birch Street",
        City: "Miami",
        State: "Florida",
        Zip: "33101",
        HireDate: "2014-06-30",
        Supervisor: "Tom Keene"
    },
    {
        ID: 7,
        FirstName: "Ava",
        MiddleName: "Nicole",
        LastName: "Martinez",
        DisplayName: "Ava Martinez",
        Email: "ava.martinez@example.com",
        PhoneNumber: "(555) 789-0123",
        GenderID: 2,
        EthnicityID: 3,
        RaceID: 2,
        DOB: "1993-04-15",
        Address: "345 Walnut Street",
        City: "Chicago",
        State: "Illinois",
        Zip: "60601",
        HireDate: "2017-08-05",
        Supervisor: "Peter Parker"
    },
    {
        ID: 8,
        FirstName: "James",
        MiddleName: "Robert",
        LastName: "Taylor",
        DisplayName: "James Taylor",
        Email: "james.taylor@example.com",
        PhoneNumber: "(555) 890-1234",
        GenderID: 1,
        EthnicityID: 1,
        RaceID: 3,
        DOB: "1991-07-23",
        Address: "678 Pine Street",
        City: "San Francisco",
        State: "California",
        Zip: "94101",
        HireDate: "2016-10-12",
        Supervisor: "Peter Parker"
    },
    {
        ID: 9,
        FirstName: "Emma",
        MiddleName: "Louise",
        LastName: "Clark",
        DisplayName: "Emma Clark",
        Email: "emma.clark@example.com",
        PhoneNumber: "(555) 901-2345",
        GenderID: 2,
        EthnicityID: 2,
        RaceID: 4,
        DOB: "1986-02-09",
        Address: "456 Maple Street",
        City: "Seattle",
        State: "Washington",
        Zip: "98101",
        HireDate: "2012-04-18",
        Supervisor: "Tom Keene"
    },
    {
        ID: 10,
        FirstName: "Noah",
        MiddleName: "Daniel",
        LastName: "Noah",
        DisplayName: "Noah Noah",
        Email: "noah.white@example.com",
        PhoneNumber: "(555) 012-3456",
        GenderID: 1,
        EthnicityID: 3,
        RaceID: 5,
        DOB: "1988-10-05",
        Address: "567 Elm Street",
        City: "Denver",
        State: "Colorado",
        Zip: "80202",
        HireDate: "2011-09-07",
        Supervisor: "Peter Parker"
    }
    // Other objects follow the same pattern...
]