import NetworkUtil from "./NetworkUtil";
import { ServerResponse } from "../types/ServerResponse";
import Employee from "../types/Employee";

export default class EmployeeAPI {
  public static async getEmployeesFromEmployeePortal(): Promise<{
    employees: [];
    success: boolean;
    reason: string;
  }> {
    return NetworkUtil.makeGet(
      "/api/admin/employee/getEmployeesFromEmployeePortal"
    );
  }

  public static async filterEmployees(
    filter
  ): Promise<{ success: boolean; data: Employee[]; reason: string }> {
    return await NetworkUtil.makePost(
      "/api/admin/employee/employeefilter",
      filter
    );
  }

  public static async editEmployee(body): Promise<ServerResponse> {
    return await NetworkUtil.makePost("/api/admin/employee/edit", body);
  }
}
