import NetworkUtil from "./NetworkUtil";
import {CustomFieldType} from "../types/CustomFieldType";
import {ServerResponse} from "../types/ServerResponse";
import VaccineType from "../types/VaccineType";
import EvaluationType from "../types/EvaluationType";
import TestType from "../types/TestType";
import { PasswordConfigurationValues } from "../types/PasswordConfig";
import { EmployeeWithOSHAReports } from "../types/Employee";

export default class SystemAPI {

    public static async getProductBrandingFromURL(url):Promise<{ProductID:number, Product:string, ProductLogoURL:string, ProductAltLogoURL:string, Name:string, ProductBackgroundImageURL:string, PrimaryColor:string, ProductAdminNavLogoURL:string, SecondaryColor:string, AccentColor:string, ProductHelpEmail:string, reason?:string, success:boolean}>{
        return NetworkUtil.makePost("/api/system/logoURL", {currentURL:url});
    }

    public static async getBranding():Promise<{branding}>{
        return NetworkUtil.makeGet("/api/system/branding");
    }

    public static async getAllStates():Promise<{label:string, value:string}[]>{
        return NetworkUtil.makeGet("/api/system/states");
    }

    public static async getAllCountries():Promise<{countries}>{
        return NetworkUtil.makeGet("/api/system/countries");
    }

    public static async getAllRaces():Promise<{label:string, value:number}[]>{
        return NetworkUtil.makeGet("/api/system/race");
    }

    public static async getAllGenders():Promise<{label:string, value:number}[]>{
        return NetworkUtil.makeGet("/api/system/gender");
    }

    public static async getAllEthnicities():Promise<{label:string, value:number}[]>{
        return NetworkUtil.makeGet("/api/system/ethnicity");
    }

    public static async getAllTests():Promise<TestType[]>{
        return NetworkUtil.makeGet("/api/system/tests");
    }

    public static async getAllEnabledTests():Promise<TestType[]>{
        return NetworkUtil.makeGet("/api/system/enabledtests");
    }

    public static async getAllVaccines():Promise<VaccineType[]>{
        return NetworkUtil.makeGet("/api/system/vaccines");
    }

    public static async getAllServiceTypes():Promise<[]>{
        return NetworkUtil.makeGet("/api/system/serviceTypes");
    }

    public static async getAllEnabledVaccines():Promise<VaccineType[]>{
        return NetworkUtil.makeGet("/api/system/enabledvaccines");
    }

    public static async getAllEvaluations():Promise<EvaluationType[]>{
        return NetworkUtil.makeGet("/api/system/evaluations");
    }

    public static async getAllEnabledEvaluations():Promise<EvaluationType[]>{
        return NetworkUtil.makeGet("/api/system/enabledevaluations");
    }

    public static async checkIfVaccineAssignedToService(ID):Promise<VaccineType[]>{
        return NetworkUtil.makePost("/api/system/isvaccineassigned", {ID: ID});
    }
    public static async checkIfTestAssignedToService(ID):Promise<TestType[]>{
        return NetworkUtil.makePost("/api/system/istestassigned", {ID: ID});
    }
    public static async checkIfEvaluationAssignedToService(ID):Promise<EvaluationType[]>{
        return NetworkUtil.makePost("/api/system/isevaluationassigned", {ID: ID});
    }

    public static async isInsuranceRequiredOnEvaluation(ID):Promise<EvaluationType[]>{
        return NetworkUtil.makePost("/api/system/insurancerequired", {ID: ID});
    }

    public static async getConsentEntities():Promise<{ID:number,Name:string,FacilityID:number,AccessCode:string}[]>{
        return NetworkUtil.makeGet("/api/system/consentEntities");
    }
    
    public static async getCustomAppointmentQuestions():Promise<CustomQuestionsReponse>{
        return NetworkUtil.makeGet("/api/system/appointment/questions");
    }

    public static async getCustomQuestions(page):Promise<CustomQuestionsReponse>{
        return NetworkUtil.makePost("/api/system/appointment/questions", {page: page});
    }

    public static async getCustomQuestionsByID(id):Promise<CustomQuestionsReponse>{
        return NetworkUtil.makePost("/api/system/appointment/questions", {id: id});
    }

    public static async getPasswordRequirementConfig():Promise<{success:boolean,reason:string,data:PasswordConfigurationValues}>{
        return NetworkUtil.makeGet("/api/system/getPswdConfig");
    }

    public static async getAllResultTypes():Promise<{label:string, value:number, description:string}[]>{
        return NetworkUtil.makeGet("/api/system/resulttypes");
    }

    public static async getAllEmployees():Promise<{data}>{
        return NetworkUtil.makeGet("/api/system/getAllEmployees")
    }

    public static async getAllEmployeesForOSHA():Promise<{data:EmployeeWithOSHAReports[]}>{
        return NetworkUtil.makeGet("/api/system/getAllEmployeesForOSHA")
    }

    public static async getAllCaseNumbers():Promise<{data}>{
        return NetworkUtil.makeGet("/api/system/getAllCaseNumbers")
    }

    public static async getCaseClassificationTypes():Promise<{data}>{
        return NetworkUtil.makeGet("/api/system/getCaseClassificationTypes")
    }
    
    public static async getInjuryTypes():Promise<{data}>{
        return NetworkUtil.makeGet("/api/system/getInjuryTypes")
    }


}

interface CustomQuestionsReponse extends ServerResponse{
    questions:CustomFieldType[]
}