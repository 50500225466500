import { useRef, useEffect, useState } from "react";
import ReactDOM from 'react-dom';

//import "../../public/";
const AutoComplete = (props) => {
    const autoCompleteRef = useRef();
    const inputRef = useRef();
    const [address, setAddress] = useState('');
    const [streetAddress, setStreetAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [county, setCounty] = useState('');
    const [country, setCountry] = useState('');
    const [defaultValue, setDefaultValue] = useState('');
    const [isGoogleLoaded, setIsGoogleLoaded] = useState(false);
    const autocomplete = useRef(null);
    const options = {
    //componentRestrictions: {  },
    fields: ["address_components", "geometry", "icon", "name"],
    types: []
    };

    useEffect(() => {
      const checkGoogleMapsLoaded = () => {
        //@ts-ignore
        if (window.google && window.google.maps && window.google.maps.places) {
          setIsGoogleLoaded(true);
        } else {
          setTimeout(checkGoogleMapsLoaded, 100);
        }
      };
  
      checkGoogleMapsLoaded();
    }, []);

 useEffect(() => {
  if (isGoogleLoaded && inputRef.current) {
      try {
        //@ts-ignore
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        options
        );
        initAutocomplete()
      } catch (error) {
        console.error("Error initializing Google Maps Autocomplete:", error);
        // Handle the error gracefully, e.g., show a user-friendly message
      }
    }
 }, [isGoogleLoaded]);
 
 useEffect(()=>{
  //This allows you to input a value prop to provide a default value for the field.
  if(props.value && !defaultValue){
    setAddress(props.value)
    setDefaultValue(props.value)
  }
 }, props.value)

const initAutocomplete = () => {
  const input = document.getElementById(props.id);
  //@ts-ignore
  autocomplete.current = new window.google.maps.places.Autocomplete(input);
  autocomplete.current.addListener('place_changed', handlePlaceSelect);
};

const handlePlaceSelect = () => {
  const place = autocomplete.current.getPlace();
  const addressComponents = place.address_components;
    let streetAddress = '';
    let city = '';
    let state = '';
    let postalCode = '';
    let country = '';
    let county = '';

    for (const component of addressComponents) {
        const componentType = component.types[0];

        switch (componentType) {
            case 'street_number':
            streetAddress = component.long_name;
            break;
            case 'route':
            streetAddress += ' ' + component.long_name;
            break;
            case 'locality':
            city = component.long_name;
            break;
            case 'administrative_area_level_1':
            state = component.short_name;
            break;
            case 'administrative_area_level_2':
            county = component.long_name;
            break;
            case 'postal_code':
            postalCode = component.long_name;
            break;
            case 'country':
            country = component.long_name;
            break;
            default:
            break;
        }
    }

    setStreetAddress(streetAddress);
    setCity(city);
    setState(state);
    setPostalCode(postalCode);
    setCounty(county)
    setCountry(country);
    setAddress(place.formatted_address);

    props.updateFormData(streetAddress, city, state, postalCode, county=null, country=null)

  // Process and use the address components as needed
  //console.log(addressComponents);
};

const handleChange = (event) => {
    props.onChange(event.target.value)
    setAddress(event.target.value);
};

    return (
      <div>
        {isGoogleLoaded ? (
          <input type="text" className="form-control" id={props.id} placeholder="123 Main Street..." ref={inputRef} value={address} onChange={(e) => {handleChange(e)}}/>
        ):(
          <p>Loading...</p>
        )}
      </div>
    );
};
export default AutoComplete;