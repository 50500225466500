import React from "react";
import Overlay from "../Overlay";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie, Bar, Line, Doughnut} from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import ReactSpeedometer from "react-d3-speedometer"

ChartJS.register(ArcElement, Tooltip, Legend);

const safetyTrainingCompletionRatesChartData = {
    labels: ['Module 1', 'Module 2', 'Module 3', 'Module 4'],
    datasets: [
      {
        label: 'Completed',
        data: [80, 70, 90, 85], // Completion rates for each module
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
      {
        label: 'Incomplete',
        data: [20, 30, 10, 15], // Incompletion rates for each module
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  }
  
  const safetyTrainingCompletionRatesChartOptions = {
    indexAxis: 'x', // Rotate chart to use x-axis for categories
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
    },
  }
  
  const safetyComplianceScoreChartData = 73;
  
  const safetyComplianceScoreChartOptions = {
  }
  
  const safetyIncidentBarChartData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'Injuries',
        data: ['January', 'February', 'March', 'April', 'May', 'June', 'July'].map(() => faker.datatype.number({ min: 1, max: 20 })),
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Illnesses',
        data: ['January', 'February', 'March', 'April', 'May', 'June', 'July'].map(() => faker.datatype.number({ min: 1, max: 20 })),
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  }
  
  const safetyIncidentBarChartOptions = {
  };

  const supervisorVaccineComplianceDoughtnutChartData = {
    labels: ['Compliant', 'Non-Compliant'],
    datasets: [
      {
        data: [75, 25], // Percentage of compliant and non-compliant employees
        backgroundColor: ['#36A2EB', '#FF6384'],
        hoverBackgroundColor: ['#36A2EB', '#FF6384'],
      },
    ],
}

const supervisorVaccineComplianceDoughtnutChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'right',
      labels: {
        boxWidth: 20,
      },
    },
  },
};

const supervisorAbsenteeismRateBarChartData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [
      {
        label: 'Absenteeism Rate',
        data: [3, 4, 2, 5, 3, 6], // Replace with actual absenteeism rate data
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
    ],
}
  
const supervisorAbsenteeismRateBarChartOptions = { 
}

const claimsCostLineChartData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [
      {
        label: 'Medical Expenses',
        data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'].map(() => faker.datatype.number({ min: 1, max: 5000 })),
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
        fill: 'origin',
      },
      {
        label: 'Compensation',
        data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'].map(() => faker.datatype.number({ min: 1, max: 2500 })),
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
        fill: '-1',
      },
    ],
}
  
const claimsCostLineChartOptions = {
}

let chartsArray = [
    {label: 'Training Completion Rates', chartType: 'bar', chartData: safetyTrainingCompletionRatesChartData, options: safetyTrainingCompletionRatesChartOptions},
    {label: 'Safety Compliance Score', chartType: 'gauge', chartData: safetyComplianceScoreChartData, options: safetyComplianceScoreChartOptions},
    {label: 'Incident Injury vs Illness', chartType: 'bar', chartData: safetyIncidentBarChartData, options: safetyIncidentBarChartOptions },
    {label: 'Vaccine Compliance Status', chartType: 'doughnut', chartData: supervisorVaccineComplianceDoughtnutChartData, options: supervisorVaccineComplianceDoughtnutChartOptions },
    {label: 'Absenteeism Rate (%)', chartType: 'bar', chartData: supervisorAbsenteeismRateBarChartData, options: supervisorAbsenteeismRateBarChartOptions },
    {label: 'Cost of Claims', chartType: 'line', chartData: claimsCostLineChartData, options: claimsCostLineChartOptions }
  ]

interface AnalyticsState {
  showLoading?: boolean;
}

interface AnalyticsProps {
  demoUser?
}

export default class Analytics extends React.Component<
  any,
  AnalyticsState
> {

  constructor(props) {
    super(props);
    this.state = {
      showLoading: false,
    }
  }



  componentDidMount(): void {
    document.title = 'Analytics';
    document.body.style.backgroundImage = ``;
    document.body.style.backgroundColor = '#FFFFFF'
  }


  getChart(data){
    return(
      <div className="col-12 col-lg-4">
        <div className="card mb-3 text-center" style={{borderColor: '#21212E'}}>
          <div className="card-body" style={window.innerWidth > 1540 ? {height: '450px'} : {height: '400px'}}>
            <div className="row no-gutters">
              <div className="col-12">
                <h3 className="card-title">{data.label}</h3>
              </div>
            </div>
            {data && data.chartType === 'bar' 
            ?
            <div className="row no-gutters">
              <div className="col-12">
                <div>
                  <Bar 
                    height={200}
                    data={data.chartData} 
                    options={data.options}
                  />
                </div>
              </div>
            </div>
            : data && data.chartType === 'pie'
            ?
            <div className="row no-gutters pt-0 pt-xxl-5">
              <div className="col-12">
                <div>
                  <Pie 
                    data={data.chartData} 
                    options={data.options}
                    style={{ height: '300px' }}
                  />
                </div>
              </div>
            </div>
            : data && data.chartType === 'line'
            ?
            <div className="row no-gutters">
              <div className="col-12">
                <div>
                  <Line 
                    height={200}
                    data={data.chartData} 
                    options={data.options}
                  />
                </div>
              </div>
            </div>
            : data && data.chartType === 'doughnut'
            ?
            <div className="row no-gutters pt-0 pt-xxl-5">
              <div className="col-12">
                <div >
                  <Doughnut 
                    height={300}
                    data={data.chartData} 
                    options={data.options}
                  />
                </div>
              </div>
            </div>
            : data && data.chartType === 'gauge'
            ?
            <div className="row no-gutters" style={window.innerWidth > 1540 ? {paddingTop: '40px'} : {paddingTop: '65px'}}>
              <div className="col-12">
                <div style={window.innerWidth > 1540 ? {width: '100%', height: '325px'} : {width: '100%', height: '300px'}}>
                  <ReactSpeedometer
                    fluidWidth={true}
                    maxValue={100}
                    minValue={0}
                    value={data.chartData}
                    needleColor="blue"
                    startColor="red"
                    segments={10}
                    endColor="green"
                  />
                </div>
              </div>
            </div>
            
            :
            null
          }
          </div>
        </div>
      </div>
  )}

  
      

  render():
    | React.ReactElement
    | string
    | number
    | {}
    | React.ReactNodeArray
    | React.ReactPortal
    | boolean
    | null
    | undefined {

    return (
      <React.Fragment>
        <Overlay show_loading={this.state.showLoading} />
       
        <main id="main-content" tabIndex={-1} aria-label="Analytics">
          <div className="container-fluid">
              <div className={"row mt-3"}>
                  {chartsArray && chartsArray.length > 0 && chartsArray.map((f, index) => {
                    return (
                      <>
                        {this.getChart(f)}
                      </>
                    )
                  })}
              </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

