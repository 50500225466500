export default interface Incident {
    ID
    EmployeeName
    EmployeeID
    IncidentType
    IncidentDate
    IncidentTime
    DidEmployeeDie
    DateOfDeath
    ActivityBeforeIncident
    Description
    ObjectOrSubstanceInvolved
    CaseNumber

    CreatedBy
    CreatedDate
    LastUpdatedBy
    LastUpdatedDate
}

export const FakeIncidents = [
    {
        ID: 1,
        EmployeeName: "Emily Anderson",
        EmployeeID: 1,
        IncidentType: 'Accident', // Injury Altercation
        IncidentDate: "4/24/2024",
        IncidentTime: "16:30:00",
        DidEmployeeDie: false,
        DateOfDeath: null,
        ActivityBeforeIncident: "Walking looking at phone",
        Description: "fell into fountain",
        ObjectOrSubstanceInvolved: "cell phone, fountain",
        CaseNumber: '7788-9965',
        CreatedBy: "amber-hayes@immytech.com",
        CreatedDate: "4/24/2023 3:52:15 PM",
        LastUpdatedBy: "amber-hayes@immytech.com",
        LastUpdatedDate: "4/25/2023 09:00:00 AM"
    }, {
        ID: 2,
        EmployeeName: "Michael Roberts",
        EmployeeID: 2,
        IncidentType: 'Injury',
        IncidentDate: "3/15/2024",
        IncidentTime: "09:42:00",
        DidEmployeeDie: false,
        DateOfDeath: null,
        ActivityBeforeIncident: "Working with crimping machine",
        Description: "Hand got caught in machine",
        ObjectOrSubstanceInvolved: "crimper",
        CaseNumber: "4433-9087",
        CreatedBy: "amber-hayes@immytech.com",
        CreatedDate: "4/4/2024 2:39:10 PM",
        LastUpdatedBy: "amber-hayes@immytech.com",
        LastUpdatedDate: "4/4/2024 2:39:10 PM"
    }, {
        ID: 3,
        EmployeeName: "Emma Clark",
        EmployeeID: 9,
        IncidentType: 'Altercation',
        IncidentDate: "12/23/2020",
        IncidentTime: "13:33:00",
        DidEmployeeDie: false,
        DateOfDeath: null,
        ActivityBeforeIncident: "filing invoices",
        Description: "loud argument with another employee, handful of paperclips thrown",
        ObjectOrSubstanceInvolved: "paperclips",
        CaseNumber: "1337-8890",
        CreatedBy: "amber-hayes@immytech.com",
        CreatedDate: "12/23/2020 4:50:15 PM",
        LastUpdatedBy: "amber-hayes@immytech.com",
        LastUpdatedDate: "12/23/2020 4:50:15 PM"
    }
]
