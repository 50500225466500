import React, { Component } from 'react';
import Select from 'react-select';
import ReactQuill from 'react-quill';
import {
  buildRow,
  getBoolOptionsWithNullString,
  getBoolSelectOptions,
  getBoolSelectOptionsSpanish,
  getBoolSelectVal,
  getFacilityLabel,
  getLabel,
  getLabelFromName,
  getSpanishBoolSelectVal,
  getTranslatedOptions,
  hideModal,
  parseDate,
  parseDateWithZ,
} from '../../util/FormatUtil';
import { sweetalert } from '../../App';
import Validator, { ValidationEntry } from '../../validation/Validator';
import { Validators } from '../../validation/Validators';
import { isEmptyObject } from 'jquery';
import GetField from '../../customfields/GetField';
import CustomInput from '../form/CustomInput';
import CustomDate from '../form/CustomDate';
import CustomSelect from '../form/CustomSelect';
import CustomPhone from '../form/CustomPhone';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import CustomTextArea from '../form/CustomTextArea';
import { OSHAReportWithEmployeeData } from '../../types/OSHAReport';
import moment from 'moment';
import GooglePlacesAutoComplete from '../admin/GooglePlacesAutoComplete';
import i18next from '../../util/i18n';
import { withTranslation } from 'react-i18next';

interface OSHAFormModalState {
  selectedOSHARecord: OSHAReportWithEmployeeData;
  employeeArrowDown: boolean;
  incidentArrowDown: boolean;
  treatmentArrowDown: boolean;
  formCompletedByArrowDown: boolean;
  employeeLabel?;
}

interface OSHAFormModalProps {
  caseClassificationTypes?;
  injuryTypes?;
  selectedOSHARecord;
  genders;
  states;
  facilities?;
  facilityLabels?;
  allEmployees;
  onSubmit?: (oshaForm) => void;
  t;
}

class OSHAFormModal extends Component<OSHAFormModalProps, OSHAFormModalState> {
  public static ID = 'oshaform';
  constructor(props) {
    super(props);
    this.state = {
      selectedOSHARecord: null,
      employeeArrowDown: false,
      incidentArrowDown: false,
      treatmentArrowDown: false,
      formCompletedByArrowDown: false,
      employeeLabel: null,
    };
  }

  componentWillReceiveProps(nextProps: Readonly<OSHAFormModalProps>, nextContext: any) {
    let record = nextProps.selectedOSHARecord;
    // console.log('OSHAFormModal.tsx componentWillReceiveProps', record);
    let translationGenders = nextProps.genders && nextProps.genders.length > 0 ? getTranslatedOptions(nextProps.genders) : [];
    let translationCaseClassifications =
      nextProps.caseClassificationTypes && nextProps.caseClassificationTypes.length > 0 ? getTranslatedOptions(nextProps.caseClassificationTypes) : [];
    let translationInjuryTypes = nextProps.injuryTypes && nextProps.injuryTypes.length > 0 ? getTranslatedOptions(nextProps.injuryTypes) : [];

    function booleanSelectValueLabels(int) {
      if (i18next.language === 'es') {
        return getSpanishBoolSelectVal(int);
      } else {
        return getBoolSelectVal(int);
      }
    }

    let employeeLabel;
    if (nextProps.allEmployees && nextProps.allEmployees.length > 0 && record.EmployeeUID) {
      let temp = nextProps.allEmployees.find(f => f.UID === record.EmployeeUID);
      employeeLabel = { label: temp.DisplayName, value: temp.UID };
    } else {
      employeeLabel = null;
    }
    if (!isEmptyObject(nextProps.selectedOSHARecord)) {
      this.setState({
        employeeLabel: employeeLabel,
        selectedOSHARecord: {
          EmployeeUID: record && record.EmployeeUID ? record.EmployeeUID : null,
          FirstName: record && record.FirstName ? record.FirstName : null,
          LastName: record && record.LastName ? record.LastName : null,
          DateOfBirth: record && record.DateOfBirth ? parseDateWithZ(record.DateOfBirth) : null,
          HireDate: record && record.HireDate ? parseDateWithZ(record.HireDate) : null,
          GenderID: record && record.GenderID ? record.GenderID : null,
          employeeGenderLabel: record && record.GenderID ? getLabel(record.GenderID, translationGenders) : '',
          Title: record && record.Title ? record.Title : null,
          Email: record && record.Email ? record.Email : null,
          PhoneNumber: record && record.PhoneNumber ? record.PhoneNumber : null,
          PhysicianFirstName: record && record.PhysicianFirstName ? record.PhysicianFirstName : null,
          PhysicianLastName: record && record.PhysicianLastName ? record.PhysicianLastName : null,
          TreatmentFacilityAddress: record && record.TreatmentFacilityAddress ? record.TreatmentFacilityAddress : null,
          TreatmentFacilityCity: record && record.TreatmentFacilityCity ? record.TreatmentFacilityCity : null,
          TreatmentFacilityName: record && record.TreatmentFacilityName ? record.TreatmentFacilityName : null,
          TreatmentFacilityState: record && record.TreatmentFacilityState ? record.TreatmentFacilityState : null,
          treatmentFacilityStateLabel: record && record.TreatmentFacilityState ? getLabel(record.TreatmentFacilityState, this.props.states) : '',
          TreatmentFacilityZip: record && record.TreatmentFacilityZip ? record.TreatmentFacilityZip : null,
          TreatedInEmergencyRoom: record && record.TreatedInEmergencyRoom ? 1 : 0,
          treatedInEmergencyRoomLabel: record && record.TreatedInEmergencyRoom ? booleanSelectValueLabels(1) : booleanSelectValueLabels(0),
          WasInpatient: record && record.WasInpatient ? 1 : 0,
          wasInpatientLabel: record && record.WasInpatient ? booleanSelectValueLabels(1) : booleanSelectValueLabels(0),
          CaseNumber: record && record.CaseNumber ? record.CaseNumber : null,
          DateOfInjury: record && record.DateOfInjury ? parseDateWithZ(record.DateOfInjury) : null,
          caseClassificationLabel: record && record.CaseClassification ? getLabel(record.CaseClassification, translationCaseClassifications) : '',
          injuryTypeLabel: record && record.TypeOfInjury ? getLabel(record.TypeOfInjury, translationInjuryTypes) : '',
          TimeEmployeeBeganWork: record && record.TimeEmployeeBeganWork ? record.TimeEmployeeBeganWork : null,
          TimeOfEvent: record && record.TimeOfEvent ? record.TimeOfEvent : null,
          ActivityBeforeIncident: record && record.ActivityBeforeIncident ? record.ActivityBeforeIncident : null,
          IncidentDescription: record && record.IncidentDescription ? record.IncidentDescription : null,
          InjuryDescription: record && record.InjuryDescription ? record.InjuryDescription : null,
          ObjectOrSubstanceInvolved: record && record.ObjectOrSubstanceInvolved ? record.ObjectOrSubstanceInvolved : null,
          DidEmployeeDie: record && record.DidEmployeeDie ? 1 : 0,
          didEmployeeDieLabel: record && record.DidEmployeeDie ? booleanSelectValueLabels(1) : booleanSelectValueLabels(0),
          EmployeeDeathDate: record && record.EmployeeDeathDate ? parseDateWithZ(record.EmployeeDeathDate) : null,
          FormCompletedBy: record && record.FormCompletedBy ? record.FormCompletedBy : null,
          FormCompletedByTitle: record && record.FormCompletedByTitle ? record.FormCompletedByTitle : null,
          FormCompletedByPhone: record && record.FormCompletedByPhone ? record.FormCompletedByPhone : null,
          FormCompletedDate: record && record.FormCompletedDate ? parseDateWithZ(record.FormCompletedDate) : null,
          ReturnToFullDutyDate: record && record.ReturnToFullDutyDate ? parseDateWithZ(record.ReturnToFullDutyDate) : null,
          TypeOfInjury: record && record.TypeOfInjury ? record.TypeOfInjury : null,
          CaseClassification: record && record.CaseClassification ? record.CaseClassification : null,
          ReturnedToFullDuty: record && record.ReturnToFullDutyDate ? 1 : 0,
          returnedToFullDutyLabel: record && record.ReturnToFullDutyDate ? booleanSelectValueLabels(1) : booleanSelectValueLabels(0),
          LocationOfEvent: record && record.LocationOfEvent ? record.LocationOfEvent : null,
          FacilityID: record && record.FacilityID ? record.FacilityID : null,
          facilityLabel: record && record.FacilityID ? getLabelFromName(record.FacilityID, this.props.facilities) : '',
        } as OSHAReportWithEmployeeData,
      });
    } else {
      this.setState({
        employeeLabel: employeeLabel,
        selectedOSHARecord: {
          EmployeeUID: null,
          FirstName: null,
          LastName: null,
          DateOfBirth: null,
          HireDate: null,
          GenderID: null,
          employeeGenderLabel: null,
          Title: null,
          Email: null,
          PhoneNumber: null,
          PhysicianFirstName: null,
          PhysicianLastName: null,
          TreatmentFacilityAddress: null,
          TreatmentFacilityCity: null,
          TreatmentFacilityName: null,
          TreatmentFacilityState: null,
          treatmentFacilityStateLabel: null,
          TreatmentFacilityZip: null,
          TreatedInEmergencyRoom: 0,
          treatedInEmergencyRoomLabel: getBoolSelectVal(0),
          WasInpatient: 0,
          wasInpatientLabel: getBoolSelectVal(0),
          CaseNumber: null,
          DateOfInjury: null,
          caseClassificationLabel: null,
          injuryTypeLabel: null,
          TimeEmployeeBeganWork: null,
          TimeOfEvent: null,
          ActivityBeforeIncident: null,
          IncidentDescription: null,
          InjuryDescription: null,
          ObjectOrSubstanceInvolved: null,
          DidEmployeeDie: 0,
          didEmployeeDieLabel: getBoolSelectVal(0),
          EmployeeDeathDate: null,
          FormCompletedBy: null,
          FormCompletedByTitle: null,
          FormCompletedByPhone: null,
          FormCompletedDate: null,
          ReturnToFullDutyDate: null,
          TypeOfInjury: null,
          CaseClassification: null,
          ReturnedToFullDuty: 0,
          returnedToFullDutyLabel: getBoolSelectVal(0),
          LocationOfEvent: null,
          FacilityID: null,
          facilityLabel: null,
        } as OSHAReportWithEmployeeData,
      });
    }
  }

  handleChange(e, stateToSet, stateLabelToSet = '', type = 'input') {
    if (type === 'select') {
      if (stateToSet === 'ReturnedToFullDuty' && e) {
        if (!e.value) {
          this.setState(prevState => ({
            selectedOSHARecord: {
              ...prevState.selectedOSHARecord,
              ReturnToFullDutyDate: null,
            },
          }));
        }
        this.setState(prevState => ({
          selectedOSHARecord: {
            ...prevState.selectedOSHARecord,
            DidEmployeeDie: false,
            didEmployeeDieLabel: { label: 'No', value: false },
            EmployeeDeathDate: null,
          },
        }));
      }
      if (stateToSet === 'DidEmployeeDie' && e) {
        if (!e.value) {
          this.setState(prevState => ({
            selectedOSHARecord: {
              ...prevState.selectedOSHARecord,
              EmployeeDeathDate: null,
            },
          }));
        }
        this.setState(prevState => ({
          selectedOSHARecord: {
            ...prevState.selectedOSHARecord,
            ReturnedToFullDuty: false,
            returnedToFullDutyLabel: { label: 'No', value: false },
            ReturnToFullDutyDate: null,
          },
        }));
      }
      this.setState(prevState => ({
        selectedOSHARecord: {
          ...prevState.selectedOSHARecord,
          [stateLabelToSet]: e ? e : null,
          [stateToSet]: e && e.value === false ? false : e && e.value ? e.value : null,
        },
      }));
    } else {
      this.setState(prevState => ({
        selectedOSHARecord: {
          ...prevState.selectedOSHARecord,
          [stateToSet]: e.target.value,
        },
      }));
    }
  }

  handleDateOnChange(value, state) {
    if (value) {
      this.setState(prevState => ({
        selectedOSHARecord: {
          ...prevState.selectedOSHARecord,
          [state]: value,
        },
      }));
    } else {
      this.setState(prevState => ({
        selectedOSHARecord: {
          ...prevState.selectedOSHARecord,
          [state]: null,
        },
      }));
    }
  }

  setEmployeeFields(employeeUID) {
    let employee = this.props.allEmployees.find(e => e.UID === employeeUID);
    this.setState(prevState => ({
      selectedOSHARecord: {
        ...prevState.selectedOSHARecord,
        EmployeeUID: employeeUID,
        FirstName: employee.FirstName,
        LastName: employee.LastName,
        Email: employee.Email,
        PhoneNumber: employee.PhoneNumber,
        Title: employee.Title,
        DateOfBirth: parseDateWithZ(employee.DateOfBirth),
        HireDate: parseDateWithZ(employee.HireDate),
        GenderID: employee.GenderID,
        employeeGenderLabel: {
          label: this.props.genders.find(g => g.value === employee.GenderID).label,
          value: employee.GenderID,
        },
      },
    }));
  }

  getReturnToFullDutyEmployeeDeathFlow() {
    let translationBooleans = i18next.language === 'es' ? getBoolSelectOptionsSpanish() : getBoolSelectOptions();
    if (this.state.selectedOSHARecord?.ReturnedToFullDuty) {
      return (
        <CustomDate
          doNotDisplay={!this.state.selectedOSHARecord?.ReturnedToFullDuty}
          fromFilterPage={false}
          value={this.state.selectedOSHARecord?.ReturnToFullDutyDate}
          label={this.props.t('Return to Full Duty Date')}
          tooltip={this.props.t(`The date returned to full duty`)}
          handleDateOnChange={e => this.handleDateOnChange(e, 'ReturnToFullDutyDate')}
        />
      );
    } else if (!this.state.selectedOSHARecord?.ReturnedToFullDuty && !this.state.selectedOSHARecord?.DidEmployeeDie) {
      return (
        <CustomSelect
          doNotDisplay={this.state.selectedOSHARecord?.ReturnedToFullDuty}
          fromFilterPage={false}
          value={this.state.selectedOSHARecord?.didEmployeeDieLabel}
          label={this.props.t('Did Employee Die?')}
          tooltip={this.props.t(`Mark 'Yes' if employee died`)}
          handleChange={e => this.handleChange(e, 'DidEmployeeDie', 'didEmployeeDieLabel', 'select')}
          options={translationBooleans}
        />
      );
    }

    if (!this.state.selectedOSHARecord?.ReturnedToFullDuty && this.state.selectedOSHARecord?.DidEmployeeDie) {
      return (
        <>
          <CustomSelect
            doNotDisplay={this.state.selectedOSHARecord?.ReturnedToFullDuty}
            fromFilterPage={false}
            value={this.state.selectedOSHARecord?.didEmployeeDieLabel}
            label={this.props.t('Did Employee Die?')}
            tooltip={this.props.t(`Mark 'Yes' if employee died`)}
            handleChange={e => this.handleChange(e, 'DidEmployeeDie', 'didEmployeeDieLabel', 'select')}
            options={translationBooleans}
          />
          <CustomDate
            doNotDisplay={!this.state.selectedOSHARecord?.DidEmployeeDie}
            fromFilterPage={false}
            value={this.state.selectedOSHARecord?.EmployeeDeathDate}
            label={this.props.t('Date of Death')}
            tooltip={this.props.t(`The date the employee died`)}
            handleDateOnChange={e => this.handleDateOnChange(e, 'EmployeeDeathDate')}
          />
        </>
      );
    }
  }

  clearFields() {
    this.setState({
      selectedOSHARecord: {
        id: null,
        EmployeeUID: null,
        FirstName: '',
        LastName: '',
        DateOfBirth: '',
        Email: '',
        PhoneNumber: '',
        Title: '',
        HireDate: '',
        GenderID: null,
        employeeGenderLabel: null,
        PhysicianFirstName: '',
        PhysicianLastName: '',
        TreatmentFacilityAddress: '',
        TreatmentFacilityCity: '',
        TreatmentFacilityName: '',
        TreatmentFacilityState: null,
        TreatmentFacilityStateLabel: null,
        TreatmentFacilityZip: '',
        TreatedInEmergencyRoom: 0,
        TreatedInEmergencyRoomLabel: getBoolSelectVal(0),
        WasInpatient: 0,
        WasInpatientLabel: getBoolSelectVal(0),
        CaseNumber: '',
        DateOfInjury: '',
        TimeEmployeeBeganWork: '',
        TimeOfEvent: '',
        ActivityBeforeIncident: '',
        IncidentDescription: '',
        InjuryDescription: '',
        ObjectOrSubstanceInvolved: '',
        DidEmployeeDie: 0,
        DidEmployeeDieLabel: getBoolSelectVal(0),
        EmployeeDeathDate: '',
        FormCompletedBy: '',
        FormCompletedByTitle: '',
        FormCompletedByPhone: '',
        FormCompletedDate: '',
        CaseClassification: null,
        CaseClassificationLabel: null,
        TypeOfInjury: null,
        injuryTypeLabel: null,
        ReturnToFullDutyDate: '',
        ReturnedToFullDuty: 0,
        returnedToFullDutyLabel: getBoolSelectVal(0),
        LocationOfEvent: '',
        FacilityID: null,
        facilityLabel: null,
      } as OSHAReportWithEmployeeData,
    });
  }

  render() {
    // console.log('OSHA Record Modal STATE', this.state)

    let translationGenders = this.props.genders && this.props.genders.length > 0 ? getTranslatedOptions(this.props.genders) : [];
    let tranlationCaseClassifications =
      this.props.caseClassificationTypes && this.props.caseClassificationTypes.length > 0 ? getTranslatedOptions(this.props.caseClassificationTypes) : [];
    let tranlationInjuryTypes = this.props.injuryTypes && this.props.injuryTypes.length > 0 ? getTranslatedOptions(this.props.injuryTypes) : [];
    let translationBooleans = i18next.language === 'es' ? getBoolSelectOptionsSpanish() : getBoolSelectOptions();

    return (
      <div className="modal fade form_modal" id={OSHAFormModal.ID} tabIndex={-1} role="dialog" aria-labelledby="result_modal_label" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-body p-0 m-0">
              <div className="container-fluid">
                <div className={'row'}>
                  <div className="col-xl-12 col-lg-12 col-md-12 col-12 pt-2">
                    <div className="card mb-2">
                      <div className="card-header verlag-bold">
                        <h4>
                          {this.props.t('Injury and Illness Incident Report (Form 301)')}
                          <button style={{ outline: 'none' }} type="button" className="close mr-4" data-dismiss="modal" aria-label="Close" onClick={() => this.clearFields()}>
                            <span aria-hidden="true" style={{ fontSize: '1.5em', fontWeight: 'bold' }}>
                              {' '}
                              &times;{' '}
                            </span>
                          </button>
                        </h4>
                      </div>

                      <div className="card-body py-0">
                        <div className={'row'}>
                          <div className="col-xl-12 pt-2">
                            <div className="card mb-2">
                              {/*Employee*/}
                              <button
                                className="card-header verlag-bold accordionButton w-100"
                                data-toggle="collapse"
                                data-target="#employeeXLCollapse"
                                onClick={() =>
                                  this.setState({
                                    employeeArrowDown: !this.state.employeeArrowDown,
                                  })
                                }
                                aria-expanded="false"
                                aria-controls="employeeXLCollapse"
                              >
                                <div className="row justify-content-between px-3">
                                  <div>
                                    <h4>{this.props.t('Employee')}</h4>
                                  </div>
                                  <div className="col-2">
                                    {this.state.employeeArrowDown ? (
                                      <AiOutlineUp
                                        className={'float-right'}
                                        size={30}
                                        data-toggle="collapse"
                                        data-target="#employeeXLCollapse"
                                        role="button"
                                        aria-expanded="false"
                                        aria-controls="employeeXLCollapse"
                                      />
                                    ) : (
                                      <AiOutlineDown
                                        className={'float-right'}
                                        size={30}
                                        data-toggle="collapse"
                                        data-target="#employeeXLCollapse"
                                        role="button"
                                        aria-expanded="false"
                                        aria-controls="employeeXLCollapse"
                                      />
                                    )}
                                  </div>
                                </div>
                              </button>
                              <div className="card-body collapse show" id={'employeeXLCollapse'}>
                                <div className="row">
                                  <div className="col-12 col-lg-6">
                                    {!this.state.selectedOSHARecord?.EmployeeUID &&
                                      buildRow(
                                        this.props.t('Employee Search'),
                                        <Select
                                          isDisabled={this.state.selectedOSHARecord?.EmployeeUID}
                                          placeholder={'Please Select...'}
                                          className={'state_select'}
                                          name={'Employee Search'}
                                          options={this.props.allEmployees.map(e => {
                                            return {
                                              label: e.DisplayName,
                                              value: e.UID,
                                            };
                                          })}
                                          onChange={e => {
                                            this.setState({ employeeLabel: e });
                                            this.setEmployeeFields(e.value);
                                          }}
                                          value={this.state.employeeLabel}
                                        />
                                      )}

                                    <CustomInput
                                      readOnly={true}
                                      fromFilterPage={false}
                                      value={this.state.selectedOSHARecord?.FirstName}
                                      label={this.props.t('Employee First Name')}
                                      tooltip={this.props.t('The first name of the employee')}
                                      handleChange={e => this.handleChange(e, 'FirstName')}
                                    />
                                    <CustomInput
                                      readOnly={true}
                                      fromFilterPage={false}
                                      required={true}
                                      value={this.state.selectedOSHARecord?.LastName}
                                      label={this.props.t('Employee Last Name')}
                                      tooltip={this.props.t('The last name of the employee')}
                                      handleChange={e => this.handleChange(e, 'LastName')}
                                    />
                                    <CustomSelect
                                      disabled={true}
                                      fromFilterPage={false}
                                      value={this.state.selectedOSHARecord?.employeeGenderLabel}
                                      label={this.props.t('Gender')}
                                      tooltip={this.props.t('The gender of the employee')}
                                      handleChange={e => this.handleChange(e, 'GenderID', 'employeeGenderLabel', 'select')}
                                      options={translationGenders}
                                    />
                                    <CustomDate
                                      readOnly={true}
                                      fromFilterPage={false}
                                      value={this.state.selectedOSHARecord?.DateOfBirth}
                                      label={this.props.t('Date of Birth')}
                                      tooltip={this.props.t(`The date of birth of the employee`)}
                                      handleDateOnChange={e => this.handleDateOnChange(e, 'DateOfBirth')}
                                    />
                                  </div>
                                  <div className="col-12 col-lg-6">
                                    <CustomInput
                                      readOnly={true}
                                      fromFilterPage={false}
                                      required={true}
                                      value={this.state.selectedOSHARecord?.Title}
                                      label={this.props.t('Employee Title')}
                                      tooltip={this.props.t('The title of the employee')}
                                      handleChange={e => this.handleChange(e, 'Title')}
                                    />
                                    <CustomInput
                                      readOnly={true}
                                      fromFilterPage={false}
                                      required={true}
                                      value={this.state.selectedOSHARecord?.Email}
                                      label={this.props.t('Employee Email')}
                                      tooltip={this.props.t('The email of the employee')}
                                      handleChange={e => this.handleChange(e, 'Email')}
                                    />
                                    <CustomPhone
                                      fromFilterPage={false}
                                      disabled={true}
                                      value={this.state.selectedOSHARecord?.PhoneNumber}
                                      label={this.props.t('Phone')}
                                      tooltip={this.props.t(`The phone number of the employee`)}
                                      handleDateOnChange={e => this.handleDateOnChange(e, 'PhoneNumber')}
                                    />
                                    <CustomDate
                                      readOnly={true}
                                      fromFilterPage={false}
                                      value={this.state.selectedOSHARecord?.HireDate}
                                      label={this.props.t('Employee Hire Date')}
                                      tooltip={this.props.t(`The date employee was hired`)}
                                      handleDateOnChange={e => this.handleDateOnChange(e, 'HireDate')}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card-body py-0">
                        <div className={'row'}>
                          <div className="col-xl-12">
                            <div className="card mb-2">
                              {/*Incident*/}
                              <button
                                className="card-header verlag-bold accordionButton w-100"
                                data-toggle="collapse"
                                data-target="#incidentXLCollapse"
                                onClick={() =>
                                  this.setState({
                                    incidentArrowDown: !this.state.incidentArrowDown,
                                  })
                                }
                                aria-expanded="false"
                                aria-controls="incidentXLCollapse"
                              >
                                <div className="row justify-content-between px-3">
                                  <div>
                                    <h4>{this.props.t('Incident')}</h4>
                                  </div>
                                  <div className="col-2">
                                    {this.state.incidentArrowDown ? (
                                      <AiOutlineUp
                                        className={'float-right'}
                                        size={30}
                                        data-toggle="collapse"
                                        data-target="#incidentXLCollapse"
                                        role="button"
                                        aria-expanded="false"
                                        aria-controls="incidentXLCollapse"
                                      />
                                    ) : (
                                      <AiOutlineDown
                                        className={'float-right'}
                                        size={30}
                                        data-toggle="collapse"
                                        data-target="#incidentXLCollapse"
                                        role="button"
                                        aria-expanded="false"
                                        aria-controls="incidentXLCollapse"
                                      />
                                    )}
                                  </div>
                                </div>
                              </button>
                              <div className="card-body collapse show" id={'incidentXLCollapse'}>
                                <div className="row">
                                  <div className="col-12 col-lg-6">
                                    <CustomInput
                                      fromFilterPage={false}
                                      readOnly={true}
                                      value={this.state.selectedOSHARecord?.CaseNumber}
                                      label={this.props.t('Case Number')}
                                      tooltip={this.props.t('The case number')}
                                      handleChange={e => this.handleChange(e, 'CaseNumber')}
                                    />
                                    <CustomSelect
                                      fromFilterPage={false}
                                      value={this.state.selectedOSHARecord?.caseClassificationLabel}
                                      label={this.props.t('Case Classification')}
                                      tooltip={this.props.t('Case Classification')}
                                      handleChange={e => this.handleChange(e, 'CaseClassification', 'caseClassificationLabel', 'select')}
                                      options={tranlationCaseClassifications}
                                    />
                                    <CustomSelect
                                      fromFilterPage={false}
                                      value={this.state.selectedOSHARecord?.facilityLabel}
                                      label={this.props.t('Facility')}
                                      tooltip={this.props.t('Facility')}
                                      handleChange={e => this.handleChange(e, 'FacilityID', 'facilityLabel', 'select')}
                                      options={this.props.facilityLabels}
                                    />
                                    <div className="form-group row" data-toggle={'tooltip'} data-placement={'top'} title={this.props.t('Time employee began work')}>
                                      <label
                                        id={'timeWorkBegan'}
                                        className="col-12 col-sm-4 col-form-label text-center text-md-left"
                                        style={{
                                          fontWeight: 'bold',
                                          paddingTop: '0.5em',
                                          fontSize: '0.8em',
                                        }}
                                      >
                                        {this.props.t('Time Work Began')}
                                      </label>
                                      <div className="col-12 col-sm-8 p-0 m-0 text-center text-md-left" id={'timeWorkBegan'}>
                                        <input
                                          type="time"
                                          step="60"
                                          name={this.props.t('Time Work Began')}
                                          onChange={e => {
                                            this.setState(prevState => ({
                                              selectedOSHARecord: {
                                                ...prevState.selectedOSHARecord,
                                                TimeEmployeeBeganWork: e.target.value,
                                              },
                                            }));
                                          }}
                                          value={this.state.selectedOSHARecord?.TimeEmployeeBeganWork}
                                        />
                                      </div>
                                    </div>

                                    <div className="form-group row" data-toggle={'tooltip'} data-placement={'top'} title={this.props.t('Time employee began work')}>
                                      <label
                                        id={'timeOfEvent'}
                                        className="col-12 col-sm-4 col-form-label text-center text-md-left"
                                        style={{
                                          fontWeight: 'bold',
                                          paddingTop: '0.5em',
                                          fontSize: '0.8em',
                                        }}
                                      >
                                        {this.props.t('Time of Event')}
                                      </label>
                                      <div className="col-12 col-sm-8 p-0 m-0 text-center text-md-left" id={'timeOfEvent'}>
                                        <input
                                          type="time"
                                          step="60"
                                          name={this.props.t('Time of Event')}
                                          onChange={e => {
                                            this.setState(prevState => ({
                                              selectedOSHARecord: {
                                                ...prevState.selectedOSHARecord,
                                                TimeOfEvent: e.target.value,
                                              },
                                            }));
                                          }}
                                          value={this.state.selectedOSHARecord?.TimeOfEvent}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12 col-lg-6">
                                    <CustomDate
                                      fromFilterPage={false}
                                      value={this.state.selectedOSHARecord?.DateOfInjury}
                                      label={this.props.t('Date of Injury')}
                                      tooltip={this.props.t(`The date of injury`)}
                                      handleDateOnChange={e => this.handleDateOnChange(e, 'DateOfInjury')}
                                    />
                                    <CustomSelect
                                      fromFilterPage={false}
                                      value={this.state.selectedOSHARecord?.injuryTypeLabel}
                                      label={this.props.t('Injury Type')}
                                      tooltip={this.props.t('Injury Type')}
                                      handleChange={e => this.handleChange(e, 'TypeOfInjury', 'injuryTypeLabel', 'select')}
                                      options={tranlationInjuryTypes}
                                    />
                                    <CustomSelect
                                      fromFilterPage={false}
                                      value={this.state.selectedOSHARecord?.returnedToFullDutyLabel}
                                      label={this.props.t('Returned to Full Duty')}
                                      tooltip={this.props.t('Has employee returned to full duty?')}
                                      handleChange={e => this.handleChange(e, 'ReturnedToFullDuty', 'returnedToFullDutyLabel', 'select')}
                                      options={translationBooleans}
                                    />
                                    {this.getReturnToFullDutyEmployeeDeathFlow()}
                                  </div>
                                </div>
                                <div className="row pt-2">
                                  <div className="col-12">
                                    <CustomTextArea
                                      value={this.state.selectedOSHARecord?.ActivityBeforeIncident}
                                      label={this.props.t('Activity Before Incident')}
                                      tooltip={this.props.t('What was the employee doing just before the incident occured?')}
                                      handleChange={e => this.handleChange(e, 'ActivityBeforeIncident')}
                                      maxLength={1000}
                                    />
                                    <CustomTextArea
                                      value={this.state.selectedOSHARecord?.IncidentDescription}
                                      label={this.props.t('Incident Description')}
                                      tooltip={this.props.t('What happened? How did the injury occur?')}
                                      handleChange={e => this.handleChange(e, 'IncidentDescription')}
                                      maxLength={1000}
                                    />
                                    <CustomTextArea
                                      value={this.state.selectedOSHARecord?.InjuryDescription}
                                      label={this.props.t('Injury Description')}
                                      tooltip={this.props.t('What was the injury or illness?')}
                                      handleChange={e => this.handleChange(e, 'InjuryDescription')}
                                      maxLength={1000}
                                    />
                                    <CustomTextArea
                                      value={this.state.selectedOSHARecord?.ObjectOrSubstanceInvolved}
                                      label={this.props.t('Object or Substance Involved')}
                                      tooltip={this.props.t('What object or substance directly harmed the employee?')}
                                      handleChange={e => this.handleChange(e, 'ObjectOrSubstanceInvolved')}
                                      maxLength={1000}
                                    />
                                    <CustomTextArea
                                      value={this.state.selectedOSHARecord?.LocationOfEvent}
                                      label={this.props.t('Location of Event')}
                                      tooltip={this.props.t('Location of Event?')}
                                      handleChange={e => this.handleChange(e, 'LocationOfEvent')}
                                      maxLength={1000}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card-body py-0">
                        <div className={'row'}>
                          <div className="col-xl-12">
                            <div className="card mb-2">
                              {/*Treatment*/}
                              <button
                                className="card-header verlag-bold accordionButton w-100"
                                data-toggle="collapse"
                                data-target="#treatmentXLCollapse"
                                onClick={() =>
                                  this.setState({
                                    treatmentArrowDown: !this.state.treatmentArrowDown,
                                  })
                                }
                                aria-expanded="false"
                                aria-controls="treatmentXLCollapse"
                              >
                                <div className="row justify-content-between px-3">
                                  <div>
                                    <h4>{this.props.t('Treatment')}</h4>
                                  </div>
                                  <div className="col-2">
                                    {this.state.treatmentArrowDown ? (
                                      <AiOutlineUp
                                        className={'float-right'}
                                        size={30}
                                        data-toggle="collapse"
                                        data-target="#treatmentXLCollapse"
                                        role="button"
                                        aria-expanded="false"
                                        aria-controls="treatmentXLCollapse"
                                      />
                                    ) : (
                                      <AiOutlineDown
                                        className={'float-right'}
                                        size={30}
                                        data-toggle="collapse"
                                        data-target="#treatmentXLCollapse"
                                        role="button"
                                        aria-expanded="false"
                                        aria-controls="treatmentXLCollapse"
                                      />
                                    )}
                                  </div>
                                </div>
                              </button>
                              <div className="card-body collapse show" id={'treatmentXLCollapse'}>
                                <div className="row">
                                  <div className="col-12 col-lg-6">
                                    <CustomInput
                                      fromFilterPage={false}
                                      value={this.state.selectedOSHARecord?.PhysicianFirstName}
                                      label={this.props.t('Provider First Name')}
                                      tooltip={this.props.t('First name of health care provider')}
                                      handleChange={e => this.handleChange(e, 'PhysicianFirstName')}
                                    />
                                    <CustomInput
                                      fromFilterPage={false}
                                      value={this.state.selectedOSHARecord?.PhysicianLastName}
                                      label={this.props.t('Provider Last Name')}
                                      tooltip={this.props.t('Last name of health care provider')}
                                      handleChange={e => this.handleChange(e, 'PhysicianLastName')}
                                    />
                                    <CustomInput
                                      fromFilterPage={false}
                                      value={this.state.selectedOSHARecord?.TreatmentFacilityName}
                                      label={this.props.t('Treatment Facility Name')}
                                      tooltip={this.props.t('Treatment Facility Name')}
                                      handleChange={e => this.handleChange(e, 'TreatmentFacilityName')}
                                    />
                                  </div>
                                  <div className="col-12 col-lg-6">
                                    <div
                                      key={'TreatmentFacilityAddressAutocomplete'}
                                      className="form-group row"
                                      data-toggle={'tooltip'}
                                      data-placement={'top'}
                                      title={this.props.t('Treatment Facility Address')}
                                    >
                                      <label
                                        id={'Treatment Facility Address'}
                                        htmlFor={'Treatment Facility Address'}
                                        className="col-12 col-sm-4 col-form-label text-center text-md-left"
                                        style={{
                                          fontWeight: 'bold',
                                          paddingTop: '0.5em',
                                          fontSize: '0.8em',
                                        }}
                                      >
                                        {this.props.t('Treatment Facility Address')}
                                      </label>
                                      <div className="col-12 col-sm-8 p-0 m-0 text-center text-md-left" id={'Treatment Facility Address'}>
                                        <GooglePlacesAutoComplete
                                          key={'TreatmentFacilityAddressAutocomplete'}
                                          id={'TreatmentFacilityAddressAutocomplete'}
                                          value={
                                            this.state.selectedOSHARecord?.TreatmentFacilityCity
                                              ? `${this.state.selectedOSHARecord?.TreatmentFacilityAddress}, ${this.state.selectedOSHARecord?.TreatmentFacilityCity}, ${this.state.selectedOSHARecord?.TreatmentFacilityState} ${this.state.selectedOSHARecord?.TreatmentFacilityZip}`
                                              : this.state.selectedOSHARecord?.TreatmentFacilityAddress
                                          }
                                          onChange={addr => {
                                            if (!addr) {
                                              this.setState({
                                                selectedOSHARecord: {
                                                  ...this.state.selectedOSHARecord,
                                                  TreatmentFacilityAddress: '',
                                                  TreatmentFacilityCity: '',
                                                  TreatmentFacilityState: '',
                                                  TreatmentFacilityZip: '',
                                                },
                                              });
                                            }
                                            this.setState(prevState => ({
                                              selectedOSHARecord: {
                                                ...prevState.selectedOSHARecord,
                                                TreatmentFacilityAddress: addr,
                                              },
                                            }));
                                          }}
                                          updateFormData={(addr, city, state, zip) => {
                                            this.setState({
                                              selectedOSHARecord: {
                                                ...this.state.selectedOSHARecord,
                                                TreatmentFacilityAddress: addr,
                                                TreatmentFacilityCity: city,
                                                TreatmentFacilityState: state,
                                                TreatmentFacilityZip: zip,
                                              },
                                            });
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <CustomSelect
                                      fromFilterPage={false}
                                      value={this.state.selectedOSHARecord?.treatedInEmergencyRoomLabel}
                                      label={this.props.t('Treated in Emergency Room?')}
                                      tooltip={this.props.t(`Mark 'Yes' if employee was treated in emergency room`)}
                                      handleChange={e => this.handleChange(e, 'TreatedInEmergencyRoom', 'treatedInEmergencyRoomLabel', 'select')}
                                      options={translationBooleans}
                                    />
                                    <CustomSelect
                                      fromFilterPage={false}
                                      value={this.state.selectedOSHARecord?.wasInpatientLabel}
                                      label={this.props.t('Hospitalized overnight?')}
                                      tooltip={this.props.t(`Mark 'Yes' if employee was hospitalized overnight`)}
                                      handleChange={e => this.handleChange(e, 'WasInpatient', 'wasInpatientLabel', 'select')}
                                      options={translationBooleans}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card-body py-0">
                        <div className={'row'}>
                          <div className="col-xl-12">
                            <div className="card mb-2">
                              {/*Form Completed By*/}
                              <button
                                className="card-header verlag-bold accordionButton w-100"
                                data-toggle="collapse"
                                data-target="#formCompletedByXLCollapse"
                                onClick={() =>
                                  this.setState({
                                    formCompletedByArrowDown: !this.state.formCompletedByArrowDown,
                                  })
                                }
                                aria-expanded="false"
                                aria-controls="formCompletedByXLCollapse"
                              >
                                <div className="row justify-content-between px-3">
                                  <div>
                                    <h4>{this.props.t('Form Completed By')}</h4>
                                  </div>
                                  <div className="col-2">
                                    {this.state.formCompletedByArrowDown ? (
                                      <AiOutlineUp
                                        className={'float-right'}
                                        size={30}
                                        data-toggle="collapse"
                                        data-target="#formCompletedByXLCollapse"
                                        role="button"
                                        aria-expanded="false"
                                        aria-controls="formCompletedByXLCollapse"
                                      />
                                    ) : (
                                      <AiOutlineDown
                                        className={'float-right'}
                                        size={30}
                                        data-toggle="collapse"
                                        data-target="#formCompletedByXLCollapse"
                                        role="button"
                                        aria-expanded="false"
                                        aria-controls="formCompletedByXLCollapse"
                                      />
                                    )}
                                  </div>
                                </div>
                              </button>
                              <div className="card-body collapse show" id={'formCompletedByXLCollapse'}>
                                <div className="row">
                                  <div className="col-12 col-lg-6">
                                    <CustomInput
                                      fromFilterPage={false}
                                      value={this.state.selectedOSHARecord?.FormCompletedBy}
                                      label={this.props.t('Name')}
                                      tooltip={this.props.t('Name of who completed the form')}
                                      handleChange={e => this.handleChange(e, 'FormCompletedBy')}
                                    />
                                    <CustomInput
                                      fromFilterPage={false}
                                      value={this.state.selectedOSHARecord?.FormCompletedByTitle}
                                      label={this.props.t('Title')}
                                      tooltip={this.props.t('Title of who completed the form')}
                                      handleChange={e => this.handleChange(e, 'FormCompletedByTitle')}
                                    />
                                  </div>
                                  <div className="col-12 col-lg-6">
                                    <CustomPhone
                                      fromFilterPage={false}
                                      value={this.state.selectedOSHARecord?.FormCompletedByPhone}
                                      label={this.props.t('Phone')}
                                      tooltip={this.props.t(`The phone number of who completed the form`)}
                                      handleDateOnChange={e => this.handleDateOnChange(e, 'FormCompletedByPhone')}
                                    />
                                    <CustomDate
                                      fromFilterPage={false}
                                      value={this.state.selectedOSHARecord?.FormCompletedDate}
                                      label={this.props.t('Date')}
                                      tooltip={this.props.t(`The date the form was completed`)}
                                      handleDateOnChange={e => this.handleDateOnChange(e, 'FormCompletedDate')}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer mb-2 mr-2 ml-0" style={{ backgroundColor: 'transparent', borderTop: 'none' }}>
              <button
                className={'btn btn-primary'}
                style={{ float: 'right' }}
                onClick={() => {
                  if (!this.state.selectedOSHARecord || isEmptyObject(this.state.selectedOSHARecord)) {
                    return sweetalert.fire({
                      icon: 'error',
                      title: '',
                      text: this.props.t('Please complete the form before submitting'),
                    });
                  }
                  let validator = new Validator<any>();
                  validator = validator.withComposedValidation(
                    'DateOfInjury',
                    new ValidationEntry(Validators.requireNonNullValidator('Date Of Injury')),
                    new ValidationEntry(Validators.requireNotFutureDate('Date Of Injury')),
                    new ValidationEntry(Validators.requireNotBeforeDate(this.state.selectedOSHARecord?.DateOfBirth, 'Date Of Birth', 'Date Of Injury')),
                    new ValidationEntry(Validators.requireNotBeforeDate(this.state.selectedOSHARecord?.HireDate, 'Hire Date', 'Date Of Injury')),
                    new ValidationEntry(Validators.requireNotAfterDate(this.state.selectedOSHARecord?.FormCompletedDate, 'Form Completed Date', 'Date Of Injury'))
                  );

                  if (this.state.selectedOSHARecord?.ReturnToFullDutyDate) {
                    validator = validator.withComposedValidation(
                      'DateOfInjury',
                      new ValidationEntry(Validators.requireNotAfterDate(this.state.selectedOSHARecord?.ReturnToFullDutyDate, 'Return To Full Duty Date', 'Date Of Injury'))
                    );
                  }
                  validator = validator
                    .withSimpleValidation('CaseClassification', Validators.requireNonNullValidator('Case Classification'))
                    .withSimpleValidation('FacilityID', Validators.requireNonNullValidator('Facility'))
                    .withSimpleValidation('TypeOfInjury', Validators.requireNonNullValidator('Injury Type'))
                    .withSimpleValidation('TimeEmployeeBeganWork', Validators.requireNonNullValidator('Time Work Began'))
                    .withSimpleValidation('TimeOfEvent', Validators.requireNonNullValidator('Time Of Event'));
                  if (this.state.selectedOSHARecord?.ReturnedToFullDuty) {
                    validator = validator.withComposedValidation(
                      'ReturnToFullDutyDate',
                      new ValidationEntry(Validators.requireNonNullValidator('Return To Full Duty Date')),
                      new ValidationEntry(Validators.requireNotFutureDate('Return To Full Duty Date')),
                      new ValidationEntry(Validators.requireNotBeforeDate(this.state.selectedOSHARecord?.DateOfBirth, 'Date Of Birth', 'Return To Full Duty Date')),
                      new ValidationEntry(Validators.requireNotBeforeDate(this.state.selectedOSHARecord?.HireDate, 'Hire Date', 'Return To Full Duty Date')),
                      new ValidationEntry(Validators.requireNotBeforeDate(this.state.selectedOSHARecord?.DateOfInjury, 'Date Of Injury', 'Return To Full Duty Date')),
                      new ValidationEntry(Validators.requireNotAfterDate(this.state.selectedOSHARecord?.FormCompletedDate, 'Form Completed Date', 'Return To Full Duty Date'))
                    );
                  }
                  if (this.state.selectedOSHARecord?.DidEmployeeDie) {
                    validator = validator.withComposedValidation(
                      'EmployeeDeathDate',
                      new ValidationEntry(Validators.requireNonNullValidator('Employee Death Date')),
                      new ValidationEntry(Validators.requireNotFutureDate('Employee Death Date')),
                      new ValidationEntry(Validators.requireNotBeforeDate(this.state.selectedOSHARecord?.DateOfBirth, 'Date Of Birth', 'Employee Death Date')),
                      new ValidationEntry(Validators.requireNotBeforeDate(this.state.selectedOSHARecord?.HireDate, 'Hire Date', 'Employee Death Date')),
                      new ValidationEntry(Validators.requireNotBeforeDate(this.state.selectedOSHARecord?.DateOfInjury, 'Date Of Injury', 'Employee Death Date')),
                      new ValidationEntry(Validators.requireNotAfterDate(this.state.selectedOSHARecord?.FormCompletedDate, 'Form Completed Date', 'Employee Death Date'))
                    );
                  }
                  if (this.state.selectedOSHARecord?.DidEmployeeDie) {
                    validator = validator.withComposedValidation(
                      'DateOfInjury',
                      new ValidationEntry(Validators.requireNotAfterDate(this.state.selectedOSHARecord?.EmployeeDeathDate, 'Employee Death Date', 'Date Of Injury'))
                    );
                  }
                  validator = validator
                    .withSimpleValidation('ActivityBeforeIncident', Validators.requireNotBlankValidator('Activity Before Incident'))
                    .withSimpleValidation('IncidentDescription', Validators.requireNotBlankValidator('Incident Description'))
                    .withSimpleValidation('InjuryDescription', Validators.requireNotBlankValidator('Injury Description'))
                    .withSimpleValidation('ObjectOrSubstanceInvolved', Validators.requireNotBlankValidator('Object Or Substance Involved'))
                    .withSimpleValidation('LocationOfEvent', Validators.requireNotBlankValidator('Location Of Event'));
                  if (
                    this.state.selectedOSHARecord?.PhysicianFirstName ||
                    this.state.selectedOSHARecord?.PhysicianLastName ||
                    this.state.selectedOSHARecord?.TreatmentFacilityName ||
                    this.state.selectedOSHARecord?.TreatmentFacilityAddress ||
                    this.state.selectedOSHARecord?.TreatmentFacilityCity ||
                    this.state.selectedOSHARecord?.TreatmentFacilityState ||
                    this.state.selectedOSHARecord?.TreatmentFacilityZip ||
                    this.state.selectedOSHARecord?.TreatedInEmergencyRoom ||
                    this.state.selectedOSHARecord?.WasInpatient
                  ) {
                    validator = validator
                      .withSimpleValidation('PhysicianFirstName', Validators.requireNotBlankValidator('Provider First Name'))
                      .withSimpleValidation('PhysicianLastName', Validators.requireNotBlankValidator('Provider Last Name'))
                      .withSimpleValidation('TreatmentFacilityName', Validators.requireNotBlankValidator('Treatment Facility Name'))
                      .withSimpleValidation('TreatmentFacilityAddress', Validators.requireNotBlankValidator('Treatment Facility Address'));
                    if (this.state.selectedOSHARecord?.TreatedInEmergencyRoom === null || this.state.selectedOSHARecord?.TreatedInEmergencyRoom === undefined) {
                      validator = validator.withSimpleValidation('TreatedInEmergencyRoom', Validators.requireNonNullValidator('Treated In Emergency Room?'));
                    }
                    if (this.state.selectedOSHARecord?.WasInpatient === null || this.state.selectedOSHARecord?.WasInpatient === undefined) {
                      validator = validator.withSimpleValidation('WasInpatient', Validators.requireNonNullValidator('Hospitalized Overnight?'));
                    }
                  }

                  validator = validator
                    .withSimpleValidation('FormCompletedBy', Validators.requireNotBlankValidator('Form Completed By Name'))
                    .withSimpleValidation('FormCompletedByTitle', Validators.requireNotBlankValidator('Form Completed By Title'))
                    .withSimpleValidation('FormCompletedByPhone', Validators.requireNotBlankValidator('Form Completed By Phone'))
                    .withComposedValidation(
                      'FormCompletedDate',
                      new ValidationEntry(Validators.requireNonNullValidator('Form Completed Date')),
                      new ValidationEntry(Validators.requireNotFutureDate('Form Completed Date')),
                      new ValidationEntry(Validators.requireNotBeforeDate(this.state.selectedOSHARecord?.DateOfBirth, 'Date Of Birth', 'Form Completed Date')),
                      new ValidationEntry(Validators.requireNotBeforeDate(this.state.selectedOSHARecord?.HireDate, 'Hire Date', 'Form Completed Date')),
                      new ValidationEntry(Validators.requireNotBeforeDate(this.state.selectedOSHARecord?.DateOfInjury, 'Date Of Injury', 'Form Completed Date')),
                      new ValidationEntry(Validators.requireNotBeforeDate(this.state.selectedOSHARecord?.HireDate, 'Hire Date', 'Form Completed Date'))
                    );
                  let validationResponse = validator.validate(this.state.selectedOSHARecord);
                  if (!validationResponse.success) {
                    return sweetalert.fire({
                      icon: 'error',
                      title: '',
                      text: validationResponse.error,
                    });
                  }

                  if (this.state.selectedOSHARecord?.ReturnedToFullDuty && this.state.selectedOSHARecord?.DidEmployeeDie) {
                    return sweetalert.fire({
                      icon: 'error',
                      title: '',
                      text: this.props.t('Return to Full Duty and Did Employee Die cannot both be true'),
                    });
                  }
                  if (this.state.selectedOSHARecord?.ReturnedToFullDuty && this.state.selectedOSHARecord?.EmployeeDeathDate) {
                    return sweetalert.fire({
                      icon: 'error',
                      title: '',
                      text: this.props.t('If Return to Full Duty is true, Employee Death Date must be empty'),
                    });
                  }
                  if (this.state.selectedOSHARecord?.DidEmployeeDie && this.state.selectedOSHARecord?.ReturnToFullDutyDate) {
                    return sweetalert.fire({
                      icon: 'error',
                      title: '',
                      text: this.props.t('If Did Employee Die is true, Return to Full Duty Date must be empty'),
                    });
                  }

                  if (this.state.selectedOSHARecord?.TimeEmployeeBeganWork && this.state.selectedOSHARecord?.TimeOfEvent) {
                    let time1 = moment(this.state.selectedOSHARecord.TimeEmployeeBeganWork, 'HH:mm');
                    let time2 = moment(this.state.selectedOSHARecord.TimeOfEvent, 'HH:mm');
                    if (time1.isAfter(time2)) {
                      return sweetalert.fire({
                        icon: 'error',
                        title: '',
                        text: this.props.t('Time Work Began must be earlier than Time of Event'),
                      });
                    }
                  }

                  if (
                    this.state.selectedOSHARecord.DateOfInjury &&
                    this.state.selectedOSHARecord.TimeOfEvent &&
                    new Date().toISOString().split('T')[0] === this.state.selectedOSHARecord.DateOfInjury
                  ) {
                    if (moment().format('HH:mm') < this.state.selectedOSHARecord.TimeOfEvent) {
                      return sweetalert.fire({
                        icon: 'error',
                        title: '',
                        text: this.props.t('Time of Event cannot be in the future'),
                      });
                    }
                  }

                  this.props.onSubmit(this.state.selectedOSHARecord);
                }}
              >
                {this.props.selectedOSHARecord && this.props.selectedOSHARecord.ID ? this.props.t('Update Report') : this.props.t('Submit Report')}{' '}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(OSHAFormModal);
