import NetworkUtil from "./NetworkUtil";
import {ServerResponse} from "../types/ServerResponse";
import { EventSchedForSelect } from "../types/Event";

export default class EventAPI {

    public static async searchEvents(filter, page):Promise<{table_data:[], total:number, num_pages:number}>{
        return NetworkUtil.makePost( "/api/admin/events/eventSchedSearch?page=" + page, filter);
    }

    public static async eventUpsert(event):Promise<ServerResponse>{
        return NetworkUtil.makePost( "/api/admin/events/upsert", event);
    }

    public static async searchEventsForHomePage():Promise<{table_data, total}>{
        return NetworkUtil.makePost("/api/admin/events/eventSearchHomePage", {});
    }

    public static async getEvents(getZeros = 0):Promise<{schedMap}>{
        return NetworkUtil.makeGet("/api/events/" + getZeros)
    }

    public static async getAllEvents(includeData:boolean=false, active:boolean=false):Promise<{success:boolean,reason:string, data:{label:string,value:(number | EventSchedForSelect)}[]}>{
        return NetworkUtil.makePost("/api/admin/events/filter", {includedata: includeData, active: active});
    }

    public static async eventCheckin(reqNum):Promise<ServerResponse>{
        return NetworkUtil.makePost( "/api/admin/events/checkin", {reqNum: reqNum});
    }

}