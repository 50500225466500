import React from "react";
import Select from "react-select";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
// import TimePicker from 'react-time-picker';
// import DatePicker from "react-date-picker";
import DatePicker from "react-datepicker";
import { BsCheck } from "react-icons/bs";
import { AiFillEye } from "react-icons/ai";
import moment from "moment";
import Validator, { ValidationEntry } from "../validation/Validator";
import { Validators } from "../validation/Validators";
export const FIELD_TYPE = {
  SELECT: "select",
  DATE: "date",
  TEXT: "text",
  TEXT_AREA: "text_area",
  PHONE: "PHONE",
  TIME: "TIME",
  NUMBER: "number",
  EMAIL: "email",
};

export const VALIDATOR_TYPE = {
  NotBlankAndLength: "notBlankAndLength",
  Email: "Email",
  Compare: "Compare",
  Zip: "Zip",
  NotNull: "notNull",
  DOB: "DOB",
  Phone: "Phone",
  Onset: "Onset",
};
export interface ReactSelect {
  label: string;
  value: any;
}

export interface FilterPart {
  textType?: string;
  pattern?: string;
  onInput?: any;
  key: string;
  label: string | Element | any;
  default?: any;
  options?: ReactSelect[] | any[];
  isMapped?: boolean;
  placeholder?: string;
  isSearchable?: boolean;
  isClearable?: boolean;
  isMulti?: boolean;
  noOpMessage?: string;
  result_types?: string[];
  rows?: number;
  readOnly?: boolean;
  type: string;
  callback?: (any) => void;
  min?: number;
  max?: number;
  disable?: boolean;
  popoverText?: string;
  maxLength?: number;
  canUpdate?: (value) => boolean;
  isActive?: boolean;
  togglePassword?: boolean;
  dateWarning?: boolean;
  onBlur?;
  validator?;
  compareTo?;
  compareToLabel?;
  required?;
  isFilter?;
}

interface FilterProps {
  fields: FilterPart[];
  filterChanged: (FilterState: any) => void;
  labelStyle?;
  fieldStyle?;
  passClearStateFunc?;
}
interface FilterState {
  eyeColor?: boolean;
  showSSN?: boolean;
  errors?;
  EmployeeDOB?;
  DOB?;
}
export default class FilterCard extends React.Component<
  FilterProps,
  FilterState
> {
  constructor(props) {
    super(props);

    this.state = {};

    let fields = this.props.fields ? this.props.fields : [];
    let state = { errors: new Array(this.props.fields.length) };

    fields.forEach((val) => {
      state[val.key] = val.default ? val.default : "";
    });
    this.state = state;
    this.props.filterChanged(this.state);
    this.clearState = this.clearState.bind(this);
    this.filterChanged = this.filterChanged.bind(this);
    this.TextValidation = this.TextValidation.bind(this);
    this.ValidationResponse = this.ValidationResponse.bind(this);
  }

  shouldComponentUpdate(
    nextProps: Readonly<FilterProps>,
    nextState: Readonly<FilterState>,
    nextContext: any
  ): boolean {
    if (this.props.passClearStateFunc) {
      this.props.passClearStateFunc(this.clearState);
    }
    return true;
  }

  clearState = () => {
    let keys = Object.keys(this.state);
    let propFields = this.props.fields;

    // this.setState({eyeColor: false, showSSN: true})
    this.setState(this.getInitialState(), () => {
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        let propField = propFields.find((f) => f.key === key);

        if (propField && propField.default) {
          this.filterChanged(key, propField.default);
        } else if (key.includes("_selectstate")) {
          let selectPropField = propFields.find(
            (f) => f.key === key.replace("_selectstate", "")
          );
          if (selectPropField && selectPropField.default) {
            this.filterChanged(key, selectPropField.default);
          } else {
            // on CheckIn page after setting the Facility filter, on clear the dropdown
            // would populate with an empty string as a selection - ah
            // this.filterChanged(key, {label: "", value: null});
            this.filterChanged(key, null);
          }
        } else {
          this.filterChanged(key, "");
        }
      }
    });
  };
  getInitialState = () => {
    let fields = this.props.fields ? this.props.fields : [];
    let state = {};

    fields.forEach((val) => {
      state[val.key] = val.default ? val.default : "";
    });
    return state;
  };
  select_changed(data, e, index) {
    let arg = data.key + "_selectstate";
    let obj = {};
    obj[arg] = JSON.parse(JSON.stringify(e));
    this.setState(obj, () => {
      if (!e) {
        e = [];
        this.filterChanged(data.key, e, index, data);
        return;
      }
      e = Array.isArray(e) ? e.map((b) => b.value) : e.value;
      this.filterChanged(data.key, e, index, data);
      //data.validator ? this.TextValidation(this.state[data.key], data.key, data.validator, data.label, index, data.compareTo, data.compareToLabel): null
    });
  }

  filterChanged(key, e, index?, data?) {
    let state = {};
    state[key] = e;

    this.setState(state, () => {
      let obj = {};
      let keys = Object.keys(JSON.parse(JSON.stringify(this.state)));
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        if (!key.includes("_selectstate")) {
          obj[key] = JSON.parse(JSON.stringify(this.state[key]));
        }
      }

      delete obj["eyeColor"];
      delete obj["showSSN"];

      this.props.filterChanged(obj);
      this.props.fields.forEach((f) => {
        if (f.key === key && f.callback)
          f.callback(JSON.parse(JSON.stringify(e)));
      });
      if (data?.validator) {
        this.TextValidation(
          this.state[data.key],
          data.key,
          data.validator,
          data.label,
          index,
          data.compareTo,
          data.compareToLabel
        );
      }
    });
  }

  dateFilterChanged(val, id, e, type, label, index, compareTo, compareToLabel) {
    let state = {};
    state[id] = e;
    this.setState(state, () => {
      let obj = {};
      let keys = Object.keys(JSON.parse(JSON.stringify(this.state)));
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        if (!key.includes("_selectstate")) {
          obj[key] = JSON.parse(JSON.stringify(this.state[key]));
        }
      }
      this.props.filterChanged(obj);
      this.props.fields.forEach((f) => {
        if (f.key === id && f.callback)
          f.callback(JSON.parse(JSON.stringify(e)));
      });
      val = this.state[id];
      this.handleDateChange(
        val,
        id,
        e,
        type,
        label,
        index,
        compareTo,
        compareToLabel
      );
    });
  }

  handleDateChange(val, id, e, type, label, index, compareTo, compareToLabel) {
    //this.filterChanged(id, e);
    this.TextValidation(val, id, type, label, index, compareTo, compareToLabel);
  }

  getLabelStyle() {
    return this.props.labelStyle
      ? this.props.labelStyle
      : "col-sm-4 col-form-label";
  }

  getFieldStyle() {
    return this.props.fieldStyle ? this.props.fieldStyle : "col-sm-8 p-0 m-0 ";
  }

  toggleSSN(e, key) {
    let x = document.getElementById(key) as HTMLInputElement;
    if (this.state.showSSN) {
      x.type = "password";
      this.setState({ eyeColor: false, showSSN: false });
    } else {
      x.type = "text";
      this.setState({ eyeColor: true, showSSN: true });
    }
  }

  addDashes(e, data) {
    const len = e.target.value.length;
    if (len === 3 || len === 6) {
      e.preventDefault();
      this.setState({ [data.key]: `${e.target.value}-${e.data}` });
    }
  }

  TextValidation(val, id, type, label, index, compareTo, compareToLabel) {
    if (typeof label !== "string") {
      label = "Field";
    }
    let element = document.getElementById(id) as HTMLInputElement;
    let textField = {
      value: val,
    };
    if (type === "notBlankAndLength") {
      let validator = new Validator<any>().withValidation(
        "value",
        Validators.requireNotBlankAndLength(50, label)
      );
      let validationResponse = validator.validateField(textField);
      this.ValidationResponse(validationResponse, index, element);
    } else if (type === "Email") {
      let validator = new Validator<any>().withComposedValidation(
        "value",
        new ValidationEntry(Validators.requireValidEmail(label)),
        Validators.requireNotBlankAndLength(100, label)
      );
      let validationResponse = validator.validateField(textField);
      this.ValidationResponse(validationResponse, index, element);
    } else if (type === "Compare") {
      //Didnt use function because of the tailored use case of the logic only for this Validator Type
      let elementToCompare = document.getElementById(
        compareTo
      ) as HTMLInputElement;
      if (element.value !== this.state[compareTo]) {
        //incomplete
        //if previously complete, remove complete class

        if (element.classList.contains("complete")) {
          element.classList.toggle("complete");
        }
        //if already incomplete, stay that way
        if (element.classList.contains("incomplete")) {
          return;
        }
        //apply incomplete class
        element.classList.toggle("incomplete");
        if (this.state.errors) {
          let arr = this.state.errors;
          arr[index] = label + " must match " + compareToLabel;
          this.setState({ errors: arr });
        }
      } else {
        //complete
        //if previously incomplete, remove incomplete class
        if (element.classList.contains("incomplete")) {
          element.classList.toggle("incomplete");
        }
        //if already complete, stay that way
        if (element.classList.contains("complete")) {
          return;
        }
        //apply complete class
        element.classList.toggle("complete");
        if (this.state.errors[index]) {
          let arr = this.state.errors;
          arr[index] = null;
          this.setState({ errors: arr });
        }
      }
    } else if (type === "Zip") {
      let validator = new Validator<any>().withComposedValidation(
        "value",
        new ValidationEntry(Validators.requireZip(label))
      );
      let validationResponse = validator.validateField(textField);
      this.ValidationResponse(validationResponse, index, element);
    } else if (type === "notNull") {
      let validator = new Validator<any>().withSimpleValidation(
        "value",
        Validators.requireNonNullValidator(label)
      );
      let validationResponse = validator.validateField(textField);
      this.ValidationResponse(validationResponse, index, element);
    } else if (type === "DOB") {
      let validator = new Validator<any>().withValidation(
        "value",
        Validators.requireDOB(150, label)
      );
      let validationResponse = validator.validateField(textField);
      this.ValidationResponse(validationResponse, index, element);
    } else if (type === "Phone") {
      let validator = new Validator<any>().withComposedValidation(
        "value",
        new ValidationEntry(Validators.requirePhone(label))
      );
      let validationResponse = validator.validateField(textField);
      this.ValidationResponse(validationResponse, index, element);
    } else if (type === "Onset") {
      let validator = new Validator<any>().withComposedValidation(
        "value",
        new ValidationEntry(
          Validators.requireNotBeforeDate(this.state.EmployeeDOB ? this.state.EmployeeDOB : null, label, label)
        ),
        new ValidationEntry(Validators.requireNonNullValidator(label)),
        new ValidationEntry(Validators.requireNotFutureDate(label))
      );
      let validationResponse = validator.validateField(textField);
      this.ValidationResponse(validationResponse, index, element);
    }
  }

  ValidationResponse(response, index, element) {
    if (!response.success) {
      //incomplete
      //if previously complete, remove complete class
      if (element.classList.contains("complete")) {
        element.classList.toggle("complete");
      }
      //if already incomplete, stay that way
      if (element.classList.contains("incomplete")) {
        return;
      }
      //apply incomplete class
      element.classList.toggle("incomplete");
      if (this.state.errors) {
        let arr = this.state.errors;
        arr[index] = response.error;
        this.setState({ errors: arr });
      }
    } else {
      //complete
      //if previously incomplete, remove incomplete class
      if (element.classList.contains("incomplete")) {
        element.classList.toggle("incomplete");
      }
      //if already complete, stay that way
      if (element.classList.contains("complete")) {
        return;
      }
      //apply complete class
      element.classList.toggle("complete");
      if (this.state.errors[index]) {
        let arr = this.state.errors;
        arr[index] = null;
        this.setState({ errors: arr });
      }
    }

    return console.error(response.error);
  }

  buildText(data: FilterPart, index) {
    return (
      <div
        className={"form-group row"}
        data-toggle={"tooltip"}
        data-placement={"top"}
        title={data.popoverText}
      >
        <label htmlFor={data.key} className={this.getLabelStyle()}>
          {data.label}
          {data.isActive ? (
            <BsCheck className={"float-right"} color={"blue"} size={20} />
          ) : null}
        </label>
        <div
          className={
            data.togglePassword ? "col-8 p-0 m-0" : this.getFieldStyle()
          }
        >
          <input
            type={data.textType ? data.textType : "search"}
            id={data.key}
            placeholder={data.placeholder}
            className={
              data.validator === "Compare"
                ? this.state[data.key] === this.state[data.compareTo]
                  ? "form-control complete"
                  : "form-control incomplete"
                : "form-control"
            }
            value={this.state[data.key]}
            readOnly={data.readOnly}
            autoComplete="off"
            pattern={data.pattern ? data.pattern : null}
            onInput={data.onInput}
            disabled={data.disable}
            aria-label={data.label + (data.isFilter ? " Search" : "")}
            maxLength={data.maxLength ? data.maxLength : null}
            aria-required={data.required ? true : false}
            onBeforeInput={
              data.togglePassword ? (e) => this.addDashes(e, data) : null
            }
            onBlur={() =>
              data.validator
                ? this.TextValidation(
                    this.state[data.key],
                    data.key,
                    data.validator,
                    data.label,
                    index,
                    data.compareTo,
                    data.compareToLabel
                  )
                : null
            }
            onChange={(e) => {
              if (!data.canUpdate || data.canUpdate(e.target.value)) {
                this.filterChanged(data.key, e.target.value, index, data);
              }
            }}
          />
          <span className={data.togglePassword ? "pt-1 m-0" : "d-none"}>
            <AiFillEye
              color={this.state.eyeColor ? "#42A5F5" : "grey"}
              style={{ position: "absolute", right: "4px", top: "4px" }}
              size={30}
              onClick={(e) => this.toggleSSN(e, data.key)}
            />
          </span>
          {this.state.errors ? (
            <div className={"validTip"}>{this.state.errors[index]}</div>
          ) : null}
        </div>
      </div>
    );
  }

  buildNumber(data: FilterPart) {
    return (
      <div
        className="form-group row"
        data-toggle={"tooltip"}
        data-placement={"top"}
        title={data.popoverText}
      >
        <label htmlFor={data.key} className={this.getLabelStyle()}>
          {data.label}
          {data.isActive ? (
            <BsCheck className={"float-right"} color={"blue"} size={20} />
          ) : null}
        </label>
        <div className={this.getFieldStyle()}>
          <input
            type={"number"}
            id={data.key}
            placeholder={data.placeholder}
            className={"form-control"}
            value={this.state[data.key]}
            readOnly={data.readOnly}
            autoComplete="off"
            pattern={data.pattern ? data.pattern : null}
            onInput={data.onInput}
            min={data.min}
            max={data.max}
            disabled={data.disable}
            aria-label={data.label + (data.isFilter ? " Search" : "")}
            aria-required={data.required ? true : false}
            onChange={(e) => {
              if (!data.canUpdate || data.canUpdate(e.target.value)) {
                this.filterChanged(data.key, e.target.value);
              }
            }}
          />
        </div>
      </div>
    );
  }

  buildTextArea(data: FilterPart) {
    return (
      <div
        className={"form-group row"}
        data-toggle={"tooltip"}
        data-placement={"top"}
        title={data.popoverText}
      >
        <label htmlFor={data.key} className={this.getLabelStyle()}>
          {data.label}
          {data.isActive ? (
            <BsCheck className={"float-right"} color={"blue"} size={20} />
          ) : null}
        </label>
        <div className={this.getFieldStyle()}>
          <textarea
            rows={data.rows}
            id={data.key}
            placeholder={data.placeholder}
            className={"form-control"}
            value={this.state[data.key]}
            readOnly={data.readOnly}
            onChange={(e) => this.filterChanged(data.key, e.target.value)}
            aria-label={data.label + (data.isFilter ? " Search" : "")}
            aria-required={data.required ? true : false}
            maxLength={data.maxLength ? data.maxLength : null}
          />
          <div className="row pt-1 pr-3 justify-content-end">
            <div
              className={
                this.state[data.key] && this.state[data.key].length > 0
                  ? "visible"
                  : "invisible"
              }
            >
              <div style={{ fontSize: "0.8em" }}>
                {this.state[data.key] && this.state[data.key].length}/
                {data.maxLength}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  buildTime(data: FilterPart) {
    return (
      <div
        className={"form-group row"}
        data-toggle={"tooltip"}
        data-placement={"top"}
        title={data.popoverText}
      >
        <label
          id={data.label.replaceAll(" ", "")}
          aria-label={data.label + (data.isFilter ? " Search" : "")}
          htmlFor={data.key}
          className="col-sm-4 col-form-label"
        >
          {data.label}
          {data.isActive ? (
            <BsCheck className={"float-right"} color={"blue"} size={20} />
          ) : null}
        </label>
        <div className="col-sm-8 p-0 m-0">
          <DatePicker
            placeholderText="--:-- --"
            minDate={new Date("01-01-1000")}
            maxDate={new Date("12-31-9999")}
            disabled={data.disable}
            showTimeSelect
            showTimeSelectOnly
            selected={Date.parse(
              moment(this.state[data.key], "h:mm aa").toISOString()
            )}
            dateFormat="h:mm aa"
            onChange={(e) => this.filterChanged(data.key, e)}
            ariaLabelledBy={data.label.replaceAll(" ", "")}
          />
        </div>
      </div>
    );
  }

  buildDate(data: FilterPart, index) {
    return (
      <>
        <div
          className={data.dateWarning ? "row" : "form-group row"}
          data-toggle={"tooltip"}
          data-placement={"top"}
          title={data.popoverText}
        >
          <label
            id={data.label.replaceAll(" ", "")}
            aria-label={data.label + (data.isFilter ? " Search" : "")}
            htmlFor={data.key}
            className="col-sm-4 col-form-label"
          >
            {data.label}
            {data.isActive ? (
              <BsCheck className={"float-right"} color={"blue"} size={20} />
            ) : null}
          </label>
          <div className="col-12 col-md-8 p-0 m-0">
            <DatePicker
              id={data.key}
              minDate={new Date("01-01-1000")}
              maxDate={new Date("12-31-9999")}
              disabled={data.disable}
              placeholderText="--/--/----"
              selected={Date.parse(
                moment(this.state[data.key], "MM-DD-YYYY").toISOString()
              )}
              onChange={(e) =>
                data.validator
                  ? this.dateFilterChanged(
                      this.state[data.key],
                      data.key,
                      e,
                      data.validator,
                      data.label,
                      index,
                      data.compareTo,
                      data.compareToLabel
                    )
                  : this.filterChanged(data.key, e, index, data)
              }
              onBlur={() =>
                data.validator
                  ? this.TextValidation(
                      this.state[data.key],
                      data.key,
                      data.validator,
                      data.label,
                      index,
                      data.compareTo,
                      data.compareToLabel
                    )
                  : null
              }
              ariaLabelledBy={data.label.replaceAll(" ", "")}
            />
            {data.dateWarning && (
              <div className="row invisible">
                <div
                  className="col-12 pr-1 mb-2 m-0 text-danger verlag-bold"
                  style={{ fontSize: "1rem" }}
                >
                  *please include ' / ' when entering date
                </div>
              </div>
            )}
            {this.state.errors ? (
              <div className={"validTip"}>{this.state.errors[index]}</div>
            ) : null}
          </div>
        </div>
      </>
    );
  }

  buildSelect(data: FilterPart, index) {
    let options = data.options
      ? data.isMapped
        ? data.options
        : data.options.map((value, index) => {
            return { label: value, value: value };
          })
      : [];

    return (
      <div
        className="form-group row"
        data-toggle={"tooltip"}
        data-placement={"top"}
        title={data.popoverText}
      >
        <label htmlFor={data.key} className={this.getLabelStyle()}>
          {data.label}
          {data.isActive ? (
            <BsCheck className={"float-right"} color={"blue"} size={20} />
          ) : null}
        </label>
        <div className={this.getFieldStyle()}>
          <Select
            key={data.key}
            id={data.key}
            isSearchable={data.isSearchable ? data.isSearchable : true}
            isClearable={data.isClearable ? data.isClearable : false}
            placeholder={
              <div className="accessibilityText">Please Select...</div>
            }
            noOptionsMessage={() =>
              data.noOpMessage ? data.noOpMessage : "No option"
            }
            defaultValue={data.default}
            isMulti={data.isMulti}
            aria-label={data.label + (data.isFilter ? " Search" : "")}
            value={this.state[data.key + "_selectstate"]}
            onChange={(e) => {
              this.select_changed(data, e, index);
            }}
            className={"state_select"}
            options={options}
            isDisabled={data.disable}
            onBlur={() =>
              data.validator
                ? this.TextValidation(
                    this.state[data.key],
                    data.key,
                    data.validator,
                    data.label,
                    index,
                    data.compareTo,
                    data.compareToLabel
                  )
                : null
            }
          />
          {this.state.errors ? (
            <div className={"validTip"}>{this.state.errors[index]}</div>
          ) : null}
        </div>
      </div>
    );
  }

  buildPhone(data: FilterPart, index) {
    return (
      <div
        className="form-group row"
        data-toggle={"tooltip"}
        data-placement={"top"}
        title={data.popoverText}
      >
        <label htmlFor={data.key} className={this.getLabelStyle()}>
          {data.label}
          {data.isActive ? (
            <BsCheck className={"float-right"} color={"blue"} size={20} />
          ) : null}
        </label>
        <div className={this.getFieldStyle()}>
          <PhoneInput
            id={data.key}
            placeholder={
              data.placeholder ? data.placeholder : "Enter phone number"
            }
            onChange={(e) => this.filterChanged(data.key, e, index, data)}
            defaultCountry="US"
            //onBlur={() => data.validator ? this.TextValidation(this.state[data.key], data.key, data.validator, data.label, index, data.compareTo, data.compareToLabel): null}
            value={this.state[data.key]}
          />
          {this.state.errors ? (
            <div className={"validTip"}>{this.state.errors[index]}</div>
          ) : null}
        </div>
      </div>
    );
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  render() {
    // console.log('props in filter card', this.props)
    // console.log('state in filter card', this.state)
    let fields = this.props.fields ? this.props.fields : [];
    return (
      <form id={"filterForm"} onSubmit={this.handleSubmit}>
        {fields.map((val, index) => {
          if (val.type === FIELD_TYPE.SELECT) {
            return this.buildSelect(val, index);
          } else if (val.type === FIELD_TYPE.TEXT) {
            return this.buildText(val, index);
          } else if (val.type === FIELD_TYPE.TEXT_AREA) {
            return this.buildTextArea(val);
          } else if (val.type === FIELD_TYPE.PHONE) {
            return this.buildPhone(val, index);
          } else if (val.type === FIELD_TYPE.TIME) {
            return this.buildTime(val);
          } else if (val.type === FIELD_TYPE.NUMBER) {
            return this.buildNumber(val);
          } else if (val.type === FIELD_TYPE.DATE) {
            return this.buildDate(val, index);
          }
        })}
      </form>
    );
  }
}
