import NetworkUtil from "./NetworkUtil";
import { AlertAudit } from "../types/AlertAudit";
import { CommunicationTemplate } from "../types/CommunicationTemplate";
import Facility from "../types/Facility";
import { EventStat } from "../types/Event";
import { Service } from "../types/Service";
import { CustomFieldType } from "../types/CustomFieldType";
import Insurance from "../types/Insurance";
import TestType from "../types/TestType";
import Employee from "../types/Employee";
import { PasswordConfigurationValues } from "../types/PasswordConfig";

export default class AuditLogsAPI {
  public static async alertAuditLogs(filter: {
    filter: { Name? };
  }): Promise<{ success: boolean; data: AlertAudit[] }> {
    return NetworkUtil.makePost(`/api/admin/audit/alertAuditLogs`, filter);
  }

  public static async commTemplateAuditLogs(filter: {
    filter: { Name? };
  }): Promise<{ success: boolean; data: CommunicationTemplate[] }> {
    return NetworkUtil.makePost(
      `/api/admin/audit/commTemplateAuditLogs`,
      filter
    );
  }

  public static async facilityAuditLogs(filter: {
    filter: { Name? };
  }): Promise<{ success: boolean; data: Facility[] }> {
    return NetworkUtil.makePost(`/api/admin/audit/facilityAuditLogs`, filter);
  }

  public static async eventAuditLogs(filter: {
    filter: { Name? };
  }): Promise<{ success: boolean; data: EventStat[] }> {
    return NetworkUtil.makePost(`/api/admin/audit/podAuditLogs`, filter);
  }

  public static async serviceAuditLogs(filter: {
    filter: { Name? };
  }): Promise<{ success: boolean; data: Service[] }> {
    return NetworkUtil.makePost(`/api/admin/audit/serviceAuditLogs`, filter);
  }

  public static async resultAuditLogs(filter: {
    filter: { Name? };
  }): Promise<{ success: boolean; data: Service[] }> {
    return NetworkUtil.makePost(`/api/admin/audit/resultAuditLogs`, filter);
  }

  public static async customFieldAuditLogs(filter: {
    filter: { DisplayName? };
  }): Promise<{ success: boolean; data: CustomFieldType[] }> {
    return NetworkUtil.makePost(
      `/api/admin/audit/customFieldAuditLogs`,
      filter
    );
  }

  public static async insuranceAuditLogs(filter: {
    filter: { Name? };
  }): Promise<{ success: boolean; data: Insurance[] }> {
    return NetworkUtil.makePost(`/api/admin/audit/insuranceAuditLogs`, filter);
  }

  public static async testsAuditLogs(filter: {
    filter: { Name? };
  }): Promise<{ success: boolean; data: TestType[] }> {
    return NetworkUtil.makePost(`/api/admin/audit/testsAuditLogs`, filter);
  }

  public static async vaccinesAuditLogs(filter: {
    filter: { Name? };
  }): Promise<{ success: boolean; data: TestType[] }> {
    return NetworkUtil.makePost(`/api/admin/audit/vaccinesAuditLogs`, filter);
  }

  public static async evaluationsAuditLogs(filter: {
    filter: { Name? };
  }): Promise<{ success: boolean; data: TestType[] }> {
    return NetworkUtil.makePost(`/api/admin/audit/treatmentsAuditLogs`, filter);
  }

  public static async employeesAuditLogs(filter: {
    filter: { Name? };
  }): Promise<{ success: boolean; data: Employee[] }> {
    return NetworkUtil.makePost(`/api/admin/audit/employeesAuditLogs`, filter);
  }

  public static async pswdConfigAuditLogs(filter: {
    filter: { Name? };
  }): Promise<{ success: boolean; data: PasswordConfigurationValues[] }> {
    return await NetworkUtil.makePost(
      `/api/admin/audit/pswdReqAuditLogs`,
      filter
    );
  }

  public static async fileMgmtAuditLogs(filter: {
    filter: { ProductID; DocumentName? };
  }): Promise<{ success: boolean; data: any }> {
    return await NetworkUtil.makePost(
      `/api/admin/audit/fileMgmtAuditLogs`,
      filter
    );
  }

  public static async bannerMgmtAuditLogs(filter: {
    filter: { BannerID };
  }): Promise<{ success: boolean; data: any }> {
    return await NetworkUtil.makePost(
      `/api/admin/audit/bannerMgmtAuditLogs`,
      filter
    );
  }

  public static async oshaReportAuditLogs(filter: {
    filter: { CaseNumber };
  }): Promise<{ success: boolean; data: any }> {
    return await NetworkUtil.makePost(
      `/api/admin/audit/oshaReportAuditLogs`,
      filter
    );
  }
}
