import React from "react";
import Overlay from "../Overlay";
import { sweetalert } from "../../App";
import Select from "react-select";
import NetworkUtil from "../../network/NetworkUtil";
import SimpleTable from "../tables/SimpleTable";
import SystemAPI from "../../network/SystemAPI";
import FilterCard, { FIELD_TYPE, ReactSelect } from "../FilterCard";
import {
    getNumPages,
    getPageOptions,
    hideModal,
    removeNull,
    showModalNoOutsideClick,
    slicePages,
} from "../../util/FormatUtil";
import { Column } from "../tables/TableBase";
import { Sorter, TableOrder } from "../../sorting/Sorter";
import PaginationTool from "../PaginationTool";
import moment from "moment";
import Incident, { FakeIncidents } from "../../types/Incident";

import OSHAAPI from "../../network/OSHAAPI";
import  OSHAFormModal  from "../modals/OSHAFormModal";
import { fakeEmployees } from "../../types/FakeEmployees";
import { IncidentsModal } from "../modals/IncidentsModal";

const ITEMS_PER_PAGE = 25;

interface IncidentsState {
    showLoading: boolean;
    incidents?: any;
    selectedIncident;
    incidentQueryResults;
    tableData: {}[];
    filter?: {
        EmployeeID
        IncidentDate?
        IncidentType?
    };
    direction?: TableOrder;
    page_options: ReactSelect[];
    selected_page?: { label; value };
    selectedRecord
    states
    gender
    employees
    selectedEmployee
    showModalLoading
}

export default class Incidents extends React.Component<
    any,
    IncidentsState
> {

    constructor(props) {
        super(props);

        this.state = {
            showLoading: false,
            selectedIncident: {},
            tableData: [] as any,
            incidentQueryResults: [] as any,
            filter: { EmployeeID: null, IncidentType: null, IncidentDate: null},
            direction: "asc",
            page_options: [{ label: "1", value: "1" }],
            selected_page: { label: "1", value: 1 },
            selectedRecord: null,
            states: [],
            gender: [],
            employees: [],
            selectedEmployee: null,
            showModalLoading:false
        };

        this.queryIncidentsData = this.queryIncidentsData.bind(this);
    }

    componentDidMount() {
        document.title = 'Incidents';
        let currentURL = window.location.href;
        this.setState({ showLoading: true }, () => {
            SystemAPI.getProductBrandingFromURL(currentURL).then(x => {
                // used on OSHAFormModal
                SystemAPI.getAllStates().then((data) => {
                    this.setState({ states: data });
                });
                // used on OSHAFormModal
                SystemAPI.getAllGenders().then((data) => {
                    this.setState({ gender: data });
                });


                // Faking data for DEMO
                let fakeIncidents:Incident[] = FakeIncidents;
                let employees = fakeEmployees;
                this.setState({
                    incidents: fakeIncidents, 
                    employees: employees, 
                    showLoading: false 
                })
                this.queryIncidentsData(1);
                // IncidentsAPI.getAllIncidents().then(response=> {
                //     // console.log(" incidents response", response)
                //     this.setState({ incidents: response.data, showLoading: false })
                // })
            });
        });
    }

    // TODO get filter working with Employee.DisplayName/ID && IncidentDate
    queryIncidentsData(page:number) {
        // console.log('queryIncidentsData filter?', this.state.filter)
        this.setState({showLoading:true}, async () => {
            try {
                let filter = {
                    EmployeeID: this.state.filter.EmployeeID,
                    IncidentDate: this.state.filter.IncidentDate,
                    IncidentType: this.state.filter.IncidentType
                }
                // let response = await == fakeIncident API call
                // let results = await IncidentsAPI.filterIncidents({
                //     filter: {
                //         EmployeeName: this.state.filter.EmployeeName
                //     }
                // });
                // DEMO FAKE DATA
                let fakeIncidents: Incident[] = FakeIncidents
                let filteredIncidents;
                if(filter.EmployeeID !== ''){
                    filteredIncidents = fakeIncidents.filter(fc => {
                        return fc.EmployeeID === filter.EmployeeID
                    });
                }
                if(filter.IncidentDate !== ''){
                    let filterDate = moment(filter.IncidentDate).format("MM/DD/YYYY")
                    filteredIncidents = fakeIncidents.filter(fc =>{
                        let filterIncidentDate = moment(new Date(fc.IncidentDate)).format("MM/DD/YYYY")
                        return filterIncidentDate === filterDate
                    })
                }
                if(filter.IncidentType !== ""){
                    filteredIncidents = fakeIncidents.filter(fc => {
                        return fc.IncidentType === filter.IncidentType
                    });
                }
                if( 
                    (filter.IncidentDate === "" || filter.IncidentDate === null)&&
                    (filter.EmployeeID  === "" || filter.EmployeeID?.length === 0 ) && 
                    (filter.IncidentType === "" || filter.IncidentType?.length === 0)
                ){
                    filteredIncidents = fakeIncidents;
                }
                let results = {
                    success: true,
                    reason: "",
                    data: filteredIncidents 
                }
                if(filteredIncidents.length === 0){ sweetalert.fire('No Incidents found.')}
                if(!results.success){
                    return sweetalert.fire({title: '', text: 'Unable to filter Incidents data at this time', icon: 'error'})
                }

                this.setState({
                    tableData: slicePages(results.data, page, ITEMS_PER_PAGE),
                    incidentQueryResults: results.data,
                    page_options: getPageOptions(getNumPages(results.data, ITEMS_PER_PAGE)),
                    showLoading: false,
                });
            } catch (e) {
                console.error(e)
                this.setState({showLoading: false})
            }
        });
    }

    handleExportToCSV() {
        //TODO Need to ensure data is set for export logic
        let fakeIncidents: Incident[] = FakeIncidents
        let filteredIncidents = fakeIncidents;

        if(this.state.filter.EmployeeID !== ''){
            filteredIncidents = fakeIncidents.filter(fc => {
                return fc.EmployeeID === this.state.filter.EmployeeID
            });
        }
        if(this.state.filter.IncidentDate !== ''){
            filteredIncidents = fakeIncidents.filter(fc =>{
                fc.IncidentDate === this.state.filter.IncidentDate
            })
        }
        if(this.state.filter.IncidentType !== ''){
            filteredIncidents = fakeIncidents.filter(fc =>{
                fc.IncidentType === this.state.filter.IncidentType
            })
        }

        this.setState({ showLoading: true }, async () => {
            await NetworkUtil.downloadCSV(
                "/api/admin/incidents/csv",
                "IncidentsManagement.xlsx",
                { filter: filteredIncidents});
            this.setState({ showLoading: false });
        });
    }

    changePage(page: number) {
        let allIncidents = this.state.incidentQueryResults;
        let returnData = slicePages(allIncidents, page, ITEMS_PER_PAGE);
        this.setState({ tableData: returnData });
    }

    useSorter(col: Column) {
        let sorter = new Sorter<any>();

        this.setState({
            tableData: sorter.sortByKey(
                this.state.tableData,
                col.key as keyof any,
                this.state.direction
            ),
            direction: this.state.direction === "asc" ? "desc" : "asc",
        });
    }
   
    // Commenting out this feature since its not consistent with other mgmt features
    // clearTable() {
    //     this.setState({
    //         tableData: [],
    //         filter: {EmployeeID: "", IncidentDate:""},
    //         selectedEmployee: null
    //         // saveButton: false,
    //         // submitted: false
    //     })
    // }
    handleReportSubmit(report) {
        // console.log('updatedReport', report)
        hideModal('oshaform')
        this.setState({ showModalLoading: true }, async () => {
            try {
                let result = await OSHAAPI.upsertReport(report)
                // console.log('result', result)
                if (result.success) {
                    this.setState({ selectedRecord: null, showLoading: false, showModalLoading: false })
                    sweetalert.fire({ icon: 'success', title: '', text: `Report ${report.ID ? 'updated' : 'created'} successfully.` })
                    // this.submit(1);
                } else {
                    sweetalert.fire({ icon: 'error', title: '', text: `Could not ${report.ID ? 'Update' : 'Create'} report at this time.` });
                    this.setState({ showModalLoading: false })
                }
            }
            catch (e) {
                console.error(e)
                sweetalert.fire({ icon: 'error', title: '', text: `Could not ${report.ID ? 'Update' : 'Create'} report at this time.` });
                this.setState({ showModalLoading: false })
            }
        })
    }


    render() {
        return (
            <React.Fragment>
                <PaginationTool />
                <Overlay show_loading={this.state.showLoading} />
                <OSHAFormModal
                    onSubmit={(report) => {
                        this.handleReportSubmit(report)
                    }}
                    selectedOSHARecord={
                        this.state.selectedRecord ? this.state.selectedRecord : {}
                    }
                    states={removeNull(this.state.states)}
                    genders={removeNull(this.state.gender)}
                    allEmployees={this.state.employees}
                />

                <IncidentsModal
                    incidents={this.state.incidents}
                    selectedIncident={this.state.selectedIncident ? this.state.selectedIncident : {}}
                    employees={this.state.employees}
                    requery={() => this.queryIncidentsData(1)}
                />
                <div className="container-fluid ">
                    <div className={"row"}>
                        <div className="col-12 col-md-12 col-lg-8 col-xl-5 pt-2">
                            <main id="main-content" tabIndex={-1} aria-label="Incidents">
                                <div className="card mb-2">
                                    <div className="card-header verlag-bold">
                                        <h3>Incidents</h3>
                                    </div>
                                    <div className="card-body">
                                        <FilterCard
                                            fields={[
                                                {
                                                    label: "Employee",
                                                    key: "EmployeeID",
                                                    type: FIELD_TYPE.SELECT,
                                                    options: this.state.employees.map(u => { return { label: u.DisplayName, value: u.ID } }),
                                                    isMapped: true,
                                                    isClearable: true,
                                                    textType: "text",
                                                    isFilter: true
                                                },
                                                {
                                                    label: "Incident Type",
                                                    key: "IncidentType",
                                                    type: FIELD_TYPE.SELECT,
                                                    options: [
                                                        { label: "Accident", value: "Accident" },
                                                        { label: "Injury", value: "Injury" },
                                                        { label: "Altercation", value: "Altercation" },
                                                    ],
                                                    isMapped: true,
                                                    isClearable: true,
                                                    textType: "text",
                                                    isFilter: true
                                                },
                                                {
                                                    label: "Incident Date",
                                                    key: "IncidentDate",
                                                    type: FIELD_TYPE.DATE,
                                                }
                                            ]}
                                            filterChanged={(e) => {
                                                this.setState({ filter: e, selectedEmployee: this.state.employees.filter(emp => emp.ID === e.value) })
                                            }}
                                        />
                                    </div>
                                    <div className="card-footer">
                                        <button
                                            className={"btn immySubmitButtonOutline"}
                                            onClick={() => {
                                                this.setState(
                                                    {
                                                        selected_page: { label: 1, value: 1 },
                                                    },
                                                    () => {
                                                        this.queryIncidentsData(
                                                            this.state.selected_page.value
                                                        );
                                                    }
                                                );
                                            }}
                                        >
                                            Search
                                        </button>

                                        {/* { this.state.tableData?.length > 0 && 
                                            <>&nbsp;<button
                                                className={"btn immyClearButtonOutline"}
                                                onClick={() => {
                                                    this.clearTable()
                                                }}
                                            >
                                                Clear Table
                                            </button></>
                                        } */}
                                        
                                        <button
                                            className="btn btn-outline-success float-right"
                                            onClick={() =>
                                                this.setState(
                                                    {
                                                        selectedIncident: {
                                                            ID: null,
                                                            EmployeeName: "",
                                                        },
                                                    },
                                                    () => {
                                                        showModalNoOutsideClick("incidentsmodal")
                                                    }
                                                )
                                            }
                                        >
                                            Create New
                                        </button>
                                    </div>
                                </div>
                            </main>
                        </div>
                        {this.state.tableData && this.state.tableData.length > 0 && (
                            <div className="col-12 col-md-12 col-lg-12 pt-2">
                                <div className="card mt-2" style={{ marginBottom: '4rem' }}>
                                    <div className="card-header verlag-bold stickToTop">
                                        <h4 className="text-center text-md-left">
                                            Incidents
                                            <section className="tableHeaderSection float-md-right d-flex justify-content-around">
                                                <h4 className={"float-md-right"} aria-label="Total Records" role="alert">
                                                    Total: {this.state.incidentQueryResults.length}
                                                </h4>
                                                <h4 className="d-inline-block float-right align-middle pr-2 ml-5">
                                                    Page{" "}
                                                </h4>
                                                <div className=" align-middle float-right pages ">
                                                    <Select
                                                        isSearchable={true}
                                                        placeholder={"1"}
                                                        noOptionsMessage={() => "No option"}
                                                        value={this.state.selected_page}
                                                        aria-label="Table Page Number"
                                                        onChange={(e: ReactSelect) =>
                                                            this.setState({ selected_page: e }, () =>
                                                                this.changePage(e.value)
                                                            )
                                                        }
                                                        className={"state_select"}
                                                        options={this.state.page_options}
                                                    />
                                                </div>
                                            </section>
                                            <button
                                                className={"d-none d-md-inline btn btn-outline-primary ml-3"}
                                                onClick={() => this.handleExportToCSV()}
                                            >
                                                Export to CSV
                                            </button>
                                        </h4>
                                    </div>
                                    <div className="card-body p-0 m-0 table-responsive">
                                        <SimpleTable
                                            columns={[
                                                {
                                                    label: "Employee Name",
                                                    key: "EmployeeName",
                                                    popoverText: "Click to Sort By Employee Name",
                                                    rawFormat: (val) => {
                                                        return (
                                                            <a
                                                                href={"#"}
                                                                className={'tableNameLinkColor'}
                                                                onClick={() => {
                                                                    this.setState({ selectedIncident: val}, () => {
                                                                        showModalNoOutsideClick("incidentsmodal")
                                                                    });
                                                                }}
                                                            >
                                                                {val.EmployeeName}
                                                            </a>
                                                        );
                                                    },
                                                },
                                                {
                                                    label: "Case #",
                                                    key: "CaseNumber",
                                                    popoverText: "Click to Sort By Case Number",
                                                },
                                                {
                                                    label: "Incident Type",
                                                    key: "IncidentType",
                                                    popoverText: "Click to Sort By Incident Type",
                                                },
                                                {
                                                    label: "Incident Date",
                                                    key: "IncidentDate",
                                                    popoverText: "Click to Sort By Incident Date",
                                                    // rawFormat: (val) => {
                                                    //     return parseDate(val.IncidentDate);
                                                    // },
                                                },
                                                {
                                                    label: "Incident Time",
                                                    key: "IncidentTime",
                                                    popoverText: "Click to Sort By Incident Time",
                                                    // rawFormat: (val) => {
                                                    //     return parseDate(val.IncidentDate);
                                                    // },
                                                },
                                                {
                                                    label: "Activity Before Incident",
                                                    key: "ActivityBeforeIncident",
                                                    popoverText: "Click to Sort By Activity Before Incident",
                                                },
                                                {
                                                    label: "Incident Description",
                                                    key: "Description",
                                                    popoverText: "Click to Sort By Descripton",
                                                },
                                                {
                                                    label: "Object or Substance Involved",
                                                    key: "ObjectOrSubstanceInvolved",
                                                    popoverText: "click to sort by Object or Substance Involved",
                                                },
                                                {
                                                    label: "Did Employee Die?",
                                                    key: "DidEmployeeDie",
                                                    popoverText: "Click to Sort By Did Employee Die?",
                                                    // rawFormat: (val) => {
                                                    //     return parseDate(val.IncidentDate);
                                                    // },
                                                },
                                                {
                                                    label: "Date of Death",
                                                    key: "DateofDeath",
                                                    popoverText: "Click to Sort By Date of Death",
                                                    // rawFormat: (val) => {
                                                    //     return parseDate(val.IncidentDate);
                                                    // },
                                                },
                                            ]}
                                            table_data={this.state.tableData}
                                            columnClickedCallback={(col) => {
                                                this.useSorter(col);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}