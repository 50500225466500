
import React from "react";
import Select, {createFilter} from "react-select";
import {BsCheck} from "react-icons/bs";
import Overlay from "./Overlay";
import AdminAPI from "../network/AdminAPI";
import moment from "moment";
import { getUnwrappedSelect } from "../util/FormatUtil";
import FixedSizeList from "./EventMenuList"
import {sweetalert} from "../App"
import EventSched, { EventSchedForSelect } from "../types/Event"
import EventAPI from "../network/EventAPI";

interface EventProps{
    onChange:any,
    multi?,
    active?,
    vaccine?,
    testing? ,
    defaultEvent?
    shouldReset?
    isActive?
    passClearStateFunc?
}
interface EventState{
    events?:any[]
    selectedEvent?:any
    changedEvent?
    show_loading
}
class EventSelect extends React.Component<EventProps, EventState> {
    constructor(props) {
        super(props);
        this.state = {show_loading: false}
    }

    shouldComponentUpdate(nextProps: Readonly<EventProps>, nextState: Readonly<EventState>, nextContext: any): boolean {
        if(this.props.passClearStateFunc) {
            this.props.passClearStateFunc(this.clearState);
        }
        return true;
    }

    clearState = () =>{
        this.setState({selectedEvent: null})
    }


    componentDidMount(): void {
        this.setState({show_loading: true}, () => {
            
            EventAPI.getAllEvents(true, this.props.active).then(response => {
                if(!response.success){
                    sweetalert.fire("Error loading Events for EventSelect");
                }

                let events = response.data as {label: string; value: EventSchedForSelect}[];

                const today = moment().startOf('day');
                const beforeToday = events.filter((events)=>{
                        let aDate = moment(events.value.TestDate, 'YYYY-MM-DD').startOf('day');
                        return aDate.isBefore(today)
                }).reverse();
                const afterToday = events.filter((events)=>{
                    let bDate = moment(events.value.TestDate, 'YYYY-MM-DD').startOf('day');
                    return bDate.isSameOrAfter(today)
                })
                let groupedEvents = [
                    { label: "Upcoming", options: afterToday},
                    { label: "Past", options: beforeToday}
                ]
                this.setState({events:groupedEvents , show_loading: false});
            })
        })
    }


    componentWillReceiveProps(nextProps: Readonly<EventProps>, nextContext: any) {
        if(nextProps.defaultEvent && !this.state.changedEvent)
            this.setState({selectedEvent:nextProps.defaultEvent});
    }

    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        // console.log('EventSelect state', this.state)
        let events = JSON.parse(JSON.stringify(this.state.events ? this.state.events : []));
        return (<React.Fragment>
                <Overlay show_loading={this.state.show_loading}/>
                <div className="form-group row" data-toggle={'tooltip'} data-placement={'top'} title={'Lists the Event ID, Facility Name, and Event Date'}>
                    <label id={'Event'} className="col-sm-4 col-form-label">Event
                    {this.props.isActive ? (<BsCheck className={'float-right'} color={'blue'} size={20}  />) : null}
                    </label>
                    <div className="col-sm-8  p-0 m-0">
                        <Select
                            for={'Event'}
                            isSearchable={ true}
                            placeholder={"Please Select..."}
                            noOptionsMessage={()=>  "No option"}
                            isMulti={this.props.multi}
                            getOptionValue={option => option.value.ID}
                            value={this.state.selectedEvent}
                            onChange={(e)=> this.setState({selectedEvent: e, changedEvent:true}, () => this.props.onChange({event:getUnwrappedSelect(e)}))}
                            className={"state_select"}
                            options={events}
                            filterOption={createFilter({ignoreAccents:false})}
                            //@ts-ignore
                            components={{FixedSizeList}}
                        />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default EventSelect