import React from 'react';
import FilterCard, { FIELD_TYPE, ReactSelect } from '../FilterCard';
import { getPageOptions, getServiceTypeLabel, getSpecificServiceType, hideModal, parseDate, showModalNoOutsideClick, toLocalDateTime } from '../../util/FormatUtil';
import CheckinEmployeeModal from '../modals/CheckinEmployeeModal';
import Overlay from '../Overlay';
import moment from 'moment';
import EventSelect from '../EventSelect';
import SimpleTable from '../tables/SimpleTable';
import { sweetalert } from '../../App';
import Select from 'react-select';
import TestType from '../../types/TestType';
import { EventSchedForSelect } from '../../types/Event';
import { Column } from '../tables/TableBase';
import { Sorter, TableOrder } from '../../sorting/Sorter';
import ServicesAPI from '../../network/ServicesAPI';
import TestsAPI from '../../network/TestsAPI';
import SystemAPI from '../../network/SystemAPI';
import AdminAPI from '../../network/AdminAPI';
import PaginationTool from '../PaginationTool';
import EventAPI from '../../network/EventAPI';
import { FacilityAPI } from '../../network/FacilityAPI';
import Employee from '../../types/Employee';
import EmployeeAPI from '../../network/EmployeeAPI';

interface CheckinState {
  showLoading: boolean;
  employees: Employee[];
  selectedEmployeeLabel?;
  event?: any;
  selectedRecord?;
  totalAppts?: number;
  testTypes?: TestType[];
  vaccines?;
  evaluations?;
  tests?;
  serviceTypes?;
  FilterFirstName?;
  FilterLastName?;
  FilterDOB?;
  FilterReqNum?;
  FilterApptNum?;
  FilterFacility?;
  TestIDs?;
  ServiceID?;
  direction?: TableOrder;
  services?;
  facilities?;
  page_options: ReactSelect[];
  selected_page?;
  table_data?;
  events: { label: string; value: EventSchedForSelect }[];
}

export default class CheckIn extends React.Component<any, CheckinState> {
  private checkinRef: React.RefObject<CheckinEmployeeModal>;

  constructor(props) {
    super(props);
    this.state = {
      showLoading: false,
      testTypes: [],
      totalAppts: 0,
      direction: 'asc',
      page_options: [{ value: '1', label: '1' }],
      selected_page: { value: 1, label: 1 },
      services: [],
      events: [],
      facilities: [],
      vaccines: [],
      evaluations: [],
      tests: [],
      employees: [],
      serviceTypes: [],
    };
    this.assignClearState = this.assignClearState.bind(this);
    this.submit = this.submit.bind(this);
    this.checkinRef = React.createRef();
    this.assignClearStateEvent = this.assignClearStateEvent.bind(this);
  }

  componentDidMount() {
    this.setState({showLoading: true}, () => {
      ServicesAPI.getAllServices().then(data => {
        this.setState({ services: data.data });
      });
      EmployeeAPI.getEmployeesFromEmployeePortal().then(data => {
        this.setState({ employees: data.employees });
      });
      EventAPI.getAllEvents(true, false).then(response => {
        this.setState({events: response.data as { label: string; value: EventSchedForSelect }[]});
      });
      FacilityAPI.getFacilitiesForSubmissionForm().then((data) => {
        this.setState({facilities: data.authorizedFacilities});
      });
      SystemAPI.getAllEnabledVaccines().then((data) => {
        this.setState({ vaccines: data });
      });
      SystemAPI.getAllEnabledEvaluations().then((data) => {
        this.setState({ evaluations: data });
      });
      SystemAPI.getAllServiceTypes().then((data) => {
        this.setState({ serviceTypes: data });  
      })
      TestsAPI.getAllTestDetails().then((data) => {
        let enabledTests = data.tests.filter((t) => t.IsEnabled);
        this.setState({ tests: enabledTests});
      });
    })
    this.setState({showLoading: false})
  }

  clearFilterStateEvent: () => void = null;

  assignClearStateEvent(func) {
    this.clearFilterStateEvent = func;
  }

  clearFilterState: () => void = null;

  assignClearState(func) {
    this.clearFilterState = func;
  }

  submit(page) {
    if (!this.state.selectedEmployeeLabel && !this.state.FilterReqNum && !this.state.FilterApptNum && (!this.state.FilterFacility || this.state.FilterFacility.length === 0) && (!this.state.event || this.state.event.length === 0)) {
      return sweetalert.fire({
        icon: 'error',
        title: '',
        text: 'Please select a filter',
      });
    }

    this.setState({ showLoading: true }, async () => {
      try {
        let result = await AdminAPI.getFilteredRecordsForCheckin(page, {
          filter: {
            EmployeeUID: this.state.selectedEmployeeLabel && this.state.selectedEmployeeLabel.value ? this.state.selectedEmployeeLabel.value : null,
            ReqNum: this.state.FilterReqNum,
            LinkedApptsID: this.state.FilterApptNum,
            EventID: this.state.event,
            Facilities: this.state.FilterFacility,
          },
        });
        if (result.totalRecords < 1) {
          sweetalert.fire({
            icon: 'info',
            title: '',
            text: 'No appointments were returned',
          });
          this.setState({ showLoading: false, table_data: [], totalAppts: 0 });
          return;
        }
        this.setState({
          totalAppts: result.totalRecords,
          showLoading: false,
          page_options: getPageOptions(result.num_pages),
          table_data: result.table_data.sort((a, b) => a.AppointmentTime && moment(a.AppointmentTime, 'hh:mm a').diff(moment(b.AppointmentTime, 'hh:mm a'))),
        });
      } catch (e) {
        console.error(e);
        sweetalert.fire({
          icon: 'error',
          title: '',
          text: 'Search failed. Please try again.',
        });
        this.setState({ table_data: [], showLoading: false });
      }
    });
  }


  onEdit(reqNum) {
    hideModal(CheckinEmployeeModal.ID);
    this.setState({ showLoading: true }, async() => {
      try {
        let response = await EventAPI.eventCheckin(reqNum);
        if (response.success) {
          sweetalert.fire({ icon: 'success', title: '', text: 'Checked In' });
          this.setState({ totalAppts: 0 });
          this.submit(1);
        } else {
          sweetalert.fire({
            icon: 'error',
            title: '',
            text: `${response.reason}`,
          });
        }
      } catch (e) {
        console.error(e);
      }
      this.setState({ showLoading: false });
    });
  }



  useSorter(col:Column){
    let sorter = new Sorter<any>()

    this.setState({table_data: sorter.sortByKey(this.state.table_data, col.key as keyof any,this.state.direction), direction: this.state.direction === 'asc' ? 'desc' : 'asc'});
  }

  render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
    // console.log('Checkin state', this.state);


    return (
      <React.Fragment>
        <PaginationTool />
        <Overlay show_loading={this.state.showLoading} />
        <CheckinEmployeeModal
          key={this.state.selectedRecord?.ID}
          onSubmit={e => this.onEdit(e)}
          selectedRecord={this.state.selectedRecord}
          employees={this.state.employees}
          facilities={this.state.facilities}
          vaccines={this.state.vaccines}
          evaluations={this.state.evaluations}
          tests={this.state.tests}
          services={this.state.services}
          serviceTypes={this.state.serviceTypes}
          event={this.state.events.find(p => p.value.ID === this.state.selectedRecord?.EventApptID)}
        />

        <div className="container-fluid  min-vh-100 ">
          <div className={'row'}>
            <div className="col-12 col-md-12 col-lg-8 col-xl-5 pt-2">
              <div className="card mb-2">
                <div className="card-header verlag-bold" style={{zIndex: 0}}>
                  <h4>Event Check In</h4>
                </div>
                <div className="card-body">
                  <div className="form-group row">
                    <label htmlFor={'EmployeeSearch'} className="col-sm-4 col-form-label">
                      Employee
                    </label>
                    <div className="col-sm-8 p-0 m-0" id={'EmployeeSearch'}>
                      <Select
                        name="EmployeeSearch"
                        isSearchable={true}
                        isClearable={true}
                        placeholder={<div className="accessibilityText">Please Select...</div>}
                        noOptionsMessage={() => 'No option'}
                        className={'state_select'}
                        options={
                          this.state.employees && this.state.employees.length > 0
                            ? this.state.employees.map(p => {
                                return {
                                  label: `${p.FirstName} ${p.LastName} - ${parseDate(p.DateOfBirth)} - ${p.UID}`,
                                  value: p.UID,
                                };
                              })
                            : []
                        }
                        onChange={e =>
                          this.setState({
                            selectedEmployeeLabel: e,
                          })
                        }
                        value={this.state.selectedEmployeeLabel ? this.state.selectedEmployeeLabel : null}
                        aria-label="EmployeeSearch"
                      />
                    </div>
                  </div>
                  <EventSelect passClearStateFunc={this.assignClearStateEvent} testing={true} active={true} multi={true} onChange={e => this.setState({ event: e.event })} />
                  <FilterCard
                    passClearStateFunc={this.assignClearState}
                    fields={[
                      {
                        label: 'Facility',
                        key: 'FilterFacility',
                        type: FIELD_TYPE.SELECT,
                        options: this.state.facilities.map(f => {return {label: f.FacilityName, value: f.ID}}),
                        isMapped: true,
                        isMulti: true,
                        textType: 'text',
                        isClearable: true
                      },
                      {
                        label: 'Confirmation Code',
                        key: 'FilterReqNum',
                        type: FIELD_TYPE.TEXT,
                        options: [],
                        isMapped: true,
                        onInput: e => (e.target.value = e.target.value.toUpperCase()),
                      },
                      {
                        label: 'Appointment Code',
                        key: 'FilterApptNum',
                        type: FIELD_TYPE.TEXT,
                        options: [],
                        isMapped: true,
                        onInput: e => (e.target.value = e.target.value.toUpperCase()),
                      },
                    ]}
                    filterChanged={e => this.setState(e)}
                  />
                </div>
                <div className="card-footer">
                  <button
                    className="btn immySubmitButtonOutline"
                    onClick={() =>
                      this.setState(
                        {
                          selected_page: {
                            label: 1,
                            value: 1,
                          },
                        },
                        () => this.submit(1)
                      )
                    }
                  >
                    Search
                  </button>
                  <button
                    className="btn immyClearButtonOutline float-right"
                    onClick={() => {
                      this.clearFilterState();
                      this.clearFilterStateEvent();
                      this.setState({ event: null, selectedEmployeeLabel: null, table_data: [], totalAppts: 0 });
                    }}
                  >
                    Clear Filter
                  </button>
                </div>
              </div>
            </div>

            {this.state.totalAppts > 0 && (
              <div className="col-md-12 pt-2">
                <div className="card mt-2 mb-5">
                  <div className="card-header verlag-bold stickToTop">
                    <h4 className="text-center text-md-left">
                      Appointments
                      <section className="tableHeaderSection float-md-right d-flex justify-content-around">
                        <h4 style={{ float: 'right' }} aria-label="Total Records" role="alert">
                          Total: {this.state.totalAppts}
                        </h4>
                        <h4 className="d-inline-block float-right align-middle pr-2 ml-5">Page </h4>
                        <div className=" align-middle float-right pages">
                          <Select
                            isSearchable={true}
                            placeholder={'1'}
                            noOptionsMessage={() => 'No option'}
                            value={this.state.selected_page}
                            onChange={(e: ReactSelect) => this.setState({ selected_page: e }, () => this.submit(e.value))}
                            className={'state_select page-num-select'}
                            options={this.state.page_options}
                          />
                        </div>
                      </section>
                    </h4>
                  </div>
                  <div className="card-body p-0 m-0 table-responsive">
                    <SimpleTable
                      columns={[
                        {
                          label: 'Name',
                          key: 'Name',
                          rawFormat: v => {
                            let employeeName = this.state.employees && this.state.employees.length > 0 ? this.state.employees.find(e => e.UID === v.EmployeeUID).DisplayName : '';
                            return (
                              <a
                                href={'#!'}
                                className={'tableNameLinkColor'}
                                onClick={() =>
                                  this.setState(
                                    {
                                      selectedRecord: JSON.parse(JSON.stringify(v)),
                                    },
                                    () => showModalNoOutsideClick(CheckinEmployeeModal.ID)
                                  )
                                }
                              >
                                {employeeName}
                              </a>
                            );
                          },
                        },
                        {
                          label: 'DOB',
                          key: 'EmployeeDOB',
                          rawFormat: v => {
                            let employeeDob = this.state.employees && this.state.employees.length > 0 ? this.state.employees.find(e => e.UID === v.EmployeeUID).DateOfBirth : '';
                            return (
                              <div>{parseDate(employeeDob)}</div>
                            );
                          },
                        },
                        {
                          label: 'Appointment Code',
                          key: 'LinkedApptsID',
                        },
                        {
                          label: 'Confirmation Code',
                          key: 'ReqNum',
                        },
                        {
                          label: 'Appt. Date',
                          key: 'AppointmentDate',
                          rawFormat: v => {
                            return v.AppointmentDate
                            === 'Invalid date' ? '' : parseDate(v.AppointmentDate)
                            ;
                          },
                        },
                        {
                          label: 'Appt. Time',
                          key: 'AppointmentTime',
                          rawFormat: v => {
                            return v.AppointmentTime
                            === 'Invalid time' ? '' : v.AppointmentTime.replace(/^0/, '')
                            ;
                          },
                        },
                        {
                          label: 'Checked In',
                          key: 'CheckedInDate',
                          rawFormat: v => {
                            return v.CheckedInDate
                            === 'Invalid date' ? '' : toLocalDateTime(v.CheckedInDate)
                            ;
                          },
                        },
                        {
                          label: 'Service',
                          key: 'ServiceID',
                          rawFormat: v => {
                            return v.ServiceID && this.state.services && this.state.services.length > 0 ? this.state.services.find(s => s.ID === v.ServiceID).Name : '';
                          },
                        },
                        {
                          label: 'Service Type',
                          key: 'ServiceTypeID',
                          rawFormat: v => {
                            let serviceType = getServiceTypeLabel(v, this.state.serviceTypes);
                            return serviceType && serviceType.label ? serviceType.label : '';
                          },
                        },
                        {
                          label: 'Service Type Item',
                          key: 'ServiceTypeID',
                          rawFormat: v => {
                            let serviceType = getSpecificServiceType(v, this.state.vaccines, this.state.evaluations, this.state.tests);
                            return serviceType && serviceType.label ? serviceType.label : '';
                          },
                        },
                      ]}
                      table_data={this.state.table_data}
                      columnClickedCallback={col => {
                        this.useSorter(col);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
