export interface Service {
    Name: string
    ID: number
    IsEnabled
    ShowInsurance
    ShowFileUpload
    Tests
    Vaccines
    Evaluations
    HtmlTemplate:string
    ImportantInformation:string
    InformedConsent: string
    Summary:string
    ReleaseOfInformation:string
    ROIConsentAuth:string
    CreatedBy?: string
    CreatedDate: Date
    LastUpdatedBy?: string
    LastUpdatedDate: Date
    CustomFieldData?
    AllowMultipleTests?
    AllowMultipleEvaluations?
    AllowMultipleVaccines?
    AdditionalInformation
    ServiceTypes?
}


export const ServiceTypes = {
    'Vaccine': 1,
    'Evaluation': 2,
    'Test': 4,
    'Other': 5,
}
