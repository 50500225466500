import React from "react";
import ModalForm from "./ModalForm";
import { Sample, SampleEdit } from "../../types/Sample";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import * as EmailValidator from "email-validator";
import PhoneInput from "react-phone-number-input";
import Select from "react-select";
import {
  parseDate,
  toLocalDateTime,
  getDateOrNull,
  getCustomFieldDataSelectLabel,
  getValueArray,
  showModalNoOutsideClick,
  getNameFromID,
  hideModal,
  getLabel,
  getBoolOptionsWithNullValue,
  getBoolSelectOptions,
  removeNull,
} from "../../util/FormatUtil";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  AiFillEye,
  AiOutlineDown,
  AiOutlineUp,
  AiOutlineCheckCircle,
  AiOutlineClose,
} from "react-icons/ai";
import Overlay from "../Overlay";
import {
  calculateAge,
  isBlank,
  isOnlyWhitespace,
} from "../../util/ValidationUtil";
import { sweetalert } from "../../App";
import SamplesAPI, { SampleCheckinBody } from "../../network/SamplesAPI";
import Validator, { ValidationEntry } from "../../validation/Validator";
import { Validators } from "../../validation/Validators";
import TestType from "../../types/TestType";
import { CustomFieldBuilder } from "../../customfields/CustomFieldBuilder";
import { CustomFields } from "../../types/CustomFieldType";
import { InsuranceImageModal } from "./InsuranceImageModal";
import { SampleInsuranceCardModal } from "./SamplesInsuranceCardModal";
import Swal from "sweetalert2";
import { isMobile } from "react-device-detect";
import NetworkUtil from "../../network/NetworkUtil";
import { InsuranceCardUploadModal } from "./InsuranceCardUploadModal";
import InBetweenOverlay from "../InBetweenOverlay";
import GetField from "../../customfields/GetField";
import { isEmptyObject } from "jquery";
import CustomInput from "../form/CustomInput";
import CustomTextArea from "../form/CustomTextArea";
import CustomSelect from "../form/CustomSelect";
import CustomDate from "../form/CustomDate";
import CustomPhone from "../form/CustomPhone";

interface ResultProps {
  selectedSample?;
  states?;
  countries?;
  ethnicity?;
  race?;
  gender?;
  tests?;
  testDetails?;
  result_types?;
  validResultTypes?;
  facilities?;
  events?;
  getResultsButtonBoolean?: boolean;
  getPhysicianReviewButtonBoolean?: boolean;
  isITG?;
  isManagement?;
  customQuestions?;
  customFields?;
  customEmployeeFields?;
  onSubmit: () => void;
  services?;
}

interface ResultState {
  employeeArrowDown: boolean;
  appointmentArrowDown: boolean;
  sampleArrowDown: boolean;
  testArrowDown: boolean;
  facilityArrowDown: boolean;
  insuranceArrowDown: boolean;
  additionalArrowDown: boolean;
  customFieldDataArrowDown: boolean;
  questionnaireDataArrowDown: boolean;
  submitClicked: boolean;
  showLoading: boolean;
  eyeColor: boolean;
  frontInsuranceCardImage: string;
  backInsuranceCardImage: string;
  frontCardImgFile: File;
  backCardImgFile: File;
  imageSaved: boolean;
  showInBetween: boolean;
  captureClicked: boolean;
  showSSN: boolean;
  imageCached: boolean;
  selectedSample?;
  showLoadingForViewInsuranceCards: boolean;
  showLoadingForPhysicianReview: boolean;
}

export default class EmployeeRecordModal extends React.Component<
  ResultProps,
  ResultState
> {
  public formRef: React.RefObject<ModalForm>;
  public static ID = "employeeRecord";
  constructor(props) {
    super(props);
    this.state = {
      frontInsuranceCardImage: "",
      backInsuranceCardImage: "",
      frontCardImgFile: null,
      backCardImgFile: null,
      imageSaved: false,
      showInBetween: false,
      captureClicked: false,
      showSSN: false,
      imageCached: false,
      showLoadingForViewInsuranceCards: false,
      showLoadingForPhysicianReview: false,
      employeeArrowDown: false,
      appointmentArrowDown: false,
      sampleArrowDown: false,
      testArrowDown: false,
      facilityArrowDown: false,
      additionalArrowDown: false,
      insuranceArrowDown: false,
      customFieldDataArrowDown: false,
      questionnaireDataArrowDown: false,
      submitClicked: false,
      showLoading: false,
      eyeColor: false,
    };
    this.formRef = React.createRef();
  }

  componentWillReceiveProps(
    nextProps: Readonly<ResultProps>,
    nextContext: any
  ) {
    // console.log('EmployeeRecordModal nextProps', nextProps)

    if (!isEmptyObject(nextProps.selectedSample)) {
      let selectedSample = nextProps.selectedSample;

      let apptTime = selectedSample.ApptTime;
      let fakeDate = new Date("1970/01/01 " + apptTime);

      let events = [];
      this.props.events.map((p) => {
        p.options.map((o) => {
          events.push({ label: o.label, value: o.value.ID });
        });
      });

      this.setState({
        selectedSample: {
          sampleID:
            selectedSample && selectedSample.ID ? selectedSample.ID : null,
          employeePortalUID:
            selectedSample && selectedSample.EmployeePortalUID
              ? selectedSample.EmployeePortalUID
              : "",
          linkedApptsID:
            selectedSample && selectedSample.LinkedApptsID
              ? selectedSample.LinkedApptsID
              : "",
          reqNum:
            selectedSample && selectedSample.ReqNum
              ? selectedSample.ReqNum
              : "",
          specimenID:
            selectedSample && selectedSample.SpecimenID
              ? selectedSample.SpecimenID
              : "",
          internalSpecimenID:
            selectedSample && selectedSample.InternalSpecimenID
              ? selectedSample.InternalSpecimenID
              : "",
          memo:
            selectedSample && selectedSample.Memo ? selectedSample.Memo : "",
          employeeFirstName:
            selectedSample && selectedSample.EmployeeFirstName
              ? selectedSample.EmployeeFirstName
              : "",
          employeeMiddleName:
            selectedSample && selectedSample.EmployeeMiddleName
              ? selectedSample.EmployeeMiddleName
              : "",
          employeeLastName:
            selectedSample && selectedSample.EmployeeLastName
              ? selectedSample.EmployeeLastName
              : "",
          employeeDOB:
            selectedSample && selectedSample.EmployeeDOB
              ? parseDate(selectedSample.EmployeeDOB)
              : null,
          employeeZip:
            selectedSample && selectedSample.EmployeeZip
              ? selectedSample.EmployeeZip
              : "",
          employeeCity:
            selectedSample && selectedSample.EmployeeCity
              ? selectedSample.EmployeeCity
              : "",
          employeeState:
            selectedSample && selectedSample.EmployeeState
              ? selectedSample.EmployeeState
              : "",
          employeeCounty:
            selectedSample && selectedSample.EmployeeCounty
              ? selectedSample.EmployeeCounty
              : "",
          employeeCountry:
            selectedSample &&
            selectedSample.EmployeeCountry &&
            selectedSample.EmployeeCountry === "United States of America"
              ? "United States"
              : selectedSample && selectedSample.EmployeeCountry
              ? selectedSample.EmployeeCountry
              : "",
          employeeAddress:
            selectedSample && selectedSample.EmployeeAddress
              ? selectedSample.EmployeeAddress
              : "",
          employeeEmail:
            selectedSample && selectedSample.EmployeeEmail
              ? selectedSample.EmployeeEmail
              : "",
          employeePhone:
            selectedSample && selectedSample.EmployeePhone
              ? selectedSample.EmployeePhone
              : "",
          employeeEthnicityID:
            selectedSample && selectedSample.EmployeeEthnicityID
              ? selectedSample.EmployeeEthnicityID
              : "",
          employeeRaceID:
            selectedSample && selectedSample.EmployeeRaceID
              ? selectedSample.EmployeeRaceID
              : "",
          employeeGenderID:
            selectedSample && selectedSample.EmployeeGenderID
              ? selectedSample.EmployeeGenderID
              : "",
          guardianFirstName:
            selectedSample && selectedSample.GuardianFirstName
              ? selectedSample.GuardianFirstName
              : "",
          guardianLastName:
            selectedSample && selectedSample.GuardianLastName
              ? selectedSample.GuardianLastName
              : "",
          providerFirstName:
            selectedSample && selectedSample.ProviderFirstName
              ? selectedSample.ProviderFirstName
              : "",
          providerLastName:
            selectedSample && selectedSample.ProviderLastName
              ? selectedSample.ProviderLastName
              : "",
          collectionDate:
            selectedSample && selectedSample.CollectionDate
              ? toLocalDateTime(selectedSample.CollectionDate)
              : null,
          testIDs:
            selectedSample && selectedSample.TestIDs
              ? JSON.parse(selectedSample.TestIDs)
              : null,
          processedDate:
            selectedSample && selectedSample.ProcessedDate
              ? toLocalDateTime(selectedSample.ProcessedDate)
              : null,
          receivedBy:
            selectedSample && selectedSample["ReceivedBy"]
              ? selectedSample["ReceivedBy"]
              : "",
          droppedDate:
            selectedSample && selectedSample.DroppedDate
              ? toLocalDateTime(selectedSample.DroppedDate)
              : null,
          testOrderedDate:
            selectedSample && selectedSample.TestOrderedDate
              ? toLocalDateTime(selectedSample.TestOrderedDate)
              : null,
          apptTime: selectedSample && selectedSample.ApptTime ? fakeDate : null,
          eventApptID:
            selectedSample && selectedSample.EventApptID
              ? selectedSample.EventApptID
              : "",
          apptCanceled:
            selectedSample && selectedSample.ApptCanceled
              ? selectedSample.ApptCanceled
              : "",
          rerunCount:
            selectedSample && selectedSample.RerunCount
              ? selectedSample.RerunCount
              : "",
          rerunFlag:
            selectedSample && selectedSample.RerunFlag
              ? selectedSample.RerunFlag
              : "",
          consentFlag:
            selectedSample && selectedSample.ConsentFlag
              ? selectedSample.ConsentFlag
              : "",
          reason:
            selectedSample && selectedSample.Reason
              ? selectedSample.Reason
              : "",
          testResultDate:
            selectedSample && selectedSample.TestResultDate
              ? toLocalDateTime(selectedSample.TestResultDate)
              : null,
          reportedDate:
            selectedSample && selectedSample.ReportedDate
              ? toLocalDateTime(selectedSample.ReportedDate)
              : null,
          createdDate:
            selectedSample && selectedSample.CreatedDate
              ? toLocalDateTime(selectedSample.CreatedDate)
              : null,
          facilityID:
            selectedSample && selectedSample.FacilityID
              ? selectedSample.FacilityID
              : "",
          resultsAccessedDate:
            selectedSample && selectedSample.ResultsAccessedDate
              ? toLocalDateTime(selectedSample.ResultsAccessedDate)
              : null,
          facilityName:
            selectedSample && selectedSample.FacilityID
              ? getLabel(selectedSample.FacilityID, this.props.facilities)
              : "",
          employeeEthnicity:
            selectedSample && selectedSample.EmployeeEthnicityID
              ? getLabel(
                  selectedSample.EmployeeEthnicityID,
                  this.props.ethnicity
                )
              : "",
          employeeRace:
            selectedSample && selectedSample.EmployeeRaceID
              ? getLabel(selectedSample.EmployeeRaceID, this.props.race)
              : "",
          employeeGender:
            selectedSample && selectedSample.EmployeeGenderID
              ? getLabel(selectedSample.EmployeeGenderID, this.props.gender)
              : "",
          eventLabel:
            selectedSample && selectedSample.EventApptID
              ? getLabel(selectedSample.EventApptID, events)
              : "",
          stateLabel:
            selectedSample && selectedSample.EmployeeState
              ? getLabel(selectedSample.EmployeeState, this.props.states)
              : "",
          insuredStateLabel:
            selectedSample && selectedSample.InsuredState
              ? getLabel(selectedSample.InsuredState, this.props.states)
              : "",
          countryLabel:
            selectedSample &&
            selectedSample.EmployeeCountry &&
            selectedSample.EmployeeCountry === "United States of America"
              ? getLabel("United States", this.props.countries)
              : selectedSample && selectedSample.EmployeeCountry
              ? getLabel(selectedSample.EmployeeCountry, this.props.countries)
              : "",
          apptCanceledLabel:
            selectedSample && selectedSample.ApptCanceled
              ? getLabel(
                  selectedSample.ApptCanceled,
                  getBoolOptionsWithNullValue()
                )
              : "",
          consentFlagLabel:
            selectedSample && selectedSample.ConsentFlag
              ? getLabel(
                  selectedSample.ConsentFlag,
                  getBoolOptionsWithNullValue()
                )
              : "",
          rerunFlagLabel:
            selectedSample && selectedSample.RerunFlag
              ? getLabel(
                  selectedSample.RerunFlag,
                  getBoolOptionsWithNullValue()
                )
              : "",
          resultApprovedDate:
            selectedSample && selectedSample.ResultApprovedDate
              ? toLocalDateTime(selectedSample.ResultApprovedDate)
              : null,
          createdBy:
            selectedSample && selectedSample.CreatedBy
              ? selectedSample.CreatedBy
              : "",
          lastUpdatedBy:
            selectedSample && selectedSample.LastUpdatedBy
              ? selectedSample.LastUpdatedBy
              : "",
          lastUpdatedDate:
            selectedSample && selectedSample.LastUpdatedDate
              ? toLocalDateTime(selectedSample.LastUpdatedDate)
              : null,
          lot: selectedSample && selectedSample.Lot ? selectedSample.Lot : "",
          CustomFieldData:
            selectedSample && selectedSample.CustomFieldData
              ? JSON.parse(selectedSample.CustomFieldData)
              : null,
          serviceID:
            selectedSample && selectedSample.ServiceID
              ? selectedSample.ServiceID
              : "",
          insuredFirstName:
            selectedSample && selectedSample.InsuredFirstName
              ? selectedSample.InsuredFirstName
              : "",
          insuredLastName:
            selectedSample && selectedSample.InsuredLastName
              ? selectedSample.InsuredLastName
              : "",
          insuredSSN:
            selectedSample && selectedSample.InsuredSSN
              ? selectedSample.InsuredSSN
              : "",
          insuredDOB:
            selectedSample && selectedSample.InsuredDOB
              ? parseDate(selectedSample.InsuredDOB)
              : null,
          insuredMRN:
            selectedSample && selectedSample.InsuredMRN
              ? selectedSample.InsuredMRN
              : "",
          insuredAddressLine1:
            selectedSample && selectedSample.InsuredAddressLine1
              ? selectedSample.InsuredAddressLine1
              : "",
          insuredAddressLine2:
            selectedSample && selectedSample.InsuredAddressLine2
              ? selectedSample.InsuredAddressLine2
              : "",
          insuredCity:
            selectedSample && selectedSample.InsuredCity
              ? selectedSample.InsuredCity
              : "",
          insuredState:
            selectedSample && selectedSample.InsuredState
              ? selectedSample.InsuredState
              : "",
          insuredZip:
            selectedSample && selectedSample.InsuredZip
              ? selectedSample.InsuredZip
              : "",
          insuredFrontCard:
            selectedSample && selectedSample.InsuredFrontCardURL
              ? selectedSample.InsuredFrontCardURL
              : "",
          insuredBackCard:
            selectedSample && selectedSample.InsuredBackCardURL
              ? selectedSample.InsuredBackCardURL
              : "",
          IncludeInHL7:
            (selectedSample && selectedSample.IncludeInHL7 === 1) ||
            selectedSample.IncludeInHL7 === 0
              ? selectedSample.IncludeInHL7
              : "",
          IncludeInHL7Label:
            selectedSample && selectedSample.IncludeInHL7
              ? { label: "Yes", value: 1 }
              : { label: "No", value: 0 },
          testsAndResultsMap:
            selectedSample && selectedSample.TestsAndResultsMap
              ? JSON.parse(selectedSample.TestsAndResultsMap)
              : null,
          resultIDs:
            selectedSample && selectedSample.ResultIDs
              ? JSON.parse(selectedSample.ResultIDs)
              : null,
          resultsReviewed:
            selectedSample && selectedSample.HasPhysicianReviewed
              ? selectedSample.HasPhysicianReviewed
              : null,
        },
      });
    } else {
      this.setState({
        selectedSample: {
          sampleID: null,
          employeePortalUID: "",
          linkedApptsID: "",
          reqNum: "",
          specimenID: "",
          internalSpecimenID: "",
          memo: "",
          employeeFirstName: "",
          employeeMiddleName: "",
          employeeLastName: "",
          employeeDOB: null,
          employeeZip: "",
          employeeCity: "",
          employeeState: "",
          employeeCounty: "",
          employeeCountry: "",
          employeeAddress: "",
          employeeEmail: "",
          employeePhone: "",
          employeeEthnicityID: "",
          employeeRaceID: "",
          employeeGenderID: "",
          guardianFirstName: "",
          guardianLastName: "",
          providerFirstName: "",
          providerLastName: "",
          collectionDate: null,
          testIDs: null,
          processedDate: null,
          receivedBy: "",
          droppedDate: null,
          testOrderedDate: null,
          apptTime: null,
          eventApptID: "",
          apptCanceled: "",
          rerunCount: "",
          rerunFlag: "",
          consentFlag: "",
          resultIDs: null,
          reason: "",
          testResultDate: null,
          reportedDate: null,
          createdDate: null,
          facilityID: "",
          resultsAccessedDate: null,
          facilityName: "",
          employeeEthnicity: "",
          employeeRace: "",
          employeeGender: "",
          eventLabel: "",
          stateLabel: "",
          insuredStateLabel: "",
          countryLabel: "",
          apptCanceledLabel: "",
          consentFlagLabel: "",
          rerunFlagLabel: "",
          resultApprovedDate: null,
          createdBy: "",
          lastUpdatedBy: "",
          lastUpdatedDate: null,
          lot: "",
          CustomFieldData: null,
          serviceID: "",
          insuredFirstName: "",
          insuredLastName: "",
          insuredSSN: "",
          insuredDOB: null,
          insuredMRN: "",
          insuredAddressLine1: "",
          insuredAddressLine2: "",
          insuredCity: "",
          insuredState: "",
          insuredZip: "",
          insuredFrontCard: "",
          insuredBackCard: "",
          IncludeInHL7: "",
          IncludeInHL7Label: "",
          testsAndResultsMap: null,
          resultsReviewed: null,
        },
      });
    }
  }

  getEdittedObj() {
    return {
      ID: this.state.selectedSample?.sampleID,
      employeePortalUID: this.state.selectedSample?.employeePortalUID
        ? this.state.selectedSample?.employeePortalUID.trim()
        : null,
      LinkedApptsID: this.state.selectedSample?.linkedApptsID
        ? this.state.selectedSample.linkedApptsID.trim()
        : null,
      ReqNum: this.state.selectedSample?.reqNum
        ? this.state.selectedSample?.reqNum.trim()
        : null,
      SpecimenID: this.state.selectedSample?.specimenID
        ? this.state.selectedSample?.specimenID.trim()
        : null,
      InternalSpecimenID: this.state.selectedSample?.internalSpecimenID
        ? this.state.selectedSample?.internalSpecimenID.trim()
        : null,
      Memo: this.state.selectedSample?.memo
        ? this.state.selectedSample?.memo.trim()
        : null,
      EmployeeFirstName: this.state.selectedSample?.employeeFirstName
        ? this.state.selectedSample?.employeeFirstName.trim()
        : null,
      EmployeeMiddleName: this.state.selectedSample?.employeeMiddleName
        ? this.state.selectedSample?.employeeMiddleName.trim()
        : null,
      EmployeeLastName: this.state.selectedSample?.employeeLastName
        ? this.state.selectedSample?.employeeLastName.trim()
        : null,
      EmployeeDOB: getDateOrNull(this.state.selectedSample?.employeeDOB),
      EmployeeZip: this.state.selectedSample?.employeeZip
        ? this.state.selectedSample?.employeeZip.trim()
        : null,
      EmployeeCity: this.state.selectedSample?.employeeCity
        ? this.state.selectedSample?.employeeCity.trim()
        : null,
      EmployeeState: this.state.selectedSample?.stateLabel
        ? this.state.selectedSample?.stateLabel.value
        : null,
      EmployeeCounty: this.state.selectedSample?.employeeCounty
        ? this.state.selectedSample?.employeeCounty.trim()
        : null,
      EmployeeCountry: this.state.selectedSample?.countryLabel
        ? this.state.selectedSample?.countryLabel.value
        : null,
      EmployeeAddress: this.state.selectedSample?.employeeAddress
        ? this.state.selectedSample?.employeeAddress.trim()
        : null,
      EmployeeEmail: this.state.selectedSample?.employeeEmail
        ? this.state.selectedSample?.employeeEmail.trim()
        : null,
      EmployeePhone: this.state.selectedSample?.employeePhone
        ? this.state.selectedSample?.employeePhone.trim()
        : null,
      EmployeeEthnicityID: this.state.selectedSample?.employeeEthnicity
        ? this.state.selectedSample?.employeeEthnicity.value
        : null,
      EmployeeRaceID: this.state.selectedSample?.employeeRaceID
        ? this.state.selectedSample?.employeeRace.value
        : null,
      EmployeeGenderID: this.state.selectedSample?.employeeGender
        ? this.state.selectedSample?.employeeGender.value
        : null,
      GuardianFirstName: this.state.selectedSample?.guardianFirstName
        ? this.state.selectedSample?.guardianFirstName.trim()
        : null,
      GuardianLastName: this.state.selectedSample?.guardianLastName
        ? this.state.selectedSample?.guardianLastName.trim()
        : null,
      ProviderFirstName: this.state.selectedSample?.providerFirstName
        ? this.state.selectedSample?.providerFirstName.trim()
        : null,
      ProviderLastName: this.state.selectedSample?.providerLastName
        ? this.state.selectedSample?.providerLastName.trim()
        : null,
      SampleReceivedID: this.state.selectedSample?.binID
        ? this.state.selectedSample?.bin.value.ID
        : null,
      CollectionDate: getDateOrNull(this.state.selectedSample?.collectionDate),
      TestIDs: this.state.selectedSample?.testIDs
        ? this.state.selectedSample?.testIDs
        : null,
      ProcessedDate: getDateOrNull(this.state.selectedSample?.processedDate),
      TestOrderedDate: getDateOrNull(
        this.state.selectedSample?.testOrderedDate
      ),
      ApptTime: this.state.selectedSample?.apptTime
        ? moment(this.state.selectedSample?.apptTime, "hh:mm a").format(
            "hh:mm a"
          )
        : null,
      EventApptID: this.state.selectedSample?.eventLabel
        ? this.state.selectedSample?.eventLabel.value
        : null,
      ApptCanceled: this.state.selectedSample?.apptCanceledLabel
        ? this.state.selectedSample?.apptCanceledLabel.value
        : null,
      RerunCount: this.state.selectedSample?.rerunCount
        ? this.state.selectedSample?.rerunCount
        : null,
      RerunFlag: this.state.selectedSample?.rerunFlagLabel
        ? this.state.selectedSample?.rerunFlagLabel.value
        : null,
      ConsentFlag: this.state.selectedSample?.consentFlagLabel
        ? this.state.selectedSample?.consentFlagLabel.value
        : null,
      ResultIDs: this.state.selectedSample?.resultIDs
        ? this.state.selectedSample?.resultIDs
        : null,
      Reason: this.state.selectedSample?.reason
        ? this.state.selectedSample?.reason.trim()
        : null,
      TestResultDate: getDateOrNull(this.state.selectedSample?.testResultDate),
      ReportedDate: getDateOrNull(this.state.selectedSample?.reportedDate),
      CreatedDate: getDateOrNull(this.state.selectedSample?.createdDate),
      FacilityID: this.state.selectedSample?.facilityID
        ? this.state.selectedSample?.facilityName.value
        : null,
      ResultsAccessedDate: getDateOrNull(
        this.state.selectedSample?.resultsAccessedDate
      ),
      Lot: this.state.selectedSample?.lot
        ? this.state.selectedSample?.lot.trim()
        : null,
      CustomFieldData: this.state.selectedSample?.CustomFieldData
        ? this.state.selectedSample?.CustomFieldData
        : null,
      ServiceID: this.state.selectedSample?.serviceID
        ? this.state.selectedSample?.serviceID
        : null,
      InsuredFirstName: this.state.selectedSample?.insuredFirstName
        ? this.state.selectedSample?.insuredFirstName.trim()
        : null,
      InsuredLastName: this.state.selectedSample?.insuredLastName
        ? this.state.selectedSample?.insuredLastName.trim()
        : null,
      InsuredSSN: this.state.selectedSample?.insuredSSN
        ? this.state.selectedSample?.insuredSSN.replace(/-/g, "").trim()
        : null,
      InsuredDOB: getDateOrNull(this.state.selectedSample?.insuredDOB),
      InsuredMRN: this.state.selectedSample?.insuredMRN
        ? this.state.selectedSample?.insuredMRN.trim()
        : null,
      InsuredAddressLine1: this.state.selectedSample?.insuredAddressLine1
        ? this.state.selectedSample?.insuredAddressLine1.trim()
        : null,
      InsuredAddressLine2: this.state.selectedSample?.insuredAddressLine2
        ? this.state.selectedSample?.insuredAddressLine2.trim()
        : null,
      InsuredCity: this.state.selectedSample?.insuredCity
        ? this.state.selectedSample?.insuredCity.trim()
        : null,
      InsuredState: this.state.selectedSample?.insuredStateLabel
        ? this.state.selectedSample?.insuredStateLabel.value
        : null,
      InsuredZip: this.state.selectedSample?.insuredZip
        ? this.state.selectedSample?.insuredZip.trim()
        : null,
      InsuredFrontCard: this.state.selectedSample.insuredFrontCard
        ? this.state.selectedSample.insuredFrontCard
        : null,
      InsuredBackCard: this.state.selectedSample.insuredBackCard
        ? this.state.selectedSample.insuredBackCard
        : null,
      IncludeInHL7: this.state.selectedSample?.IncludeInHL7
        ? this.state.selectedSample.IncludeInHL7
        : null,
      TestsAndResultsMap: this.state.selectedSample?.testsAndResultsMap
        ? this.state.selectedSample?.testsAndResultsMap
        : null,
    };
  }

  onSubmit() {
    let validator = new Validator<any>().withValidation(
      "EmployeeFirstName",
      Validators.requireNotBlankAndLength(50, "Employee First Name")
    );
    if (
      this.state.selectedSample?.employeeMiddleName &&
      this.state.selectedSample?.employeeMiddleName.trim().length > 0
    ) {
      validator = validator.withValidation(
        "EmployeeMiddleName",
        Validators.requireNotBlankAndLength(50, "Employee Middle Name")
      );
    }
    validator = validator
      .withValidation(
        "EmployeeLastName",
        Validators.requireNotBlankAndLength(50, "Employee Last Name")
      )
      .withValidation(
        "EmployeeDOB",
        Validators.requireDOB(150, "Employee Date of Birth")
      );
    if (
      this.state.selectedSample?.employeePhone &&
      this.state.selectedSample?.employeePhone.trim().length > 0
    ) {
      validator = validator.withComposedValidation(
        "EmployeePhone",
        new ValidationEntry(
          Validators.requireNonNullValidator("Employee Phone")
        ),
        new ValidationEntry(Validators.requirePhone("Employee Phone"))
      );
    }
    if (
      this.state.selectedSample?.employeeEmail &&
      this.state.selectedSample?.employeeEmail.trim().length > 0
    ) {
      validator = validator.withComposedValidation(
        "EmployeeEmail",
        new ValidationEntry(Validators.requireValidEmail("Employee Email")),
        Validators.requireNotBlankAndLength(100, "Employee Email")
      );
    }
    if (
      this.state.selectedSample?.employeeAddress &&
      this.state.selectedSample?.employeeAddress.trim().length > 0
    ) {
      validator = validator.withValidation(
        "EmployeeAddress",
        Validators.requireNotBlankAndLength(500, "Employee Address")
      );
    }
    if (
      this.state.selectedSample?.employeeCity &&
      this.state.selectedSample?.employeeCity.trim().length > 0
    ) {
      validator = validator.withValidation(
        "EmployeeCity",
        Validators.requireNotBlankAndLength(100, "Employee City")
      );
    }
    if (
      this.state.selectedSample?.employeeZip &&
      this.state.selectedSample?.employeeZip.trim().length > 0
    ) {
      validator = validator.withComposedValidation(
        "EmployeeZip",
        new ValidationEntry(
          Validators.requireNotBlankValidator("Employee Zipcode")
        ),
        new ValidationEntry(Validators.requireZip("Employee Zipcode"))
      );
    }
    if (
      this.state.selectedSample?.guardianFirstName &&
      this.state.selectedSample?.guardianFirstName.trim().length > 0
    ) {
      validator = validator.withValidation(
        "GuardianFirstName",
        Validators.requireNotBlankAndLength(50, "Guardian First Name")
      );
    }
    if (
      this.state.selectedSample?.guardianLastName &&
      this.state.selectedSample?.guardianLastName.trim().length > 0
    ) {
      validator = validator.withValidation(
        "GuardianLastName",
        Validators.requireNotBlankAndLength(50, "Guardian Last Name")
      );
    }
    if (this.state.selectedSample?.rerunCount) {
      validator = validator.withValidation(
        "RerunCount",
        new ValidationEntry<SampleEdit>((key, attr, obj) => {
          if (isNaN(parseInt(attr))) {
            return { success: false, error: "Rerun Count must be numeric" };
          } else if (attr < 1 || attr > 100) {
            return {
              success: false,
              error: "Rerun Count must be between 1 and 100",
            };
          }
          return { success: true, error: null };
        })
      );
    }
    if (
      this.state.selectedSample?.reason &&
      this.state.selectedSample?.reason.trim().length > 0
    ) {
      validator = validator.withValidation(
        "Reason",
        Validators.requireNotBlankAndLength(500, "Result Reason")
      );
    }
    validator = validator.withSimpleValidation(
      "SpecimenID",
      Validators.requireNotBlankIfExistsValidator(
        this.props.selectedSample.SpecimenID,
        "Specimen ID"
      )
    );
    validator = validator.withSimpleValidation(
      "InternalSpecimenID",
      Validators.requireNotBlankIfExistsValidator(
        this.props.selectedSample.InternalSpecimenID,
        "Internal Specimen ID"
      )
    );
    if (
      this.state.selectedSample?.internalSpecimenID &&
      this.state.selectedSample?.internalSpecimenID.trim().length > 0
    ) {
      validator = validator.withValidation(
        "InternalSpecimenID",
        Validators.requireNotBlankAndLength(50, "Internal Specimen ID")
      );
    }
    if (this.state.selectedSample?.collectionDate) {
      validator = validator.withComposedValidation(
        "CollectionDate",
        new ValidationEntry(Validators.requireNotFutureDate("Collection Date")),
        new ValidationEntry(
          Validators.requireNotBlankIfExistsValidator(
            this.props.selectedSample.CollectionDate,
            "Collection Date"
          )
        ),
        new ValidationEntry(Validators.requireNotBeforeDate(this.state.selectedSample?.employeeDOB, "Collection Date", "Collection Date")),
      );
    }
    if (this.state.selectedSample?.testOrderedDate) {
      validator = validator.withComposedValidation(
        "TestOrderedDate",
        new ValidationEntry(
          Validators.requireNotFutureDate("Test Ordered Date")
        ),
        new ValidationEntry(
          Validators.requireNotBlankIfExistsValidator(
            this.props.selectedSample.TestOrderedDate,
            "Test Ordered Date"
          )
        ),
        new ValidationEntry(
          Validators.requireNotBeforeDate(this.state.selectedSample?.employeeDOB, "Test Ordered Date", "Test Ordered Date"
          )
        )
      );
    }
    if (this.state.selectedSample?.processedDate) {
      validator = validator.withComposedValidation(
        "ProcessedDate",
        new ValidationEntry(Validators.requireNotFutureDate("Processed Date")),
        new ValidationEntry(
          Validators.requireNotBeforeDate(this.state.selectedSample?.employeeDOB, "Processed Date", "Processed Date"
          )
        )
      );
    }
    if (this.state.selectedSample?.testResultDate) {
      validator = validator.withComposedValidation(
        "TestResultDate",
        new ValidationEntry(
          Validators.requireNotFutureDate("Test Result Date")
        ),
        new ValidationEntry(
          Validators.requireNotBeforeDate(this.state.selectedSample?.employeeDOB, "Test Result Date", "Test Result Date"
          )
        )
      );
    }
    if (this.state.selectedSample?.reportedDate) {
      validator = validator.withComposedValidation(
        "ReportedDate",
        new ValidationEntry(Validators.requireNotFutureDate("Reported Date")),
        new ValidationEntry(
          Validators.requireNotBeforeDate(this.state.selectedSample?.employeeDOB, "Reported Date", "Reported Date"
          )
        )
      );
    }
    if (this.state.selectedSample?.resultsAccessedDate) {
      validator = validator.withComposedValidation(
        "ResultsAccessedDate",
        new ValidationEntry(
          Validators.requireNotFutureDate("Results Accessed Date")
        ),
        new ValidationEntry(
          Validators.requireNotBeforeDate(this.state.selectedSample?.employeeDOB, "Results Accessed Date", "Results Accessed Date"
          )
        )
      );
    }

    validator = validator.withSimpleValidation(
      "FacilityID",
      Validators.requireNotBlankIfExistsValidator(
        this.props.selectedSample.FacilityID,
        "Facility ID"
      )
    );

    validator = validator
      .withSimpleValidation(
        "InsuredFirstName",
        Validators.requireNotBlankIfExistsValidator(
          this.props.selectedSample.InsuredFirstName,
          "Insured First Name"
        )
      )
      .withSimpleValidation(
        "InsuredLastName",
        Validators.requireNotBlankIfExistsValidator(
          this.props.selectedSample.InsuredLastName,
          "Insured Last Name"
        )
      )
      .withSimpleValidation(
        "InsuredDOB",
        Validators.requireNotBlankIfExistsValidator(
          this.props.selectedSample.InsuredDOB,
          "Insured DOB"
        )
      )
      .withSimpleValidation(
        "InsuredAddressLine1",
        Validators.requireNotBlankIfExistsValidator(
          this.props.selectedSample.InsuredAddressLine1,
          "Insured Address"
        )
      )
      .withSimpleValidation(
        "InsuredAddressLine2",
        Validators.requireNotBlankIfExistsValidator(
          this.props.selectedSample.InsuredAddressLine2,
          "Insured Address Cont."
        )
      )
      .withSimpleValidation(
        "InsuredCity",
        Validators.requireNotBlankIfExistsValidator(
          this.props.selectedSample.InsuredCity,
          "Insured City"
        )
      )
      .withSimpleValidation(
        "InsuredState",
        Validators.requireNotBlankIfExistsValidator(
          this.props.selectedSample.InsuredState,
          "Insured State"
        )
      )
      .withSimpleValidation(
        "InsuredZip",
        Validators.requireNotBlankIfExistsValidator(
          this.props.selectedSample.InsuredZip,
          "Insured Zip"
        )
      )
      .withSimpleValidation(
        "InsuredSSN",
        Validators.requireNotBlankIfExistsValidator(
          this.props.selectedSample.InsuredSSN,
          "Insured SSN"
        )
      )
      .withSimpleValidation(
        "InsuredMRN",
        Validators.requireNotBlankIfExistsValidator(
          this.props.selectedSample.InsuredMRN,
          "Insured MRN"
        )
      );
    if (
      this.state.selectedSample?.insuredFirstName &&
      this.state.selectedSample?.insuredFirstName.trim().length > 0
    ) {
      validator = validator.withValidation(
        "InsuredFirstName",
        Validators.requireNotBlankAndLength(100, "Insured First Name")
      );
    }
    if (
      this.state.selectedSample?.insuredLastName &&
      this.state.selectedSample?.insuredLastName.trim().length > 0
    ) {
      validator = validator.withValidation(
        "InsuredLastName",
        Validators.requireNotBlankAndLength(100, "Insured Last Name")
      );
    }
    if (
      this.state.selectedSample?.insuredSSN &&
      this.state.selectedSample?.insuredSSN.trim().length > 0
    ) {
      validator = validator
        .withValidation(
          "InsuredSSN",
          Validators.requireNotBlankAndLength(45, "Insured SSN")
        )
        .withSimpleValidation(
          "InsuredSSN",
          Validators.requireValidSSN("Insured SSN")
        );
    }
    if (
      this.state.selectedSample?.insuredMRN &&
      this.state.selectedSample?.insuredMRN.trim().length > 0
    ) {
      validator = validator.withValidation(
        "InsuredMRN",
        Validators.requireNotBlankAndLength(45, "Insured MRN")
      );
    }
    if (
      this.state.selectedSample?.insuredAddressLine1 &&
      this.state.selectedSample?.insuredAddressLine1.trim().length > 0
    ) {
      validator = validator.withValidation(
        "InsuredAddressLine1",
        Validators.requireNotBlankAndLength(100, "Insured Address")
      );
    }
    if (
      this.state.selectedSample?.insuredCity &&
      this.state.selectedSample?.insuredCity.trim().length > 0
    ) {
      validator = validator.withValidation(
        "InsuredCity",
        Validators.requireNotBlankAndLength(100, "Insured City")
      );
    }
    if (this.state.selectedSample?.insuredState) {
      validator = validator.withSimpleValidation(
        "InsuredState",
        Validators.requireNonNullValidator("Insured State")
      );
    }
    if (
      this.state.selectedSample?.insuredZip &&
      this.state.selectedSample?.insuredZip.trim().length > 0
    ) {
      validator = validator
        .withValidation(
          "InsuredZip",
          Validators.requireNotBlankAndLength(100, "Insured Zip")
        )
        .withSimpleValidation(
          "InsuredZip",
          Validators.requireZip("Insured Zip")
        );
    }
    if (this.state.selectedSample?.insuredDOB) {
      validator = validator.withValidation(
        "InsuredDOB",
        Validators.requireDOB(150, "Insured DOB")
      );
    }

    let validationResponse = validator.validate(this.getEdittedObj());
    if (!validationResponse.success) {
      return sweetalert.fire({
        icon: "error",
        title: "",
        text: validationResponse.error,
      });
    }

    let serviceQuestions = this.props.customQuestions.filter(
      (f) =>
        f.ServiceID &&
        f.ServiceID === this.state.selectedSample?.serviceID &&
        f.Options.isActive &&
        !f.IsEmployeeField
    );
    let customFieldDataQuestions = this.props.customQuestions.filter(
      (f) => !f.ServiceID && f.Options.isActive && !f.IsEmployeeField
    );

    let invalid = false;

    if (serviceQuestions.length > 0) {
      invalid = CustomFieldBuilder.validateCustomFields(
        this.state.selectedSample?.CustomFieldData,
        serviceQuestions,
        this.getEdittedObj()
      );
    }

    if (invalid) {
      return;
    }

    if (customFieldDataQuestions.length > 0) {
      invalid = CustomFieldBuilder.validateCustomFields(
        this.state.selectedSample?.CustomFieldData,
        customFieldDataQuestions,
        this.getEdittedObj()
      );
    }

    if (invalid) {
      return;
    }

    this.setState({ submitClicked: true });
    this.editSample();
  }

  editSample() {
    let sampleInfo = {
      ID: this.state.selectedSample?.sampleID,
      employeePortalUID: this.state.selectedSample?.employeePortalUID
        ? this.state.selectedSample?.employeePortalUID.trim()
        : null,
      LinkedApptsID: this.state.selectedSample?.linkedApptsID
        ? this.state.selectedSample?.linkedApptsID.trim()
        : null,
      ReqNum: this.state.selectedSample?.reqNum
        ? this.state.selectedSample?.reqNum.trim()
        : null,
      SpecimenID: this.state.selectedSample?.specimenID
        ? this.state.selectedSample?.specimenID.trim()
        : null,
      InternalSpecimenID: this.state.selectedSample?.internalSpecimenID
        ? this.state.selectedSample?.internalSpecimenID.trim()
        : null,
      Memo: this.state.selectedSample?.memo
        ? this.state.selectedSample?.memo.trim()
        : null,
      EmployeeFirstName: this.state.selectedSample?.employeeFirstName
        ? this.state.selectedSample?.employeeFirstName.trim()
        : null,
      EmployeeMiddleName: this.state.selectedSample?.employeeMiddleName
        ? this.state.selectedSample?.employeeMiddleName.trim()
        : null,
      EmployeeLastName: this.state.selectedSample?.employeeLastName
        ? this.state.selectedSample?.employeeLastName.trim()
        : null,
      EmployeeDOB: getDateOrNull(this.state.selectedSample?.employeeDOB),
      EmployeeZip: this.state.selectedSample?.employeeZip
        ? this.state.selectedSample?.employeeZip.trim()
        : null,
      EmployeeCity: this.state.selectedSample?.employeeCity
        ? this.state.selectedSample?.employeeCity.trim()
        : null,
      EmployeeState: this.state.selectedSample?.stateLabel
        ? this.state.selectedSample?.stateLabel.value
        : null,
      EmployeeCounty: this.state.selectedSample?.employeeCounty
        ? this.state.selectedSample?.employeeCounty.trim()
        : null,
      EmployeeCountry: this.state.selectedSample?.countryLabel
        ? this.state.selectedSample?.countryLabel.value
        : null,
      EmployeeAddress: this.state.selectedSample?.employeeAddress
        ? this.state.selectedSample?.employeeAddress.trim()
        : null,
      EmployeeEmail: this.state.selectedSample?.employeeEmail
        ? this.state.selectedSample?.employeeEmail.trim()
        : null,
      EmployeePhone: this.state.selectedSample?.employeePhone
        ? this.state.selectedSample?.employeePhone.trim()
        : null,
      EmployeeEthnicityID: this.state.selectedSample?.employeeEthnicity
        ? this.state.selectedSample?.employeeEthnicity.value
        : null,
      EmployeeRaceID: this.state.selectedSample?.employeeRaceID
        ? this.state.selectedSample?.employeeRace.value
        : null,
      EmployeeGenderID: this.state.selectedSample?.employeeGender
        ? this.state.selectedSample?.employeeGender.value
        : null,
      GuardianFirstName: this.state.selectedSample?.guardianFirstName
        ? this.state.selectedSample?.guardianFirstName.trim()
        : null,
      GuardianLastName: this.state.selectedSample?.guardianLastName
        ? this.state.selectedSample?.guardianLastName.trim()
        : null,
      ProviderFirstName: this.state.selectedSample?.providerFirstName
        ? this.state.selectedSample?.providerFirstName.trim()
        : null,
      ProviderLastName: this.state.selectedSample?.providerLastName
        ? this.state.selectedSample?.providerLastName.trim()
        : null,
      SampleReceivedID: this.state.selectedSample?.binID
        ? this.state.selectedSample?.bin.value.ID
        : null,
      CollectionDate: getDateOrNull(this.state.selectedSample?.collectionDate),
      TestIDs: this.state.selectedSample?.testIDs
        ? this.state.selectedSample?.testIDs
        : null,
      ProcessedDate: getDateOrNull(this.state.selectedSample?.processedDate),
      TestOrderedDate: getDateOrNull(
        this.state.selectedSample?.testOrderedDate
      ),
      ApptTime: this.state.selectedSample?.apptTime
        ? moment(this.state.selectedSample?.apptTime, "hh:mm a").format(
            "hh:mm a"
          )
        : null,
      EventApptID: this.state.selectedSample?.eventLabel
        ? this.state.selectedSample?.eventLabel.value
        : null,
      ApptCanceled: this.state.selectedSample?.apptCanceledLabel
        ? this.state.selectedSample?.apptCanceledLabel.value
        : null,
      RerunCount: this.state.selectedSample?.rerunCount
        ? this.state.selectedSample?.rerunCount
        : null,
      RerunFlag: this.state.selectedSample?.rerunFlagLabel
        ? this.state.selectedSample?.rerunFlagLabel.value
        : null,
      ConsentFlag: this.state.selectedSample?.consentFlagLabel
        ? this.state.selectedSample?.consentFlagLabel.value
        : null,
      ResultIDs: this.state.selectedSample?.resultIDs
        ? this.state.selectedSample?.resultIDs
        : null,
      Reason: this.state.selectedSample?.reason
        ? this.state.selectedSample?.reason.trim()
        : null,
      TestResultDate: getDateOrNull(this.state.selectedSample?.testResultDate),
      ReportedDate: getDateOrNull(this.state.selectedSample?.reportedDate),
      CreatedDate: getDateOrNull(this.state.selectedSample?.createdDate),
      FacilityID: this.state.selectedSample?.facilityID
        ? this.state.selectedSample?.facilityName.value
        : null,
      ResultsAccessedDate: getDateOrNull(
        this.state.selectedSample?.resultsAccessedDate
      ),
      Lot: this.state.selectedSample?.lot
        ? this.state.selectedSample?.lot.trim()
        : null,
      CustomFieldData: this.state.selectedSample?.CustomFieldData
        ? this.state.selectedSample?.CustomFieldData
        : null,
      IncludeInHL7: this.state.selectedSample?.IncludeInHL7
        ? this.state.selectedSample.IncludeInHL7
        : null,
      TestsAndResultsMap: this.state.selectedSample?.testsAndResultsMap
        ? this.state.selectedSample?.testsAndResultsMap
        : null,
    };
    let insuranceInfo = {
      FirstName: this.state.selectedSample?.insuredFirstName
        ? this.state.selectedSample?.insuredFirstName.trim()
        : null,
      LastName: this.state.selectedSample?.insuredLastName
        ? this.state.selectedSample?.insuredLastName.trim()
        : null,
      SSN: this.state.selectedSample?.insuredSSN
        ? this.state.selectedSample?.insuredSSN.replace(/-/g, "").trim()
        : null,
      DOB: getDateOrNull(this.state.selectedSample?.insuredDOB),
      MRN: this.state.selectedSample?.insuredMRN
        ? this.state.selectedSample?.insuredMRN.trim()
        : null,
      AddressLine1: this.state.selectedSample?.insuredAddressLine1
        ? this.state.selectedSample?.insuredAddressLine1.trim()
        : null,
      AddressLine2: this.state.selectedSample?.insuredAddressLine2
        ? this.state.selectedSample?.insuredAddressLine2.trim()
        : null,
      City: this.state.selectedSample?.insuredCity
        ? this.state.selectedSample?.insuredCity.trim()
        : null,
      State: this.state.selectedSample?.insuredStateLabel
        ? this.state.selectedSample?.insuredStateLabel.value
        : null,
      Zip: this.state.selectedSample?.insuredZip
        ? this.state.selectedSample?.insuredZip.trim()
        : null,
      FrontCard: this.state.selectedSample.insuredFrontCard
        ? this.state.selectedSample.insuredFrontCard
        : null,
      BackCard: this.state.selectedSample.insuredBackCard
        ? this.state.selectedSample.insuredBackCard
        : null,
    };
    let insuranceCards = this.state.frontCardImgFile
      ? [this.state.frontCardImgFile, this.state.backCardImgFile]
      : null;

    if (this.state.imageCached) {
      this.setState({ imageCached: false });
    }
    this.setState({ showLoading: true }, async () => {
      hideModal(EmployeeRecordModal.ID);
      try {
        let result = await SamplesAPI.updateSample(
          sampleInfo,
          insuranceInfo,
          insuranceCards
        );
        if (!result.success) {
          sweetalert.fire({ icon: "error", title: "", text: result.reason });
          this.setState({ submitClicked: false, showLoading: false });
        } else {
          sweetalert.fire({
            icon: "success",
            title: "",
            text: "Sample has been updated.",
          });
          this.setState({ submitClicked: false, showLoading: false });
          this.clearFiles();
          this.props.onSubmit();
        }
      } catch (e) {
        // console.log(e);
        sweetalert.fire({ icon: "error", title: "", text: "Server error" });
        this.setState({ submitClicked: false, showLoading: false });
      }
    });
  }

  physicianReview() {
    //sweet alert to confirm results review
    sweetalert
      .fire({
        title: "Are you sure you want to send results to employee?",
        showCloseButton: true,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Yes, send results.",
        denyButtonText: `No`,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          this.setState({ showLoadingForPhysicianReview: true });
          try {
            //send results to employee button
            let response = await SamplesAPI.resultsReviewedSendEmployeeEmail(
              this.state.selectedSample.sampleID
            );
            if (response.success) {
              this.setState({ showLoadingForPhysicianReview: false });
              hideModal(EmployeeRecordModal.ID);
              this.props.onSubmit();
              return sweetalert.fire({
                icon: "success",
                title: "",
                text: "Results sent to employee",
              });
            } else {
              this.setState({ showLoadingForPhysicianReview: false });
              return sweetalert.fire({
                icon: "error",
                title: "",
                text: "Could not send results at this time",
              });
            }
          } catch (error) {
            this.setState({ showLoadingForPhysicianReview: false });
            return sweetalert.fire({
              icon: "error",
              title: "",
              text: "Could not send results at this time",
            });
          }
        } else {
          return;
        }
      });
  }

  getPhysicianReviewButton() {
    if (this.state.selectedSample.resultsReviewed) {
      return (
        <>
          <AiOutlineCheckCircle
            className={"ml-2"}
            color={"#14410B"}
            size={30}
          />
          <span
            className={"ml-1 text-success"}
            style={{ fontSize: "1em", fontWeight: "bold" }}
          >
            {" "}
            Provider Review Complete{" "}
          </span>
        </>
      );
    } else if (
      this.props.getPhysicianReviewButtonBoolean &&
      !this.state.selectedSample.resultsReviewed &&
      this.state.selectedSample.resultApprovedDate
    ) {
      return (
        <button
          type="button"
          className={"btn btn-info text-white mr-3"}
          onClick={() => this.physicianReview()}
        >
          Send Results to Employee
        </button>
      );
    } else {
      return <div className="d-none"></div>;
    }
  }

  getLinks() {
    if (!this.props.selectedSample || !this.props.selectedSample.ReqNum) {
      return null;
    }
    return (
      <div
        className="card-footer mb-2 mr-2 ml-0"
        style={{ backgroundColor: "transparent", borderTop: "none" }}
      >
        <a
          className={this.props.getResultsButtonBoolean ? "" : "d-none"}
          href={
            this.props.selectedSample
              ? "/results?fname=" +
                this.props.selectedSample.EmployeeFirstName.trim() +
                "&lname=" +
                this.props.selectedSample.EmployeeLastName.trim() +
                "&dob=" +
                this.props.selectedSample.EmployeeDOB +
                "&conf=" +
                this.props.selectedSample.ReqNum +
                "&norecord=true"
              : ""
          }
          target={"_blank"}
        >
          <button
            type="button"
            className={
              this.props.getResultsButtonBoolean
                ? "btn btn-success mr-3"
                : "d-none"
            }
          >
            {" "}
            View Results{" "}
          </button>
        </a>
        {this.getPhysicianReviewButton()}
        <button
          disabled={this.state.submitClicked}
          className={"btn btn-primary  "}
          style={{ float: "right" }}
          onClick={() => this.onSubmit()}
        >
          {" "}
          Update Sample{" "}
        </button>
      </div>
    );
  }

  getInsuranceCardImagesFromCloud() {
    if (this.state.imageCached) {
      this.setState({ showInBetween: true });
      showModalNoOutsideClick(SampleInsuranceCardModal.ID);
    } else {
      this.setState({ showLoadingForViewInsuranceCards: true }, async () => {
        try {
          let frontImage = await SamplesAPI.getInsuranceCardImagesFromCloud({
            url: this.state.selectedSample?.insuredFrontCard,
            side: "front",
            ReqNum: this.state.selectedSample?.reqNum,
          });
          if (typeof frontImage === "object") {
            sweetalert.fire({
              icon: "error",
              title: "",
              text: "Insurance Card Images not found",
            });
            this.setState({ showLoadingForViewInsuranceCards: false });
            return;
          } else {
            this.setState({ frontInsuranceCardImage: frontImage });
          }
          let backImage = await SamplesAPI.getInsuranceCardImagesFromCloud({
            url: this.state.selectedSample?.insuredBackCard,
            side: "back",
            ReqNum: this.state.selectedSample?.reqNum,
          });
          if (typeof backImage === "object") {
            sweetalert.fire({
              icon: "error",
              title: "",
              text: "Insurance Card Images not found",
            });
            this.setState({ showLoadingForViewInsuranceCards: false });
            return;
          } else {
            this.setState({
              backInsuranceCardImage: backImage,
              showInBetween: true,
              showLoadingForViewInsuranceCards: false,
              imageCached: true,
            });
            showModalNoOutsideClick(SampleInsuranceCardModal.ID);
          }
        } catch (e) {
          // console.log(e);
          sweetalert.fire({
            icon: "error",
            title: "",
            text: "Error getting Insurance Card Images",
          });
          this.setState({ showLoadingForViewInsuranceCards: false });
        }
      });
    }
  }

  sampleInsuranceCardUpload() {
    if (!isMobile) {
      sweetalert
        .fire({
          title: "Add New Insurance Card Images",
          showCloseButton: true,
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Upload",
          denyButtonText: `Capture`,
        })
        .then((result) => {
          this.setState({ showInBetween: true });
          if (result.isConfirmed) {
            showModalNoOutsideClick(InsuranceCardUploadModal.ID);
          } else if (result.isDenied) {
            this.setState({ captureClicked: true });
            showModalNoOutsideClick(InsuranceImageModal.ID);
          } else if (result.isDismissed) {
            this.setState({ showInBetween: false });
          }
        });
    } else {
      this.setState({ showInBetween: true });
      showModalNoOutsideClick(InsuranceCardUploadModal.ID);
    }
  }

  onUpload = (state, e) => {
    if (e.target) {
      this.setState({ [state]: e.target.files[0] } as any);
    } else {
      this.setState({ [state]: e } as any);
    }
  };

  clearFiles = () => {
    const frontUpload = document.querySelector("#FrontCardCapture") as any;
    frontUpload.value = "";
    const backUpload = document.querySelector("#BackCardCapture") as any;
    backUpload.value = "";
    this.setState({
      frontCardImgFile: null,
      backCardImgFile: null,
      imageSaved: false,
    });
  };

  addDashes(e, stateToSet) {
    const len = e.target.value.length;
    if (len === 3 || len === 6) {
      e.preventDefault();
      this.setState((prevState) => ({
        selectedSample: {
          ...prevState.selectedSample,
          [stateToSet]: `${e.target.value}-${e.data}`,
        },
      }));
    }
  }

  handleChange(e, stateToSet, stateLabelToSet = "", type = "input") {
    if (type === "select") {
      this.setState((prevState) => ({
        selectedSample: {
          ...prevState.selectedSample,
          [stateLabelToSet]: e,
          [stateToSet]: e.value,
        },
      }));
    } else {
      this.setState((prevState) => ({
        selectedSample: {
          ...prevState.selectedSample,
          [stateToSet]: e.target.value,
        },
      }));
    }
  }

  handleTestChange(e, type, st?) {
    if (type === "single") {
      this.setState((prevState) => ({
        selectedSample: {
          ...prevState.selectedSample,
          testIDs: [e.value],
          testsAndResultsMap: null,

          resultIDs: null,
        },
      }));
    } else {
      let testIDsArray = JSON.parse(
        JSON.stringify(this.state.selectedSample.testIDs)
      );
      let idToReplace = testIDsArray.indexOf(st.value);
      if (idToReplace !== -1) {
        testIDsArray.splice(idToReplace, 1, e.value);
      }
      let resultIDsArray = [];
      let testsAndRusultsMap = JSON.parse(
        JSON.stringify(this.state.selectedSample.testsAndResultsMap)
      );
      if (testsAndRusultsMap && testsAndRusultsMap.length > 0) {
        let idToReplaceForMap = testsAndRusultsMap.findIndex(
          (f) => f.TestID === st.value
        );
        if (idToReplaceForMap !== -1) {
          testsAndRusultsMap.splice(idToReplaceForMap, 1);
        }
        testsAndRusultsMap.map((t) => {
          return resultIDsArray.push(t.ResultID);
        });
      }
      let duplicates = testIDsArray.filter(
        (currentValue, currentIndex) =>
          testIDsArray.indexOf(currentValue) !== currentIndex
      );
      if (duplicates.length > 0) {
        return sweetalert.fire({
          icon: "error",
          title: "",
          text: "Test must not be duplicated.",
        });
      }
      this.setState((prevState) => ({
        selectedSample: {
          ...prevState.selectedSample,
          testIDs: testIDsArray,
          testsAndResultsMap: testsAndRusultsMap,

          resultIDs: resultIDsArray,
        },
      }));
    }
  }

  handleResultChange(e, type, st?, index?, selectedTestLabels?) {
    if (type === "single") {
      this.setState((prevState) => ({
        selectedSample: {
          ...prevState.selectedSample,
          resultIDs: [e.value],
          testsAndResultsMap: [
            {
              TestID: selectedTestLabels[0].value,
              ResultID: e.value,
              ResultValue:
                this.state.selectedSample &&
                this.state.selectedSample.testsAndResultsMap
                  ? this.state.selectedSample.testsAndResultsMap[0].ResultValue
                  : null,
            },
          ],
        },
      }));
    } else {
      let testsAndResultsMapCopy =
        this.state.selectedSample &&
        this.state.selectedSample.testsAndResultsMap
          ? JSON.parse(
              JSON.stringify(this.state.selectedSample.testsAndResultsMap)
            )
          : null;
      let resultIDsCopy =
        this.state.selectedSample && this.state.selectedSample.resultIDs
          ? JSON.parse(JSON.stringify(this.state.selectedSample.resultIDs))
          : null;
      let resultIDToBeChanged;
      let indexOfTestBeingChanged = -1;
      if (!Array.isArray(testsAndResultsMapCopy)) {
        testsAndResultsMapCopy = [];
      }
      if (!Array.isArray(resultIDsCopy)) {
        resultIDsCopy = [];
      }
      if (testsAndResultsMapCopy?.length > 0) {
        for (let i = 0; i < testsAndResultsMapCopy.length; i++) {
          if (testsAndResultsMapCopy[i].TestID === st.value) {
            indexOfTestBeingChanged = i;
            resultIDToBeChanged = testsAndResultsMapCopy[i].ResultID;
            break;
          }
        }
        if (indexOfTestBeingChanged === -1) {
          testsAndResultsMapCopy.push({ TestID: st.value, ResultID: e.value });
          resultIDsCopy.push(e.value);
        } else {
          testsAndResultsMapCopy.splice(indexOfTestBeingChanged, 1, {
            TestID: st.value,
            ResultID: e.value,
            ResultValue:
              this.state.selectedSample &&
              this.state.selectedSample.testsAndResultsMap
                ? this.state.selectedSample.testsAndResultsMap[
                    indexOfTestBeingChanged
                  ].ResultValue
                : null,
          });
          let indexOfResultToBeChanged = -1;
          for (let i = 0; i < resultIDsCopy.length; i++) {
            if (resultIDsCopy[i] === resultIDToBeChanged) {
              indexOfResultToBeChanged = i;
              break;
            }
          }
          resultIDsCopy.splice(indexOfResultToBeChanged, 1, e.value);
        }
      } else {
        testsAndResultsMapCopy.push({ TestID: st.value, ResultID: e.value });
        resultIDsCopy.push(e.value);
      }
      this.setState((prevState) => ({
        selectedSample: {
          ...prevState.selectedSample,
          resultIDs: resultIDsCopy,
          testsAndResultsMap: testsAndResultsMapCopy,
        },
      }));
    }
  }

  handleResultValueChange(e, type, st?) {
    if (type === "single") {
      let testsAndResultsMapCopy = JSON.parse(
        JSON.stringify(this.state.selectedSample?.testsAndResultsMap)
      );
      testsAndResultsMapCopy.splice(
        0,
        1,
        e.target.value.length === 0
          ? (testsAndResultsMapCopy[0] = {
              TestID: testsAndResultsMapCopy[0].TestID,
              ResultID: testsAndResultsMapCopy[0].ResultID,
            })
          : { ...testsAndResultsMapCopy[0], ResultValue: e.target.value }
      );
      this.setState((prevState) => ({
        selectedSample: {
          ...prevState.selectedSample,
          testsAndResultsMap: testsAndResultsMapCopy,
        },
      }));
    } else {
      let testsAndResultsMapCopy = JSON.parse(
        JSON.stringify(this.state.selectedSample?.testsAndResultsMap)
      );
      let indexOfTestBeingChanged = -1;
      if (!Array.isArray(testsAndResultsMapCopy)) {
        testsAndResultsMapCopy = [];
      }
      if (testsAndResultsMapCopy?.length > 0) {
        for (let i = 0; i < testsAndResultsMapCopy.length; i++) {
          if (testsAndResultsMapCopy[i].TestID === st.value) {
            indexOfTestBeingChanged = i;
            break;
          }
        }
        if (indexOfTestBeingChanged === -1) {
          return;
        } else {
          testsAndResultsMapCopy.splice(
            indexOfTestBeingChanged,
            1,
            e.target.value.length === 0
              ? (testsAndResultsMapCopy[indexOfTestBeingChanged] = {
                  TestID:
                    testsAndResultsMapCopy[indexOfTestBeingChanged].TestID,
                  ResultID:
                    testsAndResultsMapCopy[indexOfTestBeingChanged].ResultID,
                })
              : {
                  ...testsAndResultsMapCopy[indexOfTestBeingChanged],
                  ResultValue: e.target.value,
                }
          );
        }
      }
      this.setState((prevState) => ({
        selectedSample: {
          ...prevState.selectedSample,
          testsAndResultsMap: testsAndResultsMapCopy,
        },
      }));
    }
  }

  handleDateOnChange(value, state) {
    if (value) {
      this.setState((prevState) => ({
        selectedSample: {
          ...prevState.selectedSample,
          [state]: value,
        },
      }));
    } else {
      this.setState((prevState) => ({
        selectedSample: {
          ...prevState.selectedSample,
          [state]: null,
        },
      }));
    }
  }

  disableCollectionRelevantFields(field) {
    if (!this.props.selectedSample[field] && !this.props.isITG) {
      return true;
    }
    return false;
  }

  getResultOptions(testID) {
    //go through testDetails for this test and display valid test results options
    let resultOptions = [];
    if (this.props.testDetails?.length > 0) {
      let temp = this.props.testDetails.find((td) => td.ID === testID);
      if (temp && temp.ValidResults?.length > 0) {
        for (let i = 0; i < temp.ValidResults.length; i++) {
          resultOptions.push({
            label: temp.ValidResults[i].Value,
            value: temp.ValidResults[i].ID,
          });
        }
      }
    }
    return resultOptions;
  }

  getSelectedResultValue(testID) {
    //get resultID for selected test and display
    let testsAndResultsMap = this.state.selectedSample?.testsAndResultsMap
      ? JSON.parse(JSON.stringify(this.state.selectedSample.testsAndResultsMap))
      : [];
    let resultID;
    if (testsAndResultsMap && testsAndResultsMap.length > 0) {
      let temp = testsAndResultsMap.find((t) => t.TestID === testID);
      resultID = temp ? temp.ResultID : null;
    }
    if (resultID) {
      // let temp = this.props.testDetails.find((td) => td.ID === testID);
      // let selectedResult = temp.ValidResults.find((v) => v.ID === resultID);

      let selectedResult = this.props.result_types.find(
        (r) => r.value === resultID
      );

      return selectedResult
        ? { label: selectedResult.label, value: selectedResult.value }
        : null;
    } else {
      return null;
    }
  }

  getSelectedResultNonStandardValue(testID) {
    let testsAndResultsMap = this.state.selectedSample?.testsAndResultsMap
      ? JSON.parse(JSON.stringify(this.state.selectedSample.testsAndResultsMap))
      : [];
    let resultValue;
    if (testsAndResultsMap && testsAndResultsMap.length > 0) {
      let temp = testsAndResultsMap.find((t) => t.TestID === testID);
      resultValue = temp ? temp.ResultValue : null;
    }

    return resultValue;
  }

  resultHasNonStandardResult(testID) {
    let hasAdditionalResultValue = false;

    let resultsThatAllowNonStandard = this.props.result_types.filter(
      (r) => r.AllowNonStandardResultValue
    );
    resultsThatAllowNonStandard = resultsThatAllowNonStandard.map((r) => {
      return r.value;
    });

    let testsAndResultsMap = this.state.selectedSample?.testsAndResultsMap
      ? JSON.parse(JSON.stringify(this.state.selectedSample.testsAndResultsMap))
      : [];

    let temp = testsAndResultsMap.find((t) => t.TestID === testID);

    if (resultsThatAllowNonStandard.includes(temp.ResultID)) {
      hasAdditionalResultValue = true;
    }

    return hasAdditionalResultValue;
  }

  async handleAddNewTest(options) {
    const { value: newTest } = await sweetalert.fire({
      title: "Add Test",
      input: "select",
      inputOptions: options.map((t) => {
        return t.label;
      }),
      inputPlaceholder: "Select test",
      showCancelButton: true,
      confirmButtonText: "Add",
      inputValidator: (value) => {
        if (!value || isBlank(value) || isOnlyWhitespace(value)) {
          return "A test must be selected";
        }
      },
    });
    if (newTest) {
      let testIDsCopy = JSON.parse(
        JSON.stringify(this.state.selectedSample.testIDs)
      );
      if (Array.isArray(testIDsCopy)) {
        testIDsCopy.push(options[newTest].value);
      } else {
        testIDsCopy = [options[newTest].value];
      }

      let duplicates = testIDsCopy.filter(
        (currentValue, currentIndex) =>
          testIDsCopy.indexOf(currentValue) !== currentIndex
      );

      if (duplicates && duplicates.length > 0) {
        return sweetalert.fire({
          icon: "error",
          title: "",
          text: "Test must not be duplicated.",
        });
      }
      this.setState((prevState) => ({
        selectedSample: {
          ...prevState.selectedSample,
          testIDs: testIDsCopy,
        },
      }));
    }
  }

  removeTest(testID, isSingleTest = false) {
    sweetalert
      .fire({
        html: `<p style="margin-top: 1rem; padding-left: 1rem; padding-right: 1rem; font-weight: bold"> ${
          getLabel(testID, this.props.tests).label
        }</p><p> Confirm removal of this test?</p ><p><span style="font-weight: bold">NOTE: </span>Test will not be removed until sample is updated.</p>`,
        showCloseButton: false,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Remove Test",
        denyButtonText: "Cancel",
      })
      .then((result) => {
        if (result.isConfirmed) {
          if (!isSingleTest) {
            //remove testID from testIDs array and from testsAndResultsObj
            let testIDsCopy = JSON.parse(
              JSON.stringify(this.state.selectedSample.testIDs)
            );
            let filteredTestIDs = testIDsCopy.filter((t) => t !== testID);
            let testsAndResultsMapCopy = this.state.selectedSample
              .testsAndResultsMap
              ? JSON.parse(
                  JSON.stringify(this.state.selectedSample.testsAndResultsMap)
                )
              : null;
            let filteredTestsAndResultsMap = [];
            let resultIDsCopy = this.state.selectedSample.resultIDs
              ? JSON.parse(JSON.stringify(this.state.selectedSample.resultIDs))
              : null;
            if (filteredTestIDs.length < 1) {
              filteredTestIDs = null;
              testsAndResultsMapCopy = null;
              resultIDsCopy = null;
            } else if (
              testsAndResultsMapCopy &&
              testsAndResultsMapCopy.length > 0
            ) {
              testsAndResultsMapCopy.map((t) => {
                if (t.TestID === testID) {
                  let indexToRemove = resultIDsCopy.indexOf(t.ResultID);
                  if (indexToRemove !== -1) {
                    resultIDsCopy.splice(indexToRemove, 1);
                  }
                }
              });
              filteredTestsAndResultsMap = filteredTestsAndResultsMap.concat(
                testsAndResultsMapCopy.filter((t) => t.TestID !== testID)
              );
            }

            this.setState((prevState) => ({
              selectedSample: {
                ...prevState.selectedSample,
                testIDs: filteredTestIDs,
                testsAndResultsMap:
                  filteredTestsAndResultsMap.length > 0
                    ? filteredTestsAndResultsMap
                    : testsAndResultsMapCopy,
                resultIDs: resultIDsCopy,
              },
            }));
          }
          //isSingleTest is true
          else {
            this.setState((prevState) => ({
              selectedSample: {
                ...prevState.selectedSample,
                testIDs: null,
                resultIDs: null,
                testsAndResultsMap: null,
              },
            }));
          }
        } else {
          return;
        }
      });
  }

  render():
    | React.ReactElement
    | string
    | number
    | {}
    | React.ReactNodeArray
    | React.ReactPortal
    | boolean
    | null
    | undefined {
    // console.log('EmployeeRecordModal props', this.props)
    // console.log('EmployeeRecordModal state', this.state)

    let events = [];
    if (this.props.events) {
      this.props.events?.map((p) => {
        return p.options.map((o) => {
          return events.push({ label: o.label, value: o.value.ID });
        });
      });
    }

    let serviceQuestions = this.props.customQuestions
      ? this.props.customQuestions.filter(
          (f) =>
            f.ServiceID &&
            f.ServiceID === this.state.selectedSample.serviceID &&
            f.Options.isActive &&
            !f.IsEmployeeField
        )
      : [];
    let customFieldDataQuestions = this.props.customFields
      ? this.props.customFields
      : [];
    let customEmployeeFields = this.props.customEmployeeFields
      ? this.props.customEmployeeFields
      : [];

    //get tests based on service
    let service = this.state.selectedSample?.serviceID
      ? this.props.services.find(
          (s) => s.ID === this.state.selectedSample.serviceID
        )
      : null;

    //check if service allows multiple tests
    let multiTestAllow = false;
    if (service) {
      multiTestAllow = service.AllowMultipleTests ? true : false;
    }

    //get associated tests for servcie
    let serviceAssociatedTests = [];
    let serviceAssociatedTestsLabels = [];
    if (service?.Tests) {
      for (let i = 0; i < service.Tests.length; i++) {
        for (let j = 0; j < this.props.testDetails.length; j++) {
          if (service.Tests[i] === this.props.testDetails[j].ID) {
            serviceAssociatedTests.push(this.props.testDetails[j]);
            serviceAssociatedTestsLabels.push({
              label: this.props.testDetails[j].Name,
              value: this.props.testDetails[j].ID,
            });
          }
        }
      }
    }

    let selectedTestLabels = [];
    let selectedTests = [];
    if (
      this.state.selectedSample?.testIDs?.length > 0 &&
      this.props.testDetails.length > 0
    ) {
      if (multiTestAllow) {
        for (let i = 0; i < this.props.testDetails.length; i++) {
          for (let j = 0; j < this.state.selectedSample.testIDs.length; j++) {
            if (
              this.props.testDetails[i].ID ===
              this.state.selectedSample.testIDs[j]
            ) {
              selectedTestLabels.push({
                label: this.props.testDetails[i].Name,
                value: this.props.testDetails[i].ID,
              });
              selectedTests.push(this.props.testDetails[i]);
            }
          }
        }
      } else {
        let temp = this.props.testDetails.filter(
          (t) => t.ID === this.state.selectedSample.testIDs[0]
        );
        selectedTestLabels.push({ label: temp[0].Name, value: temp[0].ID });
        selectedTests = selectedTests.concat(temp);
      }
    }

    return (
      <React.Fragment>
        <SampleInsuranceCardModal
          handleLoading={() =>
            this.setState({
              showInBetween: false,
              showLoadingForViewInsuranceCards: false,
            })
          }
          closeInBetween={() => this.setState({ showInBetween: false })}
          frontImage={this.state.frontInsuranceCardImage}
          backImage={this.state.backInsuranceCardImage}
          reqNum={this.state.selectedSample?.reqNum}
        />
        <InsuranceCardUploadModal
          frontUpload={(e) => this.onUpload("frontCardImgFile", e)}
          backUpload={(e) => this.onUpload("backCardImgFile", e)}
          clearFiles={() => this.clearFiles()}
          handleLoading={() => this.setState({ showInBetween: false })}
          closeInBetween={() => this.setState({ showInBetween: false })}
          handleImageSavedButton={(e) => this.setState({ imageSaved: e })}
          isDupe={false}
          clearImageState={() =>
            this.setState({ frontCardImgFile: null, backCardImgFile: null })
          }
        />
        <InsuranceImageModal
          onFrontImgChange={(e) => this.onUpload("frontCardImgFile", e)}
          onBackImgChange={(e) => this.onUpload("backCardImgFile", e)}
          clearFiles={() => this.clearFiles()}
          handleLoading={() => this.setState({ showInBetween: false })}
          closeInBetween={() => this.setState({ showInBetween: false })}
          handleImageSavedButton={(e) => this.setState({ imageSaved: e })}
          captureClicked={this.state.captureClicked}
          clearImageState={() =>
            this.setState({ frontCardImgFile: null, backCardImgFile: null })
          }
        />
        <Overlay show_loading={this.state.showLoading} />
        <Overlay
          show_loading={
            this.state.showLoadingForViewInsuranceCards ||
            this.state.showLoadingForPhysicianReview
          }
          zIndex={100005}
        />
        <InBetweenOverlay showInBetween={this.state.showInBetween} />
        <div
          className="modal fade form_modal"
          id={EmployeeRecordModal.ID}
          tabIndex={-1}
          role="dialog"
          data-backdrop="static"
          data-keyboard="false"
          aria-label="Edit Sample"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-body p-0 m-0">
                <div className="container-fluid">
                  <div className={"row"}>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-12 pt-2">
                      <div className="card mb-2">
                        <div className="card-header verlag-bold">
                          <h4>
                            View Sample
                            <button
                              style={{ outline: "none" }}
                              type="button"
                              className="close mr-4"
                              data-dismiss="modal"
                              aria-label="Close"
                              onClick={() =>
                                this.setState({ imageCached: false })
                              }
                            >
                              <span
                                aria-hidden="true"
                                style={{
                                  fontSize: "1.5em",
                                  fontWeight: "bold",
                                }}
                              >
                                {" "}
                                &times;{" "}
                              </span>
                            </button>
                          </h4>
                        </div>

                        <div className="card-body">
                          <div className={"row"}>
                            <div className="col-xl-12 pt-2">
                              <div className="card mb-2">
                                {/*Employee*/}
                                <button
                                  className="card-header verlag-bold accordionButton w-100"
                                  data-toggle="collapse"
                                  data-target="#employeeXLCollapse"
                                  onClick={() =>
                                    this.setState({
                                      employeeArrowDown:
                                        !this.state.employeeArrowDown,
                                    })
                                  }
                                  aria-expanded="false"
                                  aria-controls="employeeXLCollapse"
                                >
                                  <div className="row justify-content-between px-3">
                                    <div className="">
                                      <h4>Employee</h4>
                                    </div>
                                    <div className="col-2">
                                      {this.state.employeeArrowDown ? (
                                        <AiOutlineUp
                                          className={"float-right"}
                                          size={30}
                                          data-toggle="collapse"
                                          data-target="#employeeXLCollapse"
                                          role="button"
                                          aria-expanded="false"
                                          aria-controls="employeeXLCollapse"
                                        />
                                      ) : (
                                        <AiOutlineDown
                                          className={"float-right"}
                                          size={30}
                                          data-toggle="collapse"
                                          data-target="#employeeXLCollapse"
                                          role="button"
                                          aria-expanded="false"
                                          aria-controls="employeeXLCollapse"
                                        />
                                      )}
                                    </div>
                                  </div>
                                </button>
                                <div
                                  className="card-body collapse"
                                  id={"employeeXLCollapse"}
                                >
                                  <div className="row">
                                    <div className="col-12 col-xl-6">
                                      <CustomInput
                                        fromFilterPage={false}
                                        readOnly={true}
                                        value={
                                          this.state.selectedSample
                                            ?.employeePortalUID
                                        }
                                        label={"Employee UID"}
                                        tooltip={
                                          "A employee's unique code tied to their employee profile"
                                        }
                                      />
                                      <CustomInput
                                        fromFilterPage={false}
                                        readOnly={true}
                                        required={true}
                                        value={
                                          this.state.selectedSample
                                            ?.employeeFirstName
                                        }
                                        label={"First Name"}
                                        tooltip={
                                          "The first name of the Employee"
                                        }
                                        handleChange={(e) =>
                                          this.handleChange(
                                            e,
                                            "employeeFirstName"
                                          )
                                        }
                                      />
                                      <CustomInput
                                        fromFilterPage={false}
                                        readOnly={true}
                                        value={
                                          this.state.selectedSample
                                            ?.employeeMiddleName
                                        }
                                        label={"Middle Name"}
                                        tooltip={
                                          "The middle name of the Employee"
                                        }
                                        handleChange={(e) =>
                                          this.handleChange(
                                            e,
                                            "employeeMiddleName"
                                          )
                                        }
                                      />
                                      <CustomInput
                                        fromFilterPage={false}
                                        readOnly={true}
                                        required={true}
                                        value={
                                          this.state.selectedSample
                                            ?.employeeLastName
                                        }
                                        label={"Last Name"}
                                        tooltip={
                                          "The last name of the Employee"
                                        }
                                        handleChange={(e) =>
                                          this.handleChange(
                                            e,
                                            "employeeLastName"
                                          )
                                        }
                                      />
                                      <CustomDate
                                        fromFilterPage={false}
                                        readOnly={true}
                                        value={
                                          this.state.selectedSample?.employeeDOB
                                        }
                                        label={"Date of Birth"}
                                        tooltip={`The date of birth of the Employee`}
                                        showDateWarning={true}
                                        handleDateOnChange={(e) =>
                                          this.handleDateOnChange(
                                            e,
                                            "employeeDOB"
                                          )
                                        }
                                      />
                                      <CustomInput
                                        fromFilterPage={false}
                                        readOnly={true}
                                        required={true}
                                        value={
                                          this.state.selectedSample
                                            ?.employeeEmail
                                        }
                                        label={"Email"}
                                        tooltip={"The email of the Employee"}
                                        maxLength={100}
                                        handleChange={(e) =>
                                          this.handleChange(e, "employeeEmail")
                                        }
                                      />
                                      <CustomSelect
                                        fromFilterPage={false}
                                        disabled={true}
                                        value={
                                          this.state.selectedSample
                                            ?.employeeEthnicity
                                        }
                                        label={"Ethnicity"}
                                        tooltip={
                                          "The ethnicity of the Employee"
                                        }
                                        handleChange={(e) =>
                                          this.handleChange(
                                            e,
                                            "employeeEthnicityID",
                                            "employeeEthnicity",
                                            "select"
                                          )
                                        }
                                        options={this.props.ethnicity}
                                      />
                                      <CustomSelect
                                        fromFilterPage={false}
                                        disabled={true}
                                        value={
                                          this.state.selectedSample
                                            ?.employeeRace
                                        }
                                        label={"Race"}
                                        tooltip={"The race of the Employee"}
                                        handleChange={(e) =>
                                          this.handleChange(
                                            e,
                                            "employeeRaceID",
                                            "employeeRace",
                                            "select"
                                          )
                                        }
                                        options={this.props.race}
                                      />
                                      <CustomSelect
                                        fromFilterPage={false}
                                        disabled={true}
                                        value={
                                          this.state.selectedSample
                                            ?.employeeGender
                                        }
                                        label={"Gender"}
                                        tooltip={"The gender of the Employee"}
                                        handleChange={(e) =>
                                          this.handleChange(
                                            e,
                                            "employeeGenderID",
                                            "employeeGender",
                                            "select"
                                          )
                                        }
                                        options={this.props.gender}
                                      />
                                    </div>
                                    <div className="col-12 col-xl-6">
                                      <CustomPhone
                                        fromFilterPage={false}
                                        readOnly={true}
                                        value={
                                          this.state.selectedSample
                                            ?.employeePhone
                                        }
                                        label={"Phone"}
                                        tooltip={`The phone number of the Patien`}
                                        handleDateOnChange={(e) =>
                                          this.handleDateOnChange(
                                            e,
                                            "employeePhone"
                                          )
                                        }
                                      />
                                      <CustomInput
                                        fromFilterPage={false}
                                        readOnly={true}
                                        required={true}
                                        value={
                                          this.state.selectedSample
                                            ?.employeeAddress
                                        }
                                        label={"Address"}
                                        tooltip={"The address of the Employee"}
                                        maxLength={500}
                                        handleChange={(e) =>
                                          this.handleChange(
                                            e,
                                            "employeeAddress"
                                          )
                                        }
                                      />
                                      <CustomInput
                                        fromFilterPage={false}
                                        readOnly={true}
                                        required={true}
                                        value={
                                          this.state.selectedSample
                                            ?.employeeCity
                                        }
                                        label={"City"}
                                        tooltip={"The city of the Employee"}
                                        maxLength={100}
                                        handleChange={(e) =>
                                          this.handleChange(e, "employeeCity")
                                        }
                                      />
                                      <CustomSelect
                                        fromFilterPage={false}
                                        disabled={true}
                                        value={
                                          this.state.selectedSample?.stateLabel
                                        }
                                        label={"State"}
                                        tooltip={
                                          "The state of the Employee's address"
                                        }
                                        handleChange={(e) =>
                                          this.handleChange(
                                            e,
                                            "employeeState",
                                            "stateLabel",
                                            "select"
                                          )
                                        }
                                        options={this.props.states}
                                      />
                                      <CustomInput
                                        fromFilterPage={false}
                                        readOnly={true}
                                        value={
                                          this.state.selectedSample?.employeeZip
                                        }
                                        label={"Zip"}
                                        tooltip={"The zip of the Employee"}
                                        handleChange={(e) =>
                                          this.handleChange(e, "employeeZip")
                                        }
                                      />
                                      <CustomInput
                                        fromFilterPage={false}
                                        readOnly={true}
                                        value={
                                          this.state.selectedSample
                                            ?.employeeCounty
                                        }
                                        label={"County"}
                                        tooltip={"The county of the Employee"}
                                        maxLength={100}
                                        handleChange={(e) =>
                                          this.handleChange(e, "employeeCounty")
                                        }
                                      />
                                      <CustomSelect
                                        fromFilterPage={false}
                                        disabled={true}
                                        value={
                                          this.state.selectedSample
                                            ?.countryLabel
                                        }
                                        label={"Country"}
                                        tooltip={
                                          "The country of the Employee's address"
                                        }
                                        handleChange={(e) =>
                                          this.handleChange(
                                            e,
                                            "employeeCountry",
                                            "countryLabel",
                                            "select"
                                          )
                                        }
                                        options={this.props.countries}
                                      />
                                      <CustomInput
                                        fromFilterPage={false}
                                        readOnly={true}
                                        value={
                                          this.state.selectedSample
                                            ?.guardianFirstName
                                        }
                                        label={"Guardian First Name"}
                                        tooltip={
                                          "The first name of the guardian if the employee is under 18"
                                        }
                                        handleChange={(e) =>
                                          this.handleChange(
                                            e,
                                            "guardianFirstName"
                                          )
                                        }
                                      />
                                      <CustomInput
                                        fromFilterPage={false}
                                        readOnly={true}
                                        value={
                                          this.state.selectedSample
                                            ?.guardianLastName
                                        }
                                        label={"Guardian Last Name"}
                                        tooltip={
                                          "The last name of the guardian if the employee is under 18"
                                        }
                                        handleChange={(e) =>
                                          this.handleChange(
                                            e,
                                            "guardianLastName"
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                  {/* <div className="row">
                                      {customEmployeeFields.map((val, index) => {
                                        if (val.Options.isActive) {
                                          if (index % 2 === 0) {
                                            return (
                                              <div className="col-12 col-xl-6">
                                                <div className="form-group row">
                                                  <GetField fromSamples={true} val={val} selectedItem={ this.state.selectedSample? this.state.selectedSample : {} } handleChange={(e) => { this.setState({ selectedSample: e, }); }} />
                                                </div>
                                              </div>
                                            );
                                          } else if (index % 2 === 1) {
                                            return (
                                              <div className="col-12 col-xl-6">
                                                <div className="form-group row">
                                                  <GetField fromSamples={true} val={val} selectedItem={ this.state.selectedSample? this.state .selectedSample : {} } handleChange={(e) => { this.setState({ selectedSample: e, }); }} />
                                                </div>
                                              </div>
                                            );
                                          }
                                        }
                                      })}
                                  </div> */}
                                </div>
                              </div>

                              {/* Appointment */}
                              <div className="card mb-2">
                                <button
                                  className="card-header verlag-bold accordionButton w-100"
                                  data-toggle="collapse"
                                  data-target="#appointmentXLCollapse"
                                  onClick={() =>
                                    this.setState({
                                      appointmentArrowDown:
                                        !this.state.appointmentArrowDown,
                                    })
                                  }
                                  aria-expanded="false"
                                  aria-controls="appointmentXLCollapse"
                                >
                                  <div className="row justify-content-between px-3">
                                    <div className="">
                                      <h4>Appointment</h4>
                                    </div>
                                    <div className="col-2">
                                      {this.state.appointmentArrowDown ? (
                                        <AiOutlineUp
                                          className={"float-right"}
                                          size={30}
                                          data-toggle="collapse"
                                          data-target="#appointmentXLCollapse"
                                          role="button"
                                          aria-expanded="false"
                                          aria-controls="appointmentXLCollapse"
                                        />
                                      ) : (
                                        <AiOutlineDown
                                          className={"float-right"}
                                          size={30}
                                          data-toggle="collapse"
                                          data-target="#appointmentXLCollapse"
                                          role="button"
                                          aria-expanded="false"
                                          aria-controls="appointmentXLCollapse"
                                        />
                                      )}
                                    </div>
                                  </div>
                                </button>
                                <div
                                  className="card-body collapse"
                                  id={"appointmentXLCollapse"}
                                >
                                  <div className="row">
                                    <div className="col-12 col-xl-6">
                                      <CustomInput
                                        readOnly={true}
                                        fromFilterPage={false}
                                        value={
                                          this.state.selectedSample
                                            ?.linkedApptsID
                                        }
                                        label={"Appointment #"}
                                        tooltip={
                                          "A employee's unique code tied to their appointment or submission form submission"
                                        }
                                      />
                                      <CustomInput
                                        readOnly={true}
                                        fromFilterPage={false}
                                        value={
                                          this.state.selectedSample?.reqNum
                                        }
                                        label={"Confirmation #"}
                                        tooltip={
                                          "A employee's unique code tied to their sample"
                                        }
                                      />
                                    </div>
                                    <div className="col-12 col-xl-6">
                                      <CustomDate
                                        readOnly={true}
                                        fromFilterPage={false}
                                        value={
                                          this.state.selectedSample?.apptTime
                                        }
                                        label={"Appointment Time"}
                                        tooltip={`Time of appointment`}
                                        timeOnly={true}
                                        handleDateOnChange={(e) =>
                                          this.handleDateOnChange(e, "apptTime")
                                        }
                                      />
                                      <CustomSelect
                                        disabled={true}
                                        fromFilterPage={false}
                                        value={
                                          this.state.selectedSample
                                            ?.apptCanceledLabel
                                        }
                                        label={"Canceled"}
                                        tooltip={`Appointment status. "Yes" returns canceled appointments, "No" returns active appointments.`}
                                        handleChange={(e) =>
                                          this.handleChange(
                                            e,
                                            "apptCanceled",
                                            "apptCanceledLabel",
                                            "select"
                                          )
                                        }
                                        options={getBoolOptionsWithNullValue()}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/*Sample*/}
                              <div className="card mb-2">
                                <button
                                  className="card-header verlag-bold accordionButton w-100"
                                  data-toggle="collapse"
                                  data-target="#sampleXLCollapse"
                                  onClick={() =>
                                    this.setState({
                                      sampleArrowDown:
                                        !this.state.sampleArrowDown,
                                    })
                                  }
                                  aria-expanded="false"
                                  aria-controls="sampleXLCollapse"
                                >
                                  <div className="row justify-content-between px-3">
                                    <div className="">
                                      <h4>Sample</h4>
                                    </div>
                                    <div className="col-2">
                                      {this.state.sampleArrowDown ? (
                                        <AiOutlineUp
                                          className={"float-right"}
                                          size={30}
                                          data-toggle="collapse"
                                          data-target="#sampleXLCollapse"
                                          role="button"
                                          aria-expanded="false"
                                          aria-controls="sampleXLCollapse"
                                        />
                                      ) : (
                                        <AiOutlineDown
                                          className={"float-right"}
                                          size={30}
                                          data-toggle="collapse"
                                          data-target="#sampleXLCollapse"
                                          role="button"
                                          aria-expanded="false"
                                          aria-controls="sampleXLCollapse"
                                        />
                                      )}
                                    </div>
                                  </div>
                                </button>
                                <div
                                  className="card-body collapse"
                                  id={"sampleXLCollapse"}
                                >
                                  <div className="row">
                                    <div className="col-12 col-xl-6">
                                      <CustomInput
                                        readOnly={true}
                                        fromFilterPage={false}
                                        value={
                                          this.state.selectedSample?.specimenID
                                        }
                                        label={"Specimen ID"}
                                        tooltip={
                                          "A unique identifier assigned to a sample"
                                        }
                                        handleChange={(e) =>
                                          this.handleChange(e, "specimenID")
                                        }
                                      />
                                      <CustomInput
                                        readOnly={true}
                                        fromFilterPage={false}
                                        value={
                                          this.state.selectedSample
                                            ?.internalSpecimenID
                                        }
                                        label={"Internal Specimen ID"}
                                        tooltip={
                                          "A unique secondary identifier assigned to a sample"
                                        }
                                        handleChange={(e) =>
                                          this.handleChange(
                                            e,
                                            "internalSpecimenID"
                                          )
                                        }
                                      />
                                      <CustomInput
                                        readOnly={true}
                                        fromFilterPage={false}
                                        value={
                                          this.state.selectedSample?.rerunCount
                                        }
                                        label={"Rerun count"}
                                        tooltip={
                                          "Number of times a sample has been reran"
                                        }
                                        handleChange={(e) =>
                                          this.handleChange(e, "rerunCount")
                                        }
                                      />
                                      <CustomSelect
                                        disabled={true}
                                        fromFilterPage={false}
                                        value={
                                          this.state.selectedSample
                                            ?.rerunFlagLabel
                                        }
                                        label={"Rerun flag"}
                                        tooltip={`Samples marked for rerun. "Yes" returns samples marked for rerun.`}
                                        handleChange={(e) =>
                                          this.handleChange(
                                            e,
                                            "rerunFlag",
                                            "rerunFlagLabel",
                                            "select"
                                          )
                                        }
                                        options={getBoolOptionsWithNullValue()}
                                      />
                                      <CustomInput
                                        readOnly={true}
                                        fromFilterPage={false}
                                        value={this.state.selectedSample?.lot}
                                        label={"Lot"}
                                        tooltip={
                                          "Test lot number (e.g., rapid test lot number.)"
                                        }
                                        handleChange={(e) =>
                                          this.handleChange(e, "lot")
                                        }
                                      />
                                      <CustomInput
                                        readOnly={true}
                                        fromFilterPage={false}
                                        value={
                                          this.state.selectedSample?.receivedBy
                                        }
                                        label={"Received by"}
                                        tooltip={
                                          "The name of who received samples at the lab"
                                        }
                                      />
                                      <CustomSelect
                                        disabled={true}
                                        fromFilterPage={false}
                                        value={
                                          this.state.selectedSample
                                            ?.IncludeInHL7Label
                                        }
                                        label={"Include in HL7"}
                                        tooltip={`Indicates whether sample will be included in HL7 or not`}
                                        handleChange={(e) =>
                                          this.handleChange(
                                            e,
                                            "IncludeInHL7",
                                            "IncludeInHL7Label",
                                            "select"
                                          )
                                        }
                                        options={getBoolSelectOptions()}
                                      />
                                    </div>
                                    <div className="col-12 col-xl-6">
                                      <CustomDate
                                        readOnly={true}
                                        fromFilterPage={false}
                                        hasTime={true}
                                        value={
                                          this.state.selectedSample
                                            ?.collectionDate
                                        }
                                        label={"Collection Date"}
                                        tooltip={`Date a test sample was collected`}
                                        handleDateOnChange={(e) =>
                                          this.handleDateOnChange(
                                            e,
                                            "collectionDate"
                                          )
                                        }
                                      />
                                      <CustomDate
                                        readOnly={true}
                                        fromFilterPage={false}
                                        hasTime={true}
                                        value={
                                          this.state.selectedSample
                                            ?.testOrderedDate
                                        }
                                        label={"Test Ordered Date"}
                                        tooltip={`Date the test was ordered`}
                                        handleDateOnChange={(e) =>
                                          this.handleDateOnChange(
                                            e,
                                            "testOrderedDate"
                                          )
                                        }
                                      />
                                      <CustomDate
                                        readOnly={true}
                                        fromFilterPage={false}
                                        hasTime={true}
                                        value={
                                          this.state.selectedSample?.droppedDate
                                        }
                                        label={"Received Date"}
                                        tooltip={`The date a sample was received at the lab`}
                                        handleDateOnChange={(e) =>
                                          this.handleDateOnChange(
                                            e,
                                            "droppedDate"
                                          )
                                        }
                                      />
                                      <CustomDate
                                        readOnly={true}
                                        fromFilterPage={false}
                                        hasTime={true}
                                        value={
                                          this.state.selectedSample
                                            ?.processedDate
                                        }
                                        label={"Processed Date"}
                                        tooltip={`Date of sample accession`}
                                        handleDateOnChange={(e) =>
                                          this.handleDateOnChange(
                                            e,
                                            "processedDate"
                                          )
                                        }
                                      />
                                      <CustomDate
                                        readOnly={true}
                                        fromFilterPage={false}
                                        hasTime={true}
                                        value={
                                          this.state.selectedSample
                                            ?.testResultDate
                                        }
                                        label={"Test Result Date"}
                                        tooltip={`Date a result was assigned to a test sample (note: results are not sent to the employee until they're approved`}
                                        handleDateOnChange={(e) =>
                                          this.handleDateOnChange(
                                            e,
                                            "testResultDate"
                                          )
                                        }
                                      />
                                      <CustomDate
                                        readOnly={true}
                                        fromFilterPage={false}
                                        hasTime={true}
                                        value={
                                          this.state.selectedSample
                                            ?.resultApprovedDate
                                        }
                                        label={"Result Approved Date"}
                                        tooltip={`The date a result is approved and sent to the employee`}
                                        handleDateOnChange={(e) =>
                                          this.handleDateOnChange(
                                            e,
                                            "resultApprovedDate"
                                          )
                                        }
                                      />
                                      <CustomDate
                                        readOnly={true}
                                        fromFilterPage={false}
                                        hasTime={true}
                                        value={
                                          this.state.selectedSample
                                            ?.reportedDate
                                        }
                                        label={"Reported Date"}
                                        tooltip={`The date a sample result is reported to SpringML`}
                                        handleDateOnChange={(e) =>
                                          this.handleDateOnChange(
                                            e,
                                            "reportedDate"
                                          )
                                        }
                                      />
                                      <CustomDate
                                        readOnly={true}
                                        fromFilterPage={false}
                                        hasTime={true}
                                        value={
                                          this.state.selectedSample
                                            ?.resultsAccessedDate
                                        }
                                        label={"Results Accessed Date"}
                                        tooltip={`The date a sample result is reported to SpringML`}
                                        handleDateOnChange={(e) =>
                                          this.handleDateOnChange(
                                            e,
                                            "resultsAccessedDate"
                                          )
                                        }
                                      />
                                      <CustomTextArea
                                        readOnly={true}
                                        value={this.state.selectedSample?.memo}
                                        label={"Memo"}
                                        tooltip={"General sample notes"}
                                        handleChange={(e) =>
                                          this.handleChange(e, "memo")
                                        }
                                        maxLength={1000}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* Tests  */}
                              <div className="card mb-2">
                                <button
                                  className="card-header verlag-bold accordionButton w-100"
                                  data-toggle="collapse"
                                  data-target="#testXLCollapse"
                                  onClick={() =>
                                    this.setState({
                                      testArrowDown: !this.state.testArrowDown,
                                    })
                                  }
                                  aria-expanded="false"
                                  aria-controls="testXLCollapse"
                                >
                                  <div className="row justify-content-between px-3">
                                    <div className="">
                                      <h4>Tests</h4>
                                    </div>
                                    <div className="col-2">
                                      {this.state.testArrowDown ? (
                                        <AiOutlineUp
                                          className={"float-right"}
                                          size={30}
                                          data-toggle="collapse"
                                          data-target="#testXLCollapse"
                                          role="button"
                                          aria-expanded="false"
                                          aria-controls="testXLCollapse"
                                        />
                                      ) : (
                                        <AiOutlineDown
                                          className={"float-right"}
                                          size={30}
                                          data-toggle="collapse"
                                          data-target="#testXLCollapse"
                                          role="button"
                                          aria-expanded="false"
                                          aria-controls="testXLCollapse"
                                        />
                                      )}
                                    </div>
                                  </div>
                                </button>
                                <div
                                  className="card-body collapse"
                                  id={"testXLCollapse"}
                                >
                                  <div className="row">
                                    <div className="col-12 col-xl-6">
                                      <CustomInput
                                        fromFilterPage={false}
                                        value={
                                          this.state.selectedSample?.serviceID
                                        }
                                        label={"Service"}
                                        tooltip={"Service signed up for"}
                                        readOnly={true}
                                        services={this.props.services}
                                      />
                                    </div>
                                  </div>

                                  {multiTestAllow ? (
                                    selectedTestLabels &&
                                    selectedTestLabels.map((st, index) => {
                                      return (
                                        <>
                                          <div className="row">
                                            <div className={"col-12 col-xl-6"}>
                                              <CustomSelect
                                                disabled={true}
                                                fromFilterPage={false}
                                                value={st}
                                                label={"Test"}
                                                tooltip={`The testing methods`}
                                                handleChange={(e) =>
                                                  this.handleTestChange(
                                                    e,
                                                    "multi",
                                                    st
                                                  )
                                                }
                                                options={
                                                  serviceAssociatedTestsLabels
                                                }
                                              />
                                            </div>
                                            <div
                                              className={
                                                "col-9 col-md-11 col-xl-5"
                                              }
                                            >
                                              <CustomSelect
                                                disabled={true}
                                                fromFilterPage={false}
                                                resultSelect={true}
                                                value={this.getSelectedResultValue(
                                                  st.value
                                                )}
                                                label={"Result"}
                                                tooltip={`The Result`}
                                                handleChange={(e) =>
                                                  this.handleResultChange(
                                                    e,
                                                    "multi",
                                                    st,
                                                    index
                                                  )
                                                }
                                                options={this.getResultOptions(
                                                  st.value
                                                )}
                                              />
                                            </div>

                                            <div className="col-12 col-xl-3">
                                              <CustomInput
                                                readOnly={true}
                                                id={`ResultValue${index}`}
                                                fromFilterPage={false}
                                                value={this.getSelectedResultNonStandardValue(
                                                  st.value
                                                )}
                                                label={"Value"}
                                                tooltip={
                                                  "Non-standard Result Value"
                                                }
                                                maxLength={100}
                                                handleChange={(e) =>
                                                  this.handleResultValueChange(
                                                    e,
                                                    "multi",
                                                    st
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                        </>
                                      );
                                    })
                                  ) : (
                                    //single test
                                    <div className="row">
                                      <div className={"col-12 col-xl-6"}>
                                        <CustomSelect
                                          disabled={true}
                                          fromFilterPage={false}
                                          value={selectedTestLabels}
                                          label={"Test"}
                                          tooltip={`The testing methods`}
                                          handleChange={(e) =>
                                            this.handleTestChange(e, "single")
                                          }
                                          options={serviceAssociatedTestsLabels}
                                        />
                                      </div>
                                      <div
                                        className={"col-9 col-md-11 col-xl-5"}
                                      >
                                        <CustomSelect
                                          disabled={true}
                                          fromFilterPage={false}
                                          value={
                                            selectedTestLabels?.length > 0
                                              ? this.getSelectedResultValue(
                                                  selectedTestLabels[0].value
                                                )
                                              : null
                                          }
                                          label={"Result"}
                                          tooltip={`The Result`}
                                          handleChange={(e) =>
                                            this.handleResultChange(
                                              e,
                                              "single",
                                              null,
                                              null,
                                              selectedTestLabels
                                            )
                                          }
                                          options={
                                            selectedTestLabels?.length > 0
                                              ? this.getResultOptions(
                                                  selectedTestLabels[0].value
                                                )
                                              : []
                                          }
                                        />
                                      </div>

                                      <div className="col-12 col-xl-3">
                                        <CustomInput
                                          readOnly={true}
                                          id={`ResultValueLabel`}
                                          fromFilterPage={false}
                                          value={
                                            selectedTestLabels?.length > 0 &&
                                            this.getSelectedResultNonStandardValue(
                                              selectedTestLabels[0].value
                                            )
                                              ? this.getSelectedResultNonStandardValue(
                                                  selectedTestLabels[0].value
                                                )
                                              : null
                                          }
                                          label={"Result Value"}
                                          tooltip={"Non-standard Result Value"}
                                          maxLength={100}
                                          handleChange={(e) =>
                                            this.handleResultValueChange(
                                              e,
                                              "single"
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  )}

                                  <div className="row">
                                    <div className="col-12 col-xl-6">
                                      <CustomInput
                                        readOnly={true}
                                        fromFilterPage={false}
                                        value={
                                          this.state.selectedSample?.reason
                                        }
                                        label={"Result Reason"}
                                        tooltip={
                                          "Used by lab personnel to make notes about a sample's status or results"
                                        }
                                        handleChange={(e) =>
                                          this.handleChange(e, "reason")
                                        }
                                      />
                                    </div>
                                    <div className="col-6 col-xl-4 text-danger text-small pt-1">
                                      *Specimen Source resets with Test change
                                    </div>
                                    <div className="col-6 col-xl-2 pr-1">
                                      <button
                                        className={
                                          multiTestAllow &&
                                          serviceAssociatedTests?.length >
                                            selectedTests?.length
                                            ? "btn btn-success float-right"
                                            : "d-none"
                                        }
                                        onClick={() =>
                                          this.handleAddNewTest(
                                            serviceAssociatedTestsLabels
                                          )
                                        }
                                      >
                                        {!this.state.selectedSample?.testIDs
                                          ? "Add Test"
                                          : "Add Additional Test"}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/*Facility*/}
                              <div className="card mb-2">
                                <button
                                  className="card-header verlag-bold accordionButton w-100"
                                  data-toggle="collapse"
                                  data-target="#facilityXLCollapse"
                                  onClick={() =>
                                    this.setState({
                                      facilityArrowDown:
                                        !this.state.facilityArrowDown,
                                    })
                                  }
                                  aria-expanded="false"
                                  aria-controls="facilityXLCollapse"
                                >
                                  <div className="row justify-content-between px-3">
                                    <div className="">
                                      <h4>Facility</h4>
                                    </div>
                                    <div className="col-2">
                                      {this.state.facilityArrowDown ? (
                                        <AiOutlineUp
                                          className={"float-right"}
                                          size={30}
                                          data-toggle="collapse"
                                          data-target="#facilityXLCollapse"
                                          role="button"
                                          aria-expanded="false"
                                          aria-controls="facilityXLCollapse"
                                        />
                                      ) : (
                                        <AiOutlineDown
                                          className={"float-right"}
                                          size={30}
                                          data-toggle="collapse"
                                          data-target="#facilityXLCollapse"
                                          role="button"
                                          aria-expanded="false"
                                          aria-controls="facilityXLCollapse"
                                        />
                                      )}
                                    </div>
                                  </div>
                                </button>
                                <div
                                  className="card-body collapse"
                                  id={"facilityXLCollapse"}
                                >
                                  <div className="row">
                                    <div className="col-12 col-xl-6">
                                      <CustomSelect
                                        disabled={true}
                                        fromFilterPage={false}
                                        value={
                                          this.state.selectedSample
                                            ?.facilityName
                                        }
                                        label={"Facility"}
                                        tooltip={`Testing locations by name`}
                                        handleChange={(e) =>
                                          this.handleChange(
                                            e,
                                            "facilityID",
                                            "facilityName",
                                            "select"
                                          )
                                        }
                                        options={this.props.facilities}
                                      />
                                      <CustomSelect
                                        disabled={true}
                                        fromFilterPage={false}
                                        value={
                                          this.state.selectedSample?.eventLabel
                                        }
                                        label={"Event"}
                                        tooltip={`Lists the Event ID, Facility Name, and Event Date`}
                                        handleChange={(e) =>
                                          this.handleChange(
                                            e,
                                            "eventApptID",
                                            "eventLabel",
                                            "select"
                                          )
                                        }
                                        options={events}
                                      />
                                    </div>
                                    <div className="col-12 col-xl-6">
                                      <CustomInput
                                        readOnly={true}
                                        fromFilterPage={false}
                                        value={
                                          this.state.selectedSample
                                            ?.providerFirstName
                                        }
                                        label={"Provider First Name"}
                                        tooltip={
                                          "The first name of the Provider that is assigned to a sample"
                                        }
                                        handleChange={(e) =>
                                          this.handleChange(
                                            e,
                                            "providerFirstName"
                                          )
                                        }
                                      />
                                      <CustomInput
                                        readOnly={true}
                                        fromFilterPage={false}
                                        value={
                                          this.state.selectedSample
                                            ?.providerLastName
                                        }
                                        label={"Provider Last Name"}
                                        tooltip={
                                          "The last name of the Provider that is assigned to a sample"
                                        }
                                        handleChange={(e) =>
                                          this.handleChange(
                                            e,
                                            "providerLastName"
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/*Custom Questionnaire Data*/}
                              {serviceQuestions &&
                              serviceQuestions.length > 0 ? (
                                <div className="card mb-2">
                                  <button
                                    className="card-header verlag-bold accordionButton w-100"
                                    data-toggle="collapse"
                                    data-target="#questionnaireDataXLCollapse"
                                    onClick={() =>
                                      this.setState({
                                        questionnaireDataArrowDown:
                                          !this.state
                                            .questionnaireDataArrowDown,
                                      })
                                    }
                                    aria-expanded="false"
                                    aria-controls="questionnaireDataXLCollapse"
                                  >
                                    <div className="row justify-content-between px-3">
                                      <div className="">
                                        <h4>Questionnaire Data</h4>
                                      </div>
                                      <div className="col-2">
                                        {this.state
                                          .questionnaireDataArrowDown ? (
                                          <AiOutlineUp
                                            className={"float-right"}
                                            size={30}
                                            data-toggle="collapse"
                                            data-target="#questionnaireDataXLCollapse"
                                            role="button"
                                            aria-expanded="false"
                                            aria-controls="questionnaireDataXLCollapse"
                                          />
                                        ) : (
                                          <AiOutlineDown
                                            className={"float-right"}
                                            size={30}
                                            data-toggle="collapse"
                                            data-target="#questionnaireDataXLCollapse"
                                            role="button"
                                            aria-expanded="false"
                                            aria-controls="questionnaireDataXLCollapse"
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </button>
                                  <div
                                    className="card-body collapse"
                                    id={"questionnaireDataXLCollapse"}
                                  >
                                    <div className="row">
                                      {serviceQuestions.map((val, index) => {
                                        if (val.Options.isActive) {
                                          if (index % 2 === 0) {
                                            return (
                                              <div className="col-12 col-xl-6">
                                                <div className="form-group row">
                                                  <GetField
                                                    employeeSearchReadOnly={
                                                      true
                                                    }
                                                    fromSamples={true}
                                                    val={val}
                                                    selectedItem={
                                                      this.state.selectedSample
                                                        ? this.state
                                                            .selectedSample
                                                        : {}
                                                    }
                                                    handleChange={(e) => {
                                                      this.setState({
                                                        selectedSample: e,
                                                      });
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            );
                                          } else if (index % 2 === 1) {
                                            return (
                                              <div className="col-12 col-xl-6">
                                                <div className="form-group row">
                                                  <GetField
                                                    employeeSearchReadOnly={
                                                      true
                                                    }
                                                    fromSamples={true}
                                                    val={val}
                                                    selectedItem={
                                                      this.state.selectedSample
                                                        ? this.state
                                                            .selectedSample
                                                        : {}
                                                    }
                                                    handleChange={(e) => {
                                                      this.setState({
                                                        selectedSample: e,
                                                      });
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            );
                                          }
                                        }
                                      })}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="invisible"></div>
                              )}

                              {/*Custom Field Data*/}
                              {customFieldDataQuestions &&
                              customFieldDataQuestions.length > 0 ? (
                                <div className="card mb-2">
                                  <button
                                    className="card-header verlag-bold accordionButton w-100"
                                    data-toggle="collapse"
                                    data-target="#customFieldDataXLCollapse"
                                    onClick={() =>
                                      this.setState({
                                        customFieldDataArrowDown:
                                          !this.state.customFieldDataArrowDown,
                                      })
                                    }
                                    aria-expanded="false"
                                    aria-controls="customFieldDataXLCollapse"
                                  >
                                    <div className="row justify-content-between px-3">
                                      <div className="">
                                        <h4>Custom Fields</h4>
                                      </div>
                                      <div className="col-2">
                                        {this.state.customFieldDataArrowDown ? (
                                          <AiOutlineUp
                                            className={"float-right"}
                                            size={30}
                                            data-toggle="collapse"
                                            data-target="#customFieldDataXLCollapse"
                                            role="button"
                                            aria-expanded="false"
                                            aria-controls="customFieldDataXLCollapse"
                                          />
                                        ) : (
                                          <AiOutlineDown
                                            className={"float-right"}
                                            size={30}
                                            data-toggle="collapse"
                                            data-target="#customFieldDataXLCollapse"
                                            role="button"
                                            aria-expanded="false"
                                            aria-controls="customFieldDataXLCollapse"
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </button>
                                  <div
                                    className="card-body collapse"
                                    id={"customFieldDataXLCollapse"}
                                  >
                                    <div className="row">
                                      {customFieldDataQuestions.map(
                                        (val, index) => {
                                          if (val.Options.isActive) {
                                            if (index % 2 === 0) {
                                              return (
                                                <div className="col-12 col-xl-6">
                                                  <div className="form-group row">
                                                    <GetField
                                                      employeeSearchReadOnly={
                                                        true
                                                      }
                                                      fromSamples={true}
                                                      val={val}
                                                      selectedItem={
                                                        this.state
                                                          .selectedSample
                                                          ? this.state
                                                              .selectedSample
                                                          : {}
                                                      }
                                                      handleChange={(e) => {
                                                        this.setState({
                                                          selectedSample: e,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              );
                                            } else if (index % 2 === 1) {
                                              return (
                                                <div className="col-12 col-xl-6">
                                                  <div className="form-group row">
                                                    <GetField
                                                      employeeSearchReadOnly={
                                                        true
                                                      }
                                                      fromSamples={true}
                                                      val={val}
                                                      selectedItem={
                                                        this.state
                                                          .selectedSample
                                                          ? this.state
                                                              .selectedSample
                                                          : {}
                                                      }
                                                      handleChange={(e) => {
                                                        this.setState({
                                                          selectedSample: e,
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              );
                                            }
                                          }
                                        }
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="invisible"></div>
                              )}

                              {/*Insurance */}
                              <div className="card mb-2">
                                <button
                                  className="card-header verlag-bold accordionButton w-100"
                                  data-toggle="collapse"
                                  data-target="#insuranceXLCollapse"
                                  onClick={() =>
                                    this.setState({
                                      insuranceArrowDown:
                                        !this.state.insuranceArrowDown,
                                    })
                                  }
                                  aria-expanded="false"
                                  aria-controls="insuranceXLCollapse"
                                >
                                  <div className="row justify-content-between px-3">
                                    <div className="">
                                      <h4>Insurance</h4>
                                    </div>
                                    <div className="col-2">
                                      {this.state.insuranceArrowDown ? (
                                        <AiOutlineUp
                                          className={"float-right"}
                                          size={30}
                                          data-toggle="collapse"
                                          data-target="#insuranceXLCollapse"
                                          role="button"
                                          aria-expanded="false"
                                          aria-controls="insuranceXLCollapse"
                                        />
                                      ) : (
                                        <AiOutlineDown
                                          className={"float-right"}
                                          size={30}
                                          data-toggle="collapse"
                                          data-target="#insuranceXLCollapse"
                                          role="button"
                                          aria-expanded="false"
                                          aria-controls="insuranceXLCollapse"
                                        />
                                      )}
                                    </div>
                                  </div>
                                </button>
                                <div
                                  className="card-body collapse"
                                  id={"insuranceXLCollapse"}
                                >
                                  {this.state.selectedSample?.rerunCount > 0 ? (
                                    <div>
                                      {" "}
                                      Please see original record to manage
                                      insurance information. Be sure to change
                                      Rerun Flag to 'No' or blank in the filter
                                      to find original record{" "}
                                    </div>
                                  ) : (
                                    <>
                                      <div className="row mb-4 pr-1">
                                        <div
                                          className={
                                            "col-12 text-center text-xl-left"
                                          }
                                        >
                                          <div className="row justify-content-between">
                                            <div className="col-12 col-xl-3 font-weight-bold">
                                              {" "}
                                              Insurance holder's information
                                            </div>
                                            <button
                                              className={
                                                this.state.selectedSample
                                                  ?.insuredFrontCard
                                                  ? "d-xl-none col-12 mt-2 btn btn-success btn-sm mb-2 w-60"
                                                  : "d-none"
                                              }
                                              onClick={() =>
                                                this.getInsuranceCardImagesFromCloud()
                                              }
                                            >
                                              {" "}
                                              View Insurance Card Images{" "}
                                            </button>
                                            <div
                                              className={
                                                this.state.imageSaved
                                                  ? "col-12 col-xl-2 pl-xl-0"
                                                  : "invisible"
                                              }
                                            >
                                              <AiOutlineCheckCircle
                                                className={"ml-2"}
                                                color={"#14410B"}
                                                size={30}
                                              />
                                              <span
                                                className={"ml-1 text-success"}
                                                style={{ fontSize: "1em" }}
                                              >
                                                {" "}
                                                Images Saved{" "}
                                              </span>
                                            </div>
                                            <button
                                              className={
                                                this.state.selectedSample
                                                  ?.insuredFrontCard
                                                  ? "d-none d-xl-inline col-xl-3 btn btn-success btn-sm"
                                                  : "d-none"
                                              }
                                              onClick={() =>
                                                this.getInsuranceCardImagesFromCloud()
                                              }
                                            >
                                              {" "}
                                              View Insurance Card Images{" "}
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-12 col-xl-6">
                                          <CustomInput
                                            readOnly={true}
                                            fromFilterPage={false}
                                            forInsurance={true}
                                            value={
                                              this.state.selectedSample
                                                ?.insuredFirstName
                                            }
                                            label={"First Name"}
                                            tooltip={
                                              "First name of insurance holder"
                                            }
                                            handleChange={(e) =>
                                              this.handleChange(
                                                e,
                                                "insuredFirstName"
                                              )
                                            }
                                          />
                                          <CustomInput
                                            readOnly={true}
                                            fromFilterPage={false}
                                            forInsurance={true}
                                            value={
                                              this.state.selectedSample
                                                ?.insuredLastName
                                            }
                                            label={"Last Name"}
                                            tooltip={
                                              "Last name of insurance holder"
                                            }
                                            handleChange={(e) =>
                                              this.handleChange(
                                                e,
                                                "insuredLastName"
                                              )
                                            }
                                          />
                                          <CustomInput
                                            readOnly={true}
                                            fromFilterPage={false}
                                            forInsurance={true}
                                            value={
                                              this.state.selectedSample
                                                ?.insuredSSN
                                            }
                                            label={"Social Security #"}
                                            tooltip={"SSN of insurance holder"}
                                            handleChange={(e) =>
                                              this.handleChange(e, "insuredSSN")
                                            }
                                            addDashes={(e) =>
                                              this.addDashes(e, "insuredSSN")
                                            }
                                          />
                                          <CustomDate
                                            readOnly={true}
                                            fromFilterPage={false}
                                            forInsurance={true}
                                            value={
                                              this.state.selectedSample
                                                ?.insuredDOB
                                            }
                                            label={"DOB"}
                                            tooltip={`The date of birth of the insurance holder`}
                                            showDateWarning={true}
                                            handleDateOnChange={(e) =>
                                              this.handleDateOnChange(
                                                e,
                                                "insuredDOB"
                                              )
                                            }
                                          />
                                          <CustomInput
                                            readOnly={true}
                                            fromFilterPage={false}
                                            forInsurance={true}
                                            value={
                                              this.state.selectedSample
                                                ?.insuredMRN
                                            }
                                            label={"Medical Record #"}
                                            tooltip={
                                              "Medical Record Number of insurance holder"
                                            }
                                            handleChange={(e) =>
                                              this.handleChange(e, "insuredMRN")
                                            }
                                          />
                                        </div>
                                        <div className="col-12 col-xl-6">
                                          <CustomInput
                                            readOnly={true}
                                            fromFilterPage={false}
                                            forInsurance={true}
                                            value={
                                              this.state.selectedSample
                                                ?.insuredAddressLine1
                                            }
                                            label={"Address"}
                                            tooltip={
                                              "Address of insurance holder"
                                            }
                                            handleChange={(e) =>
                                              this.handleChange(
                                                e,
                                                "insuredAddressLine1"
                                              )
                                            }
                                            maxLength={100}
                                          />
                                          <CustomInput
                                            readOnly={true}
                                            fromFilterPage={false}
                                            forInsurance={true}
                                            value={
                                              this.state.selectedSample
                                                ?.insuredAddressLine2
                                            }
                                            label={"Address Cont."}
                                            tooltip={
                                              "Address Cont. of insurance holder"
                                            }
                                            handleChange={(e) =>
                                              this.handleChange(
                                                e,
                                                "insuredAddressLine2"
                                              )
                                            }
                                            maxLength={100}
                                          />
                                          <CustomInput
                                            readOnly={true}
                                            fromFilterPage={false}
                                            forInsurance={true}
                                            value={
                                              this.state.selectedSample
                                                ?.insuredCity
                                            }
                                            label={"City"}
                                            tooltip={"City of insurance holder"}
                                            handleChange={(e) =>
                                              this.handleChange(
                                                e,
                                                "insuredCity"
                                              )
                                            }
                                          />
                                          <CustomSelect
                                            disabled={true}
                                            fromFilterPage={false}
                                            forInsurance={true}
                                            value={
                                              this.state.selectedSample
                                                ?.insuredStateLabel
                                            }
                                            label={"State"}
                                            tooltip={`State of insurance holder`}
                                            handleChange={(e) =>
                                              this.handleChange(
                                                e,
                                                "insuredState",
                                                "insuredStateLabel",
                                                "select"
                                              )
                                            }
                                            options={this.props.states}
                                          />
                                          <CustomInput
                                            readOnly={true}
                                            fromFilterPage={false}
                                            forInsurance={true}
                                            value={
                                              this.state.selectedSample
                                                ?.insuredZip
                                            }
                                            label={"Zip"}
                                            tooltip={
                                              "Zipcode of insurance holder"
                                            }
                                            handleChange={(e) =>
                                              this.handleChange(e, "insuredZip")
                                            }
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>

                              {/* Additional  */}
                              <div className="card mb-2">
                                <button
                                  className="card-header verlag-bold accordionButton w-100"
                                  data-toggle="collapse"
                                  data-target="#additionalXLCollapse"
                                  onClick={() =>
                                    this.setState({
                                      additionalArrowDown:
                                        !this.state.additionalArrowDown,
                                    })
                                  }
                                  aria-expanded="false"
                                  aria-controls="additionalXLCollapse"
                                >
                                  <div className="row justify-content-between px-3">
                                    <div className="">
                                      <h4>Additional</h4>
                                    </div>
                                    <div className="col-2">
                                      {this.state.additionalArrowDown ? (
                                        <AiOutlineUp
                                          className={"float-right"}
                                          size={30}
                                          data-toggle="collapse"
                                          data-target="#additionalXLCollapse"
                                          role="button"
                                          aria-expanded="false"
                                          aria-controls="additionalXLCollapse"
                                        />
                                      ) : (
                                        <AiOutlineDown
                                          className={"float-right"}
                                          size={30}
                                          data-toggle="collapse"
                                          data-target="#additionalXLCollapse"
                                          role="button"
                                          aria-expanded="false"
                                          aria-controls="additionalXLCollapse"
                                        />
                                      )}
                                    </div>
                                  </div>
                                </button>
                                <div
                                  className="card-body collapse"
                                  id={"additionalXLCollapse"}
                                >
                                  <div className="row">
                                    <div className="col-12 col-xl-6">
                                      <CustomInput
                                        fromFilterPage={false}
                                        value={
                                          this.state.selectedSample?.createdBy
                                        }
                                        label={"Created By"}
                                        tooltip={
                                          "The username of who generated a record (applies primarily to walk-ins)"
                                        }
                                        readOnly={true}
                                      />
                                      <CustomInput
                                        fromFilterPage={false}
                                        value={
                                          this.state.selectedSample
                                            ?.lastUpdatedBy
                                        }
                                        label={"Last Updated By"}
                                        tooltip={
                                          "The username of who last updated a record"
                                        }
                                        readOnly={true}
                                      />
                                      <CustomDate
                                        fromFilterPage={false}
                                        hasTime={true}
                                        value={
                                          this.state.selectedSample
                                            ?.lastUpdatedDate
                                        }
                                        label={"Last Updated Date"}
                                        tooltip={`The date the record was last updated`}
                                        handleDateOnChange={(e) =>
                                          this.handleDateOnChange(
                                            e,
                                            "lastUpdatedDate"
                                          )
                                        }
                                        readOnly={true}
                                      />
                                    </div>
                                    <div className="col-12 col-xl-6">
                                      <CustomSelect
                                        disabled={true}
                                        fromFilterPage={false}
                                        value={
                                          this.state.selectedSample
                                            ?.consentFlagLabel
                                        }
                                        label={"Consent Flag"}
                                        tooltip={`Did the employee give consent?`}
                                        handleChange={(e) =>
                                          this.handleChange(
                                            e,
                                            "consentFlag",
                                            "consentFlagLabel",
                                            "select"
                                          )
                                        }
                                        options={getBoolOptionsWithNullValue()}
                                      />
                                      <CustomDate
                                        fromFilterPage={false}
                                        hasTime={true}
                                        value={
                                          this.state.selectedSample?.createdDate
                                        }
                                        label={"Created Date"}
                                        tooltip={`The date the record was generated`}
                                        handleDateOnChange={(e) =>
                                          this.handleDateOnChange(
                                            e,
                                            "createdDate"
                                          )
                                        }
                                        readOnly={true}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
