export default interface Task {
    ID
    EmployeeID
    Title
    Description
    Priority
    DueDate
    ReminderDate
    Repeat
    Frequency
    NotifySupervisor
    OSHACaseNum 
    CompletedDate
}
export const FakeTasks = [
    {
        ID:1,
        EmployeeID: 1,
        Title: "Follow up on L&I Claim",
        Description: "Discuss updates with Supervisor",
        Priority: "High",
        DueDate: "5/2/2024 12:30:00 PM",
        ReminderDate: "5/1/2024 9:00:00 AM",
        Repeat: false,
        Frequency: null,
        NotifySupervisor: true,
        OSHACaseNum: '2332-6546',
        CompletedDate: null,
    },
    {
        ID:2,
        EmployeeID: 4, // FakeEmployee: David Brown 
        Title: "New Hire - Submission",
        Description: "Respriator Evaluation",
        Priority: "",
        DueDate: "5/15/2024 5:00:00 PM",
        ReminderDate: "5/14/2024 8:00:00 AM",
        Repeat: false,
        Frequency: null,
        NotifySupervisor: true,
        OSHACaseNum: null,
        CompletedDate:null,
    },
    {
        ID:3,
        EmployeeID: 4, // FakeEmployee: David Brown 
        Title: "Employee Physical",
        Description: "Meet with Physician for Physical",
        Priority: "High",
        DueDate: "4/24/2024 5:00:00 PM",
        ReminderDate: "4/1/2024 8:00:00 AM",
        Repeat: true,
        Frequency: "Yearly",
        NotifySupervisor: false,
        OSHACaseNum: null,
        CompletedDate: "4/21/2024 12:30:00 PM",
    }
]